import theme from 'shared/assets/style/theme';
import defaultTheme from './defaultTheme';

const windowWidth = window.innerWidth;
const windowHeight = window.innerHeight;

const StarTheme = {
  ...defaultTheme,
  LimitedTimeOffer: {
    ...defaultTheme.LimitedTimeOffer,
    LTOWrapper: {
      ...defaultTheme.LimitedTimeOffer.LTOWrapper,
      height: '82%',
    },
    BuyButton: {
      ...defaultTheme.LimitedTimeOffer.BuyButton,
      bottom: '11%',
      transform: 'translateX(2px)',
    },
    BuyButtonImageStyle: {
      ...defaultTheme.LimitedTimeOffer.BuyButtonImageStyle,
      width: windowHeight > 900 && windowWidth < 1300 ? '22vh' : '28vh',
    },
  },
  PurchaseFlow: {
    ...defaultTheme.PurchaseFlow,
    CreditCardDetails: {
      ...defaultTheme.PurchaseFlow.CreditCardDetails,
      display: 'none',
    },
  },
  VipAccessFlow: {
    ...defaultTheme.VipAccessFlow,
    VipAccessButtonText: {
      ...defaultTheme.VipAccessFlow.VipAccessButtonText,
      paddingBottom: 0,
    },
  },
  RewardListItem: {
    ...defaultTheme.RewardListItem,
    OfferNameText: {
      ...defaultTheme.RewardListItem.OfferNameText,
      paddingTop: 4,
    },
    OfferCost: {
      ...defaultTheme.RewardListItem.OfferCost,
      paddingRight: '4%',
    },
  },
  CongratulationsContainer: {
    ...defaultTheme.CongratulationsContainer,
    CongratsSpan: {
      ...defaultTheme.CongratulationsContainer.CongratsSpan,
      textShadow: `${theme.palette.primary[16]} 1px 1px 4px`,
    },
  },
  NavigationBar: {
    ...defaultTheme.NavigationBar,
    LoyaltyCurrencyWrapperStyle: {
      ...defaultTheme.NavigationBar.LoyaltyCurrencyWrapperStyle,
      right: 'calc(50% - 200px)',
    },
    LoyaltyCurrencyIconStyle: {
      ...defaultTheme.NavigationBar.LoyaltyCurrencyIconStyle,
      height: 55,
      width: 55,
    },
    BarLineAnimation: {
      ...defaultTheme.NavigationBar.BarLineAnimation,
      top: windowWidth < 1900 ? 9 : 10,
    },
  },
  DailySpin: {
    ...defaultTheme.DailySpin,
    WheelItems: {
      ...defaultTheme.DailySpin.WheelItems,
      fontSize: windowWidth < 1400 ? '1.8rem' : '2rem',
    },
    StreakDaysRowNumber: {
      ...defaultTheme.DailySpin.StreakDaysRowNumber,
      paddingRight: 1,
      paddingTop: 4,
      paddingBottom: 2,
    },
    StreakDaysRowValues: {
      ...defaultTheme.DailySpin.StreakDaysRowValues,
      lineHeight: 0,
    },
    MilestoneCoinsValue: {
      ...defaultTheme.DailySpin.MilestoneCoinsValue,
      marginBottom: 0,
    },
    MilestoneLoyaltyValue: {
      ...defaultTheme.DailySpin.MilestoneLoyaltyValue,
      marginBottom: -7,
    },
    MilestoneCircleValue: {
      ...defaultTheme.DailySpin.MilestoneCircleValue,
      marginTop: windowWidth < 1300 ? '1%' : windowWidth < 1500 ? '3%' : '1%',
    },
    BottomRightCoinImage: {
      ...defaultTheme.DailySpin.BottomRightCoinImage,
      marginBottom: 5,
    },
    DailyWheelCollectButton: {
      ...defaultTheme.DailySpin.DailyWheelCollectButton,
      paddingBottom: 0,
    },
  },
  Settings: {
    ...defaultTheme.Settings,
    TitleShadow: {
      ...defaultTheme.Settings.TitleShadow,
      display: 'none',
    },
    TitleMargin: {
      ...defaultTheme.Settings.TitleMargin,
      marginBottom: '30px',
    },
    HeaderImage: {
      ...defaultTheme.Settings.HeaderImage,
      height: '3.7rem',
      width: '100%',
      objectFit: 'contain',
      zIndex: 11,
      position: 'absolute',
      top: 0,
      left: 0,
    },
    HeaderContent: {
      ...defaultTheme.Settings.HeaderContent,
      width: ' 100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 5vmin',
    },
  },
  Tier: {
    ...defaultTheme.Tier,
    Card: {
      ...defaultTheme.Tier.Card,
      height: 60,
      width: 82,
    },
    TierWrapper: {
      ...defaultTheme.Tier.TierWrapper,
      marginRight: windowWidth < 1400 ? '85px !important' : '103px !important',
    },
    CardAnimation: {
      ...defaultTheme.Tier.CardAnimation,
      left: -30,
    },
  },
  CoinItem: {
    ...defaultTheme.CoinItem,
    CoinAmount: {
      ...defaultTheme.CoinItem.CoinAmount,
      fontSize: '25px',
    },
    Extra: {
      ...defaultTheme.CoinItem.Extra,
      fontSize: '0.9vmin',
      marginTop: 0,
    },
  },
  MissionsProgress: {
    ...defaultTheme.MissionsProgress,
    TimeRemainingText: {
      ...defaultTheme.MissionsProgress.TimeRemainingText,
      top: windowWidth < 1400 ? 51 : 55,
    },
    TimeRemainingTextWithoutCard: {
      ...defaultTheme.MissionsProgress.TimeRemainingTextWithoutCard,
      top: 55,
    },
    FillProgressWithoutCard: {
      ...defaultTheme.MissionsProgress.FillProgressWithoutCard,
      marginLeft: '-25px',
    },
    FillProgress: {
      ...defaultTheme.MissionsProgress.FillProgress,
      marginTop: windowWidth < 1400 ? '-22px' : '-17px',
      marginLeft: windowWidth < 1400 ? '-17px' : '-25px',
    },
    FillEnd: {
      ...defaultTheme.MissionsProgress.FillEnd,
      marginTop: windowWidth < 1400 ? '-22px' : '-17px',
    },
    ProgressAnimation: {
      ...defaultTheme.MissionsProgress.ProgressAnimation,
      bottom: windowWidth < 1400 ? '-155%' : '-77%',
    },
    ProgressAnimationWithoutCard: {
      ...defaultTheme.MissionsProgress.ProgressAnimationWithoutCard,
      bottom: '-77%',
    },
  },
  Account: {
    ...defaultTheme.Account,
    Label: {
      fontWeight: 400,
    },
  },
  BonusLinkMessage: {
    ...defaultTheme.BonusLinkMessage,
    LPBonusContainer: {
      ...defaultTheme.BonusLinkMessage.LPBonusContainer,
      paddingTop: '360px',
    },
    CoinsBonusContainer: {
      ...defaultTheme.BonusLinkMessage.CoinsBonusContainer,
      paddingTop: '370px',
    },
  },
};

export default StarTheme;

