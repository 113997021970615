import styled from 'styled-components';
import theme from 'shared/assets/style/theme';

export const UpdateTermsBackgroundImage = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const TermsContainer = styled.div`
  height: 85% !important;
`;

export const ErrorText = styled.p`
  text-align: center;
  margin-top: 10px;
  color: red;
`;

export const TermsTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 90%;
  height: 90%;
  margin: 1em auto;
`;

export const TermsUpdateScreenTextWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(14px + (20 - 14) * ((100vw - 800px) / (1600 - 800)));
`;

export const TermsImageWrapper = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

export const TermsImage = styled.img`
  pointer-events: auto;
  cursor: pointer;
  width: 100%;
  height: auto;
`;

export const TermsFooterWrapper = styled.div`
  justify-content: space-between !important;
  height: 10% !important;
`;

export const TermsUpdateButtonText = styled.p`
  font-weight: 700 !important;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: ${({ termsState }) => (termsState ? theme.palette.primary[28] : theme.palette.primary[1])};
`;

