import React, { Component } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Utils from 'shared/utils';
import GameTile from '../GameTile';
import { FTUE_STEPS_KEYS } from 'shared/constants';

export default class Home extends Component {
  state = {};

  componentDidUpdate(prevProps) {
    const { pageNumber, sliderRef, ftueStep } = this.props;
    if (prevProps.pageNumber !== pageNumber) {
      sliderRef.current.slickGoTo(pageNumber, true);
    }
    if (prevProps.ftueStep !== ftueStep && ftueStep === FTUE_STEPS_KEYS.INITIAL) {
      sliderRef.current.slickGoTo(0, true);
    }
  }

  render() {
    const { sliderRef, pagedGames, pageNumber, navigation, ftueStep } = this.props;

    return (
      <div className="Live-Panels-Container">
        <Slider
          ref={sliderRef}
          dots={false}
          infinite={false}
          arrows={false}
          initialSlide={ftueStep === FTUE_STEPS_KEYS.HIGHLIGHT_A_GAME ? 0 : pageNumber}
          cssEase="cubic-bezier(0.0, 0.0, 0.2, 1)"
        >
          {pagedGames.map((panelList, indexPaged) => {
            return (
              <div className="live-panel-grid" key={indexPaged}>
                {!Utils.isEmpty(panelList) &&
                  panelList.map((panel, index) => (
                    <GameTile
                      highlightedForOnboarding={index === 0 && indexPaged === 0}
                      itemData={panel}
                      key={index}
                      navigation={navigation}
                    />
                  ))}
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }
}

