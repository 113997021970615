import * as yup from 'yup';
import generateRender from '../../utils/generateRender';
import { getText, TEXT_KEY } from '../../utils/localization';
/**
 * Initial values
 */

var defaultInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  acceptedTerms: false,
  emailOptIn: false
};
var hriInitialValues = {
  email: '',
  password: '',
  confirmPassword: '',
  acceptedTerms: false,
  emailOptIn: false
};
/**
 * Validation schemas
 */

var defaultValidationSchema = yup.object({
  firstName: yup.string().required(function () {
    return getText(TEXT_KEY.FIRST_NAME_REQUIRED);
  }),
  lastName: yup.string().required(function () {
    return getText(TEXT_KEY.LAST_NAME_REQUIRED);
  }),
  email: yup.string().email(function () {
    return getText(TEXT_KEY.INVALID_EMAIL);
  }).required(function () {
    return getText(TEXT_KEY.EMAIL_REQUIRED);
  }),
  password: yup.string().min(6, function () {
    return getText(TEXT_KEY.PASSWORD_LENGTH);
  }).required(function () {
    return getText(TEXT_KEY.PASSWORD_REQUIRED);
  }),
  confirmPassword: yup.string().oneOf([yup.ref('password'), null], function () {
    return getText(TEXT_KEY.PASSWORDS_DONT_MATCH);
  }).required(function () {
    return getText(TEXT_KEY.CONFIRM_PASSWORD);
  })
});
var hriValidationSchema = yup.object({
  email: yup.string().email(function () {
    return getText(TEXT_KEY.INVALID_EMAIL);
  }).required(function () {
    return getText(TEXT_KEY.EMAIL_REQUIRED);
  }),
  password: yup.string().min(6, function () {
    return getText(TEXT_KEY.PASSWORD_LENGTH);
  }).required(function () {
    return getText(TEXT_KEY.INVALID_EMAIL);
  }),
  confirmPassword: yup.string().oneOf([yup.ref('password'), null], function () {
    return getText(TEXT_KEY.PASSWORDS_DONT_MATCH);
  }).required(function () {
    return getText(TEXT_KEY.CONFIRM_PASSWORD);
  })
});
/**
 * Output
 */

export var initialValues = generateRender({
  default: defaultInitialValues,
  hri: hriInitialValues,
  admiral: hriValidationSchema,
  admes: hriInitialValues
});
export var validationSchema = generateRender({
  default: defaultValidationSchema,
  hri: hriValidationSchema,
  admiral: hriValidationSchema,
  admes: hriValidationSchema
});