import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { createSlice } from '@reduxjs/toolkit';
var sliceName = 'PROPERTIES';
var initialState = {
  isFetchingData: false,
  data: {
    defaultSignupFormSettings: {},
    enterRequirements: [],
    propertySelector: [],
    userProperty: null,
    showLinkLoyaltyCardSetting: true,
    enableOrderCard: false,
    linkCards: null,
    dailyWheelCard: null,
    cardLinkPopupImage: null,
    isTermsUpdated: null,
    textsFile: require("../../../assets/".concat("star", "/texts/texts.json"))
  },
  errorMsg: ''
};
var propertiesSlice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    propertiesRequestData: function propertiesRequestData() {},
    propertiesIsFetching: function propertiesIsFetching(state, action) {
      state.isFetchingData = action.payload;
    },
    propertiesRequestError: function propertiesRequestError(state, action) {
      state.isFetchingData = false;
      state.errorMsg = action.payload;
    },
    propertiesRequestDataSuccess: function propertiesRequestDataSuccess(state, action) {
      state.isFetchingData = false;
      state.data = _objectSpread(_objectSpread({}, state.data.textsFile), action.payload);
    },
    propertiesClearAssets: function propertiesClearAssets(state) {
      state.data.linkCards = null;
      state.data.dailyWheelCard = null;
      state.data.cardLinkPopupImage = null;
    },
    setUpdatedTermsState: function setUpdatedTermsState(state, action) {
      state.data.isTermsUpdated = action.payload;
    }
  }
});
var actions = propertiesSlice.actions,
    reducer = propertiesSlice.reducer;
var propertiesRequestData = actions.propertiesRequestData,
    propertiesIsFetching = actions.propertiesIsFetching,
    propertiesRequestError = actions.propertiesRequestError,
    propertiesRequestDataSuccess = actions.propertiesRequestDataSuccess,
    propertiesClearAssets = actions.propertiesClearAssets,
    setUpdatedTermsState = actions.setUpdatedTermsState;
export { propertiesRequestData, propertiesIsFetching, propertiesRequestError, propertiesRequestDataSuccess, propertiesClearAssets, setUpdatedTermsState };
export default reducer;