import React, { Component } from 'react';
import SPinnedPanel from 'shared/components/PinnedPanel';
import LivePanel from '../LivePanel';

export default class PinnedPanel extends Component {
  state = {};

  render() {
    return (
      <SPinnedPanel
        {...this.props}
        render={args => {
          const { panelList } = args;
          return (
            <div className="PinnedPanel">
              {panelList.map((item, index) => {
                // Override dimension and position to suit pinnedPanel
                let pinnedPanelData = item;
                pinnedPanelData.width = 2;
                pinnedPanelData.height = 3;
                pinnedPanelData.position = { x: 0, y: 0 };
                pinnedPanelData.extras = { showFavouriteIcon: false };
                return (
                  <LivePanel
                    index={index}
                    key={`LivePanel-${index}`}
                    totalItems={panelList.length}
                    itemData={pinnedPanelData}
                    pinned
                    navigation={this.props.navigation}
                  />
                );
              })}
            </div>
          );
        }}
      />
    );
  }
}
