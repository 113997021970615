import { store } from '../state/store';

var useProgressiveJackpots = function useProgressiveJackpots() {
  var getProgressiveJackpotsData = function getProgressiveJackpotsData(gameId) {
    var gameData = store.getState().progressiveJackpots.data.find(function (game) {
      return game.gameId.toString() === gameId;
    });
    if (gameData) return gameData.displayValue;
  };

  return {
    getProgressiveJackpotsData: getProgressiveJackpotsData
  };
};

export default useProgressiveJackpots;