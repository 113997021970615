import Form from './Form';
import { connect } from '../../node_modules/react-redux';
import { setFormInfo as _setFormInfo, clearFormInfo as _clearFormInfo } from '../../state/actions/formInfo';

var mapStateToProps = function mapStateToProps(state) {
  return {
    formInfo: state.formInfo
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    setFormInfo: function setFormInfo(formInfo) {
      dispatch(_setFormInfo(formInfo));
    },
    clearFormInfo: function clearFormInfo() {
      dispatch(_clearFormInfo());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);