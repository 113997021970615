import React, { useCallback, useState } from 'react';
import NotificationContext from 'shared/context/NotificationContext';
import NotificationModal from '../components/Notifications/NotificationModal';

export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState(null);

  const clearNotification = () => setNotification(null);

  const addNotification = ({
    message,
    title,
    button,
    buttonAction,
    hideCloseButton,
    hideHelpButton,
    hasCancelButton,
  }) => setNotification({ message, title, button, buttonAction, hideCloseButton, hideHelpButton, hasCancelButton });

  const notificationContextValue = {
    notification,
    addNotification: useCallback(
      ({
        message,
        title,
        button = null,
        buttonAction = null,
        hideCloseButton = false,
        hideHelpButton = false,
        hasCancelButton = false,
      }) => addNotification({ message, title, button, buttonAction, hideCloseButton, hideHelpButton, hasCancelButton }),
      []
    ),
    clearNotification: useCallback(() => clearNotification(), []),
  };

  return (
    <NotificationContext.Provider value={notificationContextValue}>
      {notification && <NotificationModal />}
      {children}
    </NotificationContext.Provider>
  );
};

