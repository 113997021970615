import React, { useContext } from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import ThemeContext from 'shared/context/ThemeContext';
import { TermsText } from './styledComponents';

const DefaultFormFooter = ({ termsAndConditionsUrl }) => {
  const { FormFooter } = useContext(ThemeContext);

  return (
    <TermsText>
      {getText(TEXT_KEY.REGISTER_REWARD_TERMS_AGREE_MESSAGE)}{' '}
      <a style={FormFooter.TermsLink} onClick={() => window.open(termsAndConditionsUrl)}>
        {getText(TEXT_KEY.TERMS_OF_SERVICE)}
      </a>
    </TermsText>
  );
};

export default DefaultFormFooter;

