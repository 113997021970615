import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import LimitedTimeOffer from './LimitedTimeOffer';
import { connect } from '../../node_modules/react-redux';
import { setPurchaseType as _setPurchaseType, isLTOPurchase as _isLTOPurchase, setPurchaseProfile as _setPurchaseProfile } from '../../state/actions/prePurchase';
import { initiatePurchase as _initiatePurchase } from '../../state/actions/purchase';
import { setAuthCallbackPurchaseData as _setAuthCallbackPurchaseData } from '../../state/actions/callbackIntent';

var mapStateToProps = function mapStateToProps(state) {
  return _objectSpread({
    limitedTimeOffer: state.consumables.ltoOffer
  }, state.playerInfo);
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    setPurchaseType: function setPurchaseType(value) {
      dispatch(_setPurchaseType(value));
    },
    isLTOPurchase: function isLTOPurchase(value) {
      dispatch(_isLTOPurchase(value));
    },
    initiatePurchase: function initiatePurchase() {
      dispatch(_initiatePurchase());
    },
    setAuthCallbackPurchaseData: function setAuthCallbackPurchaseData(purchaseData) {
      dispatch(_setAuthCallbackPurchaseData(purchaseData));
    },
    setPurchaseProfile: function setPurchaseProfile(purchaseProfile) {
      dispatch(_setPurchaseProfile(purchaseProfile));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LimitedTimeOffer);