import React, { Component } from 'react';
import SWebFinishPurchase from 'shared/components/WebFinishPurchase';
import { Modal } from 'react-bootstrap';
import ImageBW from '../../components/ImageBoundsWrapper';
import Animated from 'animated/lib/targets/react-dom';
import { Spinner } from 'react-bootstrap';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import { getText, TEXT_KEY } from 'shared/utils/localization';

export default class WebFinishPurchase extends Component {
  componentDidMount() {
    let element = document.getElementsByClassName('ModalCloseButton');
    if (element.length !== 0) {
      element[0].style.display = 'none';
    }
  }
  componentWillUnmount() {
    let element = document.getElementsByClassName('ModalCloseButton');
    if (element.length !== 0) {
      element[0].style.display = 'block';
    }
  }
  render() {
    return (
      <SWebFinishPurchase
        navigation={this.props.navigation}
        render={() => (
          <Modal
            onHide={() => {}}
            show={true}
            size={'lg'}
            aria-labelledby={'contained-modal-title-vcenter'}
            centered={true}
            dialogClassName={'gtp-modal-finish-purchase'}
          >
            <div className={'ModalBackground'}>
              <ImageBW
                className="ModalBackgroundAsset"
                as={Animated.div}
                source={require(asset`Modal_Backing_9Slice@3x.png`)}
                ibw={require(asset`Modal_Backing_9Slice.ibw`)}
                widthScale={0.5}
              />
            </div>
            <Modal.Body>
              <div className={'header'}>
                <span
                  data-label={'PLEASE WAIT'}
                  className={`gradientText ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}
                  style={{ lineHeight: 1 }}
                >
                  {getText(TEXT_KEY.PLEASE_WAIT)}
                </span>
                <img
                  src={require(asset`images/Purchase_Flow_Assets/Light_Divider@3x.png`)}
                  alt=""
                  style={{
                    position: 'absolute',
                    width: '100%',
                    top: '100%',
                  }}
                />
              </div>
              <div className="finishPurchaseContainer">
                <span>{getText(TEXT_KEY.PROCESSING_REQUEST)}</span>
                <Spinner animation="border" />
              </div>
            </Modal.Body>
          </Modal>
        )}
      />
    );
  }
}

