import React, { Component } from 'react';
import SRewardCenterOnboarding from 'shared/screens/RewardCenter/Onboarding';

export default class RewardCenterOnboarding extends Component {
  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-reward-center-onboarding',
    };
  }

  render() {
    return (
      <SRewardCenterOnboarding
        navigation={this.props.navigation}
        render={args => {
          const { clickHandler } = args;

          return (
            <div style={{ cursor: 'pointer' }} onClick={clickHandler}>
              <img
                src={require(asset`Reward_Center_Onboarding_Popup@3x.png`)}
                alt="Reward Center Onboarding"
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '1em',
                }}
              />
            </div>
          );
        }}
      />
    );
  }
}

RewardCenterOnboarding.hideDefaultBackground = true;
