import { connect } from '../../node_modules/react-redux';
import FavouritesInformation from './FavouritesInformation';

var mapStateToProps = function mapStateToProps(state) {
  return {};
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(FavouritesInformation);