import AutoFormInput from './AutoFormInput';
import { connect } from '../../node_modules/react-redux';

var mapStateToProps = function mapStateToProps(state, _ref) {
  var id = _ref.id;
  return {
    value: state.formInfo[id] ? state.formInfo[id].value : ''
  };
};

export default connect(mapStateToProps, null)(AutoFormInput);