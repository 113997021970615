import { TEXTS } from '../../constants';
import { textsApi } from '../../state/query/texts';
import store from '../../state/store';
import ENVIRONMENT_VARIABLES from '../environmentVariables';
export var getText = function getText(key) {
  var _textsApi$endpoints$g = textsApi.endpoints.getTexts.select({
    lang: ENVIRONMENT_VARIABLES.LANGUAGE
  })(store.getState()),
      data = _textsApi$endpoints$g.data;

  return data && data[key] || TEXTS[key];
};