import ENVIRONMENT_VARIABLES from '../utils/environmentVariables';

var getApiRootValue = function getApiRootValue() {
  return ENVIRONMENT_VARIABLES.API_ROOT;
};

export var API_ROOT_LIST = getApiRootValue().split(',');
export function API_ROOT() {
  var apiRoot = getApiRootValue();
  var apiUrl = "https://".concat(apiRoot);
  return apiUrl;
}
export var Endpoints = {
  LOGIN: '/v2/login/',
  APPLE_LOGIN: '/v2/applesignin/',
  ANDROID_LOGIN: '/v2/androidlogin/',
  FORGOT_PASSWORD: '/v2/forgotpassword/',
  CHECK_IF_PLAYER_EXISTS: '/v2/checkIfPlayerExists/',
  CHECK_IF_EMAIL_EXISTS: '/v2/checkIfEmailExists/',
  CREATE_USER: '/v2/createUser/',
  GUEST_LOGIN: '/v2/guestlogin/',
  GET_GAME_ROOMS: '/v2/getgamerooms',
  GET_FAV_GAMES: '/v2/getfavgames/',
  PROGRESSIVE_JACKPOTS: '/games/progressiveJackpots/',
  GET_PLAYER_PROFILE: '/v2/getplayerprofile/',
  UPDATE_PLAYER_PROFILE: '/v2/updatePlayerProfile/',
  INIT_APP: '/v2/initapp/',
  PROPERTIES: '/v2/initproperty/',
  UPDATE_APP_EVENT_INFO: '/v2/getCampaignData/',
  USE_PROD_NEW_RELEASE: '/v2/useprodnewrelease/',
  IOS_LOGIN: '/v2/ioslogin/',
  INIT_GAME: '/v2/initgame/',
  LEVEL_UP: '/v2/levelup/',
  WEB_LOGIN: '/v2/weblogin/',
  FB_LOGIN: '/v2/fblogin',
  GOOGLE_LOGIN: '/v2/googlesignin',
  GET_FB_APP_ID: '/v2/getFbAppId',
  GET_URLS: '/v2/getUrls',
  RESTART_SESSION: '/v2/restartsession/',
  GET_CURRENCY_PACKAGES: '/v2/getcurrpkgs/',
  GET_DYNAMIC_SALE: '/v2/getdynamicsales/',
  CHECK_FOR_SALE: '/v2/checkforsale/',

  /** @deprecated Folded into GET_CURRENCY_PACKAGES */
  GET_DAILY_BONUS: '/v2/getDailyBonusData/',
  GET_DAILY_WHEEL_INFO: '/v2/getDailyWheelBonusData',
  CLAIM_DAILY_BONUS: '/v2/claimDailyBonus/',
  CLAIM_HOURLY_BONUS: '/v2/hourlybonus/',
  CLAIM_TIMED_BONUS: '/v2/claimTimedBonus/',
  GET_BILLING_PROFILES: '/v2/getbillingprofiles/',
  REMOVE_BILLING_PROFILE: '/v2/removebillingprofile/',
  REGISTER_REWARD_CARD: '/v2/registerloyaltycard',
  START_WEB_PURCHASE: '/v2/startwebpurchase/',
  FINISH_WEB_PURCHASE: '/v2/endwebpurchase/',
  FINISH_MOBILE_PURCHASE: '/v2/mobilepurchase/',
  GET_PURCHASE_ADDRESS: '/v2/getaddressforpurchase/',
  BILLING_PROFILE_PURCHASE: '/v2/purchaseagainstbillingprofile/',
  GET_ACTIVE_CONSUMABLES: '/v2/getActiveConsumables/',
  GET_FREE_CONSUMABLES: '/v2/getFreeConsumables/',
  CLAIM_FREE_CONSUMABLE: '/v2/claimFreeConsumable/',
  CHECK_PROMO_CODE: '/v2/checkpromocode/',
  GET_SSO_LINKS: '/v2/getssolinks/',
  SSO_SIGNUP: '/v2/ssosignup/',
  SSO_LOGIN: '/v2/ssologin/',
  SSO_UPGRADE: '/v2/ssoUpgrade/',
  SSO_INIT_LINK_LOYALTY_CARD: '/v2/ssoinitlinkloyaltycard/',
  SSO_LINK_LOYALTY_CARD: '/v2/ssolinkloyaltycard/',
  LINK_LOYALTY_CARD: '/v2/linkLoyaltyCard',
  DE_DUPE_CHECK: '/v2/starInfoCheck/',
  KYC_CHECK: '/v2/kyccheck/',
  KYC_UPLOAD: '/v2/kycupload/',
  GET_INBOX_MESSAGES: '/v2/getinboxmessages/',
  GET_INBOX_MESSAGE_DETAILS: '/v2/getinboxmessagedetails/',
  DELETE_INBOX_MESSAGE: '/v2/deleteInboxMessage/',
  COLLECT_MESSAGE_REWARDS: '/v2/collectmessagerewards/',
  GET_LOBBY_ITEMS: '/v2/getLobbyItems',
  INBOX_EVENTS: '/v2/inboxevents/',
  GET_LEADERBOARDS: '/v2/getleaderboards/',
  INIT_REWARDS: '/v2/initrewards/',
  GET_REWARD_DETAIL: '/v2/getrewarddetail/',
  GET_OFFER_HISTORY: '/v2/getPlayerOffersHistory/',
  PURCHASE_OFFER: '/v2/purchaseoffer/',
  GET_PLAYER_OFFER_DETAILS: '/v2/getplayerofferdetails/',
  SEND_OFFER_EMAIL: '/v2/sendofferemail/',
  GET_CASINO_DOLLAR_BALANCE: '/v2/getCasinoDollarBalance/',
  PURCHASE_WITH_CASINO_DOLLARS: '/v2/purchaseWithCasinoDollars/',
  ADD_FAVOURITE: '/v2/addfavgame/',
  REMOVE_FAVOURITE: '/v2/removefavgame/',
  SUBMIT_BONUSABLE_LINK: '/v2/submitbonusablelink/',
  // Watch and Earn (VideoAds)
  WATCH_AND_EARN_START: '/v2/watchAndEarnStart/',
  WATCH_AND_EARN_COMPLETE: '/v2/watchAndEarnComplete/',
  // Version Control
  VERSION_CONTROL: '/v2/getMobileVersionConfigs/',
  // Sound Settings
  TOGGLE_SOUND: '/v2/toggleSound/',
  TOGGLE_MUSIC: '/v2/toggleMusic/',
  CHANGE_PASSWORD: '/v2/changeplayerpassword',
  RECOVER_PASSWORD_TOKEN_CHECK: '/v2/checkpasswordtoken',
  RECOVER_PASSWORD: '/v2/updatepassword',
  // Web TrackingURL Data
  TRACKING_URL: '/v2/getaffiliatelandingpage/',
  COLLECT_WELCOME_BONUS: '/v2/collectwelcomebonus/',
  TERMS_AND_CONDITIONS: '/v2/getTermsAndConditionsUrl',
  ACCEPT_NEW_TERMS: '/v2/acceptTerms',
  DELETE_PLAYER_ACCOUNT: '/v2/deleteAccount',
  MISSIONS_PLAYER: '/missions/player',
  ASSETS: '/assets',
  ONBOARDING: '/v2/onboardingComplete',
  GEO_CHECK: '/v2/geocheck',
  LINK_GUEST_ACCOUNT_EMAIL: '/v2/guestlinkemail',
  LINK_GUEST_ACCOUNT_GOOGLE: '/v2/guestLinkGoogleAccount',
  LINK_GUEST_ACCOUNT_APPLE: '/v2/guestLinkAppleAccount',
  LINK_GUEST_ACCOUNT_FACEBOOK: '/v2/guestLinkFacebookAccount',
  SEND_OTP_EMAIL_REQUEST: '/v2/otpSendEmail',
  RESEND_OTP_EMAIL_REQUEST: '/v2/otpResendEmail',
  AUTHENTICATE_OTP: '/v2/otpAuthenticate',
  TEXTS: '/v2/texts',
  ACTION_COMMAND_NOTIFICATION: '/v2/togglenotifications',
  ACTION_COMMAND_MARKETING_EMAIL: '/v2/togglemarketingemails',
  ACTION_COMMAND_TRIGGER: 'triggers/notify',
  EVENTS_PLAYER: '/events/player'
};
export var ExternalEndpoints = {
  CUSTOMER_SUPPORT_BASE_URL: "https://".concat(ENVIRONMENT_VARIABLES.HELP_LINK),
  CUSTOMER_SUPPORT_ENDPOINT: '/api/v2/requests.json'
};
export var ApiTrackingWhitelistEndpoints = [Endpoints.LOGIN, Endpoints.APPLE_LOGIN, Endpoints.ANDROID_LOGIN, Endpoints.GUEST_LOGIN, Endpoints.GOOGLE_LOGIN, Endpoints.WEB_LOGIN, Endpoints.FB_LOGIN, Endpoints.RESTART_SESSION, Endpoints.FINISH_MOBILE_PURCHASE];
export var RestartSessionBlacklistEndpoints = [Endpoints.RESTART_SESSION, Endpoints.WEB_LOGIN, Endpoints.GUEST_LOGIN, Endpoints.LOGIN, Endpoints.ANDROID_LOGIN, Endpoints.GOOGLE_LOGIN, Endpoints.APPLE_LOGIN];
export var ContentProviders = {
  GREENTUBE: null,
  GENESIS: null,
  SPIN_GAMES: null,
  AINSWORTH: null,
  ABZORBA: null,
  LEAP: null,
  EVERI: null
};
export var InitAppResponseKeys = {
  NEWS_LIST: 'n',
  DEV_ACCOUNT: 'dev',
  GAME_TESTER: 'gameTester',
  PLAYER_STATUS: 'playerStatus',
  SALE: 'sale',
  TUTORIAL: 'tut',
  TUTORIAL_ID: 'tutgid',
  ENABLE_FTUE: 'enableFTUE',

  /** @type {SettingsInfo} */
  SETTINGS: 'settings',
  WELCOME: 'welcomeItems',

  /** @type {XPResponseKeys} */
  XP: 'xp',

  /** @type {TimedBonusKeys} */
  TIMED_BONUS: 'timedBonus',
  SOUND: 'se',
  WATCH_AND_EARN: 'wae',
  INVENTORY: 'in',
  LEADERBOARD_REWARD: 'playerPendingLeaderboardRewards',
  LOW_COINS_BALANCE_LIMIT: 'lcbl',
  FAVOURITE: 'favs',
  CASINO_ID: 'casinoId',
  OUT_OF_COINS: 'outOfCoins',

  /** @type {inboxInfo} */
  INBOX: 'inbox',
  NEW_GAME: 'ng',

  /** @type {DailyBonusResponseKeys|false} */
  DAILY_BONUS: 'db',

  /** @type {String} [Guest, Facebook, Unverified, Digitally Verified, Physically Verified] */
  CASINO_ID_STATUS: 'cs',
  GENERAL_MESSAGES: 'gm',
  INITIAL_LANDING_PAGE: 'ilp',
  STICKY_PANELS: 'sticky_panels',
  REGULAR_PANELS: 'regular_panels',
  DEFAULT_PANELS: 'default_panels',
  LOBBY: 'lobby',
  CURRENT_MEMBER_LEVEL: 'currentMemberLevel',
  PROMOTIONS: 'promoGrid',
  WELCOMEBONUS: 'welcomeBonus',
  LIMITED_TIME_OFFER: 'lto',
  CACHE_ID: 'cacheId',
  MEMBER_LEVELS: 'memberLevels',
  PAYMENT_PROVIDER: 'paymentProvider',
  MAX_DOWNLOADED_GAMES: 'maxDownloadedGames',
  APPSFLYER_GAMES_OPENED: 'appsflyerGamesOpened',
  SNEAK_PEEK: 'sneakPeek',
  IS_TERMS_UPDATED: 'isTermsUpdated'
};
export var UpdateAppEventResponseKeys = {
  SALE: 'sale',
  MESSAGES: 'messages',
  WELCOME: 'welcomeItems',
  LIMITED_TIME_OFFER: 'limited_time_offer',
  OUT_OF_COINS: 'out_of_coins',
  PROMOTIONS: 'promo_grid',
  TIME_BONUS_BOOST: 'timed_bonus_boost',
  TIMED_BONUS: 'timed_bonus',
  XP_BOOST_MULTIPLIER: 'xp_boost_multiplier',
  LOBBY_BACKGROUND: 'lobby_background'
};
export var DailyBonusResponseKeys = {
  /** @type {SpinnerItemKeys[]} The items on the wheel */
  SPINNER_ITEMS: 'spinner_items',

  /** @type {Number} Which item from the spinner items the user won */
  PRIZE_INDEX: 'prize_index',

  /** @type {Number} Bonus amount from login streak */
  LOGIN_STREAK_BONUS: 'login_streak_bonus',

  /** @type {Number} Multiplier conferred by user's login streak */
  LOGIN_STREAK_COINS_LIST: 'login_streak_coins_list',

  /** @type {LoginStreakInfo} */
  LOGIN_STREAK_LOYALTY_LIST: 'login_streak_loyalty_list',

  /** @type {String} Which currency does the user receive for daily bonus */
  DAILY_BONUS_CURRENCY: 'daily_bonus_currency',

  /** @type {Number} User is on this day in their daily rewards. */
  CURRENT_REWARD_DAY: 'current_reward_day',

  /** @type {Object} member level tiers */
  MEMBER_TIERS: 'member_tiers',

  /** @type {String} player's member tier */
  CURRENT_MEMBER_TIER: 'player_tier',
  ASSET_PACKAGE: 'assetPackageId',
  WHEEL_HERO: 'wheel_hero',
  CURRENT_REWARD_DAY_INDEX: 'current_reward_day_index',
  //New Items
  DISPLAY_TOTAL_LOYALTY: 'display_total_loyalty',
  DISPLAY_TOTAL_COINS_AFTER_TIER_BONUS: 'display_total_coins_after_tier_bonus',
  DISPLAY_WHEEL_BONUS: 'display_summary_wheel_reward',
  DISPLAY_DAY_STREAK_COINS: 'display_summary_streak_coins'
};
export var TimedBonusKeys = {
  /** @type {Number} time until new timed bonus */
  TIME_LEFT: 'timeLeft',

  /** @type {Number} current time interval */
  CURRENT_INTERVAL: 'timeInterval',

  /** @type {Number} amount of reward */
  AMOUNT: 'rewardAmount',

  /** @type {String} type of reward */
  TYPE: 'rewardCurrency'
};
export var SpinnerItemKeys = {
  /** @type {String} Currency type */
  CURRENCY: '0',

  /** @type {Number} How much */
  AMOUNT: '1',

  /** @type {Number} Order to display in */
  ORDER: '2',

  /** @type {String} Reward Type */
  REWARD: '3',

  /** @type {String} How much to Display */
  DISPLAY_AMOUNT: '4'
};
/**
 * @typedef {Object} LoginStreakInfo
 * @property {Number} amount Transformed from string
 */

/**
 * @typedef {Object} MergedLoginStreak
 * @property {Number} coins
 * @property {String} coins_trunc *Synthetic. Truncated value with postfix.
 * @property {Number} loyalty
 * @property {String} loyalty_trunc *Synthetic. Truncated value with postfix.
 */

/**
 * @typedef {Object} LoginStreakReward
 * @property {Number} amount Transformed from string
 * @property {String} currency Which currency type?
 * @property {String} [provider] *Exists on LOGIN_STREAK_REWARD, unclear why.
 */

/**
 * Model that represents Settings info from /initapp/
 * @typedef {Object} SettingsInfo
 * @property {String} soundEffects Should sound effects be on ['true' or 'false']
 * @property {String} linkedCasinoId Is there a Casino ID linked to user ['yes' or 'no']
 * @property {String} linkedFacebookId Is there a Facebook ID linked to user ['yes' or 'no']
 * @property {String} facebookId Assoicated Facebook ID
 * @property {KYCInfo} kyocInfo Object containing KYC info
 * @property {BonusInfo} linkFacebookBonus Object containing bonus info if user links Facebook account
 * @property {BonusInfo} linkedLoyaltyCardBonus Object containing bonus info if user links loyalty card
 * @property {BonusInfo} homeCasinoSelectionBonus Object containing bonus info if user selects a home casino
 */

/**
 * Model for inbox object from /initapp/
 * @typedef {Object} inboxInfo
 * @property {String} notifications Represents the number of pending notifications in Inbox [Stringify number]
 */

/**
 * Model that represents bonus info
 * @typedef {Object} BonusInfo
 * @property {Number} amount Amount of currency to receive
 * @property {String} currency The currency of the bonus
 */

/**
 * Model that represents KYC info
 * @typedef {Object} KYCInfo
 * @property {String} iskyocdone String on whether or not kyc is completed ['yes' or 'no']
 * @property {String} kyocresult Result from KYC result I guess?
 */

export var XPResponseKeys = {
  /** @type {Number} */
  CURRENT_XP: '0',

  /** @type {Number} */
  XP_PER_SPIN: '1',

  /**
   * This * number of coins bet with .5 rounded up
   * @type {Number}
   */
  XP_BET_MULTIPLIER: '2',

  /**
   * Will be 0 if we need to level up.
   * @type {Number}
   */
  XP_TO_NEXT: '3',

  /** Max level will be this number - 1
   * @type {Number}
   */
  MAX_LEVEL: '4',
  LEVEL_META: '5'
};
export var FavouriteResponseKeys = {
  FAVOURITE_GAMES: 'favourites',
  FEATURED_GAMES: 'featured',
  MAX_FAV_SLOTS: 'maxFavSlots',
  UNLOCK_LEVEL: 'unlockLvl',
  MESSAGE: 'message',
  IS_EMBEDDED: 'is_embedded',
  EMBED_URL: 'embed_url',
  HAS_PROGRESSIVE_JACKPOTS: 'has_progressive_jackpots'
};
export var ProgressiveJackpotsResponseKeys = {
  GAME_ID: 'gameId',
  DISPLAY_VALUE: 'displayValue'
};
export var NewsResponseKeys = {
  TITLE: 0,
  DESCRIPTION: 1,
  IMAGE: 2,
  SHOW_BUTTON: 3,
  ACTION: 4,
  BUTTON_TEXT: 5,
  LOBBY_NEWS_IMAGE: 6,
  GAME_PROVIDER_IMAGE: 7
};
export var FBLoginResponseKeys = {
  AUTH_TOKEN: 0,
  PLAYER_ID: 1,
  PROPERTY_ID: 2,
  RETURN_TOKEN: 3,
  CASINO_ID: 4,
  IS_NEW_USER: 5,
  EMAIL: 6
};
/**
 * @typedef {Object} BetInfo
 * @property {String} bonusXPMultiplier
 * @property {Number} bonusXPThreshold
 * @property {String} defaultBet
 */

export var InitGameResponseKeys = {
  URL: 0,
  PROVIDER: 1,

  /** @type {BetInfo} */
  BET_INFO: 2,
  GAME_TYPE: 3,
  SESSION_ID: 4,
  PROVIDER_GAME_ID: 5,
  EMBED_DATA: 6
};
/**
 * @typedef {Object} GameRoomsResponse
 * @property {GameResponseKeys[]} featured
 * @property {GameResponseKeys[]} new
 * @property {GameResponseKeys[]} new_games
 * @property {GameRoom[]} slots
 * @property {GameRoom[]} table
 * @property {GameRoom[]} vip
 * @property {GameRoom[]} configurable_vip
 */

/**
 * @typedef {Object} GameRoom
 * @property {String} room_id
 * @property {String} roomName
 * @property {String} backgroundImage
 * @property {Boolean} isGameRoomUnlocked
 * @property {Number} timeRemainingForUnlockedStateInSeconds
 * @property {RoomBalanceThreshold} unlockGameRoomPurchaseCost
 * @property {RoomBalanceThreshold} gameRoomBalanceThreshold
 * @property {Boolean} can_enter_room
 * @property {NewsItemKeys[]} news
 * @property {GameResponseKeys[]} games Transformed from object array
 */

/**
 * @typedef {Object} RoomBalanceThreshold
 * @property {Number} amount Transformed from string
 * @property {String} currency
 */

export var NewsItemKeys = {
  /** @type {String} */
  title: 0,

  /** @type {String} */
  description: 1,

  /** @type {String} */
  image: 2,

  /** @type {String} */
  showButton: 3,

  /** @type {String} */
  action: 4,

  /** @type {String} */
  buttonText: 5,

  /** @type {String} */
  lobbyNewsOverlayImage: 6,

  /** @type {String} */
  gameProviderOverlayLogoImage: 7
};
export var GameResponseKeys = {
  GAME_ID: 0,
  GAME_NAME: 1,
  PROVIDER: 2,
  UNLOCK_LEVEL: 3,
  UNLOCKED: 4,
  CORNER_TEXT: 5,
  IS_EMBEDDED: 6,
  EMBED_URL: 7,
  IS_HIDDEN: 8,
  SNEAK_PEEK_END_DATE: 9,
  HAS_PROGRESSIVE_JACKPOTS: 10
};
/**
 * @typedef {Object} MobileUrls
 * @property {String} AdvertImageBaseURL
 * @property {String} BbeURLHost
 * @property {String} ChallengeImageBaseURL
 * @property {String} DynamicSalesImageBaseURL
 * @property {String} GameAdvertImageBaseURL
 * @property {String} GameImageBaseURL
 * @property {String} GameRoomsImageBaseURL
 * @property {String} GameUnlockImageBaseURL
 * @property {String} PackagesImageBaseURL
 * @property {String} PropertyImageBaseURL
 * @property {String} RewardsImageBaseURL
 * @property {String} SaleBannerImageBaseURL
 * @property {String} WelcomeImageBaseURL
 * @property {String} mobileImageUrl
 * @property {String} mobileWebGameImageBaseURL
 * @property {String} versionAmazonURL
 * @property {String} versionAndroidURL
 * @property {String} versionIOSURL
 */

/**
 * @typedef {Object} LivePanelData
 * @property {Number} id
 * @property {Boolean} isSquare Not used on web.
 */

/**
 * @typedef {Object} LobbyItemsInfo
 * @property {LivePanelInfo[]} default_panels a list of default live panels, these are for fallback only
 * @property {LivePanelInfo[]} regular_panels a list of regular live panels
 * @property {LivePanelInfo[]} sticky_panels a list of sticky live panels
 */

/**
 * @typedef {Object} LivePanelInfo
 * @property {String} background Name of the background image
 * @property {Object} extra Extra payload if needed
 * @property {String} flag_animation Animation type [NOT USED AT THE MOMENT]
 * @property {Number} height Height of the live panel
 * @property {Number} item_id Id for the item
 * @property {String} item_type Type of live panel [news, games, sales, events, ...etc]
 * @property {Number} seconds_until_expiry Seconds until this tile expires
 * @property {Number} width Width of the live panel
 * @property {Dimension} [position] Frontend injected object to store calculated position
 */

/**
 * @typedef {Object} Dimension
 * @property {Number} x
 * @property {Number} y
 */

export var RestartResponseKeys = {
  AUTH_TOKEN: 0,
  RETURN_TOKEN: 1,
  PLAYER_ID: 2,
  PROPERTY_ID: 3,
  CASINO_ID: 6,
  EMAIL: 7
};
export var ServerResponseKeys = {
  CALL_RESPONSE: 0,
  LEVEL: 1,
  XP: 2,
  CURRENCY_BALANCES: 3,
  DYNAMIC_SALES: 5,
  PROGRESSION: 7,
  ACTIONS_COMANDS: 8
};
export var ServerResponseCurrencyKeys = {
  COINS: 0,
  PREMIUM: 1,
  LOYALTY: 2
};
/**
 * Currency package response
 * @typedef {Object} CurrencyPackagesResponse
 * @property {CurrencyPackageNewsListItemKeys[]} NEWS_LIST
 * @property {PackageListTypes} PACKAGE_LIST
 * @property {Object} NEXT_BOOST_INFO
 */

/**
 * Package lists
 * @typedef {Object} PackageListTypes
 * @property {PackageListInfo} coins
 * @property {BoostPackageListInfo} xp_boosts
 * @property {BoostPackageListInfo} vip_room_access
 */

/**
 * Package lists info
 * @typedef {Object} PackageListInfo
 * @property {CurrencyPackageItemKeys[]} items
 * @property {String} [sale_background_image] *Only if coin packages.
 * @property {String} [sale_background_title_image] *Only if coin packages.
 */

/**
 * Is actually "consumable" item, also used for VIP.
 * @typedef {Object} BoostPackageItem
 * @property {String} corner_flag If set determines if gold.
 * @property {Number} duration_in_seconds How long this boost will last.
 * @property {String} duration_friendly *Synthetic property. How long the boost will last in human readable (but short) words
 * @property {String} duration_friendly_full *Synthetic property. Same as duration_friendly, but with full words (i.e., Hour instead of Hr)
 * @property {String} image Image for this boost.
 * @property {Boolean} is_free Is this boost free?
 * @property {Number} item_id
 * @property {String} name Name of this item.
 * @property {String} original_price Used if sale.
 * @property {String} price
 * @property {String} sale_backgroung_image Seemingly unused.
 * @property {String} sale_backgroung_image_title Seemingly unused.
 * @property {Number|null} savings_percentage Seemingly unused.
 * @property {Number} xp_multiplier How big is the multiplier.
 * @property {Number} loyalty_points How much loyalty will be earned with this purchase
 * @property {String} mobile_sku IAP id to pass to mobile store. TODO: Verify this works. Not implemented in API at time of introduction.
 */

export var CurrencyPackagesResponseKeys = {
  NEWS_LIST: '0',
  PACKAGE_LIST: '1',
  NEXT_BOOST_INFO: '2',
  PAYMENT_PROVIDER: '4'
};
export var CurrencyPackageNewsListItemKeys = {
  TITLE: '0',
  DESCRIPTION: '1',
  IMAGE: '2',
  SHOW_BUTTON: '3',
  ACTION: '4',
  BUTTON_TEXT: '5',
  LOBBY_NEWS_OVERLAY_IMAGE: '6',
  GAME_PROVIDER_OVERLAY_LOGO: '7'
};
export var CurrencyPackageItemKeys = {
  NAME: '0',
  CORNER_FLAG: '1',
  IMAGE: '2',
  PERCENT_BOOST: '3',
  ORIGINAL_PRICE: '4',
  PRICE: '5',
  AMOUNT: '6',
  ORIGINAL_AMOUNT: '7',
  // If this is a coin package, it's loyalty; or vice versa
  ALT_AMOUNT: '8',
  BONUS_LOYALTY_AMOUNT: '9',
  CURRENCY_ITEM_ID: '10',
  MOBILE_PRODUCT_IAP_ID: '11',
  ORDER: '12',
  OLD_MOBILE_SKU: '13',
  IS_SALE: '14',
  PROMO_BOOSTED_VALUE: '15',
  AMOUNT_INCLUDING_SALE_AND_PROMO: '17'
};
export var OOCPackageItemKeys = {
  NAME: '0',
  PRICE: '1',
  AMOUNT: '2',
  BONUS_LOYALTY_AMOUNT: '3',
  CURRENCY_ITEM_ID: '4',
  MOBILE_PRODUCT_IAP_ID_IOS: '5',
  MOBILE_PRODUCT_IAP_ID_ANDROID: '6'
};
export var LTOPackageItemKeys = {
  NAME: 'itemname',
  TIME_LEFT_IN_SEC: 'timeLeftInSeconds',
  PRICE: 'pricePoint',
  AMOUNT: 'curramount',
  BONUS_LOYALTY_AMOUNT: 'bonusloyalty',
  CURRENCY_ITEM_ID: 'cpilId',
  MOBILE_PRODUCT_IAP_ID_IOS: 'iosSKU',
  MOBILE_PRODUCT_IAP_ID_ANDROID: 'androidSKU'
};
export var SSOLinksResponseKeys = {
  ANALYTICS: 'analytics',
  ANALYTICS_URL: 'analyticsURL',
  LINK_REDIRECT_AUTH_PARAM: 'ssoLinkRedirectAuthParam',
  LINK_REDIRECT_URI: 'ssoLinkRedirectURI',
  LINK_URL: 'ssoLinkURL',
  LOGIN_REDIRECT_AUTH_PARAM: 'ssoLoginRedirectAuthParam',
  LOGIN_REDIRECT_URI: 'ssoLoginRedirectURI',
  LOGIN_URL: 'ssoLoginURL',
  SIGNUP_URL: 'ssoSignUpURL'
};
export var SSOLoginTokenType = {
  AUTH_CODE: 'authCode',
  SSO_TOKEN: 'ssoToken',
  REFRESH_TOKEN: 'refreshToken'
};
/**
 * Model that represents SSOUpgrade response
 * @typedef {Object} SsoUpgradeResponse
 * @property {Boolean} success SSOUpdate success or not [true or false]
 * @property {String} [error] The error message, if success is false
 * @property {String} [error_code] Bluebat internal error code, if success is false
 * @property {String} [msg] The message, if success is true
 * @property {String} [sessionId] Bluebat internal session ID, if success is true
 * @property {String} [playerId] Player ID, if success is true
 * @property {String} [ssoAccessToken] Star Single Sign-on access token, if success is true
 * @property {String} [ssoRefreshToken] Star Single Sign-on refresh token, if success is true
 * @property {String} [propertyId] Casino property ID, if success is true
 * @property {String} [casinoId] Casino ID, if success is true
 */

/**
 * Model that represents purchase info for a boost.
 * @typedef {Object} PurchaseInfo
 * @property {Number} amount Required amount of currency to purchase
 * @property {String} currency The currency of the purchase
 */

/**
 * Model that represents a boost package
 * @typedef {Object} BoostPackage
 * @property {String} boostId Internal ID
 * @property {String} type "xp" or "unlockAllGames"
 * @property {PurchaseInfo} purchaseInfo
 * @property {Number} boostLength In boostLengthUnit
 * @property {String} boostLengthUnit
 * @property {Number} timeRemaining In timeRemainingUnit
 * @property {String} timeRemainingUnit
 * @property {Boolean} active
 * @property {Number} [multiplier] Only relevant if type = "xp"
 */

export var LevelUpResponseKeys = {
  /** @type {Number} */
  XP_TO_NEXT: '0',

  /** @type {LevelUpReward} */
  REWARD: '1',

  /** @type {LevelUpCampaignInfo} campaign info or something idk */
  CAMPAIGN_INFO: '2',

  /** @type {Number} The new amounts being gained when redeeming timed bonus */
  TIMED_BONUS_AMOUNT: '3',

  /** @type {Object[]} Ran out of coins, offer the player this package */
  OUT_OF_COINS_PKG_INFO: '5'
};
/**
 *
 * @typedef {Object} LevelUpReward
 * @property {Number} c Coins
 * @property {Number} p Premium
 * @property {Number} l Loyalty
 * @property {Number} nextLevelCoins
 * @property {Number} nextLevelPremium
 * @property {Number} nextLevelLoyalty
 * @property {Number} nextBoostLevel The next level a boost will be granted
 * @property {Number} nextBoostPercentage The next boost amount, in percentage points over the base (i.e. 100 = +100%).
 * @property {Boolean} isAtMaxBoost Are we already at the maximum boost?
 * @property {Number} currentBoostPercentage Current boost amount, in percentage bouses over the base (i.e. 100 = +100%).
 */

/**
 * @typedef {Object} LevelUpCampaignInfo
 * @property {String} appsflyer No idea what this does, sample response says it returns "no"
 */

/**
 * Model that represents KYC Check Info
 * @typedef {Object} KYCCheckInfo
 * @property {String} address1
 * @property {String} address2
 * @property {String} city
 * @property {String} state
 * @property {String} zip
 * @property {String} country
 * @property {String} firstname
 * @property {String} lastname
 * @property {String} dob
 */

/**
 * Model that represents KYC Check Response
 * @typedef {Object} KYCCheckResponse
 * @property {Boolean} success Whether or not KYC Check was successful [true or false]
 * @property {Boolean} [inprogress] If we require further details from users [true or false]
 * @property {String} [error] error message to show user
 * @property {String} [error_code] error code for BlueBat internal uses
 * @property {String} [msg] message to show user
 * @property {String} [iskycdone] Is know your client done? ["yes", "no"]
 * @property {String} [kycresult] know your client result. *Empty if kyc not done. ["Pass", "Fail"]
 */

/**
 * @typedef {Object} StartWebPurchaseResponse
 * @property {Boolean} success Did we win
 * @property {String} iskycdone Is know your client done? ["yes", "no"]
 * @property {String} kycresult know your client result. *Empty if kyc not done. ["Pass", "Fail"]
 * @property {Number} [transactionid] Transaction ID. *Only available on success.
 * @property {String} [formurl] Form URL to redirect to. *Only available on success.
 * @property {String} [error] Error message. *Only available if not successful.
 * @property {String} [error_code] Error code. *Only available if not successful.
 */

/**
 * @typedef {Object} FinishWebPurchaseResponse
 * @property {Boolean} success Did we win
 * @property {String} [error] Error message. *Only available if success == false.
 * @property {String} [error_code] Error code. *Only available if success == false.
 * @property {String} [msg] Success message. *Only available if success == true.
 */

/**
 * @typedef {Object} BillingProfiles
 * @property {Number} maxBillingProfiles Maximum number of billing profiles.
 * @property {BillingProfile[]} [billingProfiles] List of billing profiles. Undefined if the user has no billing profiles.
 */

/**
 * @typedef {Object} BillingProfile
 * @property {Number} profile_id Billing profile id.
 * @property {String} profileNickname Nickname for the profile
 * @property {Number} order List order to display in.
 */

/**
 * @typedef {Object} CheckPromoCodeResponse
 * @property {Boolean} success Did we win
 * @property {String} [error] Error message. *Only available if success == false.
 * @property {String} [error_code] Error code. *Only available if success == false.
 * @property {String} [msg] Success message. *Only available if success == true.
 * @property {PromoCodeReward} [redeem] Redeeming this code will confer this reward. *Only available if success == true.
 */

/**
 * @typedef {Object} PromoCodeReward
 * @property {String} currency What currency is being awarded? ["coins", "loyalty"...]
 * @property {Number} amount How much is being awarded?
 */

/**
 * @typedef {Object} InboxMessagesResponse
 * @property {Boolean} [success]
 * @property {String} [error]
 * @property {InboxCategory[]} categories
 * @property {InboxMessagePreview[]} messages
 * @property {String} notifications A string represents message count
 */

/**
 * @typedef {Object} InboxCategory
 * @property {String} ic_id
 * @property {Number} order
 * @property {String} name
 * @property {String} icon
 * @property {String} highlighted
 */

/**
 * @typedef {Object} InboxMessagePreview
 * @property {String} ic_id
 * @property {Number} message_id
 * @property {Number} order
 * @property {String} title
 * @property {String} subject
 * @property {String} preview
 * @property {String} urgent
 * @property {String} read_status
 */

/**
 * @typedef {Object} InboxMessageInfo
 * @property {Boolean} success
 * @property {Object} details
 */

/**
 * @typedef {Object} InboxMessageDetails
 * @property {String} message_id
 * @property {String} title
 * @property {String} subject
 * @property {String} body
 * @property {String} header_img
 * @property {String} btn_text
 * @property {String} action
 * @property {String} redeemed
 * @property {RewardInfo[]} reward_info
 */

/**
 * @typedef {Object} CollectMessageRewardsResponse
 * @property {Boolean} success
 * @property {String} [error] error message, only if success === false
 * @property {String} [error_code] bluebat internal error code, only if success === false
 * @property {RewardInfo} [reward_info] reward info, only if success === true
 */

/**
 * @typedef {Object} RewardInfo
 * @property {Number} amount
 * @property {String} currency
 */

/**
 * @typedef {Object} LeaderboardsResponse
 * @property {Boolean} success
 * @property {PendingLeaderboardRewards} playerPendingLeaderboardRewards Pending rewards for player
 * @property {Number} numberOfResultsPerPage Number of results displayed per page
 * @property {LeaderboardObject} hourly Object for the hourly leaderboard
 * @property {LeaderboardObject} daily Object for the daily leaderboard
 * @property {LeaderboardObject} weekly Object for the weekly leaderboard
 */

/**
 * @typedef {Object} PendingLeaderboardRewards
 * @property {LeaderboardRewardObject} hourly Pending hourly reward
 * @property {LeaderboardRewardObject} daily Pending daily reward
 * @property {LeaderboardRewardObject} weekly Pending weekly reward
 */

/**
 * @typedef {Object} LeaderboardObject
 * @property {Number} numberOfTotalResults Number of current total players in the leaderboard
 * @property {LeaderboardPlayerInfo} playerInfo Player standing for this specific leaderboard
 * @property {LeaderboardPlayerInfo[]} rankings Top 10 results
 * @property {Number} timeLeft Time left for this leaderboard
 * @property {String} currentLeaderboard The current leaderboard ID
 */

/**
 * @typedef {Object} LeaderboardRewardObject
 * @property {String} currentLeaderboard
 * @property {Number} timeLeft
 * @property {Object} reward This might be empty if leaderboard hasn't ended
 * @property {String} amount
 * @property {String} currency [Loyalty, Coins, etc]
 * @property {String} rank
 * @property {String} finishDate
 */

/**
 * @typedef {Object} LeaderboardPlayerInfo
 * @property {String} rank
 * @property {String} name
 * @property {String} credits Number of points they have won
 * @property {String} reward Possible reward when time is up depending on rank
 */

/* @typedef {Object} InitRewardsResponse
 * @property {Object} filterList Categories that rewards may be filtered by
 * @property {Object} news
 * @property {Object} offers Available offers that may be redeemed with loyalty
 * @property {Object} profile High level overview of player's profile
 * @property {Object} propertyList List of properties and their names
 * @property {Object} sortingRules Rules on how to sort the offers, in order of importance
 */

/**
 * @typedef {Object} GetPlayerOffersHistoryResponse
 * @property {Object} filterList Categories that rewards may be filtered by importance
 */

/**
 * @typedef {Object} GetRewardDetailResponse
 * @property {Object} offerDetails All low level details about an individual offer
 * @property {Boolean} success
 */

/**
 * @typedef {Object} PurchaseOfferResponse
 * @property {Object} offerDetails All low level details about an individual offer
 * @property {Boolean} success
 * @property {String} message
 */

/**
 * @typedef {Object} GetPlayerOfferDetailsResponse
 * @property {Object} offerDetails All low level details about an individual offer
 * @property {Boolean} success
 * @property {String} message
 */

/**
 * @typedef {Object} SendOfferEmailResponse
 * @property {Object} offerDetails All low level details about an individual offer
 * @property {Boolean} success
 * @property {String} message
 */

/**
 * @typedef {Object} DTS
 * @property {DTS_Offering[]} bonusOffering
 * @property {Number} bonusPercent
 * @property {DTS_Offering} currOffering
 * @property {String} currPrice
 * @property {String} description
 * @property {String} image
 * @property {Number} itemId
 * @property {DTS_Offering} origOffering
 * @property {String} origPrice
 * @property {String} origSku
 * @property {Number} saleId
 * @property {String} sku
 * @property {Number} step
 * @property {Number} timeRemaining
 */

/**
 * @typedef {Object} DTS_Offering Reward for completion
 * @property {Number} amount How much currency.
 * @property {String} currency Which currency? ["coins", "loyalty"]
 */