import React, { useState } from 'react';
import generateRender from 'shared/utils/generateRender';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import useSignup from 'shared/screens/Signup/useSignup';
import { SignUpWrapper } from './common/SignUpWrapper';
import { SignUpForm } from './SignUpForm';
import { isHri, isAdmiral } from 'shared/utils/targetApplication';
import { onPressHelp } from 'shared/utils/redirectionHelp';

const Signup = props => {
  const clientText = generateRender({
    hri: getText(TEXT_KEY.HRI),
    sga: getText(TEXT_KEY.SGA),
    ti: getText(TEXT_KEY.TI),
    admiral: getText(TEXT_KEY.ADMIRAL),
    admes: getText(TEXT_KEY.ADMIRAL),
    default: '',
  });

  const clientFurtherInfoText = generateRender({
    hri: getText(TEXT_KEY.RECEIVE_OFFERS_FROM_HRI),
    sga: getText(TEXT_KEY.RECEIVE_FURTHER_INFO_FROM_SGA),
    ti: getText(TEXT_KEY.RECEIVE_FURTHER_INFO_FROM_TI),
    admiral: getText(TEXT_KEY.RECEIVE_FURTHER_INFO_FROM_ADMIRAL),
    admes: getText(TEXT_KEY.RECEIVE_FURTHER_INFO_FROM_ADMIRAL),
    default: '',
  });

  const [showPassword, setShowPassword] = useState(false);

  const { formikValidation, handleCheckBoxToggle, getStatusMessage, isLoading, postSignupData } = useSignup();
  const { values, errors, status, handleSubmit, setFieldValue, handleBlur, handleChange } = formikValidation;
  const { isModal, isLandingPage, title, termsAndConditionsUrl, privacyPolicyUrl } = props;

  const showPasswordToggleHandle = () => {
    setShowPassword(!showPassword);
  };

  return (
    <SignUpWrapper {...props} showSpinner={false}>
      <SignUpForm
        values={values}
        errors={errors}
        status={status}
        handleSubmit={handleSubmit}
        setFieldValue={setFieldValue}
        handleBlur={handleBlur}
        handleChange={handleChange}
        handleCheckBoxToggle={handleCheckBoxToggle}
        getStatusMessage={getStatusMessage}
        title={title}
        termsAndConditionsUrl={termsAndConditionsUrl}
        privacyPolicyUrl={privacyPolicyUrl}
        onPressHelp={onPressHelp}
        showPassword={showPassword}
        showPasswordToggleHandle={showPasswordToggleHandle}
        isModal={isModal}
        isLandingPage={isLandingPage}
        clientText={clientText}
        clientFurtherInfoText={clientFurtherInfoText}
        requireName={!isHri && !isAdmiral}
        isLoading={isLoading}
        postSignupData={postSignupData}
      />
    </SignUpWrapper>
  );
};

Signup.AsModalProps = {
  dialogClassName: 'gtp-modal-signup',
};

export default Signup;

