import React, { useState } from 'react';
import { MISSIONS_TYPES } from 'shared/screens/Missions/constants';
import { generateTabs } from 'shared/screens/Missions/utils/generateMissionRendering';
import { tutorialOpacity } from 'shared/utils/tutorial';
import styled from 'styled-components';

const MissionTabs = ({ setFilterData, tutorialStep, activeTutorial, missionsAssets }) => {
  const [selectedTab, setSelectedTab] = useState(generateTabs(missionsAssets)[0]);

  const handleOnPress = (index, missionType) => {
    setSelectedTab(generateTabs(missionsAssets)[index]);
    setFilterData(MISSIONS_TYPES[missionType]);
  };

  return (
    <TabContainer tutorialStep={tutorialStep} activeTutorial={activeTutorial}>
      {selectedTab.map(({ TYPE, TAB, INDEX }, index) => {
        return (
          <div key={index} style={{ zIndex: `${INDEX}` }} onClick={() => handleOnPress(index, TYPE)}>
            <Tab src={TAB} />
          </div>
        );
      })}
    </TabContainer>
  );
};

export default MissionTabs;

const TabContainer = styled.div`
  opacity: ${({ tutorialStep, activeTutorial }) => tutorialOpacity(activeTutorial && tutorialStep > 1)};
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 4.5rem;
  right: 1.5rem;
  z-index: 6;
`;

const Tab = styled.img`
  width: 19.2rem;
  object-fit: contain;
  margin-right: -4.49rem;
  cursor: pointer;
`;

