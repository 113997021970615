import { DiveSDK, EventBody } from '@divegames/dive-ts-web-sdk';
import { EVENT_TYPES } from '../constants';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';

export default {
  handleEvent: (eventType, payload = {}) => {
    switch (eventType) {
      case EVENT_TYPES.LOGIN_COMPLETE:
        const userID = payload.userID;
        const userXP = payload.diveItems.userXP;
        const userLevel = payload.diveItems.userLevel;
        const loginMethod = payload.diveItems.loginMethod;
        const sessionId = payload.diveItems.sessionId;
        const version = `${ENVIRONMENT_VARIABLES.VERSION_MAJOR}.${ENVIRONMENT_VARIABLES.VERSION_MINOR}.${ENVIRONMENT_VARIABLES.VERSION_PATCH}-build.${ENVIRONMENT_VARIABLES.VERSION_BUILD}-${ENVIRONMENT_VARIABLES.ENVIRONMENT}`;
        //Set player settings
        DiveSDK.getInstance()
          .SetPlayerId(userID)
          .SetServerSessionId(sessionId)
          .SetPlayerLevel(userLevel)
          .SetPlayerXp(userXP)
          .SetGameVersion(version);
        //Launch the Dive SDK
        DiveSDK.getInstance().Launch(
          new EventBody().AddParam('login_type', loginMethod).AddParam('query_string_params', payload.queryParams)
        );
        break;
      case EVENT_TYPES.TRANSACTION_INITIATED:
        DiveSDK.getInstance().RecordEvent(
          'transactionInitiated',
          new EventBody()
            .AddParam('trigger', payload.trigger)
            .AddParam('product_id', payload.product_id)
            .AddParam('currency', payload.currency)
            .AddParam('amount', payload.amount)
            .AddParam('type', payload.type)
            .AddParam('is_test', payload.is_test)
            .AddParam('id', payload.id)
            .AddParam('balance_before', payload.balance_before)
            .AddParam('game_provider', payload.game_provider)
            .AddParam('game_id', payload.game_id)
        );
        break;
      case EVENT_TYPES.TRANSACTION_FAILED:
        DiveSDK.getInstance().RecordEvent(
          'transactionFailed',
          new EventBody()
            .AddParam('trigger', payload.trigger)
            .AddParam('product_id', payload.product_id)
            .AddParam('currency', payload.currency)
            .AddParam('amount', payload.amount)
            .AddParam('type', payload.type)
            .AddParam('is_test', ENVIRONMENT_VARIABLES.ENVIRONMENT !== 'PROD' ? 1 : 0)
            .AddParam('id', payload.id)
            .AddParam('store', payload.store)
            .AddParam('store_tx_id', payload.store_tx_id)
            .AddParam('tx_error', payload.tx_error)
            .AddParam('product_name', payload.product_name)
            .AddParam('game_id', payload.game_id)
            .AddParam('game_provider', payload.game_provider)
        );
        break;
      case EVENT_TYPES.FRONTEND_ERROR:
        DiveSDK.getInstance().RecordEvent(
          'frontendError',
          new EventBody()
            .AddParam('requestId', payload.requestId)
            .AddParam('headers', payload.headers)
            .AddParam('body', payload.body)
            .AddParam('error', payload.error)
            .AddParam('code', payload.code)
            .AddParam('status', payload.status)
        );
        break;
      case EVENT_TYPES.PURCHASE_COMPLETE:
        DiveSDK.getInstance().RecordEvent(
          'transactionCompleted',
          new EventBody()
            .AddParam('trigger', payload.trigger)
            .AddParam('product_id', payload.product_id)
            .AddParam('currency', payload.currency)
            .AddParam('amount', payload.amount)
            .AddParam('type', payload.type)
            .AddParam('is_test', ENVIRONMENT_VARIABLES.ENVIRONMENT !== 'PROD' ? 1 : 0)
            .AddParam('id', payload.id)
            .AddParam('store', payload.store)
            .AddParam('store_tx_id', payload.store_tx_id)
            .AddParam('promo_code', payload.promo_code)
            .AddParam('product_name', payload.product_name)
            .AddParam('loyalty_points', payload.loyalty_points)
            .AddParam('loyalty_points_before', payload.loyalty_points_before)
            .AddParam('level_after', payload.level_after)
            .AddParam('coins', payload.coins)
            .AddParam('promo_coins', payload.promo_coins)
            .AddParam('game_id', payload.game_id)
            .AddParam('game_provider', payload.game_provider)
            .AddParam('balance_before', payload.balance_before)
        );
        break;
      case EVENT_TYPES.REQUEST_SENT:
        DiveSDK.getInstance().RecordEvent(
          'requestSent',
          new EventBody()
            .AddParam('requestId', payload.requestId)
            .AddParam('headers', payload.headers)
            .AddParam('body', payload.body)
            .AddParam('uri', payload.uri)
        );
        break;
      case EVENT_TYPES.RESPONSE_RECEIVED:
        DiveSDK.getInstance().RecordEvent(
          'responseReceived',
          new EventBody()
            .AddParam('requestId', payload.requestId)
            .AddParam('status', payload.status)
            .AddParam('body', payload.body)
        );
        break;
      case EVENT_TYPES.TUTORIAL_SKIPPED:
        DiveSDK.getInstance().RecordEvent(
          'tutorialSkipeed',
          new EventBody().AddParam('id', payload.id).AddParam('skipped', payload.skipped)
        );
        break;
      case EVENT_TYPES.ENTER_MISSIONS_SCREEN:
      case EVENT_TYPES.ENTER_EVENTS_SCREEN:
        DiveSDK.getInstance().RecordEvent(
          'uiEvent',
          new EventBody()
            .AddParam('action', payload.action)
            .AddParam('domain', payload.domain)
            .AddParam('screen', payload.screen)
        );
        break;
      case EVENT_TYPES.START_MISSIONS_TUTORIAL:
      case EVENT_TYPES.DISPLAY_MISSIONS_TUTORIAL_STEP:
      case EVENT_TYPES.MISSIONS_TUTORIAL_COMPLETE:
      case EVENT_TYPES.START_FTUE_TUTORIAL:
      case EVENT_TYPES.DISPLAY_FTUE_TUTORIAL_STEP:
      case EVENT_TYPES.FTUE_TUTORIAL_COMPLETE:
        DiveSDK.getInstance().RecordEvent(
          'uiEvent',
          new EventBody()
            .AddParam('action', payload.action)
            .AddParam('domain', payload.domain)
            .AddParam('screen', payload.screen)
            .AddParam('context_tutorial_step', payload.context_tutorial_step)
        );
        break;
      case EVENT_TYPES.MISSIONS_TUTORIAL_NEXT_STEP:
      case EVENT_TYPES.MISSIONS_TUTORIAL_SKIP:
      case EVENT_TYPES.FTUE_TUTORIAL_NEXT_STEP:
      case EVENT_TYPES.FTUE_TUTORIAL_SKIP:
        DiveSDK.getInstance().RecordEvent(
          'uiEvent',
          new EventBody()
            .AddParam('action', payload.action)
            .AddParam('domain', payload.domain)
            .AddParam('screen', payload.screen)
            .AddParam('widget', payload.widget)
            .AddParam('context_tutorial_step', payload.context_tutorial_step)
        );
        break;
      case EVENT_TYPES.DISPLAY_MISSIONS_WIDGET:
      case EVENT_TYPES.MISSIONS_OPEN_INFO_SCREEN:
      case EVENT_TYPES.DISPLAY_EVENTS_WIDGET:
      case EVENT_TYPES.EVENTS_OPEN_INFO_SCREEN:
      case EVENT_TYPES.DISPLAY_EVENTS_TOOLTIP:
        DiveSDK.getInstance().RecordEvent(
          'uiEvent',
          new EventBody()
            .AddParam('action', payload.action)
            .AddParam('domain', payload.domain)
            .AddParam('screen', payload.screen)
            .AddParam('widget', payload.widget)
        );
        break;
      case EVENT_TYPES.EVENTS_OPEN_EVENT_CARD:
        DiveSDK.getInstance().RecordEvent(
          'uiEvent',
          new EventBody()
            .AddParam('action', payload.action)
            .AddParam('domain', payload.domain)
            .AddParam('screen', payload.screen)
            .AddParam('widget', payload.widget)
            .AddParam('eventId', payload.eventId)
        );
        break;
      case EVENT_TYPES.MISSIONS_CARD_REWARD_CLAIM:
        DiveSDK.getInstance().RecordEvent(
          'uiEvent',
          new EventBody()
            .AddParam('action', payload.action)
            .AddParam('domain', payload.domain)
            .AddParam('widget', payload.widget)
        );
        break;
      case EVENT_TYPES.PROMOLINK_CLICKED:
        DiveSDK.getInstance().RecordEvent('promolinkClicked', new EventBody().AddParam('promo_code', payload.code));
        break;
      case EVENT_TYPES.DISPLAY_APP_MODAL:
      case EVENT_TYPES.DISPLAY_APP_TOOLTIP:
      case EVENT_TYPES.DISPLAY_EVENTS_TUTORIAL:
        DiveSDK.getInstance().RecordEvent(
          'uiEvent',
          new EventBody()
            .AddParam('action', payload.action)
            .AddParam('domain', payload.domain)
            .AddParam('screen', payload.screen)
            .AddParam('trigger', payload.trigger)
            .AddParam('executionId', payload.executionId)
        );
        break;
      case EVENT_TYPES.ACCEPT_APP_MODAL:
      case EVENT_TYPES.DISMISS_APP_MODAL:
      case EVENT_TYPES.SKIP_ONBOARDING_TUTORIAL:
      case EVENT_TYPES.COMPLETE_ONBOARDING_TUTORIAL:
      case EVENT_TYPES.SKIP_MISSIONS_TUTORIAL:
      case EVENT_TYPES.COMPLETE_MISSIONS_TUTORIAL:
      case EVENT_TYPES.SKIP_EVENTS_TUTORIAL:
        DiveSDK.getInstance().RecordEvent(
          'uiEvent',
          new EventBody()
            .AddParam('action', payload.action)
            .AddParam('domain', payload.domain)
            .AddParam('screen', payload.screen)
            .AddParam('widget', payload.widget)
            .AddParam('trigger', payload.trigger)
            .AddParam('executionId', payload.executionId)
            .AddParam('context_tutorial_step', payload.context_tutorial_step)
        );
        break;
      default:
        break;
    }
  },
};

