import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../api/services/rtkQuery/axiosBaseQuery';
import { getDailyWheelBonusQuery, getDailyWheelDataQuery } from './queries';
export var dailyWheelApi = createApi({
  reducerPath: 'dailyWheelQuery',
  baseQuery: axiosBaseQuery(),
  keepUnusedDataFor: 0,
  tagTypes: ['DailyWheels'],
  endpoints: function endpoints(builder) {
    return {
      getDailyWheelBonus: builder.query(getDailyWheelBonusQuery),
      getDailyWheelData: builder.query(getDailyWheelDataQuery)
    };
  }
});
var useLazyGetDailyWheelBonusQuery = dailyWheelApi.useLazyGetDailyWheelBonusQuery,
    useGetDailyWheelDataQuery = dailyWheelApi.useGetDailyWheelDataQuery,
    useLazyGetDailyWheelDataQuery = dailyWheelApi.useLazyGetDailyWheelDataQuery;
export { useLazyGetDailyWheelBonusQuery, useGetDailyWheelDataQuery, useLazyGetDailyWheelDataQuery };
var _dailyWheelApi$endpoi = dailyWheelApi.endpoints,
    getDailyWheelBonus = _dailyWheelApi$endpoi.getDailyWheelBonus,
    getDailyWheelData = _dailyWheelApi$endpoi.getDailyWheelData,
    dailyWheelReducerPath = dailyWheelApi.reducerPath,
    dailyWheelQueryReducer = dailyWheelApi.reducer,
    dailyWheelMiddleware = dailyWheelApi.middleware;
export { dailyWheelReducerPath, dailyWheelQueryReducer, dailyWheelMiddleware, getDailyWheelBonus, getDailyWheelData };