import React, { Component } from 'react';
import SWelcomeBonus from 'shared/screens/WelcomeBonus';
import './WelcomeBonus.scss';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import ThemeContext from 'shared/context/ThemeContext';

export default class WelcomeBonus extends Component {
  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-WelcomeBonus',
    };
  }

  render() {
    const _overlayStyle = {
      position: 'fixed',
      width: '100%',
      height: '100%',
      left: '0px',
      top: '0px',
      zIndex: 10,
    };
    return (
      <SWelcomeBonus
        navigation={this.props.navigation}
        render={args => {
          const { bonusAmount, clickHandler } = args;
          const themeContext = this.context;
          return (
            <>
              <div onClick={clickHandler} style={_overlayStyle}></div>
              <div qa-id="welcomeBonus" className={'ModalBackground'}>
                <img
                  src={require(asset`images/Welcome_Bonus_Assets/Welcome_Bonus_web.png`)}
                  alt="Welcome dialog"
                  style={{ height: '100%', width: '100%' }}
                />
              </div>
              <div style={themeContext.WelcomeBonus.OfferDetails}>
                <div
                  className="coinContainer"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <img
                    src={require(asset`images/Top_Bar_Assets/Coin_Icon@3x.png`)}
                    className="coinIcon"
                    alt="Coin Icon"
                    style={themeContext.WelcomeBonus.CoinIcon}
                  />
                  <span
                    className={`coinAmountGradientText ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}
                    style={themeContext.WelcomeBonus.BonusAmount}
                    data-label={bonusAmount}
                  >
                    {bonusAmount}
                  </span>
                </div>
              </div>
            </>
          );
        }}
      />
    );
  }
}

WelcomeBonus.hideCloseButton = true;
WelcomeBonus.hideDefaultBackground = true;
WelcomeBonus.contextType = ThemeContext;

