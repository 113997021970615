import React from 'react';
import ImageBW from '../../../components/ImageBoundsWrapper';
import { CheckBox, CheckBoxText, LinkText, linkTextStyle } from '../common/styledComponents';
import { getText, TEXT_KEY } from 'shared/utils/localization';

const CheckBoxes = ({
  handleCheckBoxToggle,
  acceptedTerms,
  privacyPolicyUrl,
  termsAndConditionsUrl,
  clientText,
  emailOptIn,
  clientFurtherInfoText,
}) => (
  <>
    <EmailOptInCheckBox
      handleCheckBoxToggle={handleCheckBoxToggle}
      emailOptIn={emailOptIn}
      clientFurtherInfoText={clientFurtherInfoText}
    />
    <TermsCheckBox
      handleCheckBoxToggle={handleCheckBoxToggle}
      acceptedTerms={acceptedTerms}
      privacyPolicyUrl={privacyPolicyUrl}
      termsAndConditionsUrl={termsAndConditionsUrl}
      clientText={clientText}
    />
  </>
);

const TermsCheckBox = ({
  handleCheckBoxToggle,
  acceptedTerms,
  privacyPolicyUrl,
  termsAndConditionsUrl,
  clientText,
}) => {
  const activeCheckbox = require(asset`Checkbox@3x.png`);
  const nonActiveCheckbox = require(asset`Checkbox_Deselected@3x.png`);

  return (
    <CheckBox>
      <ImageBW
        onClick={() => handleCheckBoxToggle('acceptedTerms', acceptedTerms)}
        source={acceptedTerms ? activeCheckbox : nonActiveCheckbox}
        ibw={require(asset`Checkbox.ibw`)}
        style={{ minWidth: 22, height: 20 }}
        className="checkbox-image"
      />
      <CheckBoxText>
        {getText(TEXT_KEY.HAVE_READ_AND_ACCEPT)}
        {clientText}{' '}
        <LinkText style={linkTextStyle} onClick={() => window.open(privacyPolicyUrl, '_blank')}>
          {getText(TEXT_KEY.PRIVACY_POLICY)}{' '}
        </LinkText>
        {getText(TEXT_KEY.AND)}{' '}
        <LinkText style={linkTextStyle} onClick={() => window.open(termsAndConditionsUrl, '_blank')}>
          {getText(TEXT_KEY.TERMS_AND_CONDITIONS)}
        </LinkText>
      </CheckBoxText>
    </CheckBox>
  );
};

const EmailOptInCheckBox = ({ handleCheckBoxToggle, emailOptIn, clientFurtherInfoText }) => {
  const activeCheckbox = require(asset`Checkbox@3x.png`);
  const nonActiveCheckbox = require(asset`Checkbox_Deselected@3x.png`);

  return (
    <CheckBox>
      <ImageBW
        onClick={() => handleCheckBoxToggle('emailOptIn', emailOptIn)}
        source={emailOptIn ? activeCheckbox : nonActiveCheckbox}
        ibw={require(asset`Checkbox.ibw`)}
        style={{ minWidth: 22, height: 20 }}
        className="checkbox-image"
      />
      <CheckBoxText>{clientFurtherInfoText}</CheckBoxText>
    </CheckBox>
  );
};

export default CheckBoxes;

