import Utils from '.';
import { store } from '../state/store';
import { setOnboardingHighlightedPosition } from '../state/actions/currentOnboarding';
export var ONBOARDING_FLOWS = {
  FAVOURITES: 'favourites',
  DAILY_SPIN: 'dailySpin',
  REWARD_CENTER: 'reward_center',
  SNEAK_PEEK: 'sneak_peek'
};
export var onboardingHighlightByPlatform = function onboardingHighlightByPlatform(ref, message) {
  var position = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'right';

  if (Utils.getPlatform() === 'web') {
    var _ref$current$getBound = ref.current.getBoundingClientRect(),
        left = _ref$current$getBound.left,
        top = _ref$current$getBound.top,
        width = _ref$current$getBound.width,
        height = _ref$current$getBound.height;

    store.dispatch(setOnboardingHighlightedPosition({
      x: left,
      y: top,
      width: width,
      height: height
    }, {
      position: position,
      message: message
    }));
  } else {
    ref.current.measure(function (_fx, _fy, width, height, x, y) {
      store.dispatch(setOnboardingHighlightedPosition({
        x: x,
        y: y,
        width: width,
        height: height
      }, {
        position: position,
        message: message
      }));
    });
  }
};