const windowWidth = window.innerWidth;

export const animationStyleGenerator = (
  spinAnim,
  fastForwardButtonAnim,
  wheelInAnim,
  wheelPostSpinAnim,
  streakAnim,
  bottomAnim
) => {
  const spinAnimDeg = spinAnim.interpolate({
    inputRange: [0, 360],
    outputRange: ['0deg', '-360deg'],
  });

  const fastForwardButtonAnimDeg = fastForwardButtonAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [16, -57],
  });

  const wheelInAnimDeg = wheelInAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ['100vh', '0vh'],
  });

  const wheelPostSpinAnimDeg = wheelPostSpinAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ['0vh', windowWidth < 1300 ? '-40vh' : '-37vh'],
  });

  const streakAnimDeg = streakAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [
      DAILY_WHEEL_ASSUMTIONS.dailyListWidth * 4 + DAILY_WHEEL_ASSUMTIONS.dailyWidthUnit,
      -DAILY_WHEEL_ASSUMTIONS.dailyListWidth * 0.28 + DAILY_WHEEL_ASSUMTIONS.dailyWidthUnit,
    ],
  });

  const bottomAnimDeg = bottomAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ['5rem', '0rem'],
  });

  const bottomBarAnimDeg = bottomAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ['130%', '0%'],
  });

  return {
    spinAnimDeg,
    fastForwardButtonAnimDeg,
    wheelInAnimDeg,
    wheelPostSpinAnimDeg,
    streakAnimDeg,
    bottomAnimDeg,
    bottomBarAnimDeg,
  };
};

export const DAILY_WHEEL_ASSUMTIONS = {
  dailyListWidth: windowWidth < 1300 ? 35 : windowWidth < 1400 ? 33 : 33,
  dailyWidthUnit: '%',
};

