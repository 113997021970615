module.exports = {
  dimensions: {
    width: 155,
    height: 136
  },
  optical: {
    top: 13,
    left: 32,
    right: 31,
    bottom: 32
  }
};