import _toConsumableArray from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { SET_FIRST_TIME_VISIT_VIP_ROOM, SET_FIRST_TIME_FAVOURITE_HELPER, MARK_ONBOARDING_SEEN, SET_ONBOARDING_UNLOCK_LEVEL } from '../actions/types';
var initialState = {
  vipRoom: true,
  hasSeenChallengeOnboard: {},
  onboardingsSeen: [],
  onboardingsUnlockLevels: {}
};

var ftueReducer = function ftueReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_FIRST_TIME_VISIT_VIP_ROOM:
      return _objectSpread(_objectSpread({}, state), {}, {
        vipRoom: action.payload
      });

    case SET_FIRST_TIME_FAVOURITE_HELPER:
      return _objectSpread(_objectSpread({}, state), {}, {
        showFavouriteHelper: action.payload
      });

    case MARK_ONBOARDING_SEEN:
      /**
       * In order to ensure multiple users on the same device/PC see onboardings, we
       * store the onboardings the player has seen by player ID (persistent in local storage).
       * Up to 10 new players on the same devise will see onboardings (once the max is
       * exceeded, the lowest playerID is removed from the list to make room for the new one).
       */
      // Get the array of onboardings seen for this current player or create an empty array if not found.
      var playerOnboardingsArray = state.onboardingsSeen[action.payload.playerId] || []; // push the latest onboarding seen into the array

      playerOnboardingsArray.push(action.payload.onboarding); // Get the length of how many players are stored, if at 10 then delete lowest player ID

      var playerIdsArray = Object.keys(state.onboardingsSeen);

      if (playerIdsArray.length === 10) {
        delete state.onboardingsSeen[Math.min.apply(Math, _toConsumableArray(playerIdsArray))];
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        onboardingsSeen: _objectSpread(_objectSpread({}, state.onboardingsSeen), {}, _defineProperty({}, action.payload.playerId, playerOnboardingsArray))
      });

    case SET_ONBOARDING_UNLOCK_LEVEL:
      return _objectSpread(_objectSpread({}, state), {}, {
        onboardingsUnlockLevels: _objectSpread(_objectSpread({}, state.onboardingsUnlockLevels), {}, _defineProperty({}, action.payload.onboarding, action.payload.level))
      });

    default:
      return state;
  }
};

export default ftueReducer;