import React, { Component } from 'react';
import SOfferList from 'shared/components/RewardCenter/OfferList';
import { Scrollbars } from 'react-custom-scrollbars';
import preload from '../../lib/load/preload';
import ActivityIndicator from '../../components/ActivityIndicator/ActivityIndicator';
import ThemeContext from 'shared/context/ThemeContext';
import RewardListItem from './RewardListItem';

export default class RewardHome extends Component {
  state = {
    height: 0,
  };

  onScroll = () => {
    this.setState({ height: 10 });
  };

  render() {
    return (
      <SOfferList
        {...this.props}
        preload={preload}
        activeFilter={this.props.activeFilter}
        activeProperty={this.props.activeProperty}
        rewardCenterBaseUrl={this.props.rewardCenterBaseUrl}
        render={props => {
          const { api, ux, defaultOfferThumbnail, onFilterChange, shouldRender } = props;
          const { filterList } = api.core;
          const { hasData } = ux.core;
          if (!shouldRender) {
            return <ActivityIndicator />;
          }
          return (
            <>
              <div key={`RewardCenterOffers-${hasData}`} className="RewardCenterOffers" onMouseEnter={this.onScroll}>
                {hasData ? (
                  <Scrollbars
                    ref="scrollbars"
                    onScroll={this.onScroll}
                    style={{ height: 'auto' }}
                    renderThumbHorizontal={props => (
                      <div {...props} className="thumb-horizontal" style={{ height: this.state.height }} />
                    )}
                    renderTrackHorizontal={props => (
                      <div
                        {...props}
                        className={'track-horizontal'}
                        style={{ opacity: this.state.height > 0 ? 1 : 0 }}
                      />
                    )}
                  >
                    <div style={{ width: '100%', display: 'flex' }}>
                      {filterList.map(filter => (
                        <RewardListItem
                          key={`Offer-${filter.rc_id}`}
                          item={filter}
                          defaultOfferThumbnail={defaultOfferThumbnail}
                          rewardBaseUrl={this.props.rewardCenterBaseUrl}
                          onClickHandler={() => onFilterChange({ target: { value: filter.rc_id } })}
                        />
                      ))}
                    </div>
                  </Scrollbars>
                ) : (
                  <>
                    {new Array(6).fill({}).map((_, idx) => (
                      <div key={`PrerenderRewardCenterOffer-${idx}`} className="RewardCenterOffer">
                        <div className="RewardCenterOfferCost"></div>
                        <div className="RewardCenterOfferName"></div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </>
          );
        }}
      />
    );
  }
}

RewardHome.contextType = ThemeContext;

