import React from 'react';
import FacebookLinkOption from './options/FacebookLinkOption';
import GoogleLinkOption from './options/GoogleLinkOption';
import { LinkAccountDividerWrapper, LinkAccountRowWrapper } from '../../styledComponents';
import styles from '../../styles';
import ImageBW from '../../../../../components/ImageBoundsWrapper';

const StarLinkAccount = props => {
  const { googleAuth, googleToken } = props;
  return (
    <>
      <LinkAccountDividerWrapper>
        <ImageBW
          style={styles.divider}
          source={require(asset`./images/Settings/Settings_Divider@3x.png`)}
          ibw={require(asset`./images/Settings/Settings_Divider.ibw`)}
          widthScale={0.5}
        />
      </LinkAccountDividerWrapper>
      <LinkAccountRowWrapper>
        {googleAuth && <GoogleLinkOption googleToken={googleToken} />}
        <FacebookLinkOption />
      </LinkAccountRowWrapper>
      <LinkAccountDividerWrapper>
        <ImageBW
          style={styles.divider}
          source={require(asset`./images/Settings/Settings_Divider@3x.png`)}
          ibw={require(asset`./images/Settings/Settings_Divider.ibw`)}
          widthScale={0.5}
        />
      </LinkAccountDividerWrapper>
    </>
  );
};

export default StarLinkAccount;

