import React, { useContext } from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import ThemeContext from 'shared/context/ThemeContext';
import ImageBoundsWrapper from '../../../../components/ImageBoundsWrapper';
import { styles } from '../../styles';

const activeCheckbox = require(asset`Checkbox@3x.png`);
const nonActiveCheckbox = require(asset`Checkbox_Deselected@3x.png`);

const OTPFormFooter = ({
  handleRegisterRewardCard,
  termsAndConditionsUrl,
  enableOrderCard,
  showOTPConsents,
  activeOTPCheckbox,
  handleOPTCheckbox,
  selectedPropertyObj,
}) => {
  const { FormFooter } = useContext(ThemeContext);

  return (
    <>
      {showOTPConsents ? (
        <div style={styles.otpConsentsContainer}>
          <ImageBoundsWrapper
            source={activeOTPCheckbox ? activeCheckbox : nonActiveCheckbox}
            ibw={require(asset`Checkbox.ibw`)}
            style={styles.otpConsentsCheckbox}
            className="checkbox-image"
            onClick={handleOPTCheckbox}
          />
          <p style={styles.otpConsentsText}>
            {enableOrderCard && (
              <>
                {`${getText(TEXT_KEY.LINK_CARD_SIGNUP)}`}{' '}
                <a style={FormFooter.TermsLink} onClick={handleRegisterRewardCard}>
                  {getText(TEXT_KEY.LINK_CARD_SIGNUP_CTA)}
                </a>{' '}
              </>
            )}
            {getText(TEXT_KEY.LINK_CARD_OTP_CONSENTS)}
            <br />
            <a style={FormFooter.TermsLink} onClick={() => window.open(selectedPropertyObj.loyaltyTermsAndConditions)}>
              {getText(TEXT_KEY.TERMS_OF_SERVICE)}
            </a>{' '}
            {getText(TEXT_KEY.AND)}{' '}
            <a style={FormFooter.TermsLink} onClick={() => window.open(selectedPropertyObj.loyaltyPrivacyPolicy)}>
              {getText(TEXT_KEY.PRIVACY_POLICY)}
            </a>{' '}
          </p>
        </div>
      ) : (
        <p style={styles.termsText}>
          {enableOrderCard && (
            <>
              {`${getText(TEXT_KEY.LINK_CARD_SIGNUP)}`}{' '}
              <a style={FormFooter.TermsLink} onClick={handleRegisterRewardCard}>
                {getText(TEXT_KEY.LINK_CARD_SIGNUP_CTA)}
              </a>{' '}
            </>
          )}
          {getText(TEXT_KEY.TERMS_AGREE_MESSAGE)}{' '}
          <a style={FormFooter.TermsLink} onClick={() => window.open(termsAndConditionsUrl)}>
            {getText(TEXT_KEY.TERMS_OF_SERVICE)}
          </a>
        </p>
      )}
    </>
  );
};

export default OTPFormFooter;

