import React, { Component } from 'react';
import styled from 'styled-components';
import SWatchAndEarnInfo from 'shared/screens/VideoAds/Info';
import TimeRemainingText from '../../components/TimeRemainingText';
import ImageBW from '../../components/ImageBoundsWrapper';
import './VideoAds.scss';
import Utils from 'shared/utils/index';
import theme from 'shared/assets/style/theme';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import { getText, TEXT_KEY } from 'shared/utils/localization';

export default class WatchAndEarnInfo extends Component {
  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-watchAndEarn-info',
    };
  }

  componentDidMount() {
    window.history.replaceState('', '', window.location.origin);
  }

  render() {
    return (
      <SWatchAndEarnInfo
        navigation={this.props.navigation}
        render={args => {
          return (
            <div className={'watchAndEarnInfoContainer' + (Utils.ieVersion() !== 0 ? ' ieHack' : '')}>
              <ComeBackTextWrapper
                className={`watchAndEarnGradientText ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}
                data-label={getText(TEXT_KEY.COME_BACK_LATER)}
              >
                {getText(TEXT_KEY.COME_BACK_LATER)}
              </ComeBackTextWrapper>
              <ImageBW
                source={require(asset`Shop_VIP_Divider_Glows@3x.png`)}
                ibw={require(asset`Shop_VIP_Divider_Glows.ibw`)}
                manualAssetScale={3}
                style={styles.divider}
                innerStyle={styles.dividerInner}
              ></ImageBW>
              <span className="watchAndEarnPopupIcon">
                <WatchAndEarnIcon />
              </span>
              <AlreadyWatchedText>{getText(TEXT_KEY.ALREADY_WATCHED_ALL)}</AlreadyWatchedText>
              <SecondDivider />
              <ComeBackInText>{getText(TEXT_KEY.COME_BACK_IN)}</ComeBackInText>
              <span className="timerContainer">
                <TimerIcon />
                <TimeRemainingText className="timeRemaining" until={args.videoAds.refreshDate} />
              </span>
            </div>
          );
        }}
      />
    );
  }
}

WatchAndEarnInfo.hideOverlay = true;

const ComeBackTextWrapper = styled.span`
  text-align: center;
  display: flex;
  justify-content: center;
  flex: 1;
  margin-top: 3vmin;
  height: 6vmin;
  font-weight: 600;
  font-size: calc(18px + (30 - 18) * ((100vw - 800px) / (1600 - 800)));
`;

const WatchAndEarnIcon = styled.img.attrs(() => ({
  src: require(asset`images/Watch_and_Earn_Assets/Watch&Earn_PopupIcon@3x.png`),
}))`
  width: 100%;
  height: 100%;
`;

const AlreadyWatchedText = styled.span`
  font-size: 1em;
  margin-top: -2.5em;
  width: 90%;
  text-align: center;
  text-shadow: ${theme.palette.primary[16]} 1px 1px 4px;
`;

const ComeBackInText = styled.span`
  font-size: 1.1em;
  text-shadow: ${theme.palette.primary[16]} 1px 1px 4px;
`;

const TimerIcon = styled.img.attrs(() => ({
  src: require(asset`images/Watch_and_Earn_Assets/Timer_Backing@3x.png`),
}))`
  width: 100%;
  height: 100%;
`;

const SecondDivider = styled.img.attrs(() => ({
  src: require(asset`Shop_VIP_Divider@3x.png`),
}))`
  width: 50%;
  height: 0.5vmin;
  margin-top: 1vmin;
  margin-bottom: 0.8vmin;
`;

const styles = {
  dividerInner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    paddingLeft: '1vmin',
    paddingRight: '1vmin',
  },
  divider: {
    height: '7vmin',
    width: '100%',
    marginTop: '1vmin',
  },
};

