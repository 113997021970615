import React from 'react';
import { EventsEventTextWrapper, TooltipBackground } from './styledComponent';
import { EVENTS_DEFAULT_TOOLTIP } from 'shared/screens/Events/utils/generateEventsIconRender';
import { useSelector } from 'shared/node_modules/react-redux';

export default function EventsTooltip({ props }) {
  const { displayText, style } = props;

  let eventsAssets = useSelector(state => state.assetsPackage.assets.EVENTS_FEATURE);
  eventsAssets = eventsAssets ? Object.keys(eventsAssets)?.[0] : null;

  const tooltipBG = eventsAssets?.EVENTS_TOOTLIP ?? EVENTS_DEFAULT_TOOLTIP;

  return (
    <div className="Events-tooltip AnimationFadeIn" style={style}>
      <TooltipBackground src={tooltipBG} />
      <EventsEventTextWrapper>{displayText}</EventsEventTextWrapper>
    </div>
  );
}

