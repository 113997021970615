import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _handleFilterData;

import { MISSIONS_STATES, MISSIONS_TYPES } from '../../screens/Missions/constants/missionsInfo';
import { hasExplorerType, hasGrandType } from '../../screens/Missions/utils/missionsPerformance';
import { FILTER_TYPES } from './filterTypes';

var handleDefaultFilterData = function handleDefaultFilterData(item) {
  return item;
};

var handleMissionFilterData = function handleMissionFilterData(mission, filterData) {
  switch (filterData) {
    case MISSIONS_TYPES.EXPLORER:
      return (hasExplorerType(mission.missionType) || hasGrandType(mission.missionType)) && mission.missionStatus !== MISSIONS_STATES.COMPLETED && mission.missionStatus !== MISSIONS_STATES.FINISHED;

    case MISSIONS_TYPES.EXPERT:
      return mission.missionType === MISSIONS_TYPES.EXPERT || mission.missionType === MISSIONS_TYPES.FLASH;

    case MISSIONS_TYPES.BATTLES:
      return mission.missionType === MISSIONS_TYPES.BATTLES;

    default:
      return mission;
  }
};

export var handleFilterData = (_handleFilterData = {}, _defineProperty(_handleFilterData, FILTER_TYPES.DEFAULT, handleDefaultFilterData), _defineProperty(_handleFilterData, FILTER_TYPES.MISSIONS, handleMissionFilterData), _handleFilterData);