import React from 'react';
import Button from '../../../components/Button/Button';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import generateRender from 'shared/utils/generateRender';

export default function CollectButton(props) {
  const RedemButton = generateRender({
    default: DefaultCollectButton,
    star: StarCollectButton,
  });
  return <RedemButton {...props} />;
}

const DefaultCollectButton = props => {
  return (
    <Button {...props} imageSource={require(asset`RoundedRect_Primary@3x.png`)} textStroke={BUTTON_STROKES.PRIMARY} />
  );
};

const StarCollectButton = props => {
  return (
    <Button {...props} imageSource={require(asset`RoundedRect_Tertiary@2x.png`)} textStroke={BUTTON_STROKES.TERTIARY} />
  );
};

