import { TooltipTypes } from '../../../utils/tooltipConstants';
export var EventsIconTootipDetail = function EventsIconTootipDetail(title) {
  var element = getEventsIconPositionAndStyle();
  var style = element === null || element === void 0 ? void 0 : element.tooltipStyle;
  var detail = {
    popUpType: TooltipTypes.EVENTS,
    title: "".concat(title, "..."),
    style: style
  };
  return detail;
};

var getEventsIconPositionAndStyle = function getEventsIconPositionAndStyle() {
  var targetElement = document.getElementById('EVENTS_ICON');
  var elementPosition = targetElement === null || targetElement === void 0 ? void 0 : targetElement.getBoundingClientRect();
  var x_pos = elementPosition === null || elementPosition === void 0 ? void 0 : elementPosition.x;
  var y_pos = elementPosition === null || elementPosition === void 0 ? void 0 : elementPosition.y;
  var tooltipStyle = {
    zIndex: 1000,
    left: x_pos ? x_pos - 120 : '10%',
    top: y_pos ? y_pos - 100 : '70%'
  };
  return {
    elementPosition: elementPosition,
    tooltipStyle: tooltipStyle
  };
};