import React, { Component } from 'react';
import {
  UpdateTermsBackgroundImage,
  TermsContainer,
  ErrorText,
  TermsTextContainer,
  TermsUpdateScreenTextWrapper,
  TermsImageWrapper,
  TermsImage,
  TermsFooterWrapper,
  TermsUpdateButtonText,
} from './styledComponents';
import SUpdateTerms from 'shared/screens/UpdateTerms';
import ImageBW from '../../../components/ImageBoundsWrapper';
import TermsModalWrapper from '../../../components/TermsModal/TermsModalComponents/TermsModalWrapper';
import { getText, TEXT_KEY } from 'shared/utils/localization';

export default class DefaultUpdateTerms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oauthLoading: false,
      statusMessage: null,
      emailPermissionAccepted: null,
    };
  }

  render() {
    const activeBG = require(asset`RoundedRect_Primary@3x.png`);
    const nonActiveBG = require(asset`RoundedRect_Disabled@3x.png`);
    const activeCheckbox = require(asset`Checkbox@3x.png`);
    const nonActiveCheckbox = require(asset`Checkbox_Deselected@3x.png`);

    return (
      <SUpdateTerms
        navigation={this.props.navigation}
        {...this.props}
        render={args => {
          const { setTerms, terms, onSubmit, error, disableClose, termsAndConditionsUrl, privacyPolicyUrl } = args;
          return (
            <>
              <UpdateTermsBackgroundImage alt="background" src={require(asset`images/Start/Splash_BG@3x.jpg`)} />
              <TermsModalWrapper
                isVisible={true}
                title={getText(TEXT_KEY.TERMS_AND_CONDITIONS_TITLE)}
                zIndex={101}
                disableClose={disableClose}
                hideCloseBtn={true}
              >
                <TermsContainer className="terms-container">
                  {error && <ErrorText>{error}</ErrorText>}
                  <TermsTextContainer>
                    <>
                      <TermsUpdateScreenTextWrapper>
                        {getText(TEXT_KEY.TERMS_UPDATE_SCREEN_TEXT)}
                      </TermsUpdateScreenTextWrapper>

                      <div>
                        <TermsImageWrapper>
                          <TermsImage
                            src={require(asset`images/TermsAndConditions_Buttons/TermsConditions@3x.png`)}
                            alt=""
                            resizeMode={'contain'}
                            onClick={() => window.open(termsAndConditionsUrl)}
                          />
                        </TermsImageWrapper>

                        <TermsImageWrapper>
                          <TermsImage
                            src={require(asset`images/TermsAndConditions_Buttons/PrivacyPolicy@3x.png`)}
                            alt=""
                            resizeMode={'contain'}
                            onClick={() => window.open(privacyPolicyUrl)}
                          />
                        </TermsImageWrapper>
                      </div>
                    </>
                  </TermsTextContainer>
                </TermsContainer>
                <TermsFooterWrapper className="terms-footer">
                  <div className="accept-terms-container">
                    <ImageBW
                      source={terms ? activeCheckbox : nonActiveCheckbox}
                      ibw={require(asset`Checkbox.ibw`)}
                      style={{
                        objectFit: 'contain',
                      }}
                      className="checkbox-image"
                      onClick={setTerms}
                    />
                    <p qa-id="checkboxTermsAndConditions" className="accept-terms-text" onClick={setTerms}>
                      {getText(TEXT_KEY.AGREE_TERMS)}
                    </p>
                  </div>
                  <div qa-id="continueBtn" className="continue-button-container" onClick={terms ? onSubmit : null}>
                    <ImageBW
                      source={terms ? activeBG : nonActiveBG}
                      ibw={require(asset`Continue_Button.ibw`)}
                      style={{ objectFit: 'contain' }}
                      className="continue-button"
                      innerStyle={{
                        cursor: 'pointer',
                      }}
                    >
                      <div className="continue-button-content">
                        <TermsUpdateButtonText termsState={terms} className="continue-button-text">
                          {getText(TEXT_KEY.CONTINUE_BUTTON)}
                        </TermsUpdateButtonText>
                      </div>
                    </ImageBW>
                  </div>
                </TermsFooterWrapper>
              </TermsModalWrapper>
            </>
          );
        }}
      />
    );
  }
}

