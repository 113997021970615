import React, { Component } from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import SFavouriteHelper from 'shared/screens/FavouriteHelper';
import Button from '../../components/Button/Button';

export default class FavouriteOnboardingDialogue extends Component {
  state = {};
  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-favouriteHelper',
    };
  }

  render() {
    return (
      <SFavouriteHelper
        navigation={this.props.navigation}
        {...this.props}
        render={args => {
          const { removeFavouriteGame } = args;
          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div>
                  <h1 style={{ fontSize: '25px' }}>{getText(TEXT_KEY.REMOVE_FAVOURITE_GAME)}</h1>
                  <h2 style={{ fontSize: '16px' }}>{getText(TEXT_KEY.ARE_YOU_SURE_REMOVE)}</h2>
                </div>
              </div>
              <div className="dialogueButtons">
                <Button
                  imageSource={require(asset`RoundedRect_Primary.png`)}
                  containerStyle={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    margin: '1em 0',
                  }}
                  onClick={this.props.dismissModal}
                  label={getText(TEXT_KEY.CANCEL_FAVOURITE)}
                />
                <Button
                  imageSource={require(asset`RoundedRect_Primary.png`)}
                  containerStyle={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    margin: '1em 0',
                  }}
                  onClick={() => {
                    this.props.dismissModal();
                    removeFavouriteGame();
                  }}
                  label={getText(TEXT_KEY.OK)}
                />
              </div>
            </div>
          );
        }}
      />
    );
  }
}

FavouriteOnboardingDialogue.hideDefaultBackground = false;
