import React from 'react';
import FacebookLinkOption from './options/FacebookLinkOption';
import GoogleLinkOption from './options/GoogleLinkOption';
import MemberLinkOption from './options/MemberLinkOption/index';
import { LinkAccountDividerWrapper, LinkAccountRowWrapper } from '../../styledComponents';
import styles from '../../styles';
import ImageBW from '../../../../../components/ImageBoundsWrapper';

const DefaultLinkAccount = props => {
  const { rowWrapperStyles = {}, isActionCommand = false } = props;

  return (
    <>
      <LinkAccountDividerWrapper disabled={isActionCommand}>
        <ImageBW
          style={styles.divider}
          source={require(asset`./images/Settings/Settings_Divider@3x.png`)}
          ibw={require(asset`./images/Settings/Settings_Divider.ibw`)}
          widthScale={0.5}
        />
      </LinkAccountDividerWrapper>
      <LinkAccountRowWrapper styled={rowWrapperStyles}>
        <FacebookLinkOption {...props} />
        <MemberLinkOption {...props} />
      </LinkAccountRowWrapper>
      <LinkAccountDividerWrapper disabled={isActionCommand}>
        <ImageBW
          style={styles.divider}
          source={require(asset`./images/Settings/Settings_Divider@3x.png`)}
          ibw={require(asset`./images/Settings/Settings_Divider.ibw`)}
          widthScale={0.5}
        />
      </LinkAccountDividerWrapper>
    </>
  );
};

export default DefaultLinkAccount;

