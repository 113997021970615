import React from 'react';
import styled from 'styled-components';
import Modal from '../../../../../../../components/JSModal';
export default function ModalContentWrapper(props) {
  const { isVisible, closeModal, children } = props;

  return (
    <Modal
      isVisible={isVisible}
      onBackButtonPress={() => closeModal(false)}
      zIndex={9999}
      hideCloseButton={false}
      hideOverlay={true}
      modalMainBodyStyle={Styles.modalMainBodyStyle}
      modalBodyStyle={Styles.modalBodyStyle}
      style={Styles.mainModalStyle}
    >
      <Container>{children}</Container>
    </Modal>
  );
}

const Container = styled.div`
width: 100%;
maxHeight: 70vh;
display: flex,
flex-direction: column;
align-tems: center;
justify-content: center;
flex-grow: 1;
`;

const Styles = {
  mainModalStyle: {
    width: '45rem',
    top: 0,
    left: 0,
    marginBottom: '10rem',
    inset: 'auto',
    backgroundColor: 'transparent',
  },
  modalBodyStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalMainBodyStyle: {
    minHeight: '20em',
    display: 'flex',
  },
};

