import WebFinishPurchase from './WebFinishPurchase';
import { connect } from '../../node_modules/react-redux';
import { addNotif as _addNotif } from '../../state/actions/notification';
import { clearPrePurchaseStore, isOutOfCoinsPurchase as _isOutOfCoinsPurchase, isLTOPurchase as _isLTOPurchase } from '../../state/actions/prePurchase';
import { showVipAccessModal as _showVipAccessModal, clearLTOOffer as _clearLTOOffer } from '../../state/actions/consumables';

var mapStateToProps = function mapStateToProps(state) {
  return {
    cardNickname: state.prePurchase.cardNickname,
    route: state.prePurchase.route,
    ltoPurchase: state.prePurchase.ltoPurchase,
    promoCode: state.prePurchase.promoCode,
    extraParams: state.prePurchase.routeParams,
    purchaseType: state.prePurchase.purchaseType,
    paymentProvider: state.shopList.paymentProvider,
    transactionUUID: state.purchase.transaction_uuid,
    promoLink: state.pendingMessages.find(function (pendingMessage) {
      return pendingMessage.type === 'PROMO_LINK';
    }),
    coin: state.coin,
    level: state.levelInfo.level,
    loyalty: state.loyalty,
    transactionId: state.prePurchase.transactionId,
    accessCode: state.prePurchase.accessCode,
    target: state.app.target
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    addNotif: function addNotif(notif) {
      dispatch(_addNotif(notif));
    },
    clearStore: function clearStore() {
      dispatch(clearPrePurchaseStore());
    },
    showVipAccessModal: function showVipAccessModal(value) {
      dispatch(_showVipAccessModal(value));
    },
    isOutOfCoinsPurchase: function isOutOfCoinsPurchase(value) {
      dispatch(_isOutOfCoinsPurchase(value));
    },
    isLTOPurchase: function isLTOPurchase(value) {
      dispatch(_isLTOPurchase(value));
    },
    clearLTOOffer: function clearLTOOffer() {
      dispatch(_clearLTOOffer());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WebFinishPurchase);