import styled from 'styled-components';

export const BlackOverlay = styled.div`
  ${props => `opacity: ${props.isVisible ? `1` : `0`};`}
  ${props => `display: ${props.isVisible ? `block` : `none`};`}
  z-index: 202;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  transition: opacity 500ms ease-out;
`;
