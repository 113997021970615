import React, { Component } from 'react';
import SAssetPreloader from 'shared/components/AssetPreloader';
import preload from '../../lib/load/preload';
import Utils from 'shared/utils/index';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import { ASSET_PRELOADER_ERROR, BOOLEANS } from 'shared/constants';

let images = [];
if (ENVIRONMENT_VARIABLES.PRELOAD_ASSETS === BOOLEANS.TRUE) {
  try {
    images = require(`../../manifests/image.manifest.${process.env['APPLICATION_TARGET']}.js`);
  } catch (e) {
    throw new Error(ASSET_PRELOADER_ERROR);
  }
}

export default class AssetPreloader extends Component {
  state = {};
  preloading = false;

  async preloadAssets(images, markAssetLoaded) {
    if (images.length == 0 || this.preloading == true) {
      return;
    }

    this.preloading = true;

    const promises = [];

    for (let image of images) {
      promises.push(
        preload(image.file).then(result =>
          markAssetLoaded({
            name: image.file,
            status: result,
          })
        )
      );
    }
    await Promise.all(promises);
  }
  render() {
    return (
      <SAssetPreloader
        images={images}
        preload={preload}
        {...this.props}
        render={args => {
          const { percentLoaded, markAssetLoaded } = args;
          this.preloadAssets(images, markAssetLoaded);
          return (
            <div
              className="AssetPreloader"
              style={{ backgroundImage: `url(${require(asset`images/AssetPreloader/loading_screen@3x.jpg`)})` }}
            >
              <div className={'AssetPreloaderLoadingBar' + (Utils.ieVersion() !== 0 ? ' ieHack' : '')}>
                <img
                  alt=""
                  className="ExpBarInset"
                  src={require(asset`images/AssetPreloader/Loading_Bar_Inset@3x.png`)}
                />
                <img
                  alt=""
                  src={require(asset`images/AssetPreloader/Loading_Bar_Fill@3x.png`)}
                  className="ExpBarInner"
                  style={{ width: `${percentLoaded > 3 ? percentLoaded - 2 : 3}%` }}
                />
              </div>
            </div>
          );
        }}
      />
    );
  }
}

