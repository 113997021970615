import { SET_DEFAULT_PANELS, SET_REGULAR_PANELS, SET_STICKY_PANELS } from './types';
export var setDefaultPanels = function setDefaultPanels(defaultPanels) {
  return {
    type: SET_DEFAULT_PANELS,
    payload: defaultPanels
  };
};
export var setRegularPanels = function setRegularPanels(regularPanels) {
  return {
    type: SET_REGULAR_PANELS,
    payload: regularPanels
  };
};
export var setStickyPanels = function setStickyPanels(stickyPanels) {
  return {
    type: SET_STICKY_PANELS,
    payload: stickyPanels
  };
};