import React, { Component } from 'react';
import { Styles } from './Styles';
import Utils from 'shared/utils';
import PlayerInterface from 'shared/api/PlayerInterface';
import { store } from 'shared/state/store';
import QueryParamsProxy from 'shared/utils/queryParamProxy';
import RouteParamConstants from 'shared/utils/routeParamConstants';
import TermsModalWrapper from '../../components/TermsModal/TermsModalComponents/TermsModalWrapper';
import Validators from 'shared/components/Form/InputValidator';
import { setToken, setRestart, setAuthorized } from 'shared/state/actions/auth';
import { setPlayerId } from 'shared/state/actions/playerInfo';
import { getText, TEXT_KEY } from 'shared/utils/localization';

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirmPassword: '',
      error: false,
      message: '',
      response: false,
    };
  }

  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-signup',
    };
  }

  isBodyValid = () =>
    Validators.NOT_EMPTY(this.state.password) &&
    Validators.PASSWORD(this.state.password) &&
    this.state.password === this.state.confirmPassword;

  onSumbitButtonClick = async (id, token) => {
    const { password } = this.state;
    if (!this.isBodyValid()) {
      this.setState({
        error: true,
        message: getText(TEXT_KEY.INVALID_BODY_MESSAGE),
      });
      return false;
    }
    this.setState({
      error: false,
      message: getText(TEXT_KEY.SENDING_NEW_PASSWORD_REQUEST),
    });
    const passwordChangeReq = await PlayerInterface.recoverPassword({ id, token, password });
    if (passwordChangeReq.success) {
      this.setState({
        error: false,
        response: true,
        message: passwordChangeReq.msg,
      });
      QueryParamsProxy.setQueryParams({ [RouteParamConstants.PARAM_RECOVER_PASS_ID]: undefined });
      QueryParamsProxy.setQueryParams({ [RouteParamConstants.PARAM_RECOVER_PASS_TOKEN]: undefined });
      QueryParamsProxy.setQueryParams({ [RouteParamConstants.PARAM_RECOVER_PASS_PLATFORM]: undefined });
    } else {
      this.setState({
        error: true,
        response: true,
        message: passwordChangeReq.error,
      });
    }
  };

  render() {
    const {
      isVisible,
      closeModal,
      title,
      zIndex,
      modalStyle,
      modalContainerStyle,
      titleStyle,
      invalid,
      errorMessage,
      recoverId,
      recoverToken,
    } = this.props;
    return (
      <TermsModalWrapper
        isVisible={isVisible}
        zIndex={zIndex}
        title={title}
        modalStyle={modalStyle ? modalStyle : Styles.modalStyle}
        modalContainerStyle={modalContainerStyle ? modalContainerStyle : Styles.modalContainerStyle}
        titleStyle={titleStyle ? titleStyle : Styles.titleStyle}
        onBackButtonPress={closeModal}
        closeModal={closeModal}
        removeRightPart={true}
        removeLeftPart={true}
      >
        <div style={{ height: '80%' }}>
          <div body style={Styles.body}>
            {!invalid && !this.state.response ? (
              <>
                <p style={{ textAlign: 'center' }}>
                  {!this.state.response
                    ? getText(TEXT_KEY.TYPE_NEW_PASSWORD_BELOW)
                    : !this.state.error
                    ? getText(TEXT_KEY.PASSWORD_HAS_BEEN_RESET)
                    : ''}
                </p>
                {this.state.message && (
                  <div style={Styles.messageContainer}>
                    <p style={!this.state.error ? Styles.successMessage : Styles.errorMessage}>{this.state.message}</p>
                  </div>
                )}
                <div className="recoverPasswordForm" style={{ width: '90%' }}>
                  <label style={Styles.inputLabel}>
                    <h5 style={Styles.labelColor}>{getText(TEXT_KEY.NEW_PASSWORD)}</h5>
                    <input
                      style={Styles.nameInputField}
                      type="password"
                      maxLength="50"
                      value={this.state.password}
                      onChange={e => this.setState({ password: e.target.value })}
                    />
                  </label>
                  <label style={Styles.inputLabel}>
                    <h5 style={Styles.labelColor}>{getText(TEXT_KEY.CONFIRM_PASSWORD)}</h5>
                    <input
                      style={Styles.nameInputField}
                      type="password"
                      maxLength="50"
                      value={this.state.confirmPassword}
                      onChange={e => this.setState({ confirmPassword: e.target.value })}
                    />
                  </label>
                </div>
                {this.state.error && <div style={Styles.errorMessage}>{this.state.Message}</div>}
                <div style={window.innerWidth > 600 ? Styles.registerButton : Styles.registerButtonPortrait}>
                  {this.state.response && !this.state.error ? (
                    <div style={Styles.linkBtn} onClick={closeModal}>
                      {getText(TEXT_KEY.THANKS)}!
                    </div>
                  ) : (
                    <>
                      <div style={Styles.linkBtn} onClick={() => this.onSumbitButtonClick(recoverId, recoverToken)}>
                        {getText(TEXT_KEY.SUBMIT)}
                      </div>

                      <div style={Styles.linkBtnOrange} onClick={closeModal}>
                        {getText(TEXT_KEY.CANCEL)}
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                <div style={Styles.messageContainer}>
                  {this.state.response && !this.state.error ? (
                    <p style={{ textAlign: 'center' }}>{this.state.message}</p>
                  ) : (
                    <p style={Styles.errorMessage}>{errorMessage || this.state.message}</p>
                  )}
                </div>
                <div style={Styles.errorButtonHolder}>
                  {this.state.response && !this.state.error ? (
                    <div style={Styles.linkBtn} onClick={closeModal}>
                      {getText(TEXT_KEY.THANKS)}!
                    </div>
                  ) : (
                    <div style={Styles.linkBtnOrange} onClick={closeModal}>
                      {getText(TEXT_KEY.OK)}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </TermsModalWrapper>
    );
  }
}

