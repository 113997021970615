import React, { useContext } from 'react';
import styled from 'styled-components';
import ThemeContext from 'shared/context/ThemeContext';
import QueryParamsProxy from 'shared/utils/queryParamProxy';
import Screens from 'shared/screens';
import { EVENTS_FEATURE_STATUS } from 'shared/screens/Events/constants';
import useEventsIconTooltip from 'shared/screens/Events/hooks/useEventsIconTooltip';
import useCheckEventsState from 'shared/screens/Events/hooks/useCheckEventsState';
import { useSelector } from 'shared/node_modules/react-redux';
import { eventsIconRender } from 'shared/screens/Events/utils/generateEventsIconRender';

export default function EventsIcon(props) {
  const { navigation } = props;
  const themeContext = useContext(ThemeContext);

  const { eventsState, eventsCount, eventsAssetsPackageId } = useCheckEventsState();
  const eventsAssets = useSelector(state => state.assetsPackage.assets.EVENTS_FEATURE?.[eventsAssetsPackageId]);

  const { handleEventsIconTooltip } = useEventsIconTooltip({ eventsState, navigation });

  const handleEventsButton = () =>
    eventsState === EVENTS_FEATURE_STATUS.ACTIVE && eventsAssets
      ? QueryParamsProxy.navigate(navigation, Screens.Events)
      : handleEventsIconTooltip();

  return (
    <React.Fragment>
      <img
        alt="divider"
        src={require(asset`images/Bottom_Bar_Assets/Divider@3x.png`)}
        style={themeContext.BottomBar.Divider}
      />
      <div id="EVENTS_ICON" className="BarItem" onClick={handleEventsButton}>
        <EventIcon
          state={eventsState}
          eventsAssets={eventsAssets}
          draggable="false"
          theme={themeContext.BottomBar.BarItemIcon}
        />
        {eventsState === EVENTS_FEATURE_STATUS.ACTIVE && eventsCount !== 0 && (
          <EventNotificationBadge rightSpace={eventsCount > 9 ? 3 : 0} eventsAssets={eventsAssets}>
            {eventsCount}
          </EventNotificationBadge>
        )}
      </div>
    </React.Fragment>
  );
}

const EventIcon = styled.img.attrs(({ state, eventsAssets = null }) => ({
  src: eventsAssets ? eventsIconRender(eventsAssets)[state] : eventsIconRender()[EVENTS_FEATURE_STATUS.MAINTENANCE],
  alt: 'Bottom Bar Events',
}))`
  ${({ theme }) => theme}
`;

const EventNotificationBadge = styled.div`
  ${({ theme }) => theme}
  background-image: url(${({ eventsAssets }) => eventsAssets?.EVENTS_NOTIFICATION_BADGE});
  background-size: cover;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding-top: 0.6rem;
  padding-right: ${({ rightSpace }) => rightSpace}px;
  left: 5px;
  top: -10px;
  width: 2.5rem;
  z-index: 1;
  display: flex;
  font-size: 1rem;
  font-weight: 800 !important;
  color: #4c0e00;
`;

