import LivePanel from './LivePanel';
import { connect } from '../../node_modules/react-redux';

var mapStateToProps = function mapStateToProps(state) {
  return {
    assetsUrls: state.app.assetsUrls,
    isAccountLinked: state.playerInfo.isAccountLinked === 'yes'
  };
};

export default connect(mapStateToProps)(LivePanel);