import SideMenu from './SideMenu';
import { connect } from '../../node_modules/react-redux';
import { clearToken as _clearToken } from '../../state/actions/auth';
import { ONBOARDING_FLOWS } from '../../utils/onboarding';

var mapStateToProps = function mapStateToProps(state) {
  return {
    // Add state from store
    userCanFavourite: parseInt(state.levelInfo.level) >= parseInt(state.ftue.onboardingsUnlockLevels[ONBOARDING_FLOWS.FAVOURITES]),
    navState: state.navState.navState,
    sidebarState: state.sidebarState.sidebarState,
    isVipRoomUnlocked: state.consumables.isVipRoomUnlocked
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    clearToken: function clearToken() {
      dispatch(_clearToken());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);