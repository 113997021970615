var ACCOUNT_DELETION = 'ACCOUNT_DELETION';
var EMBEDED_GAMES = 'EMBEDED_GAMES';
var LOGIN = 'LOGIN';
var ODR_GAMES = 'ODR_GAMES';
export var FLAGS_MOCK = [ACCOUNT_DELETION, EMBEDED_GAMES, LOGIN, ODR_GAMES];

var alwaysTrueFeatureFlagsProvider = function alwaysTrueFeatureFlagsProvider(playerId, handlerCallback) {
  return handlerCallback(FLAGS_MOCK);
};

export default alwaysTrueFeatureFlagsProvider;