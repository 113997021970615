export var ANIMATION_TYPE = {
  SIDE_MENU_DOT_ANIMATION: 'SIDE_MENU_DOT_ANIMATION',
  NAVIGATION_BAR_ANIMATION: 'NAVIGATION_BAR_ANIMATION',
  BUY_BUTTON_ANIMATION: 'BUY_BUTTON_ANIMATION',
  BUY_BUTTON_SPARKLE_ANIMATION: 'BUY_BUTTON_SPARKLE_ANIMATION',
  MISSION_PROGRESS_ANIMATION: 'MISSION_PROGRESS_ANIMATION',
  CARD_SPARKLE_ANIMATION: 'CARD_SPARKLE_ANIMATION',
  PROMO_BANNER_REGULAR_ANIMATION: 'PROMO_BANNER_REGULAR_ANIMATION',
  PROMO_BANNER_SPECIAL_ANIMATION: 'PROMO_BANNER_SPECIAL_ANIMATION',
  COLLECT_COINS_ANIMATION: 'COLLECT_COINS_ANIMATION',
  TIMED_BONUS_FLYING_COINS_ANIMATION: 'TIMED_BONUS_FLYING_COINS_ANIMATION',
  MISSIONS_LOBBY_ICON_ANIMATION: 'MISSIONS_LOBBY_ICON_ANIMATION',
  XP_BAR_ANIMATION: 'XP_BAR_ANIMATION'
};
export var ANIMATION = {// [ANIMATION_TYPE.SIDE_MENU_DOT_ANIMATION]: require('./_mockData/side_menu_dot_animation.json'),
  // [ANIMATION_TYPE.NAVIGATION_BAR_ANIMATION]: require('./_mockData/navigation-bar-shine-animation.json'),
  // [ANIMATION_TYPE.BUY_BUTTON_ANIMATION]: require('./_mockData/buy-button-shine-animation.json'),
  // [ANIMATION_TYPE.BUY_BUTTON_SPARKLE_ANIMATION]: require('./_mockData/buy_button_sparkle_animation.json'),
  // [ANIMATION_TYPE.MISSION_PROGRESS_ANIMATION]: require('./_mockData/mission_progress_animation.json'),
  // [ANIMATION_TYPE.CARD_SPARKLE_ANIMATION]: require('./_mockData/card_sparkle_animation.json'),
  // [ANIMATION_TYPE.PROMO_BANNER_REGULAR_ANIMATION]: require('./_mockData/promo_banner_regular_animation.json'),
  // [ANIMATION_TYPE.PROMO_BANNER_SPECIAL_ANIMATION]: require('./_mockData/promo_banner_special_animation.json'),
  // [ANIMATION_TYPE.COLLECT_COINS_ANIMATION]: require('./_mockData/collect_coins_animation.json'),
  // [ANIMATION_TYPE.TIMED_BONUS_FLYING_COINS_ANIMATION]: require('./_mockData/flying_coins_animation.json'),
  // [ANIMATION_TYPE.MISSIONS_LOBBY_ICON_ANIMATION]: require('./_mockData/missions-lobby-icon-animation.json'),
  // [ANIMATION_TYPE.XP_BAR_ANIMATION]: require('./_mockData/xp_bar_animation.json'),
};