import theme from 'shared/assets/style/theme';
import defaultTheme from './defaultTheme';
import responsiveFontSize from '../lib/responsiveFontSize';

const windowWidth = window.innerWidth;
const windowHeight = window.innerHeight;

const AdmesTheme = {
  ...defaultTheme,
  Messages: {
    ...defaultTheme.Messages,
    HeaderTitleContainer: {
      ...defaultTheme.Messages.HeaderTitleContainer,
      height: '10%',
      widthScale: 0.45,
      width: '99.5%',
    },
    EmptyInboxContainer: {
      ...defaultTheme.Messages.EmptyInboxContainer,
      gap: '1rem',
    },
  },
  Start: {
    ...defaultTheme.Start,
    AdmiralBottomBarContainer: {
      width: '650px',
    },
  },
  FtueTooltip: {
    ...defaultTheme.FtueTooltip,
    TooltipTitleStyle: {
      ...defaultTheme.FtueTooltip.TooltipTitleStyle,
      fontSize: '1.1rem',
      fontWeight: '400',
    },
    TooltipRewardStyle: {
      ...defaultTheme.FtueTooltip.TooltipRewardStyle,
      fontSize: '1.2rem',
      fontWeight: '500',
    },
    BiggerBetsStyle: {
      ...defaultTheme.FtueTooltip.BiggerBetsStyle,
      fontSize: '1.1rem',
      fontWeight: '400',
    },
  },
  VipAccessFlow: {
    ...defaultTheme.VipAccessFlow,
    VipAccessImage: {
      ...defaultTheme.VipAccessFlow.VipAccessImage,
      width: '70%',
    },
  },
  BottomBar: {
    ...defaultTheme.BottomBar,
    Divider: {
      ...defaultTheme.BottomBar.Divider,
      width: 4,
    },
    BottomBarBackground: {
      ...defaultTheme.BottomBar.BottomBarBackground,
      height: '8rem',
      backgroundPosition: 'top',
    },
    BottomBarContainer: {
      ...defaultTheme.BottomBar.BottomBarContainer,
      maxWidth: '90%',
    },
    VIPIcon: {
      ...defaultTheme.BottomBar.VIPIcon,
      height: '9em',
    },
    BarItemIcon: {
      ...defaultTheme.BottomBar.BarItemIcon,
      height: '7em',
    },
    BarMissionsIcon: {
      height: '5.5em',
    },
    Notification: {
      ...defaultTheme.BottomBar.Notification,
      right: windowWidth < 1500 ? '10%' : windowWidth < 1700 ? '15%' : '20%',
      top: 10,
    },
    MissionsIconAnimation: {
      ...defaultTheme.BottomBar.MissionsIconAnimation,
      height: '8.5em',
      bottom: 0,
      left: 35,
    },
    MissionsIconText: {
      ...defaultTheme.BottomBar.MissionsIconText,
      height: '1.3em',
    },
    MissionsIcon: {
      ...defaultTheme.BottomBar.MissionsIcon,
      height: '5.5em',
    },
    TimerLabel: {
      ...defaultTheme.BottomBar.TimerLabel,
      position: 'absolute',
      width: null,
      textAlign: 'center',
      fontWeight: 600,
      fontSize: '0.8em',
      lineHeight: 1,
      bottom: null,
      zIndex: 2,
    },
    BarItem: {
      ...defaultTheme.BottomBar.BarItem,
      paddingBottom: '0.5rem',
    },
    TimerWrapper: {
      ...defaultTheme.BottomBar.TimerWrapper,
      height: null,
      justifyContent: 'center',
    },
    TimerBackground: {
      ...defaultTheme.BottomBar.TimerBackground,
      position: 'relative',
      bottom: null,
      width: '13rem',
    },
  },
  DailySpin: {
    ...defaultTheme.DailySpin,
    MilestoneLoyaltyValue: {
      ...defaultTheme.DailySpin.MilestoneLoyaltyValue,
      marginLeft: -3,
    },
    SpinButtonText: {
      ...defaultTheme.DailySpin.SpinButtonText,
      fontSize: '2.4rem',
    },
    BottomBarLeftContainerNoCard: {
      ...defaultTheme.DailySpin.BottomBarLeftContainerNoCard,
      maxWidth: '190px',
      width: '190px',
    },
    BottomBarLeftContainerWithCard: {
      ...defaultTheme.DailySpin.BottomBarLeftContainerWithCard,
      maxWidth: '170px',
      width: '170px',
    },
    BottomBarLeftContainerWithCardText: {
      ...defaultTheme.DailySpin.BottomBarLeftContainerWithCardText,
      fontSize: '0.7rem',
    },
    BottomBarBottomText: {
      ...defaultTheme.DailySpin.BottomBarBottomText,
      height: '40px',
      marginBottom: windowHeight < 900 ? -15 : -20,
      objectFit: 'contain',
    },
  },
  GameTile: {
    ...defaultTheme.GameTile,
    LivePanelItem: {
      ...defaultTheme.GameTile.LivePanelItem,
      height: '40%',
    },
  },
  Chest: {
    ...defaultTheme.Chest,
    ChestAnimation: {
      ...defaultTheme.Chest.ChestAnimation,
      height: 146.78,
      width: 155,
    },
    ChestAnimationEnd: {
      ...defaultTheme.Chest.ChestAnimationEnd,
      height: 166,
      width: 175,
    },
  },
  VideoAds: {
    ...defaultTheme.VideoAds,
    CloseText: {
      ...defaultTheme.VideoAds.CloseText,
      fontSize: '17px',
      fontWeight: 900,
    },
    KeepWatchingText: {
      ...defaultTheme.VideoAds.KeepWatchingText,
      width: '100%',
      lineHeight: '1.2',
      position: 'relative',
      paddingBottom: '0px !important',
      fontWeight: 900,
    },
  },
  CustomerSupport: {
    ...defaultTheme.CustomerSupport,
    GuestMessageText: {
      ...defaultTheme.CustomerSupport.GuestMessageText,
      color: theme.palette.common[1],
      textDecoration: 'underline',
      padding: '1%',
    },
  },
  RewardListItem: {
    ...defaultTheme.RewardListItem,
    RewardImagePlaceholderContainer: {
      ...defaultTheme.RewardListItem.RewardImagePlaceholderContainer,
      borderRadius: '30px',
    },
    OfferCost: {
      ...defaultTheme.RewardListItem.OfferCost,
      height: '11%',
      top: '-28.5%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '4px',
      paddingRight: '4%',
      marginBottom: '0.5em',
    },
    LoyaltyPointsText: {
      ...defaultTheme.RewardListItem.LoyaltyPointsText,
      marginLeft: -5,
    },
  },
  WelcomeBonus: {
    ...defaultTheme.WelcomeBonus,
    CoinIcon: {
      ...defaultTheme.WelcomeBonus.CoinIcon,
      width: '3%',
    },
    BonusAmount: {
      ...defaultTheme.WelcomeBonus.BonusAmount,
      fontSize: responsiveFontSize(30, 28),
    },
    OfferDetails: {
      ...defaultTheme.WelcomeBonus.OfferDetails,
      bottom: '6vw',
    },
  },
  BonusLinkMessage: {
    ...defaultTheme.BonusLinkMessage,
    LPBonusContainer: {
      ...defaultTheme.BonusLinkMessage.LPBonusContainer,
      paddingTop: 0,
      bottom: 0,
    },
    CoinsBonusContainer: {
      ...defaultTheme.BonusLinkMessage.CoinsBonusContainer,
      bottom: '-3rem',
    },
    BonusInfoContainer: {
      ...defaultTheme.BonusLinkMessage.BonusInfoContainer,
      alignItems: 'center',
      gap: '1rem',
    },
    CoinImage: {
      ...defaultTheme.BonusLinkMessage.CoinImage,
      width: '2.5vh',
      height: '2.5vh',
      marginBottom: '0.5rem',
      marginRight: 0,
    },
  },
  Login: {
    ...defaultTheme.Login,
    LoginButtonText: {
      ...defaultTheme.Login.LoginButtonText,
      fontSize: '20px',
    },
  },
  NavigationBar: {
    ...defaultTheme.NavigationBar,
    BarLineAnimation: {
      ...defaultTheme.NavigationBar.BarLineAnimation,
      top: windowWidth < 2000 ? 8 : 9,
    },
    SplittedLeftButton: {
      ...defaultTheme.NavigationBar.SplittedLeftButton,
      fontSize: '1.3rem',
      fontWeight: 900,
      lineHeight: 3,
      left: 6,
    },
    SplittedRightButton: {
      fontSize: '1.4rem',
      fontWeight: 900,
      marginTop: '10px !important',
      paddingRight: '10px',
    },
  },
  CoinItem: {
    ...defaultTheme.CoinItem,
    CoinAmount: {
      ...defaultTheme.CoinItem.CoinAmount,
      fontSize: '22px',
    },
    Extra: {
      ...defaultTheme.CoinItem.Extra,
      fontSize: '1.2vmin',
      marginTop: 0,
    },
    BuyButtonText: {
      ...defaultTheme.CoinItem.BuyButtonText,
      fontSize: '18px',
    },
  },
  Tier: {
    ...defaultTheme.Tier,
    Card: {
      ...defaultTheme.Tier.Card,
      height: 50,
      width: 70,
    },
    TierWrapper: {
      ...defaultTheme.Tier.TierWrapper,
      display: 'flex',
      alignItems: 'center',
      fontWeight: 700,
      marginLeft: 'auto',
      marginRight: 'auto',
      justifyContent: 'center',
    },
  },
  OutOfCoin: {
    ...defaultTheme.OutOfCoin,
    VisitStoreText: {
      ...defaultTheme.OutOfCoin.VisitStoreText,
      fontSize: 20,
      fontWeight: 800,
    },
    QuickBuyOfferDetails: {
      ...defaultTheme.OutOfCoin.QuickBuyOfferDetails,
      bottom: '17%',
    },
  },
  TermsModal: {
    ...defaultTheme.TermsModal,
    ContactUsButtonTextStyle: {
      ...defaultTheme.TermsModal.ContactUsButtonTextStyle,
      fontSize: 22,
    },
  },
  Favourites: {
    ...defaultTheme.Favourites,
    FavouritesImageContainer: {
      ...defaultTheme.Favourites.FavouritesImageContainer,
      alignItems: 'center',
      height: '85%',
      maxWidth: '65%',
    },
  },
  ShopScreen: {
    ...defaultTheme.ShopScreen,
    ShopContainer: {
      ...defaultTheme.ShopScreen.ShopContainer,
      PackagesContainer: {
        ...defaultTheme.ShopScreen.ShopContainer.PackagesContainer,
        CoinItemContainer: {
          ...defaultTheme.ShopScreen.ShopContainer.PackagesContainer.CoinItemContainer,
          marginTop: windowWidth < 1300 && windowHeight > 850 ? '30px' : windowWidth > 1800 ? '-25px' : '8px',
        },
        CoinItem: {
          ...defaultTheme.ShopScreen.ShopContainer.PackagesContainer.CoinItem,
          left: windowWidth < 1400 ? '1.8%' : windowWidth > 1800 ? '2.2%' : '20px',
          width: '95%',
        },
      },
    },
  },
  LimitedTimeOffer: {
    ...defaultTheme.LimitedTimeOffer,
    LTOWrapper: {
      ...defaultTheme.LimitedTimeOffer.LTOWrapper,
      inset: '-8.5% 0 0',
    },
    MainContainer: {
      ...defaultTheme.LimitedTimeOffer.MainContainer,
      justifyContent: 'flex-end',
    },
    ButtonContainer: {
      top: '82%',
    },
    BuyButtonTextStyle: {
      ...defaultTheme.LimitedTimeOffer.BuyButtonTextStyle,
      fontSize: '1.8em',
      fontWeight: 750,
      letterSpacing: '0.5px',
    },
  },
  Settings: {
    ...defaultTheme.Settings,
    SignoutButtonText: {
      ...defaultTheme.Settings.SignoutButtonText,
      fontSize: '1.3rem',
      fontWeight: 800,
    },
  },
  OnboardingLayer: {
    ...defaultTheme.OnboardingLayer,
    MessageTitleTooltip: {
      ...defaultTheme.OnboardingLayer.MessageTitleTooltip,
      fontSize: '1.2rem',
    },
  },
  VipGames: {
    ...defaultTheme.VipGames,
    TimerLabel: {
      ...defaultTheme.VipGames.TimerLabel,
      color: theme.palette.common[1],
    },
  },
  JSModal: {
    ...defaultTheme.JSModal,
    ModalBackgroundAsset: {
      ...defaultTheme.JSModal.ModalBackgroundAsset,
      widthScale: 0.5,
    },
  },
  TermsModalWrapper: {
    ...defaultTheme.TermsModalWrapper,
    Modal: {
      ...defaultTheme.TermsModalWrapper.Modal,
      padding: '0 20vmin !important',
    },
  },
  DynamicPopupsButtons: {
    ...defaultTheme.DynamicPopupsButtons,
    ButtonsContainer: {
      ...defaultTheme.DynamicPopupsButtons.ButtonsContainer,
      bottom: '5%',
    },
    RowWrapperLink: {
      ...defaultTheme.DynamicPopupsButtons.RowWrapperLink,
      width: '95%',
    },
  },
};

export default AdmesTheme;

