import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../api/services/rtkQuery/axiosBaseQuery';
import { getGameRoomsQuery } from './queries';
export var gamesApi = createApi({
  reducerPath: 'gamesQuery',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['GetGameRooms'],
  endpoints: function endpoints(builder) {
    return {
      getGameRooms: builder.query(getGameRoomsQuery)
    };
  }
});
var useGetGameRoomsQuery = gamesApi.useGetGameRoomsQuery;
export { useGetGameRoomsQuery };
var getGameRooms = gamesApi.endpoints.getGameRooms,
    gamesReducerPath = gamesApi.reducerPath,
    gamesQueryReducer = gamesApi.reducer,
    gamesMiddleware = gamesApi.middleware;
export { gamesReducerPath, gamesQueryReducer, gamesMiddleware, getGameRooms };