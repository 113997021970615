import React from 'react';
import Button from '../../Button/Button';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import generateRender from 'shared/utils/generateRender';

export default function HistoryDetailButton(props) {
  const RedemButton = generateRender({
    default: DefaultHistoryDetailButton,
    star: StarHistoryDetailButton,
  });
  return <RedemButton {...props} />;
}

const DefaultHistoryDetailButton = props => {
  return (
    <Button
      {...props}
      imageSource={require(asset`RoundedRect_Quaternary.png`)}
      textStroke={BUTTON_STROKES.QUATERNARY}
    />
  );
};

const StarHistoryDetailButton = props => {
  return (
    <Button {...props} imageSource={require(asset`RoundedRect_Quinary.png`)} textStroke={BUTTON_STROKES.QUINARY} />
  );
};

