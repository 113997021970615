import defaultTheme from './defaultTheme';
const windowWidth = window.innerWidth;
const SgaTheme = {
  ...defaultTheme,
  LimitedTimeOffer: {
    ...defaultTheme.LimitedTimeOffer,
    BannerContainer: {
      ...defaultTheme.LimitedTimeOffer.BannerContainer,
      marginBottom: '2%',
    },
    BuyButton: {
      ...defaultTheme.LimitedTimeOffer.BuyButton,
      transform: 'translateX(1%)',
    },
  },
  MessageDetails: {
    ...defaultTheme.MessageDetails,
    MessageTitle: {
      ...defaultTheme.MessageDetails.MessageTitle,
      marginBottom: '0.5rem',
    },
  },
  NavigationBar: {
    ...defaultTheme.NavigationBar,
    LoyaltyCurrencyWrapperStyle: {
      ...defaultTheme.NavigationBar.LoyaltyCurrencyWrapperStyle,
      right: windowWidth < 1400 ? 'calc(50% - 185px)' : 'calc(50% - 200px)',
    },
    BarLineAnimation: {
      ...defaultTheme.NavigationBar.BarLineAnimation,
      top: windowWidth < 2000 ? 9 : 10,
    },
  },
  Start: {
    ...defaultTheme.Start,
    BottomBarFooterText: {
      ...defaultTheme.Start.BottomBarFooterText,
      marginTop: 5,
      marginBottom: 0,
    },
  },
  RewardListItem: {
    ...defaultTheme.RewardListItem,
    OfferNameText: {
      ...defaultTheme.RewardListItem.OfferNameText,
      paddingTop: 4,
    },
    LoyaltyPointsText: {
      ...defaultTheme.RewardListItem.LoyaltyPointsText,
      paddingTop: 5,
    },
  },
  Tier: {
    ...defaultTheme.Tier,
    Card: {
      ...defaultTheme.Tier.Card,
      height: 60,
      width: 85,
    },
    TierWrapper: {
      ...defaultTheme.Tier.TierWrapper,
      marginRight:
        windowWidth < 1300 ? '85px !important' : windowWidth < 1400 ? '105px !important' : '103px !important',
    },
    CardAnimation: {
      ...defaultTheme.Tier.CardAnimation,
      left: -30,
    },
  },
  DailySpin: {
    ...defaultTheme.DailySpin,
    WheelItems: {
      ...defaultTheme.DailySpin.WheelItems,
      fontSize: '2.1rem',
    },
    StreakDaysRowNumber: {
      ...defaultTheme.DailySpin.StreakDaysRowNumber,
      paddingRight: 3,
      paddingTop: 4,
      paddingBottom: 0,
    },
    StreakDaysRowValues: {
      ...defaultTheme.DailySpin.StreakDaysRowValues,
      marginTop: 5,
      lineHeight: 0,
    },
    MilestoneCoinsValue: {
      ...defaultTheme.DailySpin.MilestoneCoinsValue,
      marginBottom: 0,
    },
    MilestoneLoyaltyValue: {
      ...defaultTheme.DailySpin.MilestoneLoyaltyValue,
      marginBottom: -7,
    },
    BottomRightCoinImage: {
      ...defaultTheme.DailySpin.BottomRightCoinImage,
      marginBottom: 5,
    },
    BottomBarBackgroundWrapperImage: {
      ...defaultTheme.DailySpin.BottomBarBackgroundWrapperImage,
      height: '105%',
    },
  },
  CoinItem: {
    ...defaultTheme.CoinItem,
    CoinAmount: {
      ...defaultTheme.CoinItem.CoinAmount,
      fontSize: '25px',
    },
    Extra: {
      ...defaultTheme.CoinItem.Extra,
      fontSize: '0.9vmin',
      marginTop: 0,
    },
  },
  CreditCardDetails: {
    ...defaultTheme.CreditCardDetails,
    CheckBoxBackgroundImage: {
      ...defaultTheme.CreditCardDetails.CheckBoxBackgroundImage,
      width: '40%',
    },
  },
  MissionsProgress: {
    ...defaultTheme.MissionsProgress,
    MissionIcon: {
      ...defaultTheme.MissionsProgress.MissionIcon,
      right: windowWidth < 1400 ? 'calc(50% - 320px)' : 'calc(50% - 335px)',
    },
    MissionIconWithoutCard: {
      ...defaultTheme.MissionsProgress.MissionIconWithoutCard,
      right: windowWidth < 1400 ? 'calc(50% - 360px)' : 'calc(50% - 385px)',
    },
    BaseBar: {
      ...defaultTheme.MissionsProgress.BaseBar,
      right: windowWidth < 1400 ? 'calc(50% - 444px)' : 'calc(50% - 509px)',
    },
    BaseBarWithoutCard: {
      ...defaultTheme.MissionsProgress.BaseBarWithoutCard,
      right: windowWidth < 1400 ? 'calc(50% - 534px)' : 'calc(50% - 559px)',
    },
    FillBar: {
      ...defaultTheme.MissionsProgress.FillBar,
      right: windowWidth < 1400 ? 'calc(50% - 475px)' : 'calc(50% - 507px)',
    },
    FillBarWithoutCard: {
      ...defaultTheme.MissionsProgress.FillBarWithoutCard,
      right: windowWidth < 1400 ? 'calc(50% - 532px)' : 'calc(50% - 557px)',
    },
    TimeBar: {
      ...defaultTheme.MissionsProgress.TimeBar,
      right: windowWidth < 1400 ? 'calc(50% - 437px)' : 'calc(50% - 482px)',
    },
    TimeBarWithoutCard: {
      ...defaultTheme.MissionsProgress.TimeBarWithoutCard,
      right: windowWidth < 1400 ? 'calc(50% - 507px)' : 'calc(50% - 532px)',
    },
    TimeRemainingText: {
      ...defaultTheme.MissionsProgress.TimeRemainingText,
      top: windowWidth < 1400 ? 51 : 56,
      right: windowWidth < 1400 ? 'calc(50% - 407px)' : 'calc(50% - 445px)',
      fontSize: windowWidth < 1400 ? 14 : 18,
    },
    TimeRemainingTextWithoutCard: {
      ...defaultTheme.MissionsProgress.TimeRemainingTextWithoutCard,
      top: 56,
      right: windowWidth < 1400 ? 'calc(50% - 475px)' : 'calc(50% - 505px)',
    },
    CollectBar: {
      ...defaultTheme.MissionsProgress.CollectBar,
      right: windowWidth < 1400 ? 'calc(50% - 452px)' : 'calc(50% - 513px)',
    },
    CollectBarWithoutCard: {
      ...defaultTheme.MissionsProgress.CollectBarWithoutCard,
      right: windowWidth < 1400 ? 'calc(50% - 537px)' : 'calc(50% - 563px)',
    },
    FillProgress: {
      ...defaultTheme.MissionsProgress.FillProgress,
      marginTop: windowWidth < 1400 ? '-22px' : '-20px',
      marginLeft: windowWidth < 1400 ? '-20px' : '-25px',
    },
    FillProgressWithoutCard: {
      ...defaultTheme.MissionsProgress.FillProgressWithoutCard,
      marginTop: '-20px',
      marginLeft: '-25px',
    },
    FillEnd: {
      ...defaultTheme.MissionsProgress.FillEnd,
      marginTop: windowWidth < 1400 ? '-22px' : '-20px',
    },
    FillEndWithoutCard: {
      ...defaultTheme.MissionsProgress.FillEndWithoutCard,
      marginTop: '-20px',
    },
    ProgressAnimation: {
      ...defaultTheme.MissionsProgress.ProgressAnimation,
      bottom: windowWidth < 1400 ? '-150%' : '-75%',
      left: windowWidth < 1400 ? '-15%' : '-9%',
    },
  },
  ShopScreen: {
    ...defaultTheme.ShopScreen,
    ShopContainer: {
      ...defaultTheme.ShopScreen.ShopContainer,
      PackagesContainer: {
        ...defaultTheme.ShopScreen.ShopContainer.PackagesContainer,
        padding: '6% 5.5rem 0.4rem',
      },
    },
  },
  Events: {
    ...defaultTheme.Events,
    TimeLeftText: {
      ...defaultTheme.Events.TimeLeftText,
      marginTop: '0.4rem',
    },
  },
  BonusLinkMessage: {
    ...defaultTheme.BonusLinkMessage,
    LPBonusContainer: {
      ...defaultTheme.BonusLinkMessage.LPBonusContainer,
      paddingTop: '360px',
    },
    CoinsBonusContainer: {
      ...defaultTheme.BonusLinkMessage.CoinsBonusContainer,
      paddingTop: '370px',
    },
  },
};

export default SgaTheme;

