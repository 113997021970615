import { SET_MODAL, SET_MODALS_LOADED } from './types';
export var setModal = function setModal(newModal) {
  return {
    type: SET_MODAL,
    payload: newModal
  };
};
export var setModalsLoaded = function setModalsLoaded(value) {
  return {
    type: SET_MODALS_LOADED,
    payload: value
  };
};