import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import ENVIRONMENT_VARIABLES from '../../../utils/environmentVariables';
import { EVENTS_FEATURE_STATUS } from '../constants';
export var baseUrl = "https://assets.".concat(ENVIRONMENT_VARIABLES.CDN_ROOT, "/assetsManager/EVENTS_FEATURE/events-feature-global-default");
export var eventsIconRender = function eventsIconRender() {
  var _ref;

  var eventsAssets = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  return _ref = {}, _defineProperty(_ref, EVENTS_FEATURE_STATUS.ACTIVE, eventsAssets === null || eventsAssets === void 0 ? void 0 : eventsAssets.EVENTS_ICON_ACTIVE), _defineProperty(_ref, EVENTS_FEATURE_STATUS.COMING_SOON, eventsAssets === null || eventsAssets === void 0 ? void 0 : eventsAssets.EVENTS_COMING_SOON), _defineProperty(_ref, EVENTS_FEATURE_STATUS.MUST_UPDATE, eventsAssets === null || eventsAssets === void 0 ? void 0 : eventsAssets.EVENTS_MUST_UPDATE), _defineProperty(_ref, EVENTS_FEATURE_STATUS.MAINTENANCE, "".concat(baseUrl, "/icons/events-error.png")), _ref;
};
export var EVENTS_DEFAULT_TOOLTIP = "".concat(baseUrl, "/tooltip/tooltip.png");