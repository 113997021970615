import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import PromotionAction from '../../utils/PromotionAction';
import CdnPath from '../../utils/cdnPath';
import audio from '../../utils/audio';
import { getText, TEXT_KEY } from '../../utils/localization';
import { PromoActionKey } from '../../utils/PromotionAction';
import useNotification from '../../hooks/useNotification';

var LivePanel = function LivePanel(props) {
  var itemData = props.itemData,
      totalItems = props.totalItems,
      isAccountLinked = props.isAccountLinked,
      navigation = props.navigation;

  var _useNotification = useNotification(),
      addNotification = _useNotification.addNotification;

  var _clickHandler = function _clickHandler() {
    audio.onClick();
    var action = itemData.action,
        game = itemData.game,
        externalUrl = itemData.externalUrl;

    if (action === PromoActionKey.LINK_LOYALTY_CARD && isAccountLinked) {
      addNotification({
        message: getText(TEXT_KEY.ACCOUNT_ALREADY_LINKED),
        title: getText(TEXT_KEY.ACCOUNT_ALREADY_LINKED_TITLE)
      });
      return;
    }

    var handler = PromotionAction.getActionCallback(navigation, {
      type: action,
      game: game,
      externalUrl: externalUrl
    });
    handler();
  };

  var _getImageUrl = function _getImageUrl(image) {
    var ImagesCdnBaseUrl = props.assetsUrls.ImagesCdnBaseUrl;
    return "".concat(ImagesCdnBaseUrl).concat(CdnPath.LOBBY_ADVERTS).concat(image);
  };

  var data = _objectSpread(_objectSpread({}, itemData), {}, {
    imageUrl: _getImageUrl(itemData.image)
  });

  return props.render({
    data: data,
    totalItems: totalItems,
    specialOffer: itemData.action === PromoActionKey.GO_TO_COIN_STORE,
    clickHandle: _clickHandler
  });
};

export default LivePanel;