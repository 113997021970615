import theme from 'shared/assets/style/theme';
export const Styles = {
  modal: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 40vmin',
  },
  modalContainer: {
    height: '75vh',
    width: '100%',
    marginTop: '-1vw',
    marginBottom: '-1vw',
  },
  body: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  newUserForm: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  inputLabel: {
    width: '100%',
    textAlign: 'left',
    marginBottom: '1em',
  },
  nameInputField: {
    width: '100%',
    height: '46px',
    textAlign: 'left',
    boxShadow: 'none',
    borderRadius: '0.3em',
    background: 'rgba(255, 255, 255, 0.1)',
    color: 'white',
    border: '1px solid rgba(255,255,255,0.1)',
    paddingLeft: 5,
    lineHeight: 2,
  },
  errorInInput: {
    width: '100%',
    height: '46px',
    textAlign: 'left',
    boxShadow: 'none',
    borderRadius: '0.3em',
    background: 'rgba(255, 255, 255, 0.1)',
    color: 'white',
    border: '1px solid red',
    paddingLeft: 5,
    lineHeight: 2,
  },
  registerButton: {
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'center',
    cursor: 'pointer',
    pointerEvents: 'auto',
    width: '75%',
  },
  registerButtonPortrait: {
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'center',
    cursor: 'pointer',
    pointerEvents: 'auto',
    width: '95%',
  },
  linkBtn: {
    backgroundImage: `url(${require(asset`RoundedRect_Secondary@2x.png`)}`,
    cursor: 'pointer',
    fontSize: '1.7rem',
    fontWeight: '600',
    width: 188,
    height: 59,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textShadow: '0px 0px 2px rgba(0,0,0,0.8)',
    paddingBottom: '15px',
  },
  linkBtnOrange: {
    backgroundImage: `url(${require(asset`RoundedRect_Primary@2x.png`)}`,
    cursor: 'pointer',
    fontSize: '1.7rem',
    fontWeight: '600',
    width: 188,
    height: 59,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textShadow: '0px 0px 2px rgba(0,0,0,0.8)',
    paddingBottom: '15px',
  },
  errorMessage: {
    textAlign: 'center',
    color: 'red',
  },
  successMessage: {
    textAlign: 'center',
    color: 'green',
  },
  messageContainer: {
    lineHeight: 'normal',
    height: '40px',
  },
  modalStyle: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 50vmin',
  },
  modalContainerStyle: {
    display: 'flex',
    flexDirection: 'column',
    height: '54vh',
    width: '100%',
    marginTop: '-1vw',
    marginBottom: '-1vw',
  },
  titleStyle: {
    fontSize: '24px',
    fontWeight: '600',
    color: theme.palette.common[1],
    textTransform: 'uppercase',
  },
  errorButtonHolder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  labelColor: {
    color: theme.palette.common[29],
    fontSize: '1.1rem',
    fontWeight: '400',
  },
};

