import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../../../../components/JSModal';
import EventsInfoSliders from './Slides';
import { sendEventsEvent } from 'shared/screens/Events/analytics/eventsLogger';
import { EVENTS_EVENT_TYPES } from 'shared/screens/Events/constants';
import useEventsTutorial from 'shared/screens/Events/hooks/useEventsTutorial';
import generateEventsInfoScreenRender from 'shared/screens/Events/utils/generateEventsInfoScreenRender';

export default function EventsInfo({ eventsAssets, eventsTexts }) {
  const [showInfo, changeInfoState] = useState(false);
  const { dismissInfoScreen, lastSlide } = useEventsTutorial({ changeInfoState });
  const infloSliderData = generateEventsInfoScreenRender(eventsTexts);

  const handleInfoModal = () => {
    !showInfo && sendEventsEvent(EVENTS_EVENT_TYPES.EVENTS_OPEN_INFO_SCREEN);
    if (showInfo) {
      dismissInfoScreen();
    }
    changeInfoState(showInfo => !showInfo);
  };

  return (
    <React.Fragment>
      <InfoContainer>
        <InfoImage onClick={handleInfoModal} src={eventsAssets.EVENTS_TUTORIAL_INFO_ICON} />
      </InfoContainer>
      <Modal
        style={modalStyle}
        modalBodyStyle={modalBodyStyle}
        isVisible={showInfo}
        hideDefaultBackground
        hideCloseButton
      >
        <ModalContainer>
          <ModalContentWrapper>
            <InfoModalBackgroundImage src={eventsAssets.EVENTS_TUTORIAL_INFO_MODAL_BG} />
            <SpaceContainer />
            <SliderContentWrapper>
              <SliderTopSpace />
              <EventsInfoSliders lastSlide={lastSlide} slides={infloSliderData} eventsAssets={eventsAssets} />
            </SliderContentWrapper>
            <CloseButton onClick={handleInfoModal}>
              <Image src={eventsAssets.EVENTS_TUTORIAL_CLOSE_ICON} />
            </CloseButton>
          </ModalContentWrapper>
        </ModalContainer>
      </Modal>
    </React.Fragment>
  );
}

const windowWidth = window.innerWidth;
const windowHeight = window.innerHeight;

const modalStyle = {
  width: '100%',
  height: '100%',
  display: 'flex',
  padding: '6vh 16vw 0',
  zIndex: '99999',
};

const modalBodyStyle = {
  padding: '0',
};

const ModalContainer = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const InfoContainer = styled.div`
  position: absolute;
  flex: 1;
  z-index: 5;
  top: 5.3rem;
  left: 1rem;
  justify-content: center;
  align-items: center;
  z-index: 99999;
`;

const SliderTopSpace = styled.div`
  height: ${windowWidth < 1400 ? 10 : 10}%;
`;

const InfoImage = styled.img`
  cursor: pointer;
  height: 3.6rem;
  width: 3.6rem;
`;

const InfoModalBackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
  z-index: 0;
`;

const CloseButton = styled.div`
  position: absolute;
  width: 80px;
  top: ${windowWidth < 1400 ? (windowHeight > 800 ? 13 : 15) : windowHeight < 880 ? 14 : 15}vh;
  right: ${windowWidth > 1800 ? '-2%' : '-3%'};
`;

const Image = styled.img`
  position: relative;
  width: 100%;
`;

const ModalContentWrapper = styled.div`
  width: ${windowWidth < 1400 ? (windowHeight > 800 ? windowWidth * 0.95 : windowWidth * 0.85) : windowWidth * 0.8}px;
  height: ${windowWidth < 1400 ? (windowHeight > 800 ? 92 : 105) : windowWidth < 1800 ? 100 : 100}%;
  bottom: 0px;
  left: ${windowWidth < 1400 ? (windowHeight > 800 ? '-190px' : 'auto') : '-14%'};
  position: absolute;
  z-index: 1;
  border-radius: 50px;
  display: flex;
`;

const SpaceContainer = styled.div`
  height: 100%;
  flex: 3;
`;

const SliderContentWrapper = styled(SpaceContainer)`
  display: flex;
  flex: 3;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10%;
`;

