import { useSelector } from 'react-redux';
import { EVENTS_FEATURE_STATUS } from '../constants';
import { useGetEventsQuery } from '../../../state/query/events';
export default function useCheckEventsState() {
  var _data$eventsFeatureSt, _data$eventsFeatureSt2, _data$eventsFeatureSt3, _data$eventsFeatureSt4;

  var _useSelector = useSelector(function (state) {
    return state.playerInfo;
  }),
      playerId = _useSelector.playerId;

  var _useGetEventsQuery = useGetEventsQuery({
    requestAssetsData: true,
    playerId: playerId
  }, {
    forceRefetch: true
  }),
      data = _useGetEventsQuery.data,
      isError = _useGetEventsQuery.isError;

  var checkEventState = function checkEventState(configState) {
    if (isError || !configState) return EVENTS_FEATURE_STATUS.MAINTENANCE;
    return EVENTS_FEATURE_STATUS[configState] || EVENTS_FEATURE_STATUS.MAINTENANCE;
  };

  return {
    eventsState: checkEventState(data === null || data === void 0 ? void 0 : (_data$eventsFeatureSt = data.eventsFeatureState) === null || _data$eventsFeatureSt === void 0 ? void 0 : _data$eventsFeatureSt.eventsFeatureStatus),
    eventsAssetsPackageId: data === null || data === void 0 ? void 0 : (_data$eventsFeatureSt2 = data.eventsFeatureState) === null || _data$eventsFeatureSt2 === void 0 ? void 0 : _data$eventsFeatureSt2.assetsPackageId,
    eventsCount: (_data$eventsFeatureSt3 = data === null || data === void 0 ? void 0 : (_data$eventsFeatureSt4 = data.eventsFeatureState) === null || _data$eventsFeatureSt4 === void 0 ? void 0 : _data$eventsFeatureSt4.newEventsCount) !== null && _data$eventsFeatureSt3 !== void 0 ? _data$eventsFeatureSt3 : 0
  };
}