import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import Game from './Game';
import { connect } from '../../node_modules/react-redux';
import { setGameId as _setGameId, setGameProvider as _setGameProvider, setGameType as _setGameType, setGameState as _setGameState, setLastGameVisited as _setLastGameVisited, setGameInfo as _setGameInfo } from '../../state/actions/game';
import { setNavState as _setNavState } from '../../state/actions/nav_state';
import { setModal } from '../../state/actions/modal';
import { setBetInfo as _setBetInfo } from '../../state/actions/betInfo';
import { clearOnboarding as _clearOnboarding, setOnboardingFtueStep as _setOnboardingFtueStep, setOnboardingHighlightedPosition as _setOnboardingHighlightedPosition } from '../../state/actions/currentOnboarding';
import { setLastTimeAdBlockerSeen as _setLastTimeAdBlockerSeen, setGamesOpenedAmount as _setGamesOpenedAmount } from '../../state/actions/playerInfo';
import Screens from '../index';
import QueryParamProxy from '../../utils/queryParamProxy';
import RouteParamConstants from '../../utils/routeParamConstants';
import { clearSpinEvent as _clearSpinEvent } from '../../state/slices/spinEvent/spinEvent';

var mapStateToProps = function mapStateToProps(state) {
  return {
    token: state.token,
    gameId: state.gameInfo,
    gameState: state.gameInfo.gameState,
    ftueStep: state.currentOnboarding.ftueStep,
    adBlockerEnabled: state.apiRootIndex.adBlockerEnabled,
    lastTimeAdBlockerSeen: state.playerInfo.lastTimeAdBlockerSeen,
    gamesOpenedAmount: state.playerInfo.gamesOpenedAmount,
    appsflyerGamesOpenedAmountConfig: state.playerInfo.appsflyerGamesOpenedAmountConfig || 10,
    actionCommands: state.actionCommands.actions[0]
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    setNavState: function setNavState(state) {
      dispatch(_setNavState(state));
    },
    onGameUnavailable: function onGameUnavailable() {
      QueryParamProxy.setQueryParams(_defineProperty({}, RouteParamConstants.PARAM_MODAL, Screens.GameUnavailable));
      dispatch(setModal(Screens.GameUnavailable));
    },
    setBetInfo: function setBetInfo(value) {
      dispatch(_setBetInfo(value));
    },
    setLastGameVisited: function setLastGameVisited(value) {
      dispatch(_setLastGameVisited(value));
    },
    setGameId: function setGameId(value) {
      dispatch(_setGameId(value));
    },
    setGameProvider: function setGameProvider(value) {
      dispatch(_setGameProvider(value));
    },
    setGameState: function setGameState(state) {
      dispatch(_setGameState(state));
    },
    setGameType: function setGameType(value) {
      dispatch(_setGameType(value));
    },
    clearOnboarding: function clearOnboarding() {
      dispatch(_clearOnboarding());
    },
    setOnboardingFtueStep: function setOnboardingFtueStep(value) {
      dispatch(_setOnboardingFtueStep(value));
    },
    setOnboardingHighlightedPosition: function setOnboardingHighlightedPosition(position, tooltip) {
      dispatch(_setOnboardingHighlightedPosition(position, tooltip));
    },
    setLastTimeAdBlockerSeen: function setLastTimeAdBlockerSeen(value) {
      dispatch(_setLastTimeAdBlockerSeen(value));
    },
    setGameInfo: function setGameInfo(gameInfo) {
      dispatch(_setGameInfo(gameInfo));
    },
    setGamesOpenedAmount: function setGamesOpenedAmount(value) {
      dispatch(_setGamesOpenedAmount(value));
    },
    clearSpinEvent: function clearSpinEvent() {
      dispatch(_clearSpinEvent());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Game);