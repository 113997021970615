import { counterTypes } from '../../utils/TimestampManager';
var ComponentsName = {
  MISSION_CARD_TIMER_DANGER: 'MISSION_CARD_TIMER_DANGER',
  MISSION_CARD_TIMER: 'MISSION_CARD_TIMER',
  EVENTS_CARD_TIMER: 'EVENTS_CARD_TIMER',
  DEFAULT: 'DEFAULT'
};
export default function timerGenerateRender(inNavBar, isTimeInDanger, counterType) {
  if (!inNavBar && isTimeInDanger && (counterType === counterTypes.MISSION_CARD || counterType === counterTypes.MISSION_GRAND_CARD)) {
    return ComponentsName.MISSION_CARD_TIMER_DANGER;
  }

  if (!inNavBar && !isTimeInDanger && (counterType === counterTypes.MISSION_CARD || counterType === counterTypes.MISSION_GRAND_CARD)) {
    return ComponentsName.MISSION_CARD_TIMER;
  }

  if (!inNavBar && !isTimeInDanger && counterType === counterTypes.EVENTS) {
    return ComponentsName.EVENTS_CARD_TIMER;
  }

  return ComponentsName.DEFAULT;
}