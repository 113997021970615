import { connect } from 'react-redux';
import GameTile from './GameTile';
import { setFavouriteGames as _setFavouriteGames } from '../../state/actions/game';
import { ONBOARDING_FLOWS } from '../../utils/onboarding';
import { setOnboardingStep as _setOnboardingStep, clearOnboarding as _clearOnboarding, setOnboardingFtueStep as _setOnboardingFtueStep, setOnboardingHighlightedPosition as _setOnboardingHighlightedPosition } from '../../state/actions/currentOnboarding';
/** @typedef {import('../../api/Constants').MobileUrls} MobileUrls */

var mapStateToProps = function mapStateToProps(_ref) {
  var cacheId = _ref.app.cacheId,
      favouriteGames = _ref.favouriteGames,
      levelInfo = _ref.levelInfo,
      ftue = _ref.ftue,
      _ref$currentOnboardin = _ref.currentOnboarding,
      currentStep = _ref$currentOnboardin.currentStep,
      ftueStep = _ref$currentOnboardin.ftueStep;
  return {
    cacheId: cacheId,
    favouriteGames: favouriteGames,
    onboardingStep: currentStep,
    ftueStep: ftueStep,
    userCanFavourite: parseInt(levelInfo.level) >= parseInt(ftue.onboardingsUnlockLevels[ONBOARDING_FLOWS.FAVOURITES])
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    setFavouriteGames: function setFavouriteGames(gameList) {
      dispatch(_setFavouriteGames(gameList));
    },
    setOnboardingStep: function setOnboardingStep(step) {
      dispatch(_setOnboardingStep(step));
    },
    setOnboardingFtueStep: function setOnboardingFtueStep(step) {
      dispatch(_setOnboardingFtueStep(step));
    },
    clearOnboarding: function clearOnboarding() {
      dispatch(_clearOnboarding());
    },
    setOnboardingHighlightedPosition: function setOnboardingHighlightedPosition(position, tooltip) {
      dispatch(_setOnboardingHighlightedPosition(position, tooltip));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GameTile);