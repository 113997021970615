import theme from '../assets/style/theme';
import generateRender from './generateRender';
var primaryStrokeColor = generateRender({
  default: theme.palette.stroke[0],
  star: theme.palette.stroke[6],
  ti: theme.palette.stroke[6]
});
var secondaryStrokeColor = generateRender({
  default: theme.palette.stroke[6],
  star: theme.palette.stroke[0],
  ti: theme.palette.stroke[0],
  sga: theme.palette.stroke[0],
  hri: theme.palette.stroke[8]
});
var quaternaryStrokeColor = generateRender({
  default: theme.palette.stroke[7],
  star: theme.palette.stroke[3]
});
export var BUTTON_STROKES = {
  PRIMARY: primaryStrokeColor,
  BLUE: theme.palette.stroke[1],
  YELLOW: theme.palette.stroke[2],
  TERTIARY: theme.palette.stroke[4],
  QUATERNARY: quaternaryStrokeColor,
  QUINARY: theme.palette.stroke[9],
  AQUA: theme.palette.stroke[3],
  DISABLED: theme.palette.stroke[5],
  SECONDARY: secondaryStrokeColor,
  DANGER: theme.palette.stroke[7],
  PURPLE: theme.palette.stroke[8],
  VIOLET: theme.palette.stroke[9],
  GREEN: theme.palette.stroke[0],
  PINK: theme.palette.stroke[6]
};