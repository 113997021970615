import React from 'react';
import { connect } from 'shared/node_modules/react-redux';
import { setUserAffId } from 'shared/state/actions/playerInfo';
import { setToken, setRestart } from 'shared/state/actions/auth';
import { setPlayerId } from 'shared/state/actions/playerInfo';
import generateRender from 'shared/utils/generateRender';
import Star from './Star';
import Hri from './Hri';
import DefaultStart from './DefaultStart';

const Start = props => {
  const { setUserAffId, navigation, setToken, setRestart, setPlayerId, googleToken, googleAuth } = props;

  const StartComponent = generateRender({
    default: DefaultStart,
    star: Star,
    hri: Hri,
  });

  return (
    <StartComponent
      setUserAffId={setUserAffId}
      setToken={setToken}
      setRestart={setRestart}
      setPlayerId={setPlayerId}
      navigation={navigation}
      googleToken={googleToken}
      googleAuth={googleAuth}
    />
  );
};
Start.path = '';

const mapDispatchToProps = {
  setUserAffId,
  setRestart,
  setToken,
  setPlayerId,
};

const mapStateToProps = state => {
  return {
    googleToken: state.token.google_token,
    googleAuth: state.availableServices.googleAuth,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Start);

