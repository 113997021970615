import { connect } from 'shared/node_modules/react-redux';
import { setPosition, setDimensions } from 'shared/state/slices/layout/layout';
import CurrencyDisplay from './CurrencyDisplay';

const mapDispatchToProps = dispatch => {
  return {
    setPosition: position => {
      dispatch(setPosition(position));
    },
    setDimensions: dimensions => {
      dispatch(setDimensions(dimensions));
    },
  };
};

export default connect(null, mapDispatchToProps)(CurrencyDisplay);
