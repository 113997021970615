module.exports = {
  dimensions: {
    width: 335,
    height: 95
  },
  optical: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  nine: {
    top: 46,
    left: 46,
    right: 46,
    bottom: 46
  }
};