import React from 'react';
import debounce from 'lodash/debounce';
import styled from 'styled-components';
import ProgressBar from './ProgressBar';
import findRewardContentStyle from './../utils';
import { MISSIONS_COUNTER } from 'shared/screens/Missions/constants';
import generateMissionCardDataRendering from 'shared/screens/Missions/utils/generateMissionCardDataRendering';
import useMissionsActions from 'shared/screens/Missions/hooks/useMissionsActions';
import { counterTypes, normalizeTimestamp } from 'shared/utils/TimestampManager';
import TimeRemainingText from '../../../components/TimeRemainingText';
import {
  hasGameIds,
  hasGameIdsAndRunningStatus,
  hasGrandType,
  hasRunningStatus,
  hasMysteryRewardTypeNoRevealed,
} from 'shared/screens/Missions/utils/missionsPerformance';
import { MissionTutorialStepsKeys } from 'shared/utils/tutorial';
import { ANIMATION_TYPE } from 'shared/components/LottieAnimation/constants';
import LottieAnimation from '../../../components/LottieAnimation/LottieAnimation';
import { getText, TEXT_KEY } from 'shared/utils/localization';

const MissionCard = props => {
  const { object, tutorialStep, activeTutorial, missionsAssets, clientProgress } = props;
  const { missionStatus, reward, endTimestamp } = object.item;
  const missionHasRunningStatus = hasRunningStatus(object.item);
  const missionHasGameIdsAndRunningStatus = hasGameIdsAndRunningStatus(object.item);

  const {
    GrandPrizeCard = null,
    BackgroundCard,
    FrameCard,
    BottomCard,
    Image,
    Text,
    RewardType,
    RewardCoinImage,
    RewardValue,
    Description,
  } = generateMissionCardDataRendering(object.item);

  const {
    cardBottomText,
    cardBottomStyle,
    mainActionHandlers,
    isLoading,
    showCollectCoinsAnimation,
    handleCollectCoinsAnimationRunning,
  } = useMissionsActions(props, Description);

  const opacityCard =
    (!GrandPrizeCard && activeTutorial && tutorialStep === MissionTutorialStepsKeys.COMPLETE_REQUESTED) ||
    (object.index !== 1 && activeTutorial && tutorialStep === MissionTutorialStepsKeys.START_MISSION);

  const strokeColor = missionHasRunningStatus ? '#255ADC' : '#643A9F';
  const { dynamicStyle, iconSizeMultiplier } = findRewardContentStyle(
    RewardValue,
    hasMysteryRewardTypeNoRevealed(reward),
    RewardCoinImage
  );

  const handleClick = debounce(
    () => {
      return isLoading ? null : mainActionHandlers[missionStatus]();
    },
    400,
    {
      leading: true,
      trailing: false,
    }
  );

  return (
    <Card shouldRunAnimation={missionHasRunningStatus} opacityCard={opacityCard}>
      <Container onClick={handleClick}>
        {showCollectCoinsAnimation && (
          <LottieAnimation
            animationType={ANIMATION_TYPE.COLLECT_COINS_ANIMATION}
            speed={0.7}
            loop={false}
            onAnimationRunning={handleCollectCoinsAnimationRunning}
            animationStyle={{
              height: '100%',
              zIndex: 10,
            }}
          />
        )}
        {GrandPrizeCard && <GrandPrize src={GrandPrizeCard} />}
        <CardFrame src={FrameCard} />
        <CardBackground src={BackgroundCard} />
        <TimeLeftText>
          <TimeRemainingText
            prefix={getText(TEXT_KEY.TIME_REMAINING_PREFIX)}
            style={{
              fontSize: '2.5vh',
              fontWeight: '700',
            }}
            strokeColor={strokeColor}
            until={normalizeTimestamp(endTimestamp)}
            counterType={GrandPrizeCard ? counterTypes.MISSION_GRAND_CARD : counterTypes.MISSION_CARD}
          />
        </TimeLeftText>
        <CardShadow src={missionsAssets.CARD_SHADOW} />
        <CardBottom src={BottomCard} cardBottomStyle={cardBottomStyle} />
        <CardImage src={Image} renderImage={object.item} shouldRunAnimation={missionHasGameIdsAndRunningStatus} />
        {!GrandPrizeCard && <CardImageText src={Text} shouldRunAnimation={missionHasGameIdsAndRunningStatus} />}
        <ProgressBar
          {...object.item}
          shouldRunShineAnimation={!!(missionHasRunningStatus || GrandPrizeCard)}
          missionsAssets={missionsAssets}
          clientProgress={clientProgress}
        />
        <RewardText hasMysteryReward={hasMysteryRewardTypeNoRevealed(reward)}>
          <RewardWinText
            dynamicStyle={dynamicStyle}
            strokeColor={strokeColor}
            hasMysteryReward={hasMysteryRewardTypeNoRevealed(reward)}
          >
            {RewardType}
          </RewardWinText>
          {RewardValue && (
            <RewardCoin>
              {RewardCoinImage && <CoinImage iconSizeMultiplier={iconSizeMultiplier} src={RewardCoinImage} />}
              <RewardCoinText dynamicStyle={dynamicStyle} strokeColor={strokeColor}>
                {RewardValue}
              </RewardCoinText>
            </RewardCoin>
          )}
        </RewardText>
        <DescriptionTextContainer>
          <DescriptionText cardBottomStyle={cardBottomStyle}>{cardBottomText}</DescriptionText>
        </DescriptionTextContainer>
      </Container>
    </Card>
  );
};

export default MissionCard;

const cardTotalHeightVh = 80;

const Card = styled.div`
  height: 100%;
  padding-top: 2vh;
  margin-right: -6px;
  opacity: ${({ opacityCard }) => (opacityCard ? 0.2 : 1)};

  ${({ shouldRunAnimation }) =>
    shouldRunAnimation &&
    `
  animation-duration: 7s;
  animation-name: frameZoom;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;

  @keyframes frameZoom {
    0% {
      transform: scale(1);
    }

    10% {
      transform: scale(1.06);
    }

    12% {
      transform: scale(1.06);
    }

    24% {
      transform: scale(1);
    }

    100% {
      transform: scale(1);
    }
    `}
`;

const Container = styled.div`
  position: relative;
  display: inline-block;
  width: 44vh;
  height: ${cardTotalHeightVh - 13.4}vh;
`;

const GrandPrize = styled.img`
  z-index: -1;
  position: absolute;
  top: -${cardTotalHeightVh / 8.9}vh;
  left: 1.6%;
  width: 100%;
  height: ${cardTotalHeightVh}vh;
`;

const CardBackground = styled.img`
  width: 90%;
  height: ${cardTotalHeightVh - 20}vh;
  top: 35px;
  margin-left: 1.4vh;
  z-index: 3;
`;

const CardFrame = styled.img`
  position: absolute;
  top: -3.2%;
  right: 1%;
  width: 95%;
  z-index: 4;
`;

const TimeLeftText = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  top: 0.8rem;
  left: 0.4rem;
  z-index: 3;
`;

const CardShadow = styled.img`
  width: 91%;
  height: 20vh;
  left: 2.8vh;
  position: absolute;
  bottom: -2.5%;
  z-index: 4;
`;

const CardBottom = styled.img`
  width: 89.5%;
  height: 20vh;
  left: 3.1vh;
  position: absolute;
  bottom: -3%;
  z-index: ${props => (props.cardBottomStyle ? 4 : -1)};
`;

const CardImage = styled.img`
  position: absolute;
  z-index: 3;
  ${({ renderImage }) => cardImageStyle(renderImage)}

  ${({ shouldRunAnimation }) =>
    shouldRunAnimation &&
    `
  animation-duration: 1500ms;
  animation-name: jump;
  animation-fill-mode: forwards;
  animation-delay: 2s;

  @keyframes jump {
    50% {
      transform: translate(0vh, -8vh) scale(1.18);
      z-index: 3;
    }

    51% {
      z-index: 4;
    }

    90%, 100% {
      transform: translate(0vh, -2vh) scale(1.08);
      z-index: 4;
    }
  }`}
`;

const CardImageText = styled.img`
  position: absolute;
  top: 24vh;
  left: 2.2vh;
  height: 18vh;
  width: 41vh;
  z-index: 3;

  ${({ shouldRunAnimation }) =>
    shouldRunAnimation &&
    `
  animation-duration: 1500ms;
  animation-name: transparency;
  animation-fill-mode: forwards;
  animation-delay: 2s;

  @keyframes transparency {
    10% {
      transform: scale(1);
      opacity: 1;
    }

    40% {
      opacity: 0.7;
      transform: translate(0vh, 2vh) scale(0.9);
    }

    100% {
      opacity: 0.5;
      transform: translate(0vh, 2vh) scale(0.9);
    }
  }`}
`;

const CoinImage = styled.img`
  bottom: 8vh;
  margin-right: 3px;
  height: ${({ iconSizeMultiplier }) => iconSizeMultiplier * 5}vh;
  left: 6vh;
  width: ${({ iconSizeMultiplier }) => iconSizeMultiplier * 4.5}vh;
`;

const RewardText = styled.div`
  align-items: center;
  bottom: ${({ hasMysteryReward }) => (hasMysteryReward ? '6.5vh' : '1vh')};
  display: flex;
  flex-direction: column;
  left: 3vh;
  position: absolute;
  width: 40vh;
  z-index: 3;
`;

const RewardWinText = styled.p`
  bottom: ${({ hasMysteryReward }) => (hasMysteryReward ? '7vh' : '8vh')};
  color: white;
  font-size: ${({ dynamicStyle }) => dynamicStyle.winFontSize};
  font-style: italic;
  font-weight: 800;
  left: ${({ hasMysteryReward }) => (hasMysteryReward ? '5vh' : '19vh')};
  -webkit-text-stroke: 1.5px ${({ strokeColor }) => strokeColor};
  text-stroke: 1.5px ${({ strokeColor }) => strokeColor};
  margin-bottom: ${({ dynamicStyle }) => dynamicStyle.winMarginBottom};
  letter-spacing: -1px;
`;

const RewardCoinText = styled.p`
  margin-bottom: ${({ dynamicStyle }) => dynamicStyle.marginBottom};
  bottom: 5vh;
  color: white;
  font-size: ${({ dynamicStyle }) => dynamicStyle.fontSize};
  font-weight: 800;
  left: 3vh;
  letter-spacing: 0px;
  -webkit-text-stroke: 1.5px ${({ strokeColor }) => strokeColor};
  text-stroke: 1.5px ${({ strokeColor }) => strokeColor};
  word-break: break-all;
  white-space: normal;
  line-height: ${({ dynamicStyle }) => dynamicStyle.lineHeight};
`;

const RewardCoin = styled.div`
  bottom: 3vh;
  display: flex;
  flex-direction: row;
  margin-top: -6px;
  position: relative;
  padding: 0px 10px;
`;

const DescriptionTextContainer = styled.div`
  z-index: 5;
  text-align: center;
  align-content: center;
  align-items: center;
  bottom: 1vh;
  margin-left: '5vh';
  width: '10vh';
`;

const DescriptionText = styled.p`
  align-content: center;
  bottom: ${props => (props.cardBottomStyle ? '0.5vh' : '-16px')};
  color: white;
  font-size: 2.5vh;
  font-style: italic;
  font-weight: 700;
  left: 1.5vh;
  line-height: 3vh;
  padding-left: 3vh;
  padding-right: 3vh;
  position: absolute;
  text-align: center;
  text-shadow: 0 0px 2px #000;
  white-space: initial;
  z-index: 5;
`;

const cardImageStyle = ({ missionType, counter }) =>
  counter.params !== null && hasGameIds(counter.params.gameIds)
    ? `
    top: 9vh;
    right: 5.4vh;
    width: 31.5vh;
    `
    : hasGrandType(missionType)
    ? `
    top: 5vh;
    right: 5.4vh;
    width: 31.5vh;
   `
    : MISSIONS_IMAGES_STYLES[counter.counterType];

const MISSIONS_IMAGES_STYLES = {
  [MISSIONS_COUNTER.COMPLETE_MISSION_X_TIMES]: `
    top: 4vh;
    right: 2.1vh;
    height: 45.5vh;
    width: 89%;
    `,
  [MISSIONS_COUNTER.SPIN_X_TIMES]: `
    top: 12vh;
    right: 6vh;
    height: 25.4vh;
    width: 70%;
    `,
  [MISSIONS_COUNTER.SPIN_X_TIMES_Y_GAMES]: `
    top: 11.7vh;
    right: 6vh;
    height: 28.4vh;
    width: 70%;
    `,
  [MISSIONS_COUNTER.WIN_X_TIMES]: `
    top: 12vh;
    right: 1.5vh;
    height: 35.8vh;
    width: 89.6%;
    `,
  [MISSIONS_COUNTER.WIN_X_TIMES_Y_GAMES]: `
    top: 12vh;
    right: 1.5vh;
    height: 35.8vh;
    width: 89.6%;
    `,
  [MISSIONS_COUNTER.WIN_X_AMOUNT]: `
    top: 12vh;
    right: 1.5vh;
    height: 35.8vh;
    width: 89.6%;
    `,
  [MISSIONS_COUNTER.WIN_X_AMOUNT_Y_GAMES]: `
    top: 12vh;
    right: 1.5vh;
    height: 35.8vh;
    width: 89.6%;
    `,
  [MISSIONS_COUNTER.WAGE_X_AMOUNT]: `
    top: 12vh;
    right: 1.5vh;
    height: 35.8vh;
    width: 89.6%;
    `,
  [MISSIONS_COUNTER.LEVEL_UP_X_TIMES]: `
    top: 12vh;
    right: 1.5vh;
    height: 35.8vh;
    width: 89.6%;
    `,
  [MISSIONS_COUNTER.MAX_BET_X_TIMES]: `
    top: 12vh;
    right: 1.5vh;
    height: 35.8vh;
    width: 89.6%;
    `,
  [MISSIONS_COUNTER.COLLECT_BONUS_X_TIMES]: `
    top: 7vh;
    right: 1.5vh;
    height: 35vh;
    width: 89%;
    `,
};

