import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import Utils from 'shared/utils';

export default class DatePicker extends Component {
  state = { date: undefined };

  componentDidUpdate(prevProps, prevStates) {
    if (Utils.quickObjectComparision(prevStates, this.state) === false) {
      this._emitDate();
    }
  }

  _emitDate = () => {
    const [month, day, year] = this.state.date.split('-');
    const newDate = `${year}-${this._addZero(month)}-${this._addZero(day)}`;
    this.props.onDateChange(newDate);
  };

  _checkDateValue = (str, max) => {
    if (str.charAt(0) !== '0' || str === '00') {
      let num = parseInt(str);
      if (isNaN(num) || num <= 0 || num > max) num = 1;
      str = num > parseInt(max.toString().charAt(0)) && num.toString().length === 1 ? '0' + num : num.toString();
    }
    return str;
  };

  _onDateChangeHandler = event => {
    let input = event.target.value;
    if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
    let values = input.split('-').map(value => value.replace(/\D/g, ''));
    if (values[0]) values[0] = this._checkDateValue(values[0], 12);
    if (values[1]) values[1] = this._checkDateValue(values[1], 31);
    const output = values.map((value, index) => {
      if (index === 0) {
        return (values[1] && values[1].length > 0 && value.length === 2 && !value.includes('-')) ||
          (value.length === 2 && !value.includes('-'))
          ? value + '-'
          : value.length === 2 && value.includes('-')
          ? value.replace(/-/g, '')
          : value;
      } else if (index === 1) {
        return (values[2] && values[2].length > 0 && values[0].length === 2 && value.length === 2) ||
          (!values[2] && value.length === 2)
          ? value + '-'
          : values[0].length < 2 || (values[0].length === 2 && values[0].includes('-'))
          ? ''
          : value;
      } else {
        return values[0].length < 2 || values[1].length < 2 ? '' : value;
      }
    });
    this.setState({ date: output.join('').substr(0, 10) });
  };

  _onDateBlurHandler = event => {
    let input = event.target.value;
    const values = input.split('-').map(value => value.replace(/\D/g, ''));
    let output = '';

    if (values.length === 3) {
      const year = values[2].length !== 4 ? parseInt(values[2]) + 2000 : parseInt(values[2]);
      const month = parseInt(values[0]) - 1;
      const day = parseInt(values[1]);
      const d = new Date(year, month, day);
      if (!isNaN(d)) {
        const dates = [d.getMonth() + 1, d.getDate(), d.getFullYear()];
        output = dates
          .map(value => {
            value = value.toString();
            return value.length === 1 ? '0' + value : value;
          })
          .join('-');
      }
    }
    this.setState({ date: output });
  };

  _addZero = value => {
    if (parseInt(value) < 10) {
      value = `0${parseInt(value)}`;
    }
    return value;
  };

  render() {
    const { date } = this.state;
    return (
      <div className="form-datepicker">
        <Form.Control
          type="text"
          className="form-input-text"
          onChange={this._onDateChangeHandler}
          onBlur={this._onDateBlurHandler}
          placeholder="MM-DD-YYYY"
          value={date}
        />
      </div>
    );
  }
}
