import React from 'react';
import LottieAnimation from '../../LottieAnimation/LottieAnimation';
import { ANIMATION_TYPE } from 'shared/components/LottieAnimation/constants';

const FlyingCoinsAnimation = props => {
  const {
    isFlyingCoinsAnimationStopped,
    currencyDisplayXPosition,
    currencyDisplayWidth,
    currencyDisplayHeight,
    chestHeight,
    chestWidth,
    chestXPosition,
  } = props;

  const styleValues = {
    animationStyle: {
      width:
        currencyDisplayXPosition && chestXPosition && chestWidth
          ? window.innerWidth - currencyDisplayXPosition - (window.innerWidth - chestXPosition) + chestWidth
          : window.innerHeight,
      bottom: '2rem',
      height: 'unset',
    },
  };

  return (
    <div
      style={{
        display: isFlyingCoinsAnimationStopped ? `none` : `block`,
        zIndex: 99999,
        position: 'absolute',
        left:
          currencyDisplayXPosition && currencyDisplayWidth ? currencyDisplayXPosition + 60 : 0,
        height: '100vh',
      }}
    >
      <LottieAnimation
        shouldScaleY
        heightToScale={currencyDisplayHeight && chestHeight ? window.innerHeight - chestHeight / 6 : 0}
        animationType={ANIMATION_TYPE.TIMED_BONUS_FLYING_COINS_ANIMATION}
        animationStyle={styleValues.animationStyle}
        startDelay={2000}
        speed={0.58}
        manualIsStopped={isFlyingCoinsAnimationStopped}
      />
    </div>
  );
};

export default FlyingCoinsAnimation;
