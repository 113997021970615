import GenericMessage from './GenericMessage';
import { connect } from '../../node_modules/react-redux';
import { shiftPendingMessage as _shiftPendingMessage } from '../../state/actions/pendingMessage';

var mapStateToProps = function mapStateToProps(state) {
  return {
    navState: state.navState.navState,
    pendingMessage: state.pendingMessages[0]
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    shiftPendingMessage: function shiftPendingMessage() {
      dispatch(_shiftPendingMessage());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GenericMessage);