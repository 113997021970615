import React, { Component } from 'react';
import { Form, Tooltip, Button as GenericButton, Overlay } from 'react-bootstrap';
import Validators from 'shared/components/Form/InputValidator';
import AutoFormInput from '../../components/AutoFormInput';
import FormType from 'shared/components/Form/FormType';
import FormId from 'shared/components/Form/FormId';
import theme from 'shared/assets/style/theme';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import Button from '../../components/Button/Button';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';

export default class BillingProfile extends Component {
  constructor(...args) {
    super(...args);
    this.attachRef = target => this.setState({ target });

    this.state = {
      profileSelected: this.props.profiles[0].profile_id,
      promoCode: '',
      showDeleteConfirmation: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.profiles.length !== nextProps.profiles.length && nextProps.profiles.length > 0) {
      this.setState({ profileSelected: nextProps.profiles[0].profile_id });
    }
  }

  render() {
    const {
      profiles,
      buyWithBillingProfile,
      deleteBillingProfile,
      purchaseType,
      promoReward,
      getPurchaseStrings,
      renderTotal,
      packageData,
      hasOffer,
      termsAndConditionsURL,
    } = this.props;

    const disabledBuyNowButton = require(asset`RoundedRect_Disabled@3x.png`);
    const buyNowButton = require(asset`RoundedRect_Primary@3x.png`);

    let profileOptions = [];
    for (let i = 0; i < profiles.length; i++) {
      profileOptions.push({ value: profiles[i].profile_id, label: profiles[i].profileNickname });
    }

    if (packageData === null) return null;

    let { purchaseNameString, purchasePriceString } = getPurchaseStrings(purchaseType, packageData, hasOffer);

    return (
      <div
        style={{
          marginTop: '2rem',
        }}
      >
        <Form.Group controlId="billingProfileSelect">
          <Form.Label className="billingProfileLabel">
            {getText(TEXT_KEY.PAYMENT_INFO_SELECT_METHOD)}
            <span className="profileControls">
              <span onClick={() => buyWithBillingProfile(-1)}>{getText(TEXT_KEY.ADD)}</span>
              {profiles.length > 0 && parseInt(profiles[0].profile_id) >= 0 && (
                <>
                  <span
                    ref={this.attachRef}
                    onClick={() =>
                      this.setState({
                        showDeleteConfirmation: !this.state.showDeleteConfirmation,
                      })
                    }
                  >
                    {getText(TEXT_KEY.DELETE)}
                  </span>
                  <Overlay target={this.state.target} show={this.state.showDeleteConfirmation} placement="bottom">
                    {props => (
                      <Tooltip {...props}>
                        <div style={{ margin: '1rem' }}>
                          <h4>{getText(TEXT_KEY.CONFIRM_DELETE_CARD)}</h4>
                          <GenericButton
                            block
                            onClick={() => {
                              deleteBillingProfile(parseInt(this.state.profileSelected));

                              this.setState({
                                showDeleteConfirmation: !this.state.showDeleteConfirmation,
                              });
                            }}
                          >
                            {getText(TEXT_KEY.YES)}
                          </GenericButton>
                          <GenericButton
                            block
                            onClick={() =>
                              this.setState({
                                showDeleteConfirmation: false,
                              })
                            }
                          >
                            {getText(TEXT_KEY.CANCEL)}
                          </GenericButton>
                        </div>
                      </Tooltip>
                    )}
                  </Overlay>
                </>
              )}
            </span>
          </Form.Label>
          <div
            className="billingProfileSelect"
            onChange={evt =>
              this.setState({
                profileSelected: evt.target.value,
              })
            }
          >
            <AutoFormInput
              controlId={FormId.BILLING_PROFILE}
              id={FormId.BILLING_PROFILE}
              validator={Validators.NO_VALIDATION}
              type={FormType.DROPDOWN}
              keyboardHint={''}
              label={''}
              options={profileOptions}
              validated={true}
              emitInput={() => {}}
            />
            <img className="downIcon" src={require(asset`caret_down.png`)} alt="" />
          </div>
          <div style={{ height: '2rem' }} />
        </Form.Group>
        {renderTotal(purchaseNameString, purchasePriceString, promoReward)}
        <Button
          textStroke={BUTTON_STROKES.PRIMARY}
          disabled={profiles?.length === 0}
          imageSource={profiles.length > 0 ? buyNowButton : disabledBuyNowButton}
          onClick={profiles.length > 0 ? () => buyWithBillingProfile(parseInt(this.state.profileSelected)) : () => {}}
          label={getText(TEXT_KEY.BUY_NOW)}
          containerStyle={{ width: '100%', padding: '1rem' }}
          textStyle={{ fontSize: '1.2vw' }}
        />

        <div style={{ justifyContent: 'center', marginTop: 5, display: 'flex' }}>
          <a
            style={{
              color: theme.palette.secondary[7],
              textDecoration: 'underline',
            }}
            target="_blank"
            rel="noopener noreferrer"
            href={termsAndConditionsURL}
          >
            {getText(TEXT_KEY.TERMS_AND_CONDITIONS_IAP)}
          </a>
        </div>
      </div>
    );
  }
}

