import { createSlice } from '@reduxjs/toolkit';
var sliceName = 'XP';
var initialState = {
  xp: 0,
  xpToNext: 0,
  xpBetMultiplier: 1,
  xpPerSpin: 20,
  xpRequired: 9999999
};
var xpInfoSlice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    setXp: function setXp(state, action) {
      state.xp = action.payload;
    },
    addXp: function addXp(state, action) {
      state.xp = parseInt(state.xp) + action.payload;
    },
    setXpToNext: function setXpToNext(state, action) {
      state.xpToNext = action.payload;
    },
    setXpBetMultiplier: function setXpBetMultiplier(state, action) {
      state.xpBetMultiplier = action.payload;
    },
    setXpPerSpin: function setXpPerSpin(state, action) {
      state.xpPerSpin = action.payload;
    },
    setXpRequired: function setXpRequired(state, action) {
      state.xpRequired = action.payload;
    }
  }
});
var actions = xpInfoSlice.actions,
    reducer = xpInfoSlice.reducer;
var setXp = actions.setXp,
    addXp = actions.addXp,
    setXpToNext = actions.setXpToNext,
    setXpBetMultiplier = actions.setXpBetMultiplier,
    setXpPerSpin = actions.setXpPerSpin,
    setXpRequired = actions.setXpRequired;
export { setXp, addXp, setXpToNext, setXpBetMultiplier, setXpPerSpin, setXpRequired };
export default reducer;