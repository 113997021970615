import Ajv from 'ajv';
import { getSchema } from './schemas';
import useFeatureFlags from '../../hooks/useFeatureFlags.js';
import Logger from '../../lib/analytics/logger';
import { EVENT_TYPES } from '../../lib/analytics/constants';
import ERROR_CODE from '../../utils/errorCodes';
import { ValidationError } from '../../state/api/services/exceptions';
import { FE_JSONSCHEMA_VALIDATION } from '../featureFlags/constants';
import HTTP from '../httpMethods';
var ENABLED = 'enabled';
var SKIP_ERRORS = 'skip_errors';
export var validateJson = function validateJson(schemaId, json) {
  var method = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : HTTP.GET;

  var _useFeatureFlags = useFeatureFlags(),
      getFeatureConfig = _useFeatureFlags.getFeatureConfig;

  var schema = getSchema(schemaId, method);
  if (!getFeatureConfig(FE_JSONSCHEMA_VALIDATION) || !getFeatureConfig(FE_JSONSCHEMA_VALIDATION)[schemaId] || !getFeatureConfig(FE_JSONSCHEMA_VALIDATION)[schemaId][method] || !getFeatureConfig(FE_JSONSCHEMA_VALIDATION)[schemaId][method][ENABLED]) return true;
  var ajv = new Ajv();
  var validate = ajv.compile(schema);
  var valid = validate(json);

  if (!valid) {
    if (!getFeatureConfig(FE_JSONSCHEMA_VALIDATION)[schemaId][method][SKIP_ERRORS]) throw new ValidationError("There was an error validating ".concat(schemaId), validate.errors);
    Logger.sendEvent(EVENT_TYPES.FRONTEND_ERROR, {
      error: validate.errors,
      code: ERROR_CODE.JSONSCHEMA_ERRORS_SKIPED,
      body: schemaId
    });
  }

  return true;
};