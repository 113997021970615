import React, { Component } from 'react';

import GamePage from '../../components/GamePage';
import PinnedPanel from '../../components/PinnedPanel';
import SFavourites from 'shared/screens/Favourites';
import Utils from 'shared/utils';
import audio from 'shared/utils/audio';
import ThemeContext from 'shared/context/ThemeContext';

export default class Favourites extends Component {
  slider = React.createRef();

  handleNextClick = () => {
    audio.onClick();
    this.slider.current.slickNext();
  };

  handlePrevClick = () => {
    audio.onClick();
    this.slider.current.slickPrev();
  };

  handleFirstClick = () => {
    audio.onClick();
    this.slider.current.slickGoTo(0);
  };

  handleLastClick = pagesCount => {
    audio.onClick();
    this.slider.current.slickGoTo(pagesCount);
  };

  render() {
    const themeContext = this.context;
    return (
      <SFavourites
        navigation={this.props.navigation}
        {...this.props}
        render={args => {
          const { pagedGames, lobbyPromotions } = args;
          return (
            <div className="roomPage">
              <div className="roomContent">
                <div className="NavigationArrowsContainer left">
                  <img
                    src={require(asset`images/Lobby_Assets/Scroll_Buttons@3x.png`)}
                    className="LeftNavigationArrows"
                    alt="Left navigation arrows"
                  />
                  <div onClick={this.handlePrevClick} className="NavigationArrowTop" />
                  <div onClick={this.handleFirstClick} className="NavigationArrowBottom" />
                </div>
                <div className="PinnedPanelContainer">
                  {!Utils.isEmpty(lobbyPromotions) && (
                    <PinnedPanel panelList={lobbyPromotions} navigation={this.props.navigation} />
                  )}
                  {Utils.isEmpty(pagedGames) && (
                    <div className="GameListContainer" style={themeContext.Favourites.FavouritesImageContainer}>
                      <img
                        src={require(asset`images/Empty_favorites_Message/Empty_Info@3x.png`)}
                        alt="Empty Favourites Message"
                        style={{ height: '100%' }}
                      />
                    </div>
                  )}
                  {!Utils.isEmpty(pagedGames) && (
                    <div className="GameListContainer">
                      <GamePage {...args} sliderRef={this.slider} navigation={this.props.navigation} />
                    </div>
                  )}
                </div>
                <div className="NavigationArrowsContainer right">
                  <img
                    src={require(asset`images/Lobby_Assets/Scroll_Buttons@3x.png`)}
                    className="RightNavigationArrows"
                    alt="Right navigation arrows"
                  />
                  <div onClick={this.handleNextClick} className="NavigationArrowTop" />
                  <div onClick={() => this.handleLastClick(pagedGames.length - 1)} className="NavigationArrowBottom" />
                </div>
              </div>
            </div>
          );
        }}
      />
    );
  }
}

Favourites.contextType = ThemeContext;
