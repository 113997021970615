import React, { Component } from 'react';
import SRewardProfile from 'shared/components/RewardCenter/RewardProfile';
import ImageBW from '../../components/ImageBoundsWrapper';
import Button from '../Button/Button';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import CopySelector from 'shared/utils/copySelector';

export default class RewardProfile extends Component {
  render() {
    const {
      api: {
        core: {
          profile: {
            name,
            linkedCasinoId,
            playerProfileInfo: { memberLevel, balances },
          },
        },
      },
      enableOrderCard,
    } = this.props;
    const profileBalances = linkedCasinoId === 'yes' && balances && Object.values(balances);

    return (
      <SRewardProfile
        render={props => {
          const PropertyName = props.propertyName || '';
          const { handleLinkAccountForm, handleRegisterRewardCard, copys } = props;
          return (
            <div className="RewardCenterProfileDetail">
              {linkedCasinoId === 'yes' && profileBalances ? (
                <>
                  <h6>{getText(TEXT_KEY.WELCOME)}</h6>
                  <h3>{name}</h3>
                  <h6>{PropertyName}</h6>
                  <h6>
                    {getText(TEXT_KEY.STATUS)}: {memberLevel}
                  </h6>
                  <div className="RewardCenterProfileItems">
                    {profileBalances.map(({ displayName, currency, balance }, index) => (
                      <ImageBW
                        source={require(asset`section.png`)}
                        ibw={require(asset`section.ibw`)}
                        className="RewardCenterProfileDetailBox"
                        innerClassName="RewardCenterProfileDetailBoxInner"
                        key={index}
                      >
                        <h4>{displayName}</h4>
                        <h6>{getText(TEXT_KEY.AVAILABLE_BALANCE)}</h6>
                        {balance || balance == 0 ? (
                          <p>
                            <span style={{ fontSize: 18 }}>{currency === 'USD' ? '$' : ''}</span>
                            {balance}
                          </p>
                        ) : (
                          <p>{getText(TEXT_KEY.NA)}</p>
                        )}
                      </ImageBW>
                    ))}
                  </div>
                </>
              ) : (
                <div className="unlinkedProfileContainer">
                  {enableOrderCard && (
                    <ImageBW
                      source={require(asset`section.png`)}
                      ibw={require(asset`section.ibw`)}
                      className="RewardCenterUnlinkedProfileDetailBox"
                      innerClassName="RewardCenterProfileDetailBoxInner"
                      innerStyle={{ justifyContent: 'space-around' }}
                    >
                      <h4>
                        {CopySelector.getCopy(CopySelector.COPY_TYPES.REWARD_CENTER_PROFILE, copys).register_card.title}
                      </h4>
                      <p>
                        {CopySelector.getCopy(CopySelector.COPY_TYPES.REWARD_CENTER_PROFILE, copys).register_card.text}
                      </p>
                      <Button
                        imageSource={require(asset`RoundedRect_Primary@2x.png`)}
                        onClick={handleRegisterRewardCard}
                        label={
                          CopySelector.getCopy(CopySelector.COPY_TYPES.REWARD_CENTER_PROFILE, copys).register_card
                            .button
                        }
                      />
                    </ImageBW>
                  )}
                  <ImageBW
                    source={require(asset`section.png`)}
                    ibw={require(asset`section.ibw`)}
                    className="RewardCenterUnlinkedProfileDetailBox"
                    innerClassName="RewardCenterProfileDetailBoxInner"
                    innerStyle={{ justifyContent: 'space-around' }}
                  >
                    <h4>
                      {CopySelector.getCopy(CopySelector.COPY_TYPES.REWARD_CENTER_PROFILE, copys).link_card.title}
                    </h4>
                    <p>{CopySelector.getCopy(CopySelector.COPY_TYPES.REWARD_CENTER_PROFILE, copys).link_card.text}</p>
                    <Button
                      imageSource={require(asset`images/Rewards_Center_Assets/Link_Button@3x.png`)}
                      onClick={handleLinkAccountForm}
                      containerStyle={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '70%',
                        cursor: 'pointer',
                        marginTop: '1rem',
                        marginBottom: '1rem',
                      }}
                    />
                  </ImageBW>
                </div>
              )}
            </div>
          );
        }}
      />
    );
  }
}

