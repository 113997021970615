module.exports = {
  dimensions: {
    width: 92,
    height: 93
  },
  optical: {
    top: 1,
    left: 1,
    right: 1,
    bottom: 1
  },
  content: {
    top: 16,
    left: 1,
    right: 1,
    bottom: 1
  }
};