export var BOOLEANS = {
  TRUE: 'true',
  FALSE: 'false',
  YES: 'yes',
  NO: 'no'
};
export var LOGIN_METHOD = {
  SIGNIN: 'SignInMethod',
  GUEST: 'Guest',
  BBEGuest: 'BBEGuest',
  MEMBER: 'Member'
};
export var SCALAR_TYPE = {
  NUMBER: 'number',
  BOOLEAN: 'boolean',
  STRING: 'string'
};
export var CARD_VALUES = {
  VISA: 'MY VISA',
  MASTERCARD: 'MY MASTERCARD',
  AMEX: 'MY AMEX'
};
export var TARGET_PROPERTY = 'target';
export var DIMENSIONS = {
  SCREEN: 'screen',
  WINDOW: 'window'
};
export var IS_EMBEDDED_TYPE = {
  YES: 'yes',
  NO: 'no'
};
export var PENDING_MESSAGE = {
  BONUS_LINK: 'BONUS_LINK',
  PROMO_LINK: 'PROMO_LINK'
};
export var TYPEOF = {
  FUNCTION: 'function',
  OBJECT: 'object',
  NUMBER: 'number'
};
export var PURCHASE_RESPONSE = {
  SUCCESS: 'success'
};
export var PURCHASE_TYPE = {
  VIP: 'vip',
  VIP_ROOM_ACCESS: 'vip_room_access'
};
export var PLATFORM = {
  ANDROID: 'android',
  IOS: 'ios',
  WEB: 'web'
};
export var TERMS_TYPE = {
  TERMS: 'TERMS',
  POLICY: 'POLICY'
};
export var EVENT_EMITTER_TYPE = {
  ACTION_COMMANDS: 'ACTION_COMMANDS'
};