import styled from 'styled-components';
import theme from 'shared/assets/style/theme';

const RewardItemContainer = styled.div`
  margin: 1em 2em 1em;
  border-radius: 1.2em;
  cursor: pointer;
  height: 24em;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-width: 270px;
  max-width: 270px;
  flex-grow: 1;

  &:hover {
    border-color: $secondary-26;
  }

  @media only screen and (max-height: 900px) and (max-width: 1400px) {
    margin: 0.3em 1em 0em 1em;
  }
`;

const RewardThumbnailContainer = styled.div`
  flex: 1;
  position: relative;
  display: block;
  box-sizing: border-box;
`;

const RewardContentContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: relative;
`;

const RewardCardContainer = styled.div`
  width: 100%;
  height: 100%;
  z-index: -1;
  ${({ theme }) => theme};
`;

const RewardCardImage = styled.div`
  height: 100%;
  width: 100%;
  ${({ theme }) => theme};
`;

const RewardImage = styled.div`

position: static;
inset: 26px; 23px; 0px;

`;

const BorderImageReward = styled.div`
  position: absolute;
  top: -27px;
  left: -30px;
  height: 464px;
  width: 330px;
  border-style: solid;
  border-image: url(${({ borderImg }) => borderImg}) 11 11 41 fill / 11px 11px 41px / 0px stretch;
  pointer-events: none;
`;

const RewardImagePlaceholderContainer = styled.div`
  background-image: ${({ backgroundUrl }) => backgroundUrl};
  ${({ theme }) => theme}
`;

const RewardImagePlaceholder = styled(RewardImagePlaceholderContainer)``;

const RewardHoverOverlay = styled.div`
  ${({ theme }) => theme}
`;

const OfferCost = styled.div`
  ${({ theme }) => theme}
`;

const Icon = {
  loyalty: require(asset`Star_Icon@3x.png`),
  coins: require(asset`images/Top_Bar_Assets/Coin_Icon@3x.png`),
};

const LoyaltyIcon = styled.img`
  height: 30px;
  width: 30px;
  object-fit: contain;
`;

const LoyaltyPointsText = styled.span`
  font-size: 1em;
  ${({ theme }) => theme}
`;

const OfferName = styled.div`
  border-bottom-right-radius: 1em;
  border-bottom-left-radius: 1em;
  text-align: center;
  padding: 0.6em 0;
  flex: 0;
  position: relative;
  top: ${({ top }) => top || '-27%'};
  font-size: 1.5em;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme}
`;

const CoinsIcon = styled.img`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  object-fit: contain;
`;

const CoinsText = styled.span`
  font-size: ${({ size }) => size};
  ${({ theme }) => theme}
`;

const OfferCategoryName = styled.div`
  border-bottom-right-radius: 1em;
  border-bottom-left-radius: 1em;
  text-align: center;
  padding: 0.6em 0;
  flex: 0;
  position: relative;
  font-size: 1.5em;
  font-weight: 600;

  background: linear-gradient(to bottom, ${theme.palette.secondary[15]} 30%, ${theme.palette.secondary[27]} 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  ${({ ieVersion }) =>
    ieVersion !== 0 &&
    `color: ${theme.palette.secondary[24]};
  background: none;`}

  ${({ theme }) => theme};
`;

export {
  RewardItemContainer,
  RewardThumbnailContainer,
  RewardContentContainer,
  RewardCardContainer,
  RewardCardImage,
  RewardImage,
  BorderImageReward,
  RewardImagePlaceholderContainer,
  RewardImagePlaceholder,
  RewardHoverOverlay,
  OfferCost,
  Icon,
  LoyaltyIcon,
  LoyaltyPointsText,
  OfferName,
  CoinsIcon,
  CoinsText,
  OfferCategoryName,
};

