var FormId = require("../../../components/Form/FormId").default;

var FormType = require("../../../components/Form/FormType").default;

var InputValidator = require("../../../components/Form/InputValidator").default;

module.exports = {
  header: {
    title: "",
    backButtonText: ""
  },
  steps: [{
    title: "",
    formInputList: [{
      id: FormId.SUBJECT,
      label: "Message subject",
      type: FormType.TEXT,
      validator: InputValidator.NOT_EMPTY,
      maxChar: 150,
      customClassName: 'customInputText',
      customLabel: 'custom-input-label',
      inputStyle: true
    }, {
      id: FormId.BODY,
      label: "Type message here",
      type: FormType.TEXT,
      validator: InputValidator.NOT_EMPTY,
      multiline: true,
      numberOfLines: 7,
      rows: 9,
      containerStyle: {
        marginTop: '0.5rem'
      },
      customClassName: 'customTextArea',
      customLabel: 'custom-text-area-label',
      inputStyle: true
    }],
    submitButtonText: "SEND"
  }]
};