import { setXp, setXpRequired } from '../../slices/xp/xp';
import { setCurrentLevelBonusCoins, setLevel, setMaxBet, setNextLevelBonusCoins, setNextLevelMaxBet, setPreviousLevelBonusCoins } from '../../slices/level/level';
import { setCoin, setLoyalty, setPremium } from '../../actions/extras';
import { ServerResponseCurrencyKeys, ServerResponseKeys } from '../../../api/Constants';
export var updateUserInfoHandler = function updateUserInfoHandler(dispatch, response) {
  var balance = response[ServerResponseKeys.CURRENCY_BALANCES];
  var progression = response[ServerResponseKeys.PROGRESSION];
  dispatch(setCoin(parseInt(balance[ServerResponseCurrencyKeys.COINS])));
  dispatch(setPremium(balance[ServerResponseCurrencyKeys.PREMIUM]));
  dispatch(setLoyalty(parseInt(balance[ServerResponseCurrencyKeys.LOYALTY])));
  dispatch(setLevel(progression.level));
  dispatch(setXp(progression.xp));
  dispatch(setXpRequired(progression.xpneeded));
  dispatch(setMaxBet(progression.maxbet));
  dispatch(setNextLevelMaxBet(progression.nextLevelMaxbet));
  dispatch(setNextLevelBonusCoins(progression.nextLevelCoins));
  dispatch(setCurrentLevelBonusCoins(progression.coins));
  dispatch(setPreviousLevelBonusCoins(progression.previousLevelCoins));
};