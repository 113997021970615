import TimeRemainingText from './TimeRemainingText';
import { connect } from '../../node_modules/react-redux';
import { hideTimerOnFinish as _hideTimerOnFinish, hideActiveTrackerOnFinish as _hideActiveTrackerOnFinish } from '../../state/actions/nav_state';
import { removePushNotification as _removePushNotification } from '../../state/actions/notification';
import { setNewGames as _setNewGames, setFeaturedGames as _setFeaturedGames, setSlotGames as _setSlotGames, setTableGames as _setTableGames, setVipGames as _setVipGames, setFavouriteGames as _setFavouriteGames } from '../../state/actions/game';
import { showVipAccessModal as _showVipAccessModal, setVipRoomUnlocked as _setVipRoomUnlocked } from '../../state/actions/consumables';
import { shouldShowTooltip as _shouldShowTooltip, tooltipDetails as _tooltipDetails } from '../../state/actions/tooltip';
import { setShowMissionsProgressBar as _setShowMissionsProgressBar } from '../../state/slices/missions/missions';

var mapStateToProps = function mapStateToProps(state) {
  return {
    hideTimer: state.navState.hideTimer,
    modal: state.modal.modal,
    showMissionsProgressBar: state.missions.showMissionsProgressBar,
    activeTutorial: state.tutorial.activeTutorial
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    hideTimerOnFinish: function hideTimerOnFinish(value) {
      dispatch(_hideTimerOnFinish(value));
    },
    hideActiveTrackerOnFinish: function hideActiveTrackerOnFinish(value) {
      dispatch(_hideActiveTrackerOnFinish(value));
    },
    removePushNotification: function removePushNotification(value) {
      dispatch(_removePushNotification(value));
    },
    setNewGames: function setNewGames(gameList) {
      dispatch(_setNewGames(gameList));
    },
    setFeaturedGames: function setFeaturedGames(gameList) {
      dispatch(_setFeaturedGames(gameList));
    },
    setSlotGames: function setSlotGames(gameList) {
      dispatch(_setSlotGames(gameList));
    },
    setTableGames: function setTableGames(gameList) {
      dispatch(_setTableGames(gameList));
    },
    setVipGames: function setVipGames(gameList) {
      dispatch(_setVipGames(gameList));
    },
    setFavouriteGames: function setFavouriteGames(gameList) {
      dispatch(_setFavouriteGames(gameList));
    },
    showVipAccessModal: function showVipAccessModal(value) {
      dispatch(_showVipAccessModal(value));
    },
    setVipRoomUnlocked: function setVipRoomUnlocked(value) {
      dispatch(_setVipRoomUnlocked(value));
    },
    shouldShowTooltip: function shouldShowTooltip(value) {
      dispatch(_shouldShowTooltip(value));
    },
    tooltipDetails: function tooltipDetails(detail) {
      dispatch(_tooltipDetails(detail));
    },
    setShowMissionsProgressBar: function setShowMissionsProgressBar(value) {
      dispatch(_setShowMissionsProgressBar(value));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeRemainingText);