import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { SET_ASSET_BASE_URL, SET_APP_BACKGROUND, SET_ASSETS_URLS, SET_TARGET, SHOW_CUSTOM_MODAL, SET_CACHE_ID, SET_RESTART_SESSION } from '../actions/types';
var initialState = {
  assetBaseUrl: '',
  appBackground: '',
  assetsUrls: {},
  target: null,
  showCustomModal: false,
  cacheId: null,
  restartSession: false
};

var appReducer = function appReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_ASSET_BASE_URL:
      return _objectSpread(_objectSpread({}, state), {}, {
        assetBaseUrl: action.payload
      });

    case SET_APP_BACKGROUND:
      return _objectSpread(_objectSpread({}, state), {}, {
        appBackground: action.payload
      });

    case SET_ASSETS_URLS:
      return _objectSpread(_objectSpread({}, state), {}, {
        assetsUrls: action.payload
      });

    case SET_RESTART_SESSION:
      return _objectSpread(_objectSpread({}, state), {}, {
        restartSession: action.payload
      });

    case SET_TARGET:
      return _objectSpread(_objectSpread({}, state), {}, {
        target: action.payload
      });

    case SHOW_CUSTOM_MODAL:
      return _objectSpread(_objectSpread({}, state), {}, {
        showCustomModal: action.payload
      });

    case SET_CACHE_ID:
      return _objectSpread(_objectSpread({}, state), {}, {
        cacheId: action.payload
      });

    default:
      return state;
  }
};

export default appReducer;