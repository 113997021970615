import _toConsumableArray from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
  downloadedLottieJsonFiles: []
};
var lottieAnimationsSlice = createSlice({
  name: 'lottieAnimations',
  initialState: initialState,
  reducers: {
    setDownloadedLottieJsonFile: function setDownloadedLottieJsonFile(state, action) {
      if (!state.downloadedLottieJsonFiles.includes(action.payload)) {
        state.downloadedLottieJsonFiles = [].concat(_toConsumableArray(state.downloadedLottieJsonFiles), [action.payload]);
      }
    },
    removeDownloadedLottieJsonFile: function removeDownloadedLottieJsonFile(state, action) {
      state.downloadedLottieJsonFiles = state.downloadedLottieJsonFiles.filter(function (file) {
        return file !== action.payload;
      });
    }
  }
});
var actions = lottieAnimationsSlice.actions,
    reducer = lottieAnimationsSlice.reducer;
var setDownloadedLottieJsonFile = actions.setDownloadedLottieJsonFile,
    removeDownloadedLottieJsonFile = actions.removeDownloadedLottieJsonFile;
export { setDownloadedLottieJsonFile, removeDownloadedLottieJsonFile };
export default reducer;