import React, { useRef, useEffect, useContext } from 'react';
import * as PIXI from 'pixi.js-legacy';
import useFeatureFlags from 'shared/hooks/useFeatureFlags';
import { FE_ANIMATION } from 'shared/utils/featureFlags/constants';
import ThemeContext from 'shared/context/ThemeContext';

const f = (A, B) => {
  return {
    m: (B.y - A.y) / (B.x - A.x),
    n: -(((B.y - A.y) * A.x) / (B.x - A.x)) + A.y,
  };
};

const getNextPoint = (P, D, r) => {
  const a = 1 + r.m * r.m;
  const b = -2 * P.x + 2 * r.m * r.n - 2 * P.y * r.m;
  const c = P.x * P.x + P.y * P.y + r.n * r.n - 2 * P.y * r.n - D * D;
  const x = (-b - Math.sqrt(b * b - 4 * a * c)) / (2 * a);
  const y = r.m * x + r.n;

  return {
    x,
    y,
  };
};

const app = new PIXI.Application({
  transparent: true,
  height: 698,
  width: 825.609,
});

const MAX_SPEED = 20;
const MIN_SPEED = 8;

const spritesheet = require(asset`spritesheet.png`);
const spritesheetJSON = require('./spritesheet.json');
const sheet1 = new PIXI.BaseTexture.from(spritesheet);
const sheet2 = new PIXI.BaseTexture.from(spritesheet);

const coinFrames = spritesheetJSON.animations.coin.map(frame => {
  const { x, y, h, w } = spritesheetJSON.frames[frame].frame;
  return new PIXI.Texture(sheet1, new PIXI.Rectangle(x, y, h, w));
});

const chestFrames = spritesheetJSON.animations.chest.map(frame => {
  const { x, y, h, w } = spritesheetJSON.frames[frame].frame;
  return new PIXI.Texture(sheet2, new PIXI.Rectangle(x, y, h, w));
});

const chestAnimation = new PIXI.AnimatedSprite(chestFrames);
chestAnimation.loop = false;
chestAnimation.height = 116.78;
chestAnimation.width = 115;
chestAnimation.y = 0;
chestAnimation.x = 0;
chestAnimation.animationSpeed = 0.2;

const coinsAnimation = [
  new PIXI.AnimatedSprite(coinFrames),
  new PIXI.AnimatedSprite(coinFrames),
  new PIXI.AnimatedSprite(coinFrames),
  new PIXI.AnimatedSprite(coinFrames),
  new PIXI.AnimatedSprite(coinFrames),
  new PIXI.AnimatedSprite(coinFrames),
  new PIXI.AnimatedSprite(coinFrames),
  new PIXI.AnimatedSprite(coinFrames),
  new PIXI.AnimatedSprite(coinFrames),
  new PIXI.AnimatedSprite(coinFrames),
  new PIXI.AnimatedSprite(coinFrames),
  new PIXI.AnimatedSprite(coinFrames),
];

const moveCoin = style => {
  coinsAnimation.forEach(coin => {
    if (coin) {
      const relativeX = (Math.abs(coin.position.x - chestAnimation.x) / Math.abs(0 - chestAnimation.x)) * 100;
      coin.speed =
        ((-MAX_SPEED - MIN_SPEED) / 2500) * Math.pow(relativeX, 2) +
        ((MAX_SPEED + MIN_SPEED) / 25) * relativeX +
        MIN_SPEED;
      if (coin.position.y > 80) {
        const P = getNextPoint(
          { x: coin.position.x, y: coin.position.y },
          coin.speed,
          f({ x: chestAnimation.x, y: chestAnimation.y }, { x: 0, y: 0 })
        );
        coin.position.x = P.x;
        coin.position.y = P.y;
      } else {
        app.stage.removeChild(coin);
      }
    }
  });
};

const loadCoins = () => {
  coinsAnimation.forEach((coinAnimation, index) => {
    setTimeout(() => {
      coinAnimation.id = index;
      coinAnimation.width = 30;
      coinAnimation.height = 30;
      coinAnimation.x = chestAnimation.x;
      coinAnimation.position.x = chestAnimation.x;
      coinAnimation.position.y = chestAnimation.y;
      coinAnimation.y = chestAnimation.y;
      coinAnimation.animationSpeed = 0.2;
      app.stage.addChild(coinAnimation);
      coinAnimation.play();
    }, 100 * index);
  });
};

const Chest = ({ style, showOpen, shouldPlay, className, showEnd }) => {
  const appContainer = useRef();
  const { hasFeature } = useFeatureFlags();
  const { Chest } = useContext(ThemeContext);

  useEffect(() => {
    chestAnimation.loop = false;
    chestAnimation.height = Chest.ChestAnimation.height;
    chestAnimation.width = Chest.ChestAnimation.width;
    chestAnimation.animationSpeed = 0.2;
    app.stage.addChild(chestAnimation);
    appContainer.current.appendChild(app.view);
  }, []);

  useEffect(() => {
    if (showEnd) {
      chestAnimation.loop = false;
      chestAnimation.height = Chest.ChestAnimationEnd.height;
      chestAnimation.width = Chest.ChestAnimationEnd.width;
      chestAnimation.animationSpeed = 1;
      app.stage.addChild(chestAnimation);
      appContainer.current.appendChild(app.view);
      chestAnimation.play();
    }
  }, [showEnd]);

  useEffect(() => {
    if (!showOpen) {
      chestAnimation.animationSpeed = -0.2;
      chestAnimation.play();
    }
  }, [showOpen]);

  useEffect(() => {
    if (shouldPlay) {
      // playing old coins animation
      // if the new one is disabled
      if (!hasFeature(FE_ANIMATION)) {
        loadCoins();
        app.ticker.add(() => moveCoin(style));
      }
      chestAnimation.gotoAndStop(0);
      chestAnimation.animationSpeed = 0.2;
      chestAnimation.play();
    }
  }, [shouldPlay]);

  useEffect(() => {
    chestAnimation.x = style.width - chestAnimation.width - 17;
    chestAnimation.y = style.height - chestAnimation.height - 30;
    app.resizeTo = appContainer.current;
    app.resize();
  }, [style]);

  return (
    <div
      id="animationContainer"
      className={className}
      style={style}
      ref={r => {
        appContainer.current = r;
      }}
    ></div>
  );
};

export default Chest;

