import React, { useContext } from 'react';
import { useSelector } from 'shared/node_modules/react-redux';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import SSettings from 'shared/screens/Settings';
import QueryParamsProxy from 'shared/utils/queryParamProxy';
import RouteParamConstants from 'shared/utils/routeParamConstants';
import Account from './Account/Account';
import CustomerSupport from './CustomerSupport';
import ChangePassword from './ChangePassword';
import UpdateEmail from './UpdateEmail';
import ImageBW from '../../components/ImageBoundsWrapper';
import ThemeContext from 'shared/context/ThemeContext';
import TermsAndConditions from './components/TermsAndConditions';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { isHri } from 'shared/utils/targetApplication';
import Button from '../../components/Button/Button';
import { listButtonTextStyle } from './customStyles';
import useNotification from 'shared/hooks/useNotification';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';

const SettingsComponents = {
  account: Account,
  customerSupport: CustomerSupport,
  changePassword: ChangePassword,
  updateEmail: UpdateEmail,
};

const Settings = props => {
  const { Settings: SettingsStyles } = useContext(ThemeContext);
  const { addNotification } = useNotification();
  const activeBG = require(asset`RoundedRect_Primary@2x.png`);
  const headerHeight = '6rem';
  const { userProperty, propertySelector } = useSelector(state => state.properties.data);
  const property = propertySelector.find(property => property.propertyId == userProperty);

  const platformLogoutHandler = callback => {
    addNotification({
      title: getText(TEXT_KEY.SIGN_OUT),
      message: getText(TEXT_KEY.CONFIRM_LOG_OUT),
      button: getText(TEXT_KEY.LEAVE),
      buttonAction: () => {
        callback();
      },
      hasCancelButton: true,
      hideCloseButton: true,
      hideHelpButton: true,
    });
  };

  const getSwitch = (title, handle, value = false) => {
    return (
      <>
        <div className="menu-item" style={{ padding: '0.7rem 0px 1.3rem' }}>
          <span
            className={`${value ? 'settings-text' : 'settings-text-disabled'} ${
              ENVIRONMENT_VARIABLES.APPLICATION_TARGET
            }`}
            style={SettingsStyles.TitleMargin}
            data-label={title}
          >
            <span style={SettingsStyles.TitleShadow}>{title}</span>
          </span>
          <div onClick={handle}>
            <label className="custom-switch" style={{ marginBottom: 0 }}>
              <>
                <input type="checkbox" checked={value} readOnly />
                <span className="slider" onClick={handle} />
              </>
            </label>
          </div>
        </div>
        <div className="bottom-divider">
          <ImageBW
            style={_style.divider}
            source={require(asset`./images/Settings/Settings_Divider@3x.png`)}
            ibw={require(asset`./images/Settings/Settings_Divider.ibw`)}
          />
        </div>
      </>
    );
  };

  const getListButton = (title, handle) => {
    return (
      <>
        <div className="menu-item pointer" style={_style.listButton} onClick={handle}>
          <span
            className={`settings-text ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}
            style={SettingsStyles.TitleMargin}
            data-label={title}
          >
            <span style={{ ...SettingsStyles.TitleShadow, ...listButtonTextStyle(title) }}>{title}</span>
          </span>
          <div className="forward-arrow-container" onClick={handle}>
            <ImageBW
              source={require(asset`./images/Settings/Forward_Arrow@3x.png`)}
              ibw={require(asset`./images/Settings/Forward_Arrow.ibw`)}
              style={{
                objectFit: 'contain',
                height: '1.8rem',
                width: '3.5rem',
              }}
              alt="Close"
              widthScale={0.5}
            />
          </div>
        </div>
        <div className="bottom-divider">
          <ImageBW
            style={_style.divider}
            source={require(asset`./images/Settings/Settings_Divider@3x.png`)}
            ibw={require(asset`./images/Settings/Settings_Divider.ibw`)}
            widthScale={0.5}
          />
        </div>
      </>
    );
  };

  const _style = {
    header: {
      width: '100%',
      height: headerHeight,
    },
    headerImage: {
      height: headerHeight,
      width: '100%',
      objectFit: 'contain',
      zIndex: 11,
      position: 'absolute',
      top: 0,
      left: 0,
    },
    listButton: {
      padding: '0.7rem 0 1.3rem 0',
    },
    divider: {
      width: '100%',
      height: '0.2rem',
      zIndex: 500,
    },
  };

  return (
    <SSettings
      navigation={props.navigation}
      dismissModal={props.dismissModal}
      platformLogout={platformLogoutHandler}
      render={args => {
        const {
          logoutHandle,
          isGuest,
          settingsScreen,
          onChangeComponentHandler,
          goBackHandler,
          soundEffects,
          music,
          toggleSound,
          toggleMusic,
          titleEnum,
          screensEnum,
          showPopUp,
          popUpTitle,
          showCheatMenu,
          onClickCheatMenu,
          privacyPolicy,
          termsAndConditions,
        } = args;

        const CurrentSettingsComponent = SettingsComponents[settingsScreen] || <div />;
        const termsAndConditionsUrl = property?.termsAndConditionsURL || termsAndConditions;
        const privacyPolicyUrl = property?.privacyPolicyURL || privacyPolicy;

        const version = `${ENVIRONMENT_VARIABLES.VERSION_MAJOR}.${ENVIRONMENT_VARIABLES.VERSION_MINOR}.${ENVIRONMENT_VARIABLES.VERSION_PATCH}.${ENVIRONMENT_VARIABLES.VERSION_BUILD}`;
        return (
          <div style={SettingsStyles.BackgroundLightDivider}>
            <div className="settings-header" style={_style.header}>
              <ImageBW
                source={require(asset`Full_Modal_Header.png`)}
                ibw={require(asset`Full_Modal_Header.ibw`)}
                style={SettingsStyles.HeaderImage}
              >
                <div style={SettingsStyles.HeaderContent}>
                  <div>
                    {settingsScreen !== screensEnum.settings ? (
                      <div style={SettingsStyles.BackArrowContainer} onClick={() => goBackHandler()}>
                        <ImageBW
                          source={require(asset`Back_Arrow@3x.png`)}
                          ibw={require(asset`Back_Arrow.ibw`)}
                          style={{
                            objectFit: 'contain',
                          }}
                          alt="Close"
                          widthScale={0.5}
                        />
                      </div>
                    ) : (
                      <div style={{ width: '2rem' }} />
                    )}
                  </div>
                  <span
                    className={isHri ? 'settings-title hri' : 'settings-title'}
                    data-label={titleEnum[showPopUp ? popUpTitle : settingsScreen] || titleEnum.settings}
                  >
                    {titleEnum[showPopUp ? popUpTitle : settingsScreen] || titleEnum.settings}
                  </span>
                  <div style={{ width: '2rem' }} />
                </div>
              </ImageBW>
            </div>

            <div className="settings-container">
              {settingsScreen === screensEnum.settings ? (
                <>
                  <div className="actions-container">
                    <div className="action-column">
                      {getListButton(getText(TEXT_KEY.CONTACT_SUPPORT), () =>
                        onChangeComponentHandler(screensEnum.customerSupport)
                      )}
                      <TermsAndConditions
                        getListButton={getListButton}
                        termsAndConditions={termsAndConditionsUrl}
                        privacyPolicy={privacyPolicyUrl}
                        onChangeComponentHandler={onChangeComponentHandler}
                        screensEnum={screensEnum}
                      />
                    </div>
                    <div className="action-column">
                      {getListButton(getText(TEXT_KEY.ACCOUNT), () => onChangeComponentHandler(screensEnum.account))}
                      {getSwitch(getText(TEXT_KEY.SOUND_EFFECTS), () => toggleSound(), soundEffects)}
                      {getSwitch(getText(TEXT_KEY.MUSIC), () => toggleMusic(), music)}
                    </div>
                  </div>
                  <div className="bottom-container">
                    {!isGuest && (
                      <Button
                        textStroke={BUTTON_STROKES.PRIMARY}
                        imageSource={activeBG}
                        onClick={logoutHandle}
                        label={getText(TEXT_KEY.SIGN_OUT)}
                        containerStyle={{
                          position: 'relative',
                          bottom: 0,
                          left: 0,
                          right: 0,
                          padding: '2rem',
                          width: '100%',
                        }}
                        textStyle={SettingsStyles.SignoutButtonText}
                        objectFit={'contain'}
                      />
                    )}
                    <div className="version-container">
                      <div>
                        {getText(TEXT_KEY.VERSION)}
                        <span className="CanSelect">{version}</span>
                      </div>
                      <div>{getText(TEXT_KEY.SETTINGS_COPYRIGHT)}</div>
                    </div>
                    {showCheatMenu && (
                      <button className="cheat-menu-button" data-label="Cheat Menu" onClick={onClickCheatMenu}>
                        {getText(TEXT_KEY.CHEAT_MENU)}
                      </button>
                    )}
                  </div>
                </>
              ) : (
                <CurrentSettingsComponent getSwitch={getSwitch} version={version} {...args} />
              )}
            </div>
          </div>
        );
      }}
    />
  );
};

Settings.DismissHandler = () => {
  QueryParamsProxy.setQueryParams({
    [RouteParamConstants.PARAM_MODAL]: undefined,
    [RouteParamConstants.PARAM_SETTINGS_SCREEN]: undefined,
  });
};

Settings.AsModalProps = {
  hideBeforeSelector: isHri,
};

export default Settings;

