import React, { Component } from 'react';
import styled from 'styled-components';
import SRewardCenter from 'shared/screens/RewardCenter';
import ControlHeader from '../../components/RewardCenter/ControlHeader';
import { RewardCenterTabs } from 'shared/screens/RewardCenter/Constants';
import ThemeContext from 'shared/context/ThemeContext';
import Animated from 'animated/lib/targets/react-dom';
import Easing from 'animated/lib/Easing';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import RewardTabContent from '../../components/RewardCenter/RewardTabContent';

export default class RewardCenter extends Component {
  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-reward-center',
    };
  }

  render() {
    const themeContext = this.context;
    return (
      <SRewardCenter
        navigation={this.props.navigation}
        animated={Animated}
        easing={Easing}
        render={props => {
          const { api, ux, onTabChange, activeFilter, activeProperty, showLinkLoyaltyCardSetting } = props;
          const { profile } = api.core;
          const { activeTab } = ux;
          const RewardTab = RewardTabContent[activeTab];

          return (
            <MainContainer>
              <div className="RewardCenter">
                <ControlHeader
                  showProfileTab={profile && showLinkLoyaltyCardSetting && profile.displayRewardProfileScreen === 'yes'}
                  {...props}
                  dismissModal={() => {
                    onTabChange({ target: { dataset: { tabName: RewardCenterTabs.BACK } } });
                  }}
                />
                <RewardCenterMainContentHolder
                  theme={themeContext.RewardCenter.RewardCenterMainContentHolder}
                  tab={activeTab}
                >
                  <RewardTab {...props} {...{ activeFilter, activeProperty }} />
                </RewardCenterMainContentHolder>
              </div>
              <div className="RewardCenterLoyaltyExpiry">
                <BottomTextHolder>
                  {getText(TEXT_KEY.YOUR)} {getText(TEXT_KEY.LOYALTY_POINTS)}
                  {getText(TEXT_KEY.EXPIRE_ON)}
                  {profile.loyaltyExpiryDate}, {getText(TEXT_KEY.BE_QUICK_AND_REDEEM_NOW)}
                </BottomTextHolder>
              </div>
            </MainContainer>
          );
        }}
      />
    );
  }
}

RewardCenter.hideOverlay = false;
RewardCenter.contextType = ThemeContext;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const BottomTextHolder = styled.span`
  font-size: calc(12px + (16 - 12) * ((100vw - 800px) / (1600 - 800)));
`;

const RewardCenterMainContentHolder = styled.div`
  ${({ theme }) => theme}
  height: 545px;

  @media only screen and (max-height: 900px) {
    height: 500px;
  }

  @media only screen and (max-height: 1000px) and (max-width: 1400px) {
    height: ${({ tab }) => (tab === 'offer' ? `400px` : `470px`)};
  }
`;

