import React from 'react';
import { setAuthCallbackIntent } from 'shared/state/actions/callbackIntent';
import { useSelector } from 'shared/node_modules/react-redux';
import { getPlayerLoyaltyCardType } from 'shared/screens/LinkAccount/utils';
import UnityLinkAccount from './UnityLinkAccount';
import DefaultLinkAccount from './DefaultLinkAccount';

export default function LinkAccount() {
  const {
    data: { userProperty, propertySelector },
  } = useSelector(state => state.properties);
  const playerLoyaltyCardType = getPlayerLoyaltyCardType(userProperty, propertySelector);
  const LinkAccount =
    {
      default: DefaultLinkAccount,
      unity: UnityLinkAccount,
    }[playerLoyaltyCardType] || DefaultLinkAccount;

  return <LinkAccount setAuthCallbackIntent={setAuthCallbackIntent} />;
}
