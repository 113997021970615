/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import SShop from 'shared/screens/Shop';
import { ShopTabs } from 'shared/screens/Shop/Constants';
import ImageBW from '../../components/ImageBoundsWrapper';
import coinItem from './CoinItem';
import vipItem from './VIPItem';
import Modal from '../../components/JSModal';
import GenericMessage from '../../screens/GenericMessage/GenericMessage';
import TimeRemainingText from '../../components/TimeRemainingText';
import preload from '../../lib/load/preload';
import Animated from 'animated/lib/targets/react-dom';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import ThemeContext from 'shared/context/ThemeContext';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import Button from '../../components/Button/Button';

const activeVip = require(asset`images/Coin_Store_Assets/VIP_tab_active@3x.png`);
const activeVipIbw = require(asset`images/Coin_Store_Assets/VIP_tab_active.ibw`);
const activeCoins = require(asset`images/Coin_Store_Assets/COINS_tab_active@3x.png`);
const activeCoinsIbw = require(asset`images/Coin_Store_Assets/COINS_tab_active.ibw`);

export default class Shop extends Component {
  constructor(props) {
    super(props);
    this.firstShow = false;
    this.state = {
      showBar: false,
    };
  }

  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-shop',
    };
  }

  /** @typedef {import('shared/api/Constants').CurrencyPackageItemKeys} CPIK */
  /** @typedef {import('shared/api/Constants').CurrencyPackageNewsListItemKeys} News */

  render() {
    const themeContext = this.context;

    return (
      <SShop
        navigation={this.props.navigation}
        dismissModal={this.props.dismissModal}
        preload={preload}
        render={
          /**
           * @param {Object} args
           * @param {News[]} args.newsItems
           * @param {BP[]} args.vipItems
           * @param {String} args.shopTab Current shop tab
           * @param {Function} args.setShopTabHandler Takes a ShopTab constant
           * @param {Function} args.buyHandle purchase handler
           * @param {Function} args.freeHandle claim free items
           * @param {Object} args.coinSale coin sale info
           * @param {String} args.coinSale.backgroundImage if not null, background image
           * @param {String} args.coinSale.headerImage if not null, header image
           * @param {Object} args.activeConsumables
           */
          args => {
            const {
              coinItems,
              vipItems,
              shopTab,
              setShopTabHandler,
              buyHandle,
              freeHandle,
              coinSale,
              isSaleOn,
              activeConsumables,
              showPromoBoost,
              promoBoostDialogType,
              promoBoostDialogMessage,
              promoBoostDialogButtonHandle,
              assetsUrls,
              hasVIPPass,
              isVipRoomUnlocked,
            } = args;

            const modalInsets = {
              top: promoBoostDialogType === 'success' ? 110 : '23vmin',
              left: promoBoostDialogType === 'success' ? 220 : '22%',
              right: promoBoostDialogType === 'success' ? 220 : '22%',
              bottom: promoBoostDialogType === 'success' ? 110 : '23vmin',
            };

            if (!shopTab) {
              return <div></div>;
            }

            let currentTab = {
              image: activeCoins,
              ibw: activeCoinsIbw,
            };
            if (shopTab === ShopTabs.VIP) {
              currentTab.image = activeVip;
              currentTab.ibw = activeVipIbw;
            }

            if (coinItem.length > 0 && !this.firstShow) {
              this.firstShow = true;
              setTimeout(() => {
                this.setState({ showBar: true });
              }, 100);
            }

            return (
              <>
                <div
                  style={{
                    width: '100%',
                  }}
                >
                  <img
                    className="ShopBackground"
                    src={
                      coinSale.backgroundImage
                        ? assetsUrls.promotionImagesUrl + '/' + coinSale.backgroundImage
                        : require(asset`images/Coin_Store_Assets/Default_Background@3x.jpg`)
                    }
                    onError={ev => (ev.target.src = require(asset`images/Coin_Store_Assets/Default_Background@3x.jpg`))}
                  />
                </div>
                <ImageBW
                  className="PopupOverlay"
                  source={require(asset`Popup_framing@3x.png`)}
                  ibw={require(asset`Popup_framing.ibw`)}
                  manualAssetScale={3}
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                  }}
                />
                <div style={themeContext.ShopScreen}>
                  <div style={themeContext.ShopScreen.ShopContainer}>
                    <div style={themeContext.ShopScreen.ShopContainer.ShopHeader}></div>
                    <div style={themeContext.ShopScreen.ShopContainer.PackagesContainer}>
                      <div style={themeContext.ShopScreen.ShopContainer.PackagesContainer.TabButtonsContainer}>
                        <button
                          style={themeContext.ShopScreen.ShopContainer.PackagesContainer.TabButtonsContainer.TabButton}
                          onClick={() => setShopTabHandler(ShopTabs.COINS)}
                        />
                        <button
                          style={themeContext.ShopScreen.ShopContainer.PackagesContainer.TabButtonsContainer.TabButton}
                          onClick={() => setShopTabHandler(ShopTabs.VIP)}
                        />
                      </div>
                      <ImageBW
                        ibw={currentTab.ibw}
                        source={currentTab.image}
                        style={{
                          flex: 1,
                          zIndex: 10,
                        }}
                        innerStyle={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          overflowY: 'auto',
                          overflowX: 'hidden',
                          paddingTop: '8%',
                          height: '100%',
                        }}
                      >
                        {this.state.showBar && (
                          <div style={themeContext.ShopScreen.ShopContainer.PackagesContainer.CoinItemContainer}>
                            {shopTab === ShopTabs.COINS &&
                              coinItems.map(item =>
                                coinItem(
                                  item,
                                  buyHandle,
                                  showPromoBoost,
                                  themeContext.ShopScreen.ShopContainer.PackagesContainer.CoinItem,
                                  themeContext.ShopScreen.ShopContainer.PackagesContainer.InnerItem,
                                  themeContext.ShopScreen.ShopContainer.PackagesContainer.InnerItem.Stack,
                                  themeContext.CoinItem.CoinAmount,
                                  themeContext.CoinItem.Extra,
                                  themeContext.CoinItem.BuyButtonText
                                )
                              )}
                            {shopTab === ShopTabs.VIP && (
                              <div style={themeContext.ShopScreen.ShopContainer.PackagesContainer.VIPItemContainer}>
                                {vipItems.map((item, index) =>
                                  vipItem(
                                    item,
                                    index,
                                    buyHandle,
                                    freeHandle,
                                    activeConsumables.vip_room_access,
                                    hasVIPPass,
                                    isVipRoomUnlocked,
                                    themeContext
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </ImageBW>
                    </div>
                  </div>
                  {isSaleOn && (
                    <ImageBW
                      source={require(asset`images/Coin_Store_Assets/Glowing_Container@3x.png`)}
                      ibw={require(asset`images/Coin_Store_Assets/Glowing_Container.ibw`)}
                      className="saleTimer"
                      style={themeContext.ShopScreen.SaleTimer}
                      widthScale={0.285}
                    >
                      <img src={require(asset`Clock_Icon_Glowing@3x.png`)} alt={'Clock Icon'} className="timerIcon" />
                      {coinSale.endDate > 0 && (
                        <>
                          <TimeRemainingText
                            className="timerLabel"
                            until={coinSale.endDate} // Assuming server sends the time remaining in seconds
                          />
                        </>
                      )}
                    </ImageBW>
                  )}
                  {promoBoostDialogType !== null && !!promoBoostDialogMessage && (
                    <Modal
                      isVisible={true}
                      onBackButtonPress={promoBoostDialogButtonHandle}
                      zIndex={9999}
                      hideCloseButton={true}
                      insets={modalInsets}
                      hideOverlay={true}
                      dialogClassName={'promo-link-applied'}
                      modalMainBodyStyle={{ minHeight: promoBoostDialogType === 'success' ? '32em' : '20vmin' }}
                    >
                      {promoBoostDialogType === 'success' ? (
                        <GenericMessage
                          message={
                            promoBoostDialogType === 'success'
                              ? promoBoostDialogMessage
                              : getText(TEXT_KEY.ALREADY_CLAIMED_BONUS_OR_EXPIRED)
                          }
                          dismissModal={promoBoostDialogButtonHandle}
                          title={
                            promoBoostDialogType === 'success'
                              ? `${getText(TEXT_KEY.CONGRATULATIONS)}!`
                              : getText(TEXT_KEY.SORRY)
                          }
                          navigation={this.props.navigation}
                          dividerImage={
                            promoBoostDialogType === 'success'
                              ? {
                                  src: require(asset`images/Light_Divider@3x.png`),
                                }
                              : null
                          }
                          centerImage={
                            promoBoostDialogType === 'success'
                              ? {
                                  src: require(asset`images/Promo_Links_Assets/Coin_Graphic_Sale@3x.png`),
                                }
                              : null
                          }
                          buttonImage={
                            promoBoostDialogType === 'success'
                              ? {
                                  src: require(asset`images/Promo_Links_Assets/Buy_Now_Button@3x.png`),
                                }
                              : null
                          }
                        />
                      ) : (
                        <>
                          <div className="ModalBackground hideBefore">
                            <ImageBW
                              className="ModalBackgroundAsset"
                              as={Animated.div}
                              source={require(asset`Modal_Backing_9Slice@3x.png`)}
                              ibw={require(asset`Modal_Backing_9Slice.ibw`)}
                              widthScale={0.5}
                            />
                          </div>
                          <div className="ModalCloseButton" onClick={promoBoostDialogButtonHandle}>
                            <ImageBW
                              source={require(asset`CloseButton@3x.png`)}
                              ibw={require(asset`CloseButton.ibw`)}
                              manualAssetScale={3}
                              style={{ objectFit: 'contain', height: '100%', width: '100%' }}
                              innerStyle={{ cursor: 'pointer' }}
                              alt="Close"
                            />
                          </div>
                          <div style={{ position: 'relative', flex: 1 }}>
                            <div
                              className={`gradientText ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}
                              data-label="SORRY"
                              style={{
                                lineHeight: 1,
                                fontSize: '4vmin',
                                fontWeight: '600',
                                margin: '0 0 3vmin 0',
                                position: 'relative',
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <span>{getText(TEXT_KEY.SORRY)}</span>
                              <img
                                src={require(asset`images/Purchase_Flow_Assets/Light_Divider@3x.png`)}
                                style={{ position: 'absolute', width: '100%', top: '100%', pointerEvents: 'none' }}
                                alt=""
                              />
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                height: '70%',
                                alignItems: 'center',
                              }}
                            >
                              <img
                                style={{ width: '9%', zIndex: 1, margin: '1rem 0' }}
                                src={require(asset`images/Purchase_Flow_Assets/Warning_Symbol@3x.png`)}
                              />
                              <span style={{ fontWeight: 500 }}>
                                {getText(TEXT_KEY.ALREADY_CLAIMED_BONUS_OR_EXPIRED)}
                              </span>
                              <Button
                                imageSource={require(asset`RoundedRect_Primary@3x.png`)}
                                imageStyle={{ width: '25%' }}
                                containerStyle={{ height: '100%' }}
                                onClick={promoBoostDialogButtonHandle}
                                label={getText(TEXT_KEY.OK)}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </Modal>
                  )}
                </div>
              </>
            );
          }
        }
      />
    );
  }
}

Shop.contextType = ThemeContext;

