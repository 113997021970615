import { connect } from 'react-redux';
import Home from './Home';
import { setGameId as _setGameId } from '../../state/actions/game';
import { setOnboardingStep as _setOnboardingStep } from '../../state/actions/currentOnboarding';
import { setNavState as _setNavState } from '../../state/actions/nav_state';

var mapStateToProps = function mapStateToProps(state) {
  return {
    // Add state from store
    favouriteGames: state.favouriteGames,
    livePanelList: state.livePanelList,
    lobbyPromotions: state.promotions.lobbyNews,
    onboardingsSeen: state.ftue.onboardingsSeen[state.playerInfo.playerId] || [],
    onboardingsUnlockLevels: state.ftue.onboardingsUnlockLevels,
    panels: state.panels,
    slotGames: state.slotGames,
    userLevel: state.levelInfo.level,
    gameInfo: state.gameInfo
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    // Add action from store
    setGameId: function setGameId(gameid) {
      dispatch(_setGameId(gameid));
    },
    setOnboardingStep: function setOnboardingStep(step) {
      dispatch(_setOnboardingStep(step));
    },
    setNavState: function setNavState(state) {
      dispatch(_setNavState(state));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);