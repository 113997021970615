import React, { useContext } from 'react';

import { getText, TEXT_KEY } from 'shared/utils/localization';
import ThemeContext from 'shared/context/ThemeContext';

import ImageBoundsWrapper from '../../../../components/ImageBoundsWrapper';

import { Styles, OTPWrapper, OTPTextWrapper, TermsText } from './styledComponents';

const activeCheckbox = require(asset`Checkbox@3x.png`);
const nonActiveCheckbox = require(asset`Checkbox_Deselected@3x.png`);

const OTPFormFooter = ({ showOTPConsents, activeOTPCheckbox, handleOPTCheckbox, selectedPropertyObj }) => {
  const { FormFooter } = useContext(ThemeContext);

  return (
    <>
      {showOTPConsents ? (
        <OTPWrapper>
          <ImageBoundsWrapper
            source={activeOTPCheckbox ? activeCheckbox : nonActiveCheckbox}
            ibw={require(asset`Checkbox.ibw`)}
            style={Styles.otpConsentsCheckbox}
            className="checkbox-image"
            onClick={handleOPTCheckbox}
          />
          <OTPTextWrapper>
            {getText(TEXT_KEY.REGISTER_CARD_OTP_CONSENTS)}{' '}
            <a style={FormFooter.TermsLink} onClick={() => window.open(selectedPropertyObj.loyaltyTermsAndConditions)}>
              {getText(TEXT_KEY.TERMS_OF_SERVICE)}
            </a>{' '}
            {getText(TEXT_KEY.AND)}{' '}
            <a style={FormFooter.TermsLink} onClick={() => window.open(selectedPropertyObj.loyaltyPrivacyPolicy)}>
              {getText(TEXT_KEY.PRIVACY_POLICY)}
            </a>{' '}
          </OTPTextWrapper>
        </OTPWrapper>
      ) : (
        <TermsText>
          {getText(TEXT_KEY.REGISTER_REWARD_TERMS_AGREE_MESSAGE)}{' '}
          <a style={FormFooter.TermsLink} onClick={() => window.open(selectedPropertyObj.loyaltyTermsAndConditions)}>
            {getText(TEXT_KEY.TERMS_OF_SERVICE)}
          </a>
        </TermsText>
      )}
    </>
  );
};

export default OTPFormFooter;

