import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { ASSIGN_TRANSACTION_UUID, ASSIGN_USER_UUID, INITIALIZE_SESSION_UUID, INITIATE_PURCHASE, SET_ACTION_TRIGGER, SET_PURCHASE_OVERLAY } from '../actions/types';
var initialState = {
  initiatePurchase: false,
  isPurchaseOverlayEnabled: false,
  transaction_uuid: '',
  actionTrigger: '',
  session_uuid: '',
  user_uuid: ''
};
export var purchaseReducer = function purchaseReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case INITIATE_PURCHASE:
      return _objectSpread(_objectSpread({}, state), {}, {
        initiatePurchase: action.payload
      });

    case ASSIGN_TRANSACTION_UUID:
      return _objectSpread(_objectSpread({}, state), {}, {
        transaction_uuid: action.payload
      });

    case SET_ACTION_TRIGGER:
      return _objectSpread(_objectSpread({}, state), {}, {
        actionTrigger: action.payload
      });

    case INITIALIZE_SESSION_UUID:
      return _objectSpread(_objectSpread({}, state), {}, {
        session_uuid: action.payload
      });

    case ASSIGN_USER_UUID:
      return _objectSpread(_objectSpread({}, state), {}, {
        user_uuid: action.payload
      });

    case SET_PURCHASE_OVERLAY:
      return _objectSpread(_objectSpread({}, state), {}, {
        isPurchaseOverlayEnabled: action.payload
      });

    default:
      return state;
  }
};