import React from 'react';
import { EVENTS_CARD_TYPE } from 'shared/screens/Events/constants';
import { TEXT_KEY, getText } from 'shared/utils/localization';
import styled from 'styled-components';

const EntriesCounter = ({ renderElements, item }) => {
  const { totalEntriesAcquired, totalEntriesAvailable, cardType } = item;
  const { EntriesCounterDetail, EntriesBarDetail, EntriesBarFillDetail, EntriesBarEndDetail } = renderElements;

  const getEntriesAmount = () =>
    totalEntriesAvailable ? `${totalEntriesAcquired}/${totalEntriesAvailable ?? 0}` : totalEntriesAcquired;

  const isBarFillable = cardType === EVENTS_CARD_TYPE.TOP_BANNER;

  return (
    <EntriesWrapper>
      <EntriesLabelWrapper bgImage={EntriesCounterDetail}>
        <EntriesTitle>{getText(TEXT_KEY.EVENTS_ENTRIES_TITLE)}</EntriesTitle>
        <EntriesAmount bgImage={EntriesBarDetail}>
          <AmountText>{getEntriesAmount()}</AmountText>
        </EntriesAmount>
      </EntriesLabelWrapper>
      {isBarFillable && (
        <FillBar>
          <FillStart src={EntriesBarEndDetail} />
          <FillProgress
            src={EntriesBarFillDetail}
            percentage={
              totalEntriesAvailable ? ((totalEntriesAcquired ?? 0) * 100) / (totalEntriesAvailable ?? 0) : 100
            }
          />
          <FillEnd src={EntriesBarEndDetail} />
        </FillBar>
      )}
    </EntriesWrapper>
  );
};

export default EntriesCounter;

const EntriesWrapper = styled.div`
  height: 100%;
  width: 70%;
  align-items: center;
`;

const EntriesLabelWrapper = styled.div`
  background-image: ${({ bgImage }) => `url(${bgImage})`};
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const EntriesTitle = styled.span`
  font-size: 1.2rem;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 0px 0.5px 1px #424242;
  font-family: Campton;
  font-weight: 500;
`;

const EntriesAmount = styled.div`
  background-image: ${({ bgImage }) => `url(${bgImage})`};
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 75%;
  padding: 0.2rem;
  margin-bottom: 0.3rem;
`;

const AmountText = styled.span`
  font-size: 1.2rem;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 0px 0.5px 1px #424242;
  font-family: Campton;
  font-weight: 600;
  position: relative;
  z-index: 2;
`;

const FillBar = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  height: 75%;
  left: 15%;
  z-index: 1;
  bottom: 120%;
  @media only screen and (max-height: 800px) {
    bottom: 132%;
  }
  @media only screen and (min-height: 1080px) {
    bottom: 85%;
    height: 50%;
  }
  @media only screen and (max-height: 865px) and (min-width: 1500px) {
    bottom: 110%;
  }
  @media only screen and (min-height: 950px) and (max-height: 1000px) {
    bottom: 100%;
  }
  @media only screen and (min-height: 900px) and (max-height: 950px) {
    bottom: 105%;
  }
  @media only screen and (min-height: 865px) and (max-height: 900px) and (min-width: 1900px) {
    bottom: 109%;
  }
`;

const FillStart = styled.img`
  transform: rotateY(180deg);
  height: 100%;
  @media only screen and (min-height: 800px) and (max-height: 965px) {
    height: 80%;
  }
`;

const FillProgress = styled.img`
  position: relative;
  height: 100%;
  width: ${props => Math.floor(props.percentage - 36)}%;
  margin-left: -1px;
  @media only screen and (min-height: 800px) and (max-height: 965px) {
    height: 80%;
  }
`;

const FillEnd = styled.img`
  object-fit: contain;
  height: 100%;
  position: relative;
  margin-left: -1px;
  @media only screen and (min-height: 800px) and (max-height: 965px) {
    height: 80%;
  }
`;

