import { TARGET_TYPES } from './constants';
import logger from 'shared/lib/analytics/logger';
import facebookPixelTarget from './targets/facebookPixel';
import facebookAppEventsTarget from './targets/facebookAppEvents';
import xtremepushTarget from './targets/xtremepush';
import diveEventsTriggersTarget from './targets/diveEventsTriggers';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import gameVersion from 'shared/utils/gameVersion';
/** Dive SDK */
import { DiveSDK, LogLevels } from '@divegames/dive-ts-web-sdk';
import { processDiveEventQueue, sendDiveEvent } from './targets/utils/diveEventsQueue';

let logInterface;
let isDiveInitialized = false;
let initializedLogger = false;
const sendEvent = (eventType, payload = {}, reducerState = null) => {
  for (let target of logInterface.targets) {
    switch (target) {
      case TARGET_TYPES.FACEBOOK_PIXEL:
        facebookPixelTarget.handleEvent(eventType, payload);
        break;
      case TARGET_TYPES.FACEBOOK_APP_EVENTS:
        facebookAppEventsTarget.handleEvent(eventType, payload);
        break;
      case TARGET_TYPES.XTREMEPUSH:
        xtremepushTarget.handleEvent(eventType, payload);
        break;
      case TARGET_TYPES.DIVE:
        const diveEvent = () => diveEventsTriggersTarget.handleEvent(eventType, payload);
        sendDiveEvent(diveEvent, isDiveInitialized);
        break;
      default:
        break;
    }
  }
};

const initialize = ({ targets } = { targets: [] }) => {
  if (initializedLogger) return;
  initializedLogger = true;
  let supportedTargets = [];

  logger.sendEvent = sendEvent;
  for (let target of targets) {
    const { name } = target;
    switch (name) {
      case TARGET_TYPES.FACEBOOK_APP_EVENTS:
        if (!supportedTargets.includes(TARGET_TYPES.FACEBOOK_APP_EVENTS)) {
          supportedTargets.push(TARGET_TYPES.FACEBOOK_APP_EVENTS);
        }
        break;
      case TARGET_TYPES.XTREMEPUSH:
        if (!supportedTargets.includes(TARGET_TYPES.XTREMEPUSH)) {
          supportedTargets.push(TARGET_TYPES.XTREMEPUSH);
        }
        break;
      case TARGET_TYPES.DIVE:
        if (!supportedTargets.includes(TARGET_TYPES.DIVE)) {
          supportedTargets.push(TARGET_TYPES.DIVE);
        }
        /** Initialize Dive SDK */
        diveSdkInit();
        break;
      default:
        console.error(`Unable to initialize logger: ${name}`);
        break;
    }
  }

  logInterface = {
    targets: supportedTargets,
  };
};

export const Logger = {
  initialize,
  sendEvent,
};

export const diveSdkInit = () => {
  if (isDiveInitialized) {
    processDiveEventQueue();
  } else {
    DiveSDK.Init({
      AppToken: ENVIRONMENT_VARIABLES.DSDK_APP_TOKEN,
      HashKey: ENVIRONMENT_VARIABLES.DSDK_HASH_KEY,
      AnalyticsURL: ENVIRONMENT_VARIABLES.DSDK_SV_URL,
      ApiURL: ENVIRONMENT_VARIABLES.DSDK_API_URL,
      Environment: ENVIRONMENT_VARIABLES.ENVIRONMENT,
      Domain: ENVIRONMENT_VARIABLES.DSDK_DOMAIN,
      GameVersion: gameVersion(),
      Logger: {
        level: LogLevels.DEBUG,
      },
    });
    isDiveInitialized = true;
    processDiveEventQueue();
  }
};

