import React from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import ImageBW from '../../../../components/ImageBoundsWrapper';

const DefaultLevelUpText = () => {
  return (
    <div>
      <div className="levelUpTitle">{getText(TEXT_KEY.LEVEL_UP_TEXT)}</div>
      <ImageBW
        source={require(asset`Shop_VIP_Divider_Glows.png`)}
        ibw={require(asset`Shop_VIP_Divider_Glows.ibw`)}
        style={{
          width: '100%',
          height: '2.5rem',
          objectFit: 'contain',
          position: 'absolute',
        }}
      />
    </div>
  );
};

export default DefaultLevelUpText;

