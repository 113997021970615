import React, { useContext } from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import ThemeContext from 'shared/context/ThemeContext';
import { styles } from '../../styles';

const DefaultFormFooter = ({ enableOrderCard, handleRegisterRewardCard, termsAndConditionsUrl }) => {
  const { FormFooter } = useContext(ThemeContext);

  return (
    <p style={styles.termsText}>
      {enableOrderCard && (
        <>
          {`${getText(TEXT_KEY.LINK_CARD_SIGNUP)}`}{' '}
          <a style={FormFooter.TermsLink} onClick={handleRegisterRewardCard}>
            {getText(TEXT_KEY.LINK_CARD_SIGNUP_CTA)}
          </a>{' '}
        </>
      )}
      {getText(TEXT_KEY.TERMS_AGREE_MESSAGE)}{' '}
      <a style={FormFooter.TermsLink} onClick={() => window.open(termsAndConditionsUrl)}>
        {getText(TEXT_KEY.TERMS_OF_SERVICE)}
      </a>
    </p>
  );
};

export default DefaultFormFooter;

