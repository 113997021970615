import { createApi } from '@reduxjs/toolkit/query/react';
import { postFinishMobilePurchaseMutation } from './queries';
import { axiosBaseQuery } from '../../api/services/rtkQuery/axiosBaseQuery';
export var purchaseApi = createApi({
  reducerPath: 'purchaseQuery',
  baseQuery: axiosBaseQuery(),
  endpoints: function endpoints(builder) {
    return {
      postFinishMobilePurchase: builder.mutation(postFinishMobilePurchaseMutation)
    };
  }
});
var usePostFinishMobilePurchaseMutation = purchaseApi.usePostFinishMobilePurchaseMutation;
export { usePostFinishMobilePurchaseMutation };
var postFinishMobilePurchase = purchaseApi.endpoints.postFinishMobilePurchase,
    purchaseReducerPath = purchaseApi.reducerPath,
    purchaseQueryReducer = purchaseApi.reducer,
    purchaseMiddleware = purchaseApi.middleware;
export { purchaseReducerPath, purchaseQueryReducer, purchaseMiddleware, postFinishMobilePurchase };