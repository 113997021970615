import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { INITIALIZE_REWARD_CENTER, SET_OFFER_HISTORY, ENTER_OFFER_VIEW, SET_REWARD_CENTER_CURR_TAB, SET_REWARD_CENTER_PREV_TAB, SET_OFFER_DETAIL, PURCHASE_OFFER, SET_ACTIVE_HISTORY_ITEM, CLEAR_REWARD_CENTER_DATA, SET_REWARD_CENTER_BASE_URL, IS_IN_OFFERS_VIEW } from '../actions/types';
import { RewardCenterTabs } from '../../screens/RewardCenter/Constants';
var initialState = {
  api: {
    core: {
      filterList: [],
      news: [],
      // unsupported
      offers: [],
      profile: [],
      propertyList: [],
      sortingRules: []
    },
    history: [],
    offerDetails: {}
  },
  ux: {
    core: {
      hasData: false
    },
    history: {
      activeHistoryItem: null
    },
    offerDetails: {},
    activeTab: RewardCenterTabs.HOME,
    prevTab: ''
  },
  rewardCenterBaseUrl: '',
  isInOffersView: true
};
export var rewardCenterReducer = function rewardCenterReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var api = state.api;
  var ux = state.ux;

  switch (action.type) {
    case INITIALIZE_REWARD_CENTER:
      return _objectSpread(_objectSpread({}, state), {}, {
        api: _objectSpread(_objectSpread({}, api), {}, {
          core: action.payload.api
        }),
        ux: _objectSpread(_objectSpread({}, ux), {}, {
          core: action.payload.ux
        })
      });

    case SET_OFFER_HISTORY:
      var history = state.history;
      return _objectSpread(_objectSpread({}, state), {}, {
        api: _objectSpread(_objectSpread({}, api), {}, {
          history: action.payload
        })
      });

    case ENTER_OFFER_VIEW:
      return _objectSpread(_objectSpread({}, state), {}, {
        ux: _objectSpread(_objectSpread({}, ux), {}, {
          activeTab: RewardCenterTabs.OFFER,
          activeOffer: action.payload
        })
      });

    case SET_REWARD_CENTER_CURR_TAB:
      return _objectSpread(_objectSpread({}, state), {}, {
        ux: _objectSpread(_objectSpread({}, ux), {}, {
          activeTab: action.payload
        })
      });

    case SET_REWARD_CENTER_PREV_TAB:
      return _objectSpread(_objectSpread({}, state), {}, {
        ux: _objectSpread(_objectSpread({}, ux), {}, {
          prevTab: action.payload
        })
      });

    case SET_OFFER_DETAIL:
      var offerDetails = action.payload;
      return _objectSpread(_objectSpread({}, state), {}, {
        api: _objectSpread(_objectSpread({}, api), {}, {
          offerDetails: offerDetails
        })
      });

    case PURCHASE_OFFER:
      return _objectSpread({}, state);

    case SET_ACTIVE_HISTORY_ITEM:
      return _objectSpread(_objectSpread({}, state), {}, {
        ux: _objectSpread(_objectSpread({}, ux), {}, {
          activeTab: RewardCenterTabs.HISTORY_DETAIL,
          history: {
            activeHistoryItem: action.payload
          }
        })
      });

    case CLEAR_REWARD_CENTER_DATA:
      return _objectSpread({}, initialState);

    case SET_REWARD_CENTER_BASE_URL:
      return _objectSpread(_objectSpread({}, state), {}, {
        rewardCenterBaseUrl: action.payload
      });

    case IS_IN_OFFERS_VIEW:
      return _objectSpread(_objectSpread({}, state), {}, {
        isInOffersView: action.payload
      });

    default:
      return state;
  }
};