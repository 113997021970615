import _toConsumableArray from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { SET_ASSET_REFERENCE_COUNT, MARK_ASSET_LOADED, SET_LAST_INIT_APP, SET_INIT_APP_RESPONSE, SET_IS_INIT_APP_EXECUTING, SET_IS_DIVE_INITIALIZED, SET_INIT_ASSETS_LOADED, SET_INIT_TEXTS_LOADED } from '../actions/types';
var initialState = {
  assets: {
    processed: [],
    referenceCount: 0
  },
  lastInitApp: 0,
  initAssetsLoaded: false,
  initTextsLoaded: false,
  initAppResponse: {},
  isInitAppExecuting: false,
  isDiveInitialized: false
};

var loadReducer = function loadReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_ASSET_REFERENCE_COUNT:
      return _objectSpread(_objectSpread({}, state), {}, {
        assets: _objectSpread(_objectSpread({}, state.assets), {}, {
          referenceCount: action.payload
        })
      });

    case MARK_ASSET_LOADED:
      return _objectSpread(_objectSpread({}, state), {}, {
        assets: _objectSpread(_objectSpread({}, state.assets), {}, {
          processed: [].concat(_toConsumableArray(state.assets.processed), [action.payload])
        })
      });

    case SET_INIT_ASSETS_LOADED:
      return _objectSpread(_objectSpread({}, state), {}, {
        initAssetsLoaded: action.payload
      });

    case SET_INIT_TEXTS_LOADED:
      return _objectSpread(_objectSpread({}, state), {}, {
        initTextsLoaded: action.payload
      });

    case SET_LAST_INIT_APP:
      return _objectSpread(_objectSpread({}, state), {}, {
        lastInitApp: action.payload
      });

    case SET_INIT_APP_RESPONSE:
      return _objectSpread(_objectSpread({}, state), {}, {
        initAppResponse: action.payload
      });

    case SET_IS_INIT_APP_EXECUTING:
      return _objectSpread(_objectSpread({}, state), {}, {
        isInitAppExecuting: action.payload
      });

    case SET_IS_DIVE_INITIALIZED:
      return _objectSpread(_objectSpread({}, state), {}, {
        isDiveInitialized: action.payload
      });

    default:
      return state;
  }
};

export default loadReducer;