import Messages from './Messages';
import { connect } from '../../node_modules/react-redux';

var mapStateToProps = function mapStateToProps(state) {
  return {
    assetsUrls: state.app.assetsUrls
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Messages);