import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import SPasswordInput from 'shared/components/PasswordInput';
import styles from './styles';

export default class PasswordInput extends Component {
  render() {
    const { navigation, customPeekerStyle } = this.props;
    return (
      <SPasswordInput
        navigation={navigation}
        render={args => {
          const { showPassword, showPasswordToggleHandle } = args;
          return (
            <>
              <Form.Control className="transparentInput" type={!showPassword ? 'password' : 'text'} {...this.props} />
              <div style={customPeekerStyle ? customPeekerStyle : styles.peeker} onClick={showPasswordToggleHandle}>
                {!showPassword && <img src={require(asset`Icon_Show.png`)} />}
                {showPassword && <img src={require(asset`Icon_Hide.png`)} />}
              </div>
            </>
          );
        }}
      />
    );
  }
}
