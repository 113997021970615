import { getText, TEXT_KEY } from '../../utils/localization';

var SettingsTitleEnum = function SettingsTitleEnum() {
  return {
    customerSupport: getText(TEXT_KEY.CUSTOMER_SUPPORT_TITLE),
    messageSent: getText(TEXT_KEY.MESSAGE_SENT_TITLE),
    messageSentError: getText(TEXT_KEY.MESSAGE_SENT_ERROR_TITLE),
    account: getText(TEXT_KEY.ACCOUNT_TITLE),
    changePassword: getText(TEXT_KEY.CHANGE_PASSWORD_TITLE),
    changePasswordSuccess: getText(TEXT_KEY.CHANGE_PASSWORD_SUCCESS_TITLE),
    changePasswordError: getText(TEXT_KEY.CHANGE_PASSWORD_ERROR_TITLE),
    updateEmail: getText(TEXT_KEY.UPDATE_EMAIL_TITLE),
    updateEmailSuccess: getText(TEXT_KEY.UPDATE_EMAIL_SUCCESS_TITLE),
    updateEmailError: getText(TEXT_KEY.UPDATE_EMAIL_ERROR_TITLE),
    termsAndConditions: getText(TEXT_KEY.TERMS_AND_CONDITIONS_SETTING_TITLE),
    privacyPolicy: getText(TEXT_KEY.PRIVACY_POLICY_TITLE),
    settings: getText(TEXT_KEY.SETTINGS_TITLE)
  };
};

export default SettingsTitleEnum;