import React, { useContext } from 'react';
import Animated from 'animated/lib/targets/react-dom';
import { styles } from './styledComponent';
import ImageBW from '../ImageBoundsWrapper';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import Utils from 'shared/utils/index';
import ThemeContext from 'shared/context/ThemeContext';
import { getText, TEXT_KEY } from 'shared/utils/localization';

export default function FtueTooltip({ props }) {
  const { displayText, arrowStyle, containerStyle, animationClassName, right, style, bottom } = props;
  const themeContext = useContext(ThemeContext);
  return (
    <Animated.div
      className={`Tooltip-Container ${animationClassName ? animationClassName : 'AnimationFadeIn'}`}
      style={{
        ...style,
      }}
    >
      {!bottom && (
        <ImageBW
          source={require(asset`FlyOut_Triangle@3x.png`)}
          ibw={require(asset`FlyOut_Triangle.ibw`)}
          manualAssetScale={3}
          className="Arrow"
          style={(arrowStyle && arrowStyle) || {}}
        />
      )}

      <ImageBW
        source={require(asset`FlyOut_Backing_9Slice@3x.png`)}
        ibw={require(asset`FlyOut_Backing_9Slice.ibw`)}
        manualAssetScale={3}
        className="Container"
        style={(containerStyle && containerStyle) || {}}
      >
        <div style={styles.content}>
          <div style={styles.titleContainer}>
            <span
              className={`gradientText ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}
              style={themeContext.FtueTooltip.TooltipTitleStyle}
              data-label={displayText}
            >
              {displayText}
            </span>
            <ImageBW
              style={styles.divider}
              source={require(asset`./images/Level_Up/Light_Divider@3x.png`)}
              ibw={require(asset`./images/Level_Up/Light_Divider.ibw`)}
              widthScale={0.5}
            />
          </div>
          <div className="TooltipGameUnlock" style={{ marginTop: 10 }} onClick={() => {}}>
            <div style={styles.left}>
              <ImageBW
                source={require(asset`Coin_Icon@3x.png`)}
                ibw={require(asset`./images/Level_Up/Icon.ibw`)}
                style={styles.icon}
              />
            </div>
            <div style={styles.right}>
              <span
                className={`gradientText ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}
                style={themeContext.FtueTooltip.TooltipRewardStyle}
                data-label={getText(TEXT_KEY.FTUE_BETTER_REWARDS)}
              >
                {getText(TEXT_KEY.FTUE_BETTER_REWARDS)}
              </span>
            </div>
          </div>
          <div className="TooltipGameUnlock" style={{ marginTop: 10 }} onClick={() => {}}>
            <div style={styles.left}>
              <ImageBW
                source={require(asset`./images/Level_Up/Max_Bet@3x.png`)}
                ibw={require(asset`./images/Level_Up/Icon.ibw`)}
                style={styles.icon}
              />
            </div>
            <div style={styles.right}>
              <span
                className={`gradientText ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}
                style={themeContext.FtueTooltip.BiggerBetsStyle}
                data-label={getText(TEXT_KEY.FTUE_BETTER_BIGGER_BETS)}
              >
                {getText(TEXT_KEY.FTUE_BETTER_BIGGER_BETS)}
              </span>
            </div>
          </div>
        </div>

        {bottom && (
          <ImageBW
            source={require(asset`FlyOut_Triangle@3x.png`)}
            ibw={require(asset`FlyOut_Triangle.ibw`)}
            manualAssetScale={3}
            className="Arrow"
            style={{
              transform: 'rotate(180deg)',
              top: Utils.ieVersion() !== 0 ? '6.5rem' : '5.7rem',
              right: right ? '2rem' : '10rem',
            }}
          />
        )}
      </ImageBW>
    </Animated.div>
  );
}

