import AdmiralTheme from '../../../web/src/themes/AdmiralTheme';
import AdmesTheme from '../../../web/src/themes/AdmesTheme';
import HriTheme from '../../../web/src/themes/HriTheme';
import TiTheme from '../../../web/src/themes/TiTheme';
import SgaTheme from '../../../web/src/themes/SgaTheme';
import StarTheme from '../../../web/src/themes/StarTheme';
export var themes = {
  admes: AdmesTheme,
  admiral: AdmiralTheme,
  hri: HriTheme,
  sga: SgaTheme,
  star: StarTheme,
  ti: TiTheme
};