import React, { Component } from 'react';
import SMessageDetails from 'shared/components/MessageDetails';

import Utils from 'shared/utils';
import ImageBW from '../../components/ImageBoundsWrapper';
import { Scrollbars } from 'react-custom-scrollbars';
import ThemeContext from 'shared/context/ThemeContext';
import Button from '../Button/Button';
import { getText, TEXT_KEY } from 'shared/utils/localization';

/** @typedef {import('shared/api/Constants').InboxMessageDetails} InboxMessageDetails */
/** @typedef {import('shared/api/Constants').RewardInfo} RewardInfo */

export default class MessageDetails extends Component {
  state = {};

  _checkButtonText = message => {
    if (!Utils.isEmpty(message.reward_info)) {
      return getText(TEXT_KEY.COLLECT_BUTTON);
    }
    if (Utils.isEmpty(message.reward_info) && message.action !== '' && message.action != null && message.btn_text) {
      return message.btn_text.toUpperCase();
    }
    return getText(TEXT_KEY.OK);
  };

  render() {
    const themeContext = this.context;
    return (
      <SMessageDetails
        {...this.props}
        render={
          /**
           * @param {Object} args
           * @param {InboxMessageDetails} args.message
           * @param {Function} args.dynamicActionHandler
           */
          args => {
            const { message, dynamicActionHandler, collectRewardHandler } = args;

            if (Utils.isEmpty(message)) {
              return <></>;
            }

            return (
              <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', height: '100%' }}>
                <p style={themeContext.MessageDetails.MessageTitle}>
                  {message.title ? message.title : message.subject}
                </p>
                <div style={{ padding: '40px', paddingTop: '0px', paddingBottom: '0px', height: '90%' }}>
                  <ImageBW
                    style={{ top: '-20px' }}
                    source={require(asset`Light_Divider@3x.png`)}
                    ibw={require(asset`Light_Divider.ibw`)}
                  />
                  {message.title && <p class="message-subject">{message.subject}</p>}
                  <Scrollbars
                    ref="scrollbars"
                    style={{ height: '80%', marginRight: '-1px', marginTop: '10px' }}
                    renderThumbVertical={props => (
                      <div {...props} style={{ ...themeContext.MessageDetails.ScrollBar, width: '100%' }} />
                    )}
                    renderTrackVertical={props => (
                      <div {...props} className="track-vertical" style={{ opacity: 1, marginRight: '-5px' }} />
                    )}
                    renderThumbHorizontal={props => (
                      <div {...props} className="thumb-horizontal" style={{ display: 'none' }} />
                    )}
                    renderTrackHorizontal={props => (
                      <div {...props} className="track-horizontal" style={{ display: 'none' }} />
                    )}
                  >
                    <p class="message-body">{message.body}</p>
                  </Scrollbars>
                </div>
                {!Utils.isEmpty(message.reward_info) && (
                  <>
                    <p>{getText(TEXT_KEY.REWARD_INFO)}</p>
                    {message.reward_info.map(
                      /**
                       * @param {RewardInfo} reward
                       */
                      reward => (
                        <p style={{ fontSize: 14 }}>{`${reward.amount} ${reward.currency}`}</p>
                      )
                    )}
                  </>
                )}
                <Button
                  imageSource={require(asset`Pink_Button@3x.png`)}
                  containerStyle={{
                    cursor: 'pointer',
                    position: 'relative',
                    marginLeft: '38%',
                    top: '-50px',
                  }}
                  imageStyle={{ width: '100%' }}
                  label={this._checkButtonText(message)}
                  onClick={() => {
                    !Utils.isEmpty(message.reward_info)
                      ? collectRewardHandler(message.message_id)
                      : dynamicActionHandler(message.action);
                  }}
                />
              </div>
            );
          }
        }
      />
    );
  }
}

MessageDetails.contextType = ThemeContext;
