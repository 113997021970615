import { SET_ACTIVE_CONSUMABLES, SET_FREE_CONSUMABLES, SHOW_VIP_ACCESS_MODAL, SET_VIP_ROOM_UNLOCKED, SET_OUT_OF_COINS_OFFER, SET_LTO_OFFER, SET_WELCOME_BONUS, SET_TIMED_BONUS, SET_IS_WELCOME_BONUS_CONSUMED, CLEAR_LTO_OFFER } from './types';
export var setActiveConsumables = function setActiveConsumables(activeConsumables) {
  return {
    type: SET_ACTIVE_CONSUMABLES,
    payload: activeConsumables
  };
};
export var setFreeConsumables = function setFreeConsumables(freeConsumables) {
  return {
    type: SET_FREE_CONSUMABLES,
    payload: freeConsumables
  };
};
export var showVipAccessModal = function showVipAccessModal(value) {
  return {
    type: SHOW_VIP_ACCESS_MODAL,
    payload: value
  };
};
export var setVipRoomUnlocked = function setVipRoomUnlocked(value) {
  return {
    type: SET_VIP_ROOM_UNLOCKED,
    payload: value
  };
};
export var setOutOfCoinOfferResponse = function setOutOfCoinOfferResponse(value) {
  return {
    type: SET_OUT_OF_COINS_OFFER,
    payload: value
  };
};
export var setLTOOffer = function setLTOOffer(value) {
  return {
    type: SET_LTO_OFFER,
    payload: value
  };
};
export var clearLTOOffer = function clearLTOOffer() {
  return {
    type: CLEAR_LTO_OFFER
  };
};
export var setWelcomeBonus = function setWelcomeBonus(value) {
  return {
    type: SET_WELCOME_BONUS,
    payload: value
  };
};
export var setTimedBonus = function setTimedBonus(value) {
  return {
    type: SET_TIMED_BONUS,
    payload: value
  };
};
export var setIsWelcomeBonusConsumed = function setIsWelcomeBonusConsumed(value) {
  return {
    type: SET_IS_WELCOME_BONUS_CONSUMED,
    payload: value
  };
};