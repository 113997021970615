import generateRender from 'shared/utils/generateRender';

export const listButtonTextStyle = title =>
  generateRender({
    default: defaultListButtonStyle,
    star: starListButtonStyle,
    admiral: admiralListButtonStyle(title),
    admes: admiralListButtonStyle(title),
  });

const defaultListButtonStyle = () => ({
  display: 'flex',
});

const starListButtonStyle = () => ({
  display: 'none',
});

const admiralListButtonStyle = title => ({
  display: 'flex',
  fontSize: title.length > 25 ? '1rem' : title.length > 23 ? '1.3rem' : '1.45rem',
});

