import React from 'react';
import Button from '../../../../components/Button/Button';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import { getText, TEXT_KEY } from 'shared/utils/localization';

const DefaultFormButton = props => {
  const { handleLinkBtn, handleLinkAccount, showOTPConsents, error, activeOTPCheckbox } = props;

  let disableButton = showOTPConsents && !activeOTPCheckbox;

  return (
    <Button
      onClick={() => {
        if (!error) handleLinkBtn(handleLinkAccount);
      }}
      label={getText(TEXT_KEY.LINK)}
      isDebounce={true}
      disabled={disableButton}
      containerStyle={{
        cursor: 'pointer',
        fontSize: '1.7rem',
        fontWeight: '600',
        width: 181,
        height: 59,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        margin: '0 auto',
        marginTop: '20px',
        marginBottom: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      imageSource={
        disableButton ? require(asset`RoundedRect_Disabled@2x.png`) : require(asset`RoundedRect_Primary@2x.png`)
      }
      textStroke={BUTTON_STROKES.PRIMARY}
    />
  );
};

export default DefaultFormButton;

