import { useRef } from 'react';
import audio from '../../../utils/audio';
var maxStep = 2;
export default function useAnimationHandler(props) {
  var Animated = props.Animated,
      Easing = props.Easing,
      claimDailyBonus = props.claimDailyBonus,
      step = props.step,
      changeStep = props.changeStep,
      bonusInfo = props.bonusInfo;
  var fastForwardButtonAnim = useRef(new Animated.Value(0)).current;
  var wheelInAnim = useRef(new Animated.Value(0)).current;
  var wheelPostSpinAnim = useRef(new Animated.Value(0)).current;
  var spinAnim = useRef(new Animated.Value(0)).current;
  var bottomAnim = useRef(new Animated.Value(0)).current;
  var streakAnim = useRef(new Animated.Value(0)).current;
  var wheelFirstAnimation = useRef(false);
  var isSkippedRef = useRef(false);

  var nextStepHandler = function nextStepHandler() {
    var FastForwardSpeed = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
    audio.onClick();
    var isSkipped = FastForwardSpeed > 1;

    if (!wheelFirstAnimation.current) {
      return false;
    }

    if (isSkipped) {
      isSkippedRef.current = true;
    }

    if (step < maxStep - 1) {
      if (step + 1 === 1) {
        var sliceRotation = -360 / 16 * -bonusInfo.PRIZE_INDEX;
        Animated.sequence([Animated.parallel([Animated.timing(spinAnim, {
          toValue: 16,
          duration: isSkipped ? 0 : 2500 / FastForwardSpeed,
          useNativeDriver: true
        }), Animated.timing(fastForwardButtonAnim, {
          toValue: 1,
          duration: isSkipped ? 0 : 1500,
          useNativeDriver: true
        })]), Animated.timing(spinAnim, {
          toValue: -360 * 4 + sliceRotation - 1,
          duration: isSkipped ? 0 : 9500 / FastForwardSpeed,
          easing: Easing.out(Easing.exp),
          useNativeDriver: true
        }), Animated.delay(1000 / FastForwardSpeed), Animated.timing(wheelPostSpinAnim, {
          toValue: 1,
          duration: isSkipped ? 0 : 500 / FastForwardSpeed,
          useNativeDriver: true
        }), Animated.delay(250 / FastForwardSpeed), Animated.timing(streakAnim, {
          toValue: 1,
          duration: isSkipped ? 0 : 1000 / FastForwardSpeed,
          useNativeDriver: true
        }), Animated.delay(250 / FastForwardSpeed), Animated.parallel([Animated.timing(bottomAnim, {
          toValue: 1,
          duration: isSkipped ? 0 : 600 / FastForwardSpeed,
          useNativeDriver: true
        })])]).start(function () {
          return changeStep(function (step) {
            return step + 1;
          });
        });
      }

      changeStep(function (step) {
        return step + 1;
      });
    } else {
      claimDailyBonus(isSkippedRef === null || isSkippedRef === void 0 ? void 0 : isSkippedRef.current);
    }
  };

  var animateWheelIn = function animateWheelIn() {
    setTimeout(function () {
      return Animated.timing(wheelInAnim, {
        toValue: 1,
        duration: 1200,
        easing: Easing.elastic(1),
        useNativeDriver: true
      }).start(function () {
        return wheelFirstAnimation.current = true;
      });
    }, 800);
  };

  return {
    nextStepHandler: nextStepHandler,
    animateWheelIn: animateWheelIn,
    bottomAnim: bottomAnim,
    wheelInAnim: wheelInAnim,
    wheelPostSpinAnim: wheelPostSpinAnim,
    fastForwardButtonAnim: fastForwardButtonAnim,
    streakAnim: streakAnim,
    spinAnim: spinAnim
  };
}