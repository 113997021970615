import { EVENT_TYPES } from '../../../lib/analytics/constants';
import Logger from '../../../lib/analytics/logger';
import ERROR_CODE from '../../../utils/errorCodes';
export var bonusInfoGenerator = function bonusInfoGenerator(dailyWheelData, skipDailyWheel, dailyWheelAssets) {
  var defaultBonusData = {
    bonusInfo: false,
    currentTierBonus: null,
    wheelBonus: 0,
    dailyBonus: 0,
    totalLoyalty: 0,
    totalCoinsAfterTierBonus: 1,
    spinnerItems: [],
    showTotalLoyalty: false,
    showTotalCoins: false
  };

  try {
    var bonusInfo = JSON.parse(JSON.stringify(dailyWheelData));
    var spinnerItems = JSON.parse(JSON.stringify(dailyWheelData === null || dailyWheelData === void 0 ? void 0 : dailyWheelData.SPINNER_ITEMS));

    if (!dailyWheelAssets || !bonusInfo) {
      skipDailyWheel();
      return defaultBonusData;
    }

    var currentTierBonus = null;

    for (var i = 0; i < bonusInfo.MEMBER_TIERS.length; i++) {
      var _bonusInfo$CURRENT_ME, _bonusInfo$MEMBER_TIE;

      if (((_bonusInfo$CURRENT_ME = bonusInfo.CURRENT_MEMBER_TIER) === null || _bonusInfo$CURRENT_ME === void 0 ? void 0 : _bonusInfo$CURRENT_ME.toLowerCase()) === ((_bonusInfo$MEMBER_TIE = bonusInfo.MEMBER_TIERS[i].tier_name) === null || _bonusInfo$MEMBER_TIE === void 0 ? void 0 : _bonusInfo$MEMBER_TIE.toLowerCase())) {
        currentTierBonus = bonusInfo.MEMBER_TIERS[i].daily_bonus_boost_percentage;
      }
    }

    var totalLoyalty = bonusInfo.DISPLAY_TOTAL_LOYALTY;
    var totalCoinsAfterTierBonus = bonusInfo.DISPLAY_TOTAL_COINS_AFTER_TIER_BONUS;
    var dailyBonus = bonusInfo.DISPLAY_DAY_STREAK_COINS;
    var wheelBonus = bonusInfo.DISPLAY_WHEEL_BONUS;
    return {
      bonusInfo: bonusInfo,
      currentTierBonus: currentTierBonus,
      wheelBonus: wheelBonus,
      dailyBonus: dailyBonus,
      totalLoyalty: totalLoyalty,
      totalCoinsAfterTierBonus: totalCoinsAfterTierBonus,
      spinnerItems: spinnerItems,
      showTotalCoins: totalCoinsAfterTierBonus && totalCoinsAfterTierBonus !== '0' ? true : false,
      showTotalLoyalty: totalLoyalty && totalLoyalty !== '0' ? true : false
    };
  } catch (e) {
    Logger.sendEvent(EVENT_TYPES.FRONTEND_ERROR, {
      error: JSON.stringify(e, Object.getOwnPropertyNames(e)),
      code: ERROR_CODE.DAILY_SPIN_GET_BONUS_INFO_ERROR
    });
    skipDailyWheel();
    return defaultBonusData;
  }
};
export var getLoginStreakList = function getLoginStreakList(bonusInfo) {
  var _bonusInfo$LOGIN_STRE;

  var loginStreakList = bonusInfo === null || bonusInfo === void 0 ? void 0 : (_bonusInfo$LOGIN_STRE = bonusInfo.LOGIN_STREAK_BONUS_LIST) === null || _bonusInfo$LOGIN_STRE === void 0 ? void 0 : _bonusInfo$LOGIN_STRE.slice(0);
  var loginStreakFinal = {};
  var loginStreakFinalDayNum = 0;

  if (bonusInfo === null || bonusInfo === void 0 ? void 0 : bonusInfo.WHEEL_HERO) {
    var _loginStreakFinal;

    loginStreakFinal = loginStreakList && loginStreakList[(loginStreakList === null || loginStreakList === void 0 ? void 0 : loginStreakList.length) - 1];
    loginStreakFinalDayNum = (_loginStreakFinal = loginStreakFinal) === null || _loginStreakFinal === void 0 ? void 0 : _loginStreakFinal.day;
    loginStreakList === null || loginStreakList === void 0 ? void 0 : loginStreakList.shift();
  } else {
    var _loginStreakFinal2;

    loginStreakFinal = loginStreakList === null || loginStreakList === void 0 ? void 0 : loginStreakList.pop();
    loginStreakFinalDayNum = (_loginStreakFinal2 = loginStreakFinal) === null || _loginStreakFinal2 === void 0 ? void 0 : _loginStreakFinal2.day;
  }

  return {
    loginStreakList: loginStreakList,
    loginStreakFinal: loginStreakFinal,
    loginStreakFinalDayNum: loginStreakFinalDayNum
  };
};
export var lightAssets = function lightAssets(dailyWheelAssets) {
  return [dailyWheelAssets.DAILY_SPIN_COLUMN_LIGHT_OFF, dailyWheelAssets.DAILY_SPIN_COLUMN_LIGHT_1, dailyWheelAssets.DAILY_SPIN_COLUMN_LIGHT_2, dailyWheelAssets.DAILY_SPIN_COLUMN_LIGHT_3, dailyWheelAssets.DAILY_SPIN_COLUMN_LIGHT_4, dailyWheelAssets.DAILY_SPIN_COLUMN_LIGHT_5, dailyWheelAssets.DAILY_SPIN_COLUMN_LIGHT_6, dailyWheelAssets.DAILY_SPIN_COLUMN_LIGHT_7, dailyWheelAssets.DAILY_SPIN_COLUMN_LIGHT_8, dailyWheelAssets.DAILY_SPIN_COLUMN_LIGHT_9];
};
export var SPINNER_ITEM_REWARD_TYPE = {
  REGULAR: 'regular',
  OUTSTANDING: 'outstanding'
};