import React, { Component } from 'react';
import SNews from 'shared/screens/News';

export default class News extends Component {
  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-news',
    };
  }

  render() {
    return (
      <SNews
        navigation={this.props.navigation}
        render={args => {
          const { image, clickHandler, showButton } = args;

          return (
            <div
              className="newsBody"
              style={{ cursor: showButton && 'pointer' }}
              onClick={() => {
                showButton && clickHandler();
              }}
            >
              {image && <img alt="Welcome News" src={image} style={{ width: '100%' }} />}
            </div>
          );
        }}
      />
    );
  }
}

News.hideDefaultBackground = true;
