import React, { Component } from 'react';
import STimestampManagerComponent from 'shared/components/TimestampManagerComponent';

export default class TimestampManagerComponent extends Component {
  state = {};

  // React Component Life Cycle
  // componentDidCatch() {}
  // componentDidUpdate() {}
  // componentDidMount() {}
  // componentWillMount() {}
  // componentWillReceiveProps() {}
  // componentWillUnmount() {}
  // componentWillUpdate() {}

  render() {
    return (
      <STimestampManagerComponent
        navigation={this.props.navigation}
        render={args => {
          const {} = args;
          return <></>;
        }}
      />
    );
  }
}
