import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import QueryParamProxy from './queryParamProxy';
import RouteParamConstants from './routeParamConstants';
import Screens from '../screens';
import RewardCenterOnboarding from './rewardCenterOnboarding';
export var PromoActionKey = {
  NO_ACTION: '',
  GO_TO_COIN_STORE: 'gotoStore',
  GO_TO_REWARDS: 'rewards',
  LAUNCH_GAME: 'game',
  REDIRECT_TO_EXTERNAL_PAGE: 'url',
  DOWNLOAD_MOBILE_APP: 'downloadMobileApp',
  LINK_LOYALTY_CARD: 'linkLoyaltyCard',
  INBOX_MESSAGE: 'inboxMessage'
};
export var PromotionAction = {
  getActionCallback: function getActionCallback(navigation, action) {
    var type = action.type,
        game = action.game,
        externalUrl = action.externalUrl;

    switch (type) {
      case PromoActionKey.NO_ACTION:
        return function () {};

      case PromoActionKey.DOWNLOAD_MOBILE_APP:
        return function () {
          alert('Please Go Download Mobile App!');
        };

      case PromoActionKey.GO_TO_COIN_STORE:
        return function () {
          QueryParamProxy.setQueryParams(_defineProperty({}, RouteParamConstants.PARAM_MODAL, Screens.Shop));
        };

      case PromoActionKey.GO_TO_REWARDS:
        return function () {
          RewardCenterOnboarding.showModal(Screens.RewardCenter);
        };

      case PromoActionKey.LAUNCH_GAME:
        return function () {
          QueryParamProxy.setQueryParams(_defineProperty({}, RouteParamConstants.PARAM_MODAL, undefined));
          QueryParamProxy.navigate(navigation, Screens.Game, _defineProperty({}, RouteParamConstants.PARAM_GAME_ID, game));
        };

      case PromoActionKey.LINK_LOYALTY_CARD:
        return function () {
          QueryParamProxy.setQueryParams(_defineProperty({}, RouteParamConstants.PARAM_MODAL, Screens.LinkAccount));
        };

      case PromoActionKey.REDIRECT_TO_EXTERNAL_PAGE:
        return function () {
          QueryParamProxy.setQueryParams(_defineProperty({}, RouteParamConstants.PARAM_EXTERNAL_URL, externalUrl));
        };

      case PromoActionKey.INBOX_MESSAGE:
        return function () {
          QueryParamProxy.setQueryParams(_defineProperty({}, RouteParamConstants.PARAM_MODAL, Screens.Messages));
        };

      default:
        return function () {};
    }
  }
};
export default PromotionAction;