import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import LobbyOverlay from './LobbyOverlay';
import { connect } from '../../node_modules/react-redux';
import appInitDispatch from '../../lib/load/redux/appInitDispatch';

var mapStateToProps = function mapStateToProps(state) {
  return {
    activeConsumables: state.consumables.active,
    appBackground: state.app.appBackground,
    coin: state.coin.value,
    loyalty: state.loyalty.value,
    device: state.device,
    gameInfo: state.gameInfo,
    ftueStep: state.currentOnboarding.ftueStep,
    ftueEnabled: state.currentOnboarding.ftueEnabled,
    favouriteGames: state.favouriteGames,
    ftue: state.ftue,
    initiatePurchase: state.purchase.initiatePurchase,
    modal: state.modal,
    navState: state.navState.navState,
    notifications: state.notifications,
    notificationsSent: state.notifications.notificationsSent,
    onboardingsSeen: state.ftue.onboardingsSeen[state.playerInfo.playerId],
    onboardingsUnlockLevels: state.ftue.onboardingsUnlockLevels,
    pendingMessages: state.pendingMessages,
    showTooltip: state.tooltip.shouldShowTooltip,
    token: state.token.token,
    tooltipDetails: state.tooltip.tooltipDetails.tooltipDetails,
    userLevel: state.levelInfo.level,
    assetsUrls: state.app.assetsUrls,
    initAppResponse: state.load.initAppResponse,
    lastInitApp: state.load.lastInitApp,
    isInGame: state.gameInfo.gameId !== '' && state.gameInfo.gameProvider !== '',
    playerId: state.playerInfo.playerId,
    showCustomModal: state.app.showCustomModal,
    updatedTerms: state.properties.data.isTermsUpdated,
    actionCommands: state.actionCommands.actions
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return _objectSpread({}, appInitDispatch(dispatch));
};

export default connect(mapStateToProps, mapDispatchToProps)(LobbyOverlay);