var FormId = require('../../../components/Form/FormId').default;

var FormType = require('../../../components/Form/FormType').default;

var KeyboardHint = require('../../../components/Form/KeyboardHint').default;

var InputValidator = require('../../../components/Form/InputValidator').default;

var KycConstants = require('../../../utils/kyc/Constants');

export default {
  title: 'Almost there...',
  formInputList: [{
    id: FormId.FIRSTNAME,
    inputStyle: true,
    label: 'First Name',
    type: FormType.TEXT,
    placeholder: 'Must match your ID',
    customLabel: 'form-input-label with-placeholder',
    keyboardHint: KeyboardHint.DEFAULT,
    validator: InputValidator.NOT_EMPTY,
    descriptions: ''
  }, {
    id: FormId.LASTNAME,
    inputStyle: true,
    label: 'Last Name',
    type: FormType.TEXT,
    placeholder: 'Must match your ID',
    customLabel: 'form-input-label with-placeholder',
    keyboardHint: KeyboardHint.DEFAULT,
    validator: InputValidator.NOT_EMPTY,
    descriptions: ''
  }, {
    id: FormId.MIDDLENAME,
    inputStyle: true,
    label: 'Middle Name(s)',
    type: FormType.TEXT,
    placeholder: 'Enter if you have one',
    customLabel: 'form-input-label with-placeholder',
    keyboardHint: KeyboardHint.DEFAULT,
    validator: InputValidator.NO_VALIDATION,
    descriptions: ''
  }, {
    id: FormId.DATE_OF_BIRTH,
    inputStyle: true,
    label: 'Date of Birth',
    type: FormType.DATE_PICKER,
    placeholder: 'MM-DD-YYYY',
    keyboardHint: KeyboardHint.DEFAULT,
    validator: InputValidator.ADULT_ONLY,
    descriptions: ''
  }, {
    id: FormId.DOCUMENT_TYPE,
    inputStyle: true,
    label: 'Document',
    type: FormType.DROPDOWN,
    options: KycConstants.DOCUMENT_TYPES,
    keyboardHint: KeyboardHint.DEFAULT,
    validator: InputValidator.NOT_EMPTY,
    descriptions: '',
    focused: true
  }, {
    id: FormId.STATE,
    inputStyle: true,
    label: 'State',
    type: FormType.DROPDOWN,
    options: KycConstants.DRIVER_LICENSE_STATE,
    keyboardHint: KeyboardHint.DEFAULT,
    validator: InputValidator.NOT_EMPTY,
    descriptions: '',
    focused: true
  }, {
    id: FormId.PASSPORT_COUNTRY,
    inputStyle: true,
    label: 'Country',
    type: FormType.DROPDOWN,
    options: KycConstants.PASSPORT_COUNTRIES,
    keyboardHint: KeyboardHint.DEFAULT,
    validator: InputValidator.LETTERS_ONLY,
    descriptions: '',
    hide: true,
    focused: true
  }, {
    id: FormId.DOCUMENT_NUMBER,
    inputStyle: true,
    label: 'Document Number',
    type: FormType.TEXT,
    placeholder: 'Enter number',
    customLabel: 'form-input-label with-placeholder',
    keyboardHint: KeyboardHint.DEFAULT,
    validator: InputValidator.NOT_EMPTY,
    descriptions: '',
    hide: true
  }, {
    id: FormId.DRIVER_LICENCE_NUMBER,
    inputStyle: true,
    label: 'Driver Licence Number',
    type: FormType.TEXT,
    placeholder: 'Enter number',
    customLabel: 'form-input-label with-placeholder',
    keyboardHint: KeyboardHint.DEFAULT,
    validator: InputValidator.NOT_EMPTY,
    descriptions: ''
  }, {
    id: FormId.DRIVER_LICENCE_CARD_NUMBER,
    inputStyle: true,
    label: 'Driver Licence Card Number',
    type: FormType.TEXT,
    placeholder: 'Enter number',
    customLabel: 'form-input-label with-placeholder',
    keyboardHint: KeyboardHint.DEFAULT,
    validator: InputValidator.NOT_EMPTY,
    descriptions: ''
  }],
  submitButtonText: 'SUBMIT'
};