import { GameResponseKeys } from '../api/Constants';
import transformObjectArrayResponse from './transformObjectArrayResponse';
import transformObjectResponseKeys from './transformObjectResponseKeys';
import store from '../state/store';
export default {
  /**
   * Takes in a list of game objects and parse it to a data structure our live panel renders
   * @param {import('../api/Constants').GameResponseKeys[]} listOfGames
   * @returns {import('../api/Constants').LivePanelData[]}
   */
  normalizeGameResponse: function normalizeGameResponse(listOfGames) {
    var gameList = [];

    for (var i = 0; i < listOfGames.length; i++) {
      var item = listOfGames[i];
      /** @type {import('../api/Constants').LivePanelData} */

      var game = {};
      game.id = item.GAME_ID;
      game.isSquare = true;
      gameList.push(game);
    }

    return gameList;
  },
  sortItemsByPage: function sortItemsByPage(listOfItems) {
    var maxColumns = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 5;
    var pageList = [];
    var itemList = [];
    var pageCounter = 0;
    var colCounter = 0;

    for (var i = 0; i < Object.keys(listOfItems).length; i++) {
      var newItem = listOfItems[i];

      if (newItem.isSquare) {
        colCounter += 0.5;
      } else {
        colCounter++;
      }

      itemList.push(newItem);

      if (colCounter >= maxColumns || i == Object.keys(listOfItems).length - 1) {
        pageList.push({
          id: pageCounter,
          columns: maxColumns,
          items: itemList.concat([])
        });
        pageCounter++;
        colCounter = 0;
        itemList = [];
      }
    }

    return pageList;
  },

  /**
   * @param {import('../api/Constants').LivePanelInfo[]} listOfItems
   */
  sortPanelsByPage: function sortPanelsByPage(listOfItems) {
    var maxColumns = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 5;
    var pageList = [];
    var itemList = [];
    var pageCounter = 0;
    var colCounter = 0;

    for (var i = 0; i < Object.keys(listOfItems).length; i++) {
      var newItem = listOfItems[i];
      colCounter += newItem.height / 2;
      itemList.push(newItem);

      if (colCounter >= maxColumns || i == Object.keys(listOfItems).length - 1) {
        pageList.push({
          id: pageCounter,
          columns: maxColumns,
          items: itemList.concat([])
        });
        pageCounter++;
        colCounter = 0;
        itemList = [];
      }
    }

    return pageList;
  },
  isFavouriteGame: function isFavouriteGame(favGames, gameId) {
    if (!favGames) return false;
    return favGames.some(function (game) {
      return game.game_id === "".concat(gameId);
    });
  },
  isFeaturedGame: function isFeaturedGame(gameId) {
    var featuredGames = store.getState().featuredGames.gameList;
    if (!featuredGames) return false;
    return featuredGames.find(function (game) {
      return game.GAME_ID === gameId;
    });
  },
  getUnlockLevel: function getUnlockLevel(gameId, gameType) {
    var gamesByType = [];
    var gameTypeDataMap = {
      slots: 'slotGames',
      table: 'tableGames',
      configurable_vip: 'vipGames',
      new: 'newGames',
      featured: 'featuredGames'
    };

    if (!gameType) {
      return false;
    }

    var gameTypeKey = gameTypeDataMap[gameType];
    var gamesData = store.getState()[gameTypeKey];

    if (!gamesData || gamesData.gameList.length === 0) {
      return false;
    }

    gamesByType = gamesData.gameList[0].games || gamesData.gameList;

    if (gamesByType.length === 0) {
      return false;
    }

    return gamesByType.find(function (game) {
      return game.GAME_ID === gameId;
    });
  },
  gameTypes: {
    SLOTS: 'slots',
    TABLE: 'table'
  }
};