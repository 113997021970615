import Tooltip from './Tooltip';
import { connect } from '../../node_modules/react-redux';

var mapStateToProps = function mapStateToProps(state) {
  return {
    missionAssets: state.assetsPackage.assets.MISSIONS_FEATURE
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {// Add action from store
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tooltip);