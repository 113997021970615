import MessageDetails from './MessageDetails';
import { connect } from '../../node_modules/react-redux';

var mapStateToProps = function mapStateToProps(state) {
  return {
    // Add state from store
    loyalty: parseInt(state.loyalty.value),
    coin: parseInt(state.coin.value),
    gameInfo: state.gameInfo
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {// Add action from store
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageDetails);