import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { SET_GAME_ID, SET_GAME_TYPE, SET_GAME_PROVIDER, SET_NEW_GAMES, SET_FEATURED_GAMES, SET_SLOT_GAMES, SET_TABLE_GAMES, SET_VIP_GAMES, SET_FAVOURITE_GAMES, SET_GAME_STATE, SET_LAST_GAME_VISITED, CLEAR_GAME_INFO, SET_GAME_INFO } from '../actions/types';
var initialState = {
  gameId: '',
  gameState: '',
  gameType: '',
  gameProvider: '',
  favoriteGames: {},
  lastGameVisited: '',
  isEmbedded: '',
  embedUrl: ''
};
export var gameInfoReducer = function gameInfoReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_GAME_ID:
      return _objectSpread(_objectSpread({}, state), {}, {
        gameId: action.payload
      });

    case SET_LAST_GAME_VISITED:
      return _objectSpread(_objectSpread({}, state), {}, {
        lastGameVisited: action.payload
      });

    case SET_GAME_STATE:
      return _objectSpread(_objectSpread({}, state), {}, {
        gameState: action.payload
      });

    case SET_GAME_TYPE:
      return _objectSpread(_objectSpread({}, state), {}, {
        gameType: action.payload
      });

    case SET_GAME_PROVIDER:
      return _objectSpread(_objectSpread({}, state), {}, {
        gameProvider: action.payload
      });

    case SET_GAME_INFO:
      return _objectSpread(_objectSpread({}, state), action.payload);

    case CLEAR_GAME_INFO:
      return initialState;

    default:
      return state;
  }
};
export var newGamesReducer = function newGamesReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    gameList: []
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_NEW_GAMES:
      return _objectSpread(_objectSpread({}, state), {}, {
        gameList: action.payload
      });

    default:
      return state;
  }
};
export var featuredGamesReducer = function featuredGamesReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    gameList: []
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_FEATURED_GAMES:
      return _objectSpread(_objectSpread({}, state), {}, {
        gameList: action.payload
      });

    default:
      return state;
  }
};
export var slotGamesReducer = function slotGamesReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    gameList: []
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_SLOT_GAMES:
      return _objectSpread(_objectSpread({}, state), {}, {
        gameList: action.payload
      });

    default:
      return state;
  }
};
var initialTableGamesState = {
  gameList: [{
    games: []
  }]
};
export var tableGamesReducer = function tableGamesReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialTableGamesState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_TABLE_GAMES:
      return _objectSpread(_objectSpread({}, state), {}, {
        gameList: action.payload
      });

    default:
      return state;
  }
};
export var vipGamesReducer = function vipGamesReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    gameList: []
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_VIP_GAMES:
      return _objectSpread(_objectSpread({}, state), {}, {
        gameList: action.payload
      });

    default:
      return state;
  }
};
export var favouriteGameReducer = function favouriteGameReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    gameList: []
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_FAVOURITE_GAMES:
      return _objectSpread(_objectSpread({}, state), {}, {
        gameList: action.payload
      });

    default:
      return state;
  }
};