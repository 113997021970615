export var HTTP_REQUEST = {
  AXIOS: 'AXIOS'
};
export var METRICS = {
  REQUEST_SENT: 'REQUEST_SENT',
  RESPONSE_RECEIVED: 'RESPONSE_RECEIVED',
  RESTART_SESSION: 'RESTART_SESSION',
  REQUEST_CATCH: 'REQUEST_CATCH'
};
export var HEADER = {
  SESSION_ID: 'X-BBG-sessionId',
  REQUEST_ID: 'X-BBG-requestId',
  LANGUAGE: 'Accept-Language'
};