import React, { Component } from 'react';

/** Grid */
import Utils from 'shared/utils';
import PinnedPanel from '../../components/PinnedPanel';
import TimeRemainingText from '../../components/TimeRemainingText';
import ImageBW from '../../components/ImageBoundsWrapper';
import SVIPGames from 'shared/screens/VIPGames';
import GamePage from '../../components/GamePage';
import audio from 'shared/utils/audio';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import ThemeContext from 'shared/context/ThemeContext';

export default class VIPGames extends Component {
  state = {};
  slider = React.createRef();

  handleNextClick = () => {
    audio.onClick();
    this.slider.current.slickNext();
  };

  handlePrevClick = () => {
    audio.onClick();
    this.slider.current.slickPrev();
  };

  handleFirstClick = () => {
    audio.onClick();
    this.slider.current.slickGoTo(0);
  };

  handleLastClick = pagesCount => {
    audio.onClick();
    this.slider.current.slickGoTo(pagesCount);
  };

  render() {
    const themeContext = this.context;
    return (
      <SVIPGames
        navigation={this.props.navigation}
        {...this.props}
        render={args => {
          const { firstTimeAccess, canEnterRoom, timeRemaining, pagedGames, lobbyPromotions } = args;
          return (
            <>
              <div className="navigation-darker">
                <img alt="darker" src={require(asset`images/VIP_Assets/Navigation_Darker.png`)} />
              </div>
              <div className="roomPage">
                <div className="roomContent">
                  {(!canEnterRoom || firstTimeAccess) && (
                    <>
                      <div className="Overlay">
                        <div className="Title">{getText(TEXT_KEY.VIP_GAMES_TITLE)}</div>
                        <div className="Container">
                          <div className="Column">
                            {!canEnterRoom && (
                              <>
                                <img src={require(asset`VIP_Pass@3x.png`)} alt={'VIP Pass'} className="Icon" />
                                <p>{getText(TEXT_KEY.CHOOSE_PASS)}</p>
                              </>
                            )}
                            {canEnterRoom && firstTimeAccess && (
                              <>
                                <img src={require(asset`Checkmark@3x.png`)} alt={'Checkmark'} className="Icon" />
                                <p>{getText(TEXT_KEY.AGREE_TCS)}</p>
                              </>
                            )}
                          </div>
                          <div className="Column">
                            <img src={require(asset`Quest20_Lock@3x.png`)} alt={'Lock Icon'} className="Icon" />
                            <p>{getText(TEXT_KEY.GAIN_INSTANT_ACCESS)}</p>
                          </div>
                          <div className="Column">
                            <img src={require(asset`Slot_Coins@3x.png`)} alt={'Slots Coin'} className="Icon" />
                            <p>{getText(TEXT_KEY.PLAY_50_GAMES)}</p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {canEnterRoom && !firstTimeAccess && (
                    <>
                      {timeRemaining > 0 && (
                        <div className="VIPTimer">
                          <ImageBW
                            source={require(asset`images/VIP_Assets/Glowing_Container@3x.png`)}
                            ibw={require(asset`images/VIP_Assets/Glowing_Container.ibw`)}
                            style={{}}
                            className="TimerBackground"
                          />
                          <img
                            src={require(asset`Clock_Icon_Glowing@3x.png`)}
                            alt={'Clock Icon'}
                            className="TimerIcon"
                          />
                          <TimeRemainingText
                            style={themeContext.VipGames.TimerLabel}
                            until={timeRemaining} // Assuming server sends the time remaining in seconds
                          />
                        </div>
                      )}
                      <div className="NavigationArrowsContainer left">
                        <img
                          src={require(asset`images/Lobby_Assets/Scroll_Buttons@3x.png`)}
                          className="LeftNavigationArrows"
                          alt="Left navigation arrows"
                        />
                        <div onClick={this.handlePrevClick} className="NavigationArrowTop" />
                        <div onClick={this.handleFirstClick} className="NavigationArrowBottom" />
                      </div>
                      <div className="PinnedPanelContainer">
                        {!Utils.isEmpty(lobbyPromotions) && (
                          <PinnedPanel panelList={lobbyPromotions} navigation={this.props.navigation} />
                        )}
                        <div className="GameListContainer">
                          <GamePage {...args} sliderRef={this.slider} navigation={this.props.navigation} />
                        </div>
                      </div>
                      <div className="NavigationArrowsContainer right">
                        <img
                          src={require(asset`images/Lobby_Assets/Scroll_Buttons@3x.png`)}
                          className="RightNavigationArrows"
                          alt="Right navigation arrows"
                        />
                        <div onClick={this.handleNextClick} className="NavigationArrowTop" />
                        <div
                          onClick={() => this.handleLastClick(pagedGames.length - 1)}
                          className="NavigationArrowBottom"
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          );
        }}
      />
    );
  }
}

VIPGames.contextType = ThemeContext;
