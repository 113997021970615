// core-js includes polyfills to get the app working on Internet Explorer
import 'core-js';
// whatwg-fetch is a window.fetch polyfill needed for fetch to work in older verisons of Internet Explorer
import 'whatwg-fetch';
import React, { useEffect, useRef } from 'react';
import './index.css';
import App from './App';
import OnboardingLayer from './components/OnboardingLayer';
import MissionsTutorialLayer from './components/MissionsTutorialLayer';
import AppInterface from 'shared/api/AppInterface';
import AppInit from './components/AppInit';
import SocialLoginInterface from 'shared/api/SocialLoginInterface';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import { Provider } from 'shared/node_modules/react-redux';
import { PersistGate } from 'shared/node_modules/redux-persist/lib/integration/react';
import { persistor, store } from 'shared/state/store';
import { setIsConnected } from 'shared/state/actions/device';
import { setAdBlocker } from 'shared/state/actions/apiRoot';
import { isMobile } from 'react-device-detect';
import StoreDownload from './screens/StoreDownload';
import { ErrorBoundary } from './components/ErrorBoundary';
import ThemeContext from 'shared/context/ThemeContext';
import { themes } from 'shared/context/Themes';
import { NotificationProvider } from './context/NotificationProvider';
import { Logger } from './lib/analytics/logger';
import { EVENT_TYPES } from './lib/analytics/constants';
import ERROR_CODE from 'shared/utils/errorCodes';
import { handleHtmlScripts } from './prependScripts';

const Redux = () => {
  const willMount = useRef(true);

  const checkToLoadScripts = () => {
    if (willMount.current) {
      handleHtmlScripts();
      detectAdBlock();
    }
    willMount.current = false;
  };

  checkToLoadScripts();

  useEffect(() => {
    setTimeout(() => {
      runFacebookInitSDK();
      !window.isGsiLoaded && SocialLoginInterface.loadGsi();
    }, 1500);
  }, []);

  const runFacebookInitSDK = async () => {
    try {
      const appID = await AppInterface.getFacebookAppId();
      const isFirefox = typeof InstallTrigger !== 'undefined';
      if (appID) {
        if (!isFirefox) {
          FB.init({
            appId: appID,
            autoLogAppEvents: false,
            xfbml: false,
            version: 'v14.0',
          });
          window.facebookHasInitialized = true;
          if (window.facebookAppEventQueue) {
            for (let eventHandler of window.facebookAppEventQueue) {
              eventHandler();
            }
          }
        } else {
          window.fbAsyncInit = function () {
            FB.init({
              appId: appID,
              autoLogAppEvents: false,
              xfbml: false,
              version: 'v14.0',
            });
            window.facebookHasInitialized = true;
            if (window.facebookAppEventQueue) {
              for (let eventHandler of window.facebookAppEventQueue) {
                eventHandler();
              }
            }
          };
        }
      }
    } catch (error) {
      Logger.sendEvent(EVENT_TYPES.FRONTEND_ERROR, {
        error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
        code: ERROR_CODE.FACEBOOK_SDK_INIT_CATCH,
      });
    }
  };

  return (
    <Provider store={store}>
      <ThemeContext.Provider value={themes[ENVIRONMENT_VARIABLES.APPLICATION_TARGET]}>
        <NotificationProvider>
          {isMobile ? (
            <StoreDownload />
          ) : (
            <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
              <ErrorBoundary>
                <AppInit>
                  <App />
                  <OnboardingLayer />
                  <MissionsTutorialLayer />
                </AppInit>
              </ErrorBoundary>
            </PersistGate>
          )}
        </NotificationProvider>
      </ThemeContext.Provider>
    </Provider>
  );
};

// dispatch events if the browser goes offline/comes back online
window.addEventListener('offline', state => {
  store.dispatch(setIsConnected(false));
});

window.addEventListener('online', state => {
  store.dispatch(setIsConnected(true));
});
// end dispatch events

async function detectAdBlock() {
  let adBlockEnabled = false;
  const googleAdUrl = ENVIRONMENT_VARIABLES.GOOGLE_AD_URL;
  try {
    await fetch(new Request(googleAdUrl)).catch(() => (adBlockEnabled = true));
  } catch (e) {
    adBlockEnabled = true;
  } finally {
    store.dispatch(setAdBlocker(adBlockEnabled));
  }
}

export default Redux;

