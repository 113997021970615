import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useCallback, useEffect } from 'react';
import Screens from '../screens';
import { MISSIONS_GAMEPLAY_STATUS } from '../screens/Missions/constants';
import { generateDescription } from '../screens/Missions/utils/generateMissionCardDataRendering';
import utils from '../utils';
import QueryParamsProxy from '../utils/queryParamProxy';

var useMissionsTooltips = function useMissionsTooltips(props) {
  var currentMission = props.currentMission,
      shouldShowTooltip = props.shouldShowTooltip,
      tooltipDetails = props.tooltipDetails,
      missionFinishedTooltipDetail = props.missionFinishedTooltipDetail,
      missionsGameplayStatus = props.missionsGameplayStatus,
      modal = props.modal,
      showMissionsProgressBar = props.showMissionsProgressBar,
      navigation = props.navigation;
  var serverProgress = currentMission.serverProgress,
      goalMessage = currentMission.goalMessage;
  var handleMissionEndTooltip = useCallback(function () {
    if (Math.round(serverProgress) >= 100 && missionsGameplayStatus !== MISSIONS_GAMEPLAY_STATUS.TUTORIAL && !modal && showMissionsProgressBar) {
      tooltipDetails(missionFinishedTooltipDetail);
      shouldShowTooltip(true);
      setTimeout(function () {
        var out = _objectSpread(_objectSpread({}, missionFinishedTooltipDetail), {}, {
          animationState: utils.getPlatform() === 'web' ? 'AnimationSlideToTop' : 'out'
        });

        tooltipDetails(out);
      }, 3000);
    }
  }, [serverProgress, missionsGameplayStatus, modal, tooltipDetails, missionFinishedTooltipDetail, shouldShowTooltip, showMissionsProgressBar]);
  useEffect(function () {
    handleMissionEndTooltip(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverProgress, modal]);

  var onMissionProgressPressHandler = function onMissionProgressPressHandler() {
    if (serverProgress < 100) {
      var tooltipText = generateDescription('', {
        goalMessage: goalMessage
      });

      var detail = _objectSpread(_objectSpread({}, missionFinishedTooltipDetail), {}, {
        title: tooltipText
      });

      tooltipDetails(detail);
      shouldShowTooltip(true);
      setTimeout(function () {
        var out = _objectSpread(_objectSpread({}, detail), {}, {
          animationState: utils.getPlatform() === 'web' ? 'AnimationSlideToTop' : 'out'
        });

        tooltipDetails(out);
      }, 3000);
    } else {
      QueryParamsProxy.navigate(navigation, Screens.Missions);
    }
  };

  return {
    onMissionProgressPressHandler: onMissionProgressPressHandler
  };
};

export default useMissionsTooltips;