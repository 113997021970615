import { connect } from "react-redux";
import FormSubtitle from "./FormSubtitle";

var mapStateToProps = function mapStateToProps(state) {
  var linkedLoyaltyCardBonus = state.load.initAppResponse.SETTINGS.linkedLoyaltyCardBonus;
  return {
    copys: state.properties.data.textsFile,
    linkedLoyaltyCardBonus: linkedLoyaltyCardBonus
  };
};

export default connect(mapStateToProps)(FormSubtitle);