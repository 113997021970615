import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import SOfferDetail from 'shared/components/RewardCenter/OfferDetail';
import { Scrollbars } from 'react-custom-scrollbars';
import ImageBW from '../../components/ImageBoundsWrapper';
import QueryParamsProxy from 'shared/utils/queryParamProxy';
import RouteParamConstants from 'shared/utils/routeParamConstants';
import Screens from 'shared/screens';
import Utils from 'shared/utils/index';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import PropertySelector from '../../components/PropertySelector';
import { styles } from './OfferStyles';
import { PURCHASE_RESPONSE, PURCHASE_TYPE } from 'shared/constants';
import ThemeContext from 'shared/context/ThemeContext';
import Button from '../Button/Button';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';

const icons = {
  loyalty: require(asset`Star_Icon@3x.png`),
};

export default class OfferDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      property: null,
      isDisabled: false,
    };
  }

  onScroll = () => {
    this.setState({ width: 0.5 });
  };

  setIsDisabled = isDisabled => {
    this.setState({ isDisabled });
  };

  getScreenRes = () => {
    if (window.innerWidth > 1500) return '460px';
    return '350px';
  };

  componentDidUpdate() {
    let descriptionBox = document.getElementsByClassName('OfferDetail')[0];
    if (descriptionBox && this.state.width === 0 && descriptionBox.offsetHeight > 300) {
      this.setState({ width: 0.5 });
    }
  }

  changeOfferProperty = property => {
    property && this.setState({ property });
  };

  checkSelectedProperty = property => {
    if (!this.state.property) {
      this.changeOfferProperty(property);
    }
  };

  render() {
    const themeContext = this.context;

    return (
      <SOfferDetail
        api={this.props.api}
        isInTutorial={this.props.isInTutorial}
        isVipRoomUnlocked={this.props.isVipRoomUnlocked}
        activeFilter={this.props.activeFilter}
        render={props => {
          const {
            rewardname,
            subtitle,
            img1,
            img2,
            img3,
            rdesc,
            termsText,
            termsUrl,
            pointsneeded,
            purchaseResponse,
            onDismissPurchaseResponse,
            hasData,
            onPurchase,
            purchaseType,
            rewardtype,
            showKycPopUp,
            hideKycPopUp,
            onKycCheck,
            onDisabledButtonClick,
            propertyList,
          } = props;
          let isVipPurchase =
            (purchaseType === PURCHASE_TYPE.VIP || purchaseType === PURCHASE_TYPE.VIP_ROOM_ACCESS) &&
            purchaseResponse &&
            purchaseResponse.type === PURCHASE_RESPONSE.SUCCESS;
          let disablePurchaseButton =
            this.props.isVipRoomUnlocked && rewardtype === getText(TEXT_KEY.VIP_ROOM_ACCESS_TYPE);
          let hasVIPPass = this.props.isVipRoomUnlocked && rewardtype === getText(TEXT_KEY.VIP_ROOM_ACCESS_TYPE);
          const {
            api: {
              core: {
                profile: { linkedCasinoId },
              },
            },
          } = this.props;
          propertyList && this.checkSelectedProperty(propertyList[0].value);

          const handleDismissPurchaseResponse = () => {
            onDismissPurchaseResponse();
            this.setState({ isDisabled: false });
          };

          return (
            <>
              <div style={{ width: '100%', display: 'flex', marginTop: '-1em' }}>
                <div className="RewardCenterSideBar">
                  {hasData && (
                    <>
                      <div className="OfferDetailThumbnailContainer">
                        <ImageBW
                          className="OfferDetailThumbnailFrame"
                          source={require(asset`images/Rewards_Center_Assets/Item_Frames/Simple_Item_Frame@3x.png`)}
                          ibw={require(asset`Rewards_Center_Frame_Web.ibw`)}
                          style={{
                            position: 'absolute',
                          }}
                        />
                        <Carousel controls={false}>
                          <Carousel.Item className="OfferDetailThumbnail">
                            <img
                              src={img1}
                              alt={'Offer Detail Thumbnail 1'}
                              onError={e => {
                                e.target.style.display = 'none';
                              }}
                            />
                          </Carousel.Item>
                          <Carousel.Item className="OfferDetailThumbnail">
                            <img
                              src={img2}
                              alt={'Offer Detail Thumbnail 2'}
                              onError={e => {
                                e.target.style.display = 'none';
                              }}
                            />
                          </Carousel.Item>
                          <Carousel.Item className="OfferDetailThumbnail">
                            <img
                              src={img3}
                              alt={'Offer Detail Thumbnail 3'}
                              onError={e => {
                                e.target.style.display = 'none';
                              }}
                            />
                          </Carousel.Item>
                        </Carousel>
                      </div>
                    </>
                  )}
                </div>
                <Scrollbars
                  ref="scrollbars"
                  style={{ height: this.getScreenRes(), marginRight: '-1px' }}
                  onScroll={this.onScroll}
                  renderThumbVertical={props => (
                    <div {...props} className="thumb-vertical" style={{ width: this.state.width + 'rem' }} />
                  )}
                  renderTrackVertical={props => (
                    <div {...props} className="track-vertical" style={{ opacity: this.state.width > 0 ? 1 : 0 }} />
                  )}
                  renderThumbHorizontal={props => (
                    <div {...props} className="thumb-horizontal" style={{ display: 'none' }} />
                  )}
                  renderTrackHorizontal={props => (
                    <div {...props} className="track-horizontal" style={{ display: 'none' }} />
                  )}
                >
                  <div key={`OfferDetails-${hasData}`} className="OfferDetail">
                    {hasData ? (
                      <>
                        <div className="rewardCenterGradientText OfferDetailName" data-label={rewardname}>
                          {rewardname}
                        </div>
                        <div className={'OfferDetailSubtitle' + (Utils.ieVersion() !== 0 ? ' ieHack' : '')}>
                          {subtitle}
                        </div>
                        {propertyList && ENVIRONMENT_VARIABLES.SHOW_PROPERTY_SELECTOR === 'true' ? (
                          <div className="OfferActionContainer">
                            <div className={`OfferDetailPropertySelector ${propertyList.length === 1 && 'disable'}`}>
                              <PropertySelector
                                onChangeDOB={e => this.changeOfferProperty(e.value)}
                                selectedPropertyId={this.state.property}
                                styles={styles.inputContainer}
                                customOptions={propertyList}
                                hidePlaceHolder
                              />
                            </div>
                            <OfferActionButton
                              disablePurchaseButton={disablePurchaseButton}
                              onDisabledButtonClick={onDisabledButtonClick}
                              onPurchase={onPurchase}
                              offerProps={this.props}
                              pointsneeded={pointsneeded}
                              hasVIPPass={hasVIPPass}
                              selectedProperty={this.state.property}
                              linkedCasinoId={linkedCasinoId}
                              isDisabled={this.state.isDisabled}
                              setIsDisabled={this.setIsDisabled}
                            />
                          </div>
                        ) : (
                          <div
                            className="offerDetailsActionContainer"
                            style={{
                              height: '5em',
                            }}
                          >
                            <OfferActionButton
                              disablePurchaseButton={disablePurchaseButton}
                              onDisabledButtonClick={onDisabledButtonClick}
                              isDisabled={this.state.isDisabled}
                              setIsDisabled={this.setIsDisabled}
                              onPurchase={onPurchase}
                              offerProps={this.props}
                              pointsneeded={pointsneeded}
                              hasVIPPass={hasVIPPass}
                            />
                          </div>
                        )}
                        <div className="OfferDetailDescription">{rdesc}</div>
                        <div className="OfferDetailTermTitle">{getText(TEXT_KEY.PURCHASE_AND_USAGE_LIMITATIONS)}</div>
                        <div className="OfferDetailTerm">
                          {termsText}
                          <a href={termsUrl} target="_blank">
                            {termsUrl}
                          </a>
                        </div>
                      </>
                    ) : (
                      <>
                        <div key={`PrerenderRewardCenterOfferDetail`} className="OfferDetail"></div>
                      </>
                    )}
                  </div>
                </Scrollbars>
              </div>
              {isVipPurchase === true &&
                QueryParamsProxy.setQueryParams({
                  [RouteParamConstants.PARAM_MODAL]: Screens.PurchaseSucceeded,
                })}

              {purchaseResponse && purchaseResponse.message && (
                <>
                  <div className="RewardCenterPurchaseResponseOverlay">
                    <div className="RewardCenterPurchaseResponse">
                      <ImageBW
                        style={{
                          position: 'relative',
                          width: '100%',
                          height: '100%',
                        }}
                        source={require(asset`Dialog.png`)}
                        ibw={require(asset`Dialog.ibw`)}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            width: '100%',
                          }}
                        >
                          <div style={themeContext.OfferDetail.RewardCenterPurchaseResponseHeader}>
                            {purchaseResponse.type === PURCHASE_RESPONSE.SUCCESS
                              ? getText(TEXT_KEY.CONGRATULATIONS)
                              : getText(TEXT_KEY.OOPS)}
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              height: '50%',
                              width: '100%',
                              justifyContent: 'space-evenly',
                              alignItems: 'center',
                            }}
                          >
                            <ImageBW
                              source={require(asset`Shop_VIP_Divider_Glows@3x.png`)}
                              ibw={require(asset`Shop_VIP_Divider_Glows.ibw`)}
                              manualAssetScale={3}
                              style={{ height: '7vmin', width: '100%', marginTop: '1vmin' }}
                              innerStyle={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-evenly',
                                alignItems: 'center',
                                paddingLeft: '1vmin',
                                paddingRight: '1vmin',
                              }}
                            ></ImageBW>
                            <span
                              style={{
                                position: 'relative',
                                width: '100%',
                                height: '100%',
                                bottom: '2em,',
                              }}
                            >
                              {purchaseResponse.type === PURCHASE_RESPONSE.SUCCESS ? (
                                <img
                                  alt=""
                                  src={require(asset`images/Rewards_Center_Assets/Pile_of_Coins@3x.png`)}
                                  style={{
                                    width: 265,
                                    height: '100%',
                                  }}
                                />
                              ) : (
                                <img
                                  alt=""
                                  src={require(asset`images/Rewards_Center_Assets/Exclamation_Icon@3x.png`)}
                                  style={themeContext.OfferDetail.ExclamationIcon}
                                />
                              )}
                            </span>
                          </div>
                          <div style={themeContext.OfferDetail.RewardCenterPurchaseResponseMessage}>
                            {purchaseResponse.message}
                          </div>
                          <Button
                            onClick={onDismissPurchaseResponse}
                            containerStyle={{
                              margin: '1em auto',
                              position: 'relative',
                              textAlign: 'center',
                              cursor: 'pointer',
                              pointerEvents: 'auto',
                              width: '10em',
                              height: '14%',
                            }}
                            textStroke={BUTTON_STROKES.PRIMARY}
                            imageStyle={{ width: '100%', height: '100%' }}
                            imageSource={require(asset`RoundedRect_Primary@3x.png`)}
                            label={
                              purchaseResponse.type !== PURCHASE_RESPONSE.SUCCESS
                                ? getText(TEXT_KEY.DONE)
                                : getText(TEXT_KEY.OK)
                            }
                          />
                        </div>
                      </ImageBW>
                    </div>
                  </div>
                </>
              )}
              {showKycPopUp && (
                <>
                  <div className="RewardCenterPurchaseResponseOverlay" />
                  <div className="RewardCenterKycPopUp">
                    <ImageBW
                      style={{
                        position: 'relative',
                        width: '100%',
                        height: '65%',
                      }}
                      source={require(asset`Dialog.png`)}
                      ibw={require(asset`Dialog.ibw`)}
                    >
                      <div>
                        <div
                          onClick={hideKycPopUp}
                          style={{
                            position: 'absolute',
                            top: '-3.25rem',
                            right: '-3.3rem',
                            padding: 0,
                            height: '4rem',
                            width: '4rem',
                            zIndex: 9,
                            cursor: 'pointer',
                            pointerEvents: 'auto',
                          }}
                        >
                          <ImageBW
                            source={require(asset`CloseButton@3x.png`)}
                            ibw={require(asset`CloseButton.ibw`)}
                            style={{
                              width: '100%',
                              height: '100%',
                            }}
                            resizeMode="contain"
                          />
                        </div>
                        <div className="RewardCenterKycPopUpHeader">{getText(TEXT_KEY.KYC_CHECK)}</div>
                        <ImageBW
                          source={require(asset`Shop_VIP_Divider_Glows@3x.png`)}
                          ibw={require(asset`Shop_VIP_Divider_Glows.ibw`)}
                          manualAssetScale={3}
                          style={{ height: '7vmin', width: '100%', marginTop: '1vmin' }}
                          innerStyle={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-evenly',
                            alignItems: 'center',
                            paddingLeft: '1vmin',
                            paddingRight: '1vmin',
                          }}
                        ></ImageBW>
                        <div className="RewardCenterKycPopUpMessage">{getText(TEXT_KEY.REWARD_CENTER_KYC_MESSAGE)}</div>
                        <Button
                          onClick={onKycCheck}
                          textStroke={BUTTON_STROKES.PRIMARY}
                          imageSource={require(asset`RoundedRect_Primary@3x.png`)}
                          label={getText(TEXT_KEY.CONTINUE_BUTTON)}
                          imageStyle={{
                            width: '30%',
                            height: '50%',
                          }}
                          containerStyle={{
                            margin: '3em auto',
                          }}
                        />
                      </div>
                    </ImageBW>
                  </div>
                </>
              )}
            </>
          );
        }}
      />
    );
  }
}

OfferDetail.contextType = ThemeContext;

function OfferActionButton(
  {
    onDisabledButtonClick,
    onPurchase,
    offerProps,
    pointsneeded,
    hasVIPPass,
    selectedProperty,
    linkedCasinoId,
    isDisabled,
    setIsDisabled,
  } = this.props
) {
  const shouldShowLinkAccountPopUp = linkedCasinoId === 'no';
  const buttonSrc = isDisabled
    ? require(asset`RoundedRect_Disabled@3x.png`)
    : require(asset`RoundedRect_Primary@3x.png`);

  const purchaseButton = () => {
    setIsDisabled({ isDisabled: true });
    onPurchase(selectedProperty ? selectedProperty : offerProps.ux.activeOffer, shouldShowLinkAccountPopUp);
  };

  return (
    <div
      className={
        `OfferDetailAction proto ${selectedProperty && 'OfferDetailActionHRI'}` +
        (Utils.ieVersion() !== 0 ? ' ieHack' : '')
      }
    >
      <Button
        textStroke={BUTTON_STROKES.PRIMARY}
        imageSource={buttonSrc}
        containerStyle={{ height: '100%', width: '100%' }}
        imageStyle={{ width: '100%', height: '3em' }}
        textStyle={{
          fontSize: 'calc(18px + (26 - 18) * ((100vw - 800px) / (1600 - 800)))',
          fontWeight: 600,
          alignSelf: 'center',
          textAlign: 'center',
          marginTop: Utils.ieVersion() !== 0 ? '-0.1em' : '-0.2em',
        }}
        objectFit="fill"
        label={pointsneeded}
        onClick={isDisabled ? () => onDisabledButtonClick(hasVIPPass) : () => purchaseButton()}
        disabled={isDisabled}
        buttonIcon={icons.loyalty}
        buttonIconStyle={{ height: 45, width: 45 }}
      />
    </div>
  );
}

OfferDetail.contextType = ThemeContext;

