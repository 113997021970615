import React, { useContext } from 'react';

import { Container, LogoAsset, DividerDiv, StoreLogoAsset, LinkAssetHolder, DisclaimerText } from './styledComponents';
import ThemeContext from 'shared/context/ThemeContext';
import SmartBanner from '../../../components/SmartBanner';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';

const HriStoreDownload = () => {
  const { StoreDownload } = useContext(ThemeContext);
  return (
    <div>
      <SmartBanner style={{ position: 'unset' }} />
      <Container theme={StoreDownload.Container}>
        <LogoAsset src={require(asset`logo.png`)} alt="logo" />
        <p>Experience the thrill of an exciting new gaming experience!</p>
        <DividerDiv />
        <a href={ENVIRONMENT_VARIABLES.APPLE_STORE_URL}>
          <StoreLogoAsset src={require(asset`images/TrackingUrl/badgeIOs@3x.png`)} alt="" />
        </a>
        <DividerDiv />
        <LinkAssetHolder href={ENVIRONMENT_VARIABLES.ANDROID_STORE_URL}>
          <StoreLogoAsset src={require(asset`images/TrackingUrl/badgeAndroid@3x.png`)} alt="" />
        </LinkAssetHolder>
        {ENVIRONMENT_VARIABLES.APPLICATION_TARGET === 'hri' && (
          <DisclaimerText>
            Disclaimer: Amazon devices are no longer supported by the new Hard Rock Jackpot Casino. We are sorry for the
            inconvenience. You can still experience the thrill of this exciting new gaming experience though by visiting
            www.hardrockjackpotcasino.com on your PC device or by downloading the app to your Android or iOS device from
            the aforementioned mobile app stores.
          </DisclaimerText>
        )}
      </Container>
    </div>
  );
};
export default HriStoreDownload;

