import { SET_PRE_PURCHASE_ROUTE, SET_PRE_PURCHASE_ROUTE_PARAMS, SET_CARD_NICKNAME, SET_PROMO_CODE, CLEAR_PRE_PURCHASE_STORE, SET_PURCHASE_TYPE, IS_OUT_OF_COINS_PURCHASE, IS_LTO_PURCHASE, SET_FORM_URL, SET_ACCESS_CODE, SET_TRANSACTION_ID, SET_PURCHASE_PROFILE } from './types';
export var setPrePurchaseRoute = function setPrePurchaseRoute(route) {
  return {
    type: SET_PRE_PURCHASE_ROUTE,
    payload: route
  };
};
export var setPrePurchaseRouteParams = function setPrePurchaseRouteParams(params) {
  return {
    type: SET_PRE_PURCHASE_ROUTE_PARAMS,
    payload: params
  };
};
export var setCardNickname = function setCardNickname(nickname) {
  return {
    type: SET_CARD_NICKNAME,
    payload: nickname
  };
};
export var setPromoCode = function setPromoCode(code) {
  return {
    type: SET_PROMO_CODE,
    payload: code
  };
};
export var setPurchaseType = function setPurchaseType(type) {
  return {
    type: SET_PURCHASE_TYPE,
    payload: type
  };
};
export var isOutOfCoinsPurchase = function isOutOfCoinsPurchase(value) {
  return {
    type: IS_OUT_OF_COINS_PURCHASE,
    payload: value
  };
};
export var isLTOPurchase = function isLTOPurchase(value) {
  return {
    type: IS_LTO_PURCHASE,
    payload: value
  };
};
export var clearPrePurchaseStore = function clearPrePurchaseStore() {
  return {
    type: CLEAR_PRE_PURCHASE_STORE
  };
};
export var setFormUrl = function setFormUrl(value) {
  return {
    type: SET_FORM_URL,
    payload: value
  };
};
export var setAccessCode = function setAccessCode(value) {
  return {
    type: SET_ACCESS_CODE,
    payload: value
  };
};
export var setTransactionId = function setTransactionId(value) {
  return {
    type: SET_TRANSACTION_ID,
    payload: value
  };
};
export var setPurchaseProfile = function setPurchaseProfile(value) {
  return {
    type: SET_PURCHASE_PROFILE,
    payload: value
  };
};