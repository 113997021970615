import React, { Component } from 'react';
import SSessionExpired from 'shared/screens/SessionExpired';
import Modal from '../../components/JSModal';

export default class SessionExpired extends Component {
  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-session-expired',
    };
  }

  static get DismissHandler() {
    return () => {};
  }

  render() {
    return (
      <SSessionExpired
        navigation={this.props.navigation}
        render={args => {
          const { expireSessionHandler } = args;
          return (
            <Modal isVisible={true} closeModal={() => null} zIndex={101} style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0 40vmin',
            }}
            hideCloseButton={true}
            >
              <div className="sessionExpiredWrapper">
                <div style={{ height: '60%' }} onClick={expireSessionHandler}>
                  <img
                    src={require(asset`images/Session_Expired_Image/Session_Expired_Image@3x.png`)}
                    alt=""
                    style={{ width: '70%' }}
                  />
                </div>
              </div>
            </Modal>
          );
        }}
      />
    );
  }
}

SessionExpired.hideCloseButton = true;
