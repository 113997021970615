import React, { useContext } from 'react';
import ThemeContext from 'shared/context/ThemeContext';
import ImageBoundsWrapper from '../../../../components/ImageBoundsWrapper';

const HeaderImage = () => {
  const { Messages } = useContext(ThemeContext);

  return (
    <ImageBoundsWrapper
      source={require(asset`Full_Modal_Header@3x.png`)}
      ibw={require(asset`Full_Modal_Header.ibw`)}
      style={Messages.HeaderTitleContainer}
      innerStyle={{}}
      alt="Close"
      widthScale={Messages.HeaderTitleContainer.widthScale}
    />
  );
};

export default HeaderImage;

