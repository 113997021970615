var generateEventsInfoScreenRender = function generateEventsInfoScreenRender(eventsTexts) {
  return [{
    header: eventsTexts.infoScreenHeader,
    title: eventsTexts.infoScreenTargetedEventsTitle,
    description: eventsTexts.infoScreenTargetedEventsDescription,
    icon: 'EVENTS_TUTORIAL_SLIDE_ONE_ICON'
  }, {
    header: eventsTexts.infoScreenHeader,
    title: eventsTexts.infoScreenSweepstakesTitle,
    description: eventsTexts.infoScreenSweepstakesDescription,
    icon: 'EVENTS_TUTORIAL_SLIDE_TWO_ICON'
  }, {
    header: eventsTexts.infoScreenHeader,
    title: eventsTexts.infoScreenEarnEntriesTitle,
    description: eventsTexts.infoScreenEarnEntriesDescription,
    icon: 'EVENTS_TUTORIAL_SLIDE_THREE_ICON'
  }, {
    header: eventsTexts.infoScreenHeader,
    title: eventsTexts.infoScreenCheckYourMessagesTitle,
    description: eventsTexts.infoScreenCheckYourMessagesDescription,
    icon: 'EVENTS_TUTORIAL_SLIDE_FOUR_ICON'
  }];
};

export default generateEventsInfoScreenRender;