import React, { Component } from 'react';
import STournaments from 'shared/screens/Tournaments';

import ImageBW from '../../components/ImageBoundsWrapper';

export default class Tournaments extends Component {
  render() {
    return (
      <STournaments
        navigation={this.props.navigation}
        render={arg => {
          return (
            <div style={{ padding: 100 }}>
              <ImageBW
                source={require(asset`NewsItem_Frame_9Slice.png`)}
                ibw={require(asset`NewsItem_Frame_9Slice.ibw`)}
                style={{
                  height: 200,
                  width: 300,
                }}
              />
              <ImageBW
                source={require(asset`NewsItem_Frame_9Slice.png`)}
                ibw={require(asset`NewsItem_Frame_9Slice.ibw`)}
                style={{
                  height: 200,
                  width: 300,
                  marginTop: 8,
                }}
              />
            </div>
          );
        }}
      />
    );
  }
}
