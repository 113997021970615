import AppBackgroundType from "shared/utils/AppBackgroundType";

export const getAppBackgroundImage = (type, backgroundPromo, promotionImagesUrl) => {
    let background = backgroundPromo ? `${promotionImagesUrl}/${backgroundPromo}` : '';
    switch (type) {
        case AppBackgroundType.VIP_GAMES:
            return background || require(asset`images/VIP_Assets/VIP_Background@3x.jpg`);
        case AppBackgroundType.NORMAL:
        case AppBackgroundType.TABLE_GAMES:
        case AppBackgroundType.FAVOURITES:
        default:
            return background || require(asset`images/Lobby_Assets/Background@3x.jpg`);
    }
}