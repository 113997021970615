export default {
  type: 'object',
  properties: {
    eventsFeatureState: {
      type: 'object',
      properties: {
        texts: {
          type: 'object',
          properties: {
            entriesLabel: {
              type: 'string'
            },
            endedLabel: {
              type: 'string'
            },
            timeLeftLabel: {
              type: 'string'
            }
          }
        },
        events: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              eventId: {
                type: 'number'
              },
              eventType: {
                type: 'string'
              },
              cardType: {
                type: 'string'
              },
              eventGameplayStatus: {
                type: 'string'
              },
              isMainEvent: {
                type: 'boolean'
              },
              startTimestamp: {
                type: 'number'
              },
              endTimestamp: {
                type: 'number'
              },
              assetsPackageId: {
                type: 'string'
              },
              totalEntriesAcquired: {
                type: 'number',
                nullable: true
              },
              totalEntriesAvailable: {
                type: 'number',
                nullable: true
              },
              activities: {
                type: 'array',
                items: {
                  type: 'object',
                  properties: {
                    activityId: {
                      type: 'number'
                    },
                    maxCycles: {
                      type: 'number',
                      nullable: true
                    },
                    cycleLength: {
                      type: 'number'
                    },
                    entriesPerCycle: {
                      type: 'number'
                    },
                    completedCycles: {
                      type: 'number'
                    },
                    entriesAcquired: {
                      type: 'number'
                    },
                    entriesAvailable: {
                      type: 'number',
                      nullable: true
                    },
                    counter: {
                      type: 'object',
                      properties: {
                        counterType: {
                          type: 'string'
                        },
                        value: {
                          type: 'number'
                        },
                        gameIds: {
                          type: 'array',
                          items: {
                            type: 'number'
                          }
                        }
                      }
                    }
                  }
                }
              },
              texts: {
                type: 'object',
                properties: {
                  cardTitle: {
                    type: 'string'
                  },
                  cardDescription: {
                    type: 'string'
                  },
                  cardDetailsIntro: {
                    type: 'string'
                  },
                  cardDetailTitle: {
                    type: 'string'
                  },
                  cardDetailDescription: {
                    type: 'string'
                  },
                  rulesLink: {
                    type: 'object',
                    properties: {
                      type: {
                        type: 'string',
                        nullable: true
                      },
                      url: {
                        type: 'string',
                        nullable: true
                      },
                      text: {
                        type: 'string',
                        nullable: true
                      }
                    }
                  }
                }
              }
            }
          }
        },
        assetsPackageId: {
          type: 'string'
        },
        eventsFeatureStatus: {
          type: 'string'
        }
      }
    }
  }
};