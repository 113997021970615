import React, { Component } from 'react';
import SOfferList from 'shared/components/RewardCenter/OfferList';
import { Scrollbars } from 'react-custom-scrollbars';
import store from 'shared/state/store';
import { isInOffersView } from 'shared/state/actions/rewardCenter';
import preload from '../../lib/load/preload';
import ThemeContext from 'shared/context/ThemeContext';
import RewardListItem from './RewardListItem';

export default class OfferList extends Component {
  componentDidMount() {
    if (!this.props.isInOffersView) {
      store.dispatch(isInOffersView(true));
    }
  }

  componentWillUnmount() {
    store.dispatch(isInOffersView(false));
  }

  render() {
    const themeContext = this.context;
    return (
      <SOfferList
        {...this.props}
        preload={preload}
        activeFilter={this.props.activeFilter}
        activeProperty={this.props.activeProperty}
        rewardCenterBaseUrl={this.props.rewardCenterBaseUrl}
        render={props => {
          const { ux, offers, defaultOfferThumbnail, onActivateOfferTile, scrollBarHeight, onScroll } = props;
          const { hasData } = ux.core;

          return (
            <>
              <div key={`RewardCenterOffers-${this.props.activeFilter}`} className="RewardCenterOffers">
                {hasData ? (
                  <Scrollbars
                    ref="scrollbars"
                    style={{ height: 'auto' }}
                    onScroll={onScroll}
                    renderThumbHorizontal={props => (
                      <div {...props} className="thumb-horizontal" style={themeContext.OfferList.ScrollBar} />
                    )}
                    renderTrackHorizontal={props => (
                      <div {...props} className={'track-horizontal'} style={{ opacity: scrollBarHeight > 0 ? 1 : 0 }} />
                    )}
                  >
                    <div style={{ width: '100%', display: 'flex' }}>
                      {offers.map(offer => (
                        <RewardListItem
                          key={`Offer-${offer.rid}`}
                          item={offer}
                          defaultOfferThumbnail={defaultOfferThumbnail}
                          onClickHandler={onActivateOfferTile(offer.rid)}
                        />
                      ))}
                    </div>
                  </Scrollbars>
                ) : (
                  <>
                    {new Array(6).fill({}).map((_, idx) => (
                      <div key={`PrerenderRewardCenterOffer-${idx}`} className="RewardCenterOffer">
                        <div className="RewardCenterOfferCost"></div>
                        <div className="RewardCenterOfferName"></div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </>
          );
        }}
      />
    );
  }
}

OfferList.contextType = ThemeContext;

