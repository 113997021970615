module.exports = {
  purple: {
    backing: require(asset`images/Coin_Store_Assets/Item_Row_Purple@3x.png`),
    left: require(asset`Shop_Purple_Cap@3x.png`),
    triangle: require(asset`Shop_Purple_TriangleDivider@3x.png`),
    ribbon: require(asset`Store_Ribbon_purple.png`),
  },
  gold: {
    backing: require(asset`images/Coin_Store_Assets/Item_Row_Gold@3x.png`),
    left: require(asset`Shop_Gold_Cap@3x.png`),
    triangle: require(asset`Shop_Gold_TriangleDivider@3x.png`),
    ribbon: require(asset`Store_Percent_Ribbon_Red.png`),
  },
  ibw: {
    backing: require(asset`Store_Item_Backing.ibw`),
    left: require(asset`Store_Item_LeftEnd.ibw`),
    triangle: require(asset`Store_Item_Triangle.ibw`),
    ribbon: require(asset`Store_Item_Ribbon.ibw`),
    upsell: require(asset`Store_Item_Upsell.ibw`),
  },
  upsell: {
    amazing: require(asset`images/Coin_Store_Assets/Upsell_Tab_Amazing@3x.png`),
    'best deal': require(asset`images/Coin_Store_Assets/Upsell_Tab_Best_Deal@3x.png`),
    popular: require(asset`images/Coin_Store_Assets/Upsell_Tab_Popular@3x.png`),
  },
};
