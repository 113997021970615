// Need to remove it later once new login endpoint is implemented
export var LoginResponseKeys = {
  AUTH_TOKEN: 0,
  PLAYER_ID: 1,
  PROPERTY_ID: 2,
  RETURN_TOKEN: 3,
  CASINO_ID: 4,
  EMAIL: 5
};
export var LoginResponseKeysForSTAR = {
  AUTH_TOKEN: 0,
  PLAYER_ID: 1,
  PROPERTY_ID: 2,
  RETURN_TOKEN: 3,
  CASINO_ID: 4,
  EMAIL: 5
};
export var LoginResponseKeysForSGA = {
  AUTH_TOKEN: 'session_id',
  PLAYER_ID: 'player_id',
  PROPERTY_ID: 'property_id',
  RETURN_TOKEN: 'restart_token',
  CASINO_ID: 'casino_id',
  EMAIL: 'email',
  IS_NEW_ACCOUNT: 'is_new_account',
  STATUS: 'success'
};