var FormId = require("../../../components/Form/FormId").default;

var FormType = require("../../../components/Form/FormType").default;

var InputValidator = require("../../../components/Form/InputValidator").default;

module.exports = {
  header: {
    title: "",
    backButtonText: "Back"
  },
  steps: [{
    title: "",
    formInputList: [{
      id: FormId.CURRENT_EMAIL,
      label: "Current Email",
      type: FormType.TEXT,
      validator: InputValidator.EMAIL,
      containerStyle: {
        marginTop: '1.8rem'
      },
      customClassName: 'customInputText',
      customLabel: 'custom-input-label',
      inputStyle: true,
      disabled: true
    }, {
      id: FormId.EMAIL,
      label: "New Email",
      type: FormType.TEXT,
      validator: InputValidator.EMAIL,
      containerStyle: {
        marginTop: '1.3rem'
      },
      customClassName: 'customInputText',
      customLabel: 'custom-input-label',
      inputStyle: true
    }],
    submitButtonText: "UPDATE"
  }]
};