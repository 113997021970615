var FormId = require("../../../components/Form/FormId").default;

var FormType = require("../../../components/Form/FormType").default;

var KeyboardHint = require("../../../components/Form/KeyboardHint").default;

var InputValidator = require("../../../components/Form/InputValidator").default;

var AdditionalDocuments = require("./AdditionalDocuments").default;

var KycConstants = require("../../../utils/kyc/Constants");

module.exports = {
  header: {
    title: "",
    backButtonText: "Cancel",
    helpLink: "https://starplayhelp.zendesk.com/hc/en-us"
  },
  steps: [{
    title: "Verify 18+",
    formInputList: [{
      id: FormId.FIRSTNAME,
      inputStyle: true,
      label: "First Name",
      placeholder: "Must match your ID",
      customLabel: 'form-input-label with-placeholder',
      type: FormType.TEXT,
      keyboardHint: KeyboardHint.DEFAULT,
      validator: InputValidator.NOT_EMPTY
    }, {
      id: FormId.LASTNAME,
      inputStyle: true,
      label: "Last Name",
      placeholder: "Must match your ID",
      customLabel: 'form-input-label with-placeholder',
      type: FormType.TEXT,
      keyboardHint: KeyboardHint.DEFAULT,
      validator: InputValidator.NOT_EMPTY
    }, {
      id: FormId.DATE_OF_BIRTH,
      inputStyle: true,
      label: "Date Of Birth",
      placeholder: "MM-DD-YYYY",
      type: FormType.DATE_PICKER,
      keyboardHint: KeyboardHint.DEFAULT,
      validator: InputValidator.ADULT_ONLY
    }, {
      id: FormId.COUNTRY,
      inputStyle: true,
      label: "Country",
      type: FormType.DROPDOWN,
      options: KycConstants.PASSPORT_COUNTRIES,
      keyboardHint: KeyboardHint.DEFAULT,
      validator: InputValidator.LETTERS_ONLY,
      placeholder: "Select an item...",
      descriptions: ""
    }, {
      id: FormId.ADDRESS,
      inputStyle: true,
      label: "Address",
      type: FormType.AUTOCOMPLETE,
      placeholder: "Start typing your address...",
      customLabel: 'form-input-label with-placeholder',
      keyboardHint: KeyboardHint.DEFAULT,
      validator: InputValidator.NOT_EMPTY,
      descriptions: "",
      hide: false,
      hasAutocompleteToggle: true
    }, {
      id: FormId.UNIT_NUMBER,
      inputStyle: true,
      label: "Unit Number",
      type: FormType.TEXT,
      placeholder: "Enter unit number",
      customLabel: 'form-input-label with-placeholder',
      keyboardHint: KeyboardHint.DEFAULT,
      validator: InputValidator.NO_VALIDATION,
      descriptions: "",
      hide: true,
      hasAutocompleteToggle: true
    }, {
      id: FormId.STREET_NUMBER,
      inputStyle: true,
      label: "Street Number",
      type: FormType.TEXT,
      placeholder: "Enter street number",
      customLabel: 'form-input-label with-placeholder',
      keyboardHint: KeyboardHint.DEFAULT,
      validator: InputValidator.NO_VALIDATION,
      descriptions: "",
      hide: true
    }, {
      id: FormId.STREET_NAME,
      inputStyle: true,
      label: "Street Name",
      type: FormType.TEXT,
      placeholder: "Enter street name",
      customLabel: 'form-input-label with-placeholder',
      keyboardHint: KeyboardHint.DEFAULT,
      validator: InputValidator.NO_VALIDATION,
      descriptions: "",
      hide: true
    }, {
      id: FormId.STREET_TYPE,
      inputStyle: true,
      label: "Street Type",
      placeholder: "Enter unit number",
      customLabel: 'form-input-label with-placeholder',
      type: FormType.DROPDOWN,
      options: KycConstants.STREET_TYPES,
      keyboardHint: KeyboardHint.DEFAULT,
      validator: InputValidator.NO_VALIDATION,
      descriptions: "",
      hide: true
    }, {
      id: FormId.CITY,
      inputStyle: true,
      label: "Suburb/City",
      type: FormType.TEXT,
      placeholder: "Enter suburb/city",
      customLabel: 'form-input-label with-placeholder',
      keyboardHint: KeyboardHint.DEFAULT,
      validator: InputValidator.NO_VALIDATION,
      descriptions: "",
      hide: true
    }, {
      id: FormId.STATE,
      inputStyle: true,
      label: "State/Region",
      placeholder: 'Enter state/region',
      type: FormType.DROPDOWN,
      options: KycConstants.AUSTRALIAN_STATES,
      keyboardHint: KeyboardHint.DEFAULT,
      validator: InputValidator.NO_VALIDATION,
      descriptions: "",
      hide: true
    }, {
      id: "state_input",
      inputStyle: true,
      label: "State/Region",
      placeholder: 'Enter state/region',
      type: FormType.TEXT,
      keyboardHint: KeyboardHint.DEFAULT,
      validator: InputValidator.NO_VALIDATION,
      descriptions: "",
      hide: true
    }, {
      id: FormId.POSTAL_CODE,
      inputStyle: true,
      label: "Postcode",
      type: FormType.TEXT,
      placeholder: "Enter postcode",
      customLabel: 'form-input-label with-placeholder',
      keyboardHint: KeyboardHint.DEFAULT,
      validator: InputValidator.NO_VALIDATION,
      descriptions: "",
      hide: true
    }],
    submitButtonText: "VERIFY"
  }, AdditionalDocuments, {
    title: "Almost there...",
    formInputList: [{
      id: FormId.EMAIL,
      inputStyle: true,
      label: "Email",
      type: FormType.TEXT,
      placeholder: "Enter email",
      customLabel: 'form-input-label with-placeholder',
      keyboardHint: KeyboardHint.EMAIL,
      validator: InputValidator.EMAIL
    }, {
      id: FormId.PASSWORD,
      inputStyle: true,
      label: "Password",
      type: FormType.PASSWORD,
      placeholder: "Enter password",
      customLabel: 'form-input-label with-placeholder',
      keyboardHint: KeyboardHint.PASSWORD,
      validator: InputValidator.PASSWORD,
      descriptions: "Must be between 8 - 20 characters and include at least one number."
    }],
    submitButtonText: "SUBMIT"
  }]
};