import _slicedToArray from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendMissionsEvent } from '../screens/Missions/analytics/missionsLogger';
import { MISSIONS_ACTIONS, MISSIONS_EVENT_TYPES, MISSIONS_GAMEPLAY_STATUS } from '../screens/Missions/constants';
import { currentRunningMission, hasSomeRewardState } from '../screens/Missions/utils/missionsPerformance';
import { usePostMissionsMutation } from '../state/query/missions';
import { setActiveTutorial, setTutorialStep } from '../state/slices/tutorial/tutorial';
import { MissionTutorialStepsKeys, TUTORIAL_NAME } from '../utils/tutorial';
import { EVENT_EMITTER_TYPE } from '../constants';
import { ACTION_COMMANDS_EVENT_TYPE, ACTION_COMMANDS_TYPE } from '../api/ActionCommandsInterface/constants';
import Emitter from '../services/EmitterService';

var useMissionsTutorial = function useMissionsTutorial(props) {
  var dispatch = useDispatch();

  var _useState = useState(0),
      _useState2 = _slicedToArray(_useState, 2),
      lastLoggedStep = _useState2[0],
      setLastLoggedStep = _useState2[1];

  var tutorial = props.tutorial,
      missionsFeature = props.missionsFeature,
      gameId = props.gameId,
      missions = props.missions,
      playerId = props.playerId;
  var actionCommands = useSelector(function (state) {
    return state.actionCommands.actions[0];
  });
  var activeTutorial = tutorial.activeTutorial,
      tutorialStep = tutorial.tutorialStep;
  var missionsGameplayStatus = missionsFeature.missionsGameplayStatus;

  var _usePostMissionsMutat = usePostMissionsMutation(),
      _usePostMissionsMutat2 = _slicedToArray(_usePostMissionsMutat, 1),
      postMissions = _usePostMissionsMutat2[0];

  useEffect(function () {
    if (gameId != '' && missionsGameplayStatus === MISSIONS_GAMEPLAY_STATUS.TUTORIAL) {
      if (tutorialStep == MissionTutorialStepsKeys.START_MISSION && currentRunningMission(missions)) {
        dispatch(setTutorialStep(MissionTutorialStepsKeys.SEE_PROGRESS));
        dispatch(setActiveTutorial({
          activeTutorial: true,
          tutorialName: TUTORIAL_NAME.MISSIONS_TUTORIAL
        }));
      }

      if (tutorialStep == MissionTutorialStepsKeys.SEE_PROGRESS && hasSomeRewardState(missions)) {
        dispatch(setTutorialStep(MissionTutorialStepsKeys.COMPLETED));
        dispatch(setActiveTutorial({
          activeTutorial: true,
          tutorialName: TUTORIAL_NAME.MISSIONS_TUTORIAL
        }));
      }
    }
  }, [dispatch, gameId, missions, missionsGameplayStatus, tutorialStep]);
  useEffect(function () {
    if (missionsGameplayStatus === MISSIONS_GAMEPLAY_STATUS.TUTORIAL && tutorialStep === MissionTutorialStepsKeys.COMPLETED) {
      var _actionCommands$missi, _actionCommands$missi2, _actionCommands$missi3, _actionCommands$missi4;

      postMissions({
        action: MISSIONS_ACTIONS.FINISH_TUTORIAL,
        playerId: playerId
      });
      Emitter.emit(EVENT_EMITTER_TYPE.ACTION_COMMANDS, {
        actionType: ACTION_COMMANDS_TYPE.NOTIFY_TRIGGER_PROCESSED_REQUEST,
        data: {
          event: ACTION_COMMANDS_EVENT_TYPE.COMPLETE_MISSIONS_TUTORIAL,
          triggerExecution: actionCommands === null || actionCommands === void 0 ? void 0 : (_actionCommands$missi = actionCommands.missionsTutorial) === null || _actionCommands$missi === void 0 ? void 0 : (_actionCommands$missi2 = _actionCommands$missi.onCompleteAction) === null || _actionCommands$missi2 === void 0 ? void 0 : _actionCommands$missi2.triggerExecution,
          payload: actionCommands === null || actionCommands === void 0 ? void 0 : (_actionCommands$missi3 = actionCommands.missionsTutorial) === null || _actionCommands$missi3 === void 0 ? void 0 : (_actionCommands$missi4 = _actionCommands$missi3.onCompleteAction) === null || _actionCommands$missi4 === void 0 ? void 0 : _actionCommands$missi4.payload
        }
      });
      Emitter.emit(EVENT_EMITTER_TYPE.ACTION_COMMANDS, {
        actionType: ACTION_COMMANDS_TYPE.CLEAR_ACTION_COMMAND
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [missionsGameplayStatus, playerId, postMissions, tutorialStep]);
  useEffect(function () {
    if (activeTutorial && tutorialStep !== lastLoggedStep) {
      sendMissionsEvent(MISSIONS_EVENT_TYPES.DISPLAY_MISSIONS_TUTORIAL_STEP, {
        step: tutorialStep
      });
      setLastLoggedStep(tutorialStep);
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [tutorialStep, activeTutorial]);

  var handleFeedForwardTutorial = function handleFeedForwardTutorial() {
    sendMissionsEvent(tutorialStep === MissionTutorialStepsKeys.COMPLETED ? MISSIONS_EVENT_TYPES.MISSIONS_TUTORIAL_COMPLETE : MISSIONS_EVENT_TYPES.MISSIONS_TUTORIAL_NEXT_STEP, {
      step: tutorialStep
    });

    switch (tutorialStep) {
      case MissionTutorialStepsKeys.START_MISSION:
        if (gameId == '') {
          dispatch(setTutorialStep(MissionTutorialStepsKeys.START_MISSION));
          dispatch(setActiveTutorial({
            activeTutorial: false,
            tutorialName: null
          }));
        }

        break;

      case MissionTutorialStepsKeys.SEE_PROGRESS:
        dispatch(setActiveTutorial({
          activeTutorial: false,
          tutorialName: null
        }));
        break;

      case MissionTutorialStepsKeys.COMPLETED:
        dispatch(setActiveTutorial({
          activeTutorial: false,
          tutorialName: null
        }));
        break;

      case MissionTutorialStepsKeys.CHECK_GOAL:
        dispatch(setTutorialStep(tutorialStep + 2));
        break;

      default:
        dispatch(setTutorialStep(tutorialStep + 1));
        break;
    }
  };

  var skipTutorial = function skipTutorial() {
    var _actionCommands$missi5, _actionCommands$missi6, _actionCommands$missi7, _actionCommands$missi8;

    dispatch(setActiveTutorial({
      activeTutorial: false,
      tutorialName: null
    }));
    postMissions({
      action: MISSIONS_ACTIONS.FINISH_TUTORIAL,
      playerId: playerId
    });
    sendMissionsEvent(MISSIONS_EVENT_TYPES.MISSIONS_TUTORIAL_SKIP, {
      step: tutorialStep
    });
    Emitter.emit(EVENT_EMITTER_TYPE.ACTION_COMMANDS, {
      actionType: ACTION_COMMANDS_TYPE.NOTIFY_TRIGGER_PROCESSED_REQUEST,
      data: {
        event: ACTION_COMMANDS_EVENT_TYPE.SKIP_MISSIONS_TUTORIAL,
        triggerExecution: actionCommands === null || actionCommands === void 0 ? void 0 : (_actionCommands$missi5 = actionCommands.missionsTutorial) === null || _actionCommands$missi5 === void 0 ? void 0 : (_actionCommands$missi6 = _actionCommands$missi5.onSkipAction) === null || _actionCommands$missi6 === void 0 ? void 0 : _actionCommands$missi6.triggerExecution,
        payload: actionCommands === null || actionCommands === void 0 ? void 0 : (_actionCommands$missi7 = actionCommands.missionsTutorial) === null || _actionCommands$missi7 === void 0 ? void 0 : (_actionCommands$missi8 = _actionCommands$missi7.onSkipAction) === null || _actionCommands$missi8 === void 0 ? void 0 : _actionCommands$missi8.payload
      }
    });
    Emitter.emit(EVENT_EMITTER_TYPE.ACTION_COMMANDS, {
      actionType: ACTION_COMMANDS_TYPE.CLEAR_ACTION_COMMAND
    });
  };

  return {
    skipTutorial: skipTutorial,
    handleFeedForwardTutorial: handleFeedForwardTutorial
  };
};

export default useMissionsTutorial;