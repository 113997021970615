module.exports = {
  dimensions: {
    width: 146,
    height: 146
  },
  optical: {
    top: 27,
    left: 30,
    right: 30,
    bottom: 53
  },
  nine: {
    top: 11,
    left: 11,
    right: 11,
    bottom: 41
  },
  content: {
    top: 53,
    left: 53,
    right: 53,
    bottom: 53
  }
};