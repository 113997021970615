import React, { Component } from 'react';
import ImageBW from '../../ImageBoundsWrapper';
import Modal from '../../JSModal';
import theme from 'shared/assets/style/theme';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { termsModalHeaderfontSizeCalc } from 'shared/utils/fontSizeCalculator';
import { onPressHelp } from 'shared/utils/redirectionHelp';
import ThemeContext from 'shared/context/ThemeContext';

export default class TermsModalWrapper extends Component {
  _style = {
    modal: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 40vmin',
    },
    modalContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '70vh',
      width: '100%',
      marginTop: '-1vw',
      marginBottom: '-1vw',
    },
    header: {
      width: '100%',
      height: '14%',
    },
    headerImage: {
      height: '14%',
      width: '100%',
      objectFit: 'contain',
      zIndex: 11,
      position: 'absolute',
      top: 0,
      left: 0,
    },
    headerContent: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 5vmin',
    },
    title: {
      fontSize: '29px',
      fontWeight: '600',
      color: theme.palette.common[1],
      textTransform: 'uppercase',
    },
    help: {
      fontSize: '15px',
      fontWeight: '600',
      color: theme.palette.common[1],
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      padding: '0 2vmin',
    },
    titleHolder: {
      position: 'absolute',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      padding: '0 5vmin',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      zIndex: 0,
    },
  };

  render() {
    const themeContext = this.context;
    const {
      isVisible,
      closeModal,
      title,
      zIndex,
      children,
      modalStyle,
      modalContainerStyle,
      modalMainBodyStyle,
      bodyStyle,
      titleStyle,
      disableClose,
      hideCloseBtn,
      removeRightPart,
      removeLeftPart,
    } = this.props;

    return (
      <Modal
        isVisible={isVisible}
        zIndex={zIndex}
        style={modalStyle ? modalStyle : this._style.modal}
        className="terms-modal"
        onBackButtonPress={closeModal}
        modalMainBodyStyle={modalMainBodyStyle}
        customBackground
        disableBackDismiss={disableClose}
        hideCloseButton={hideCloseBtn}
        themeStyle={themeContext.TermsModalWrapper.Modal}
      >
        <div className="TermsModal" style={modalContainerStyle ? modalContainerStyle : this._style.modalContainer}>
          <div header style={this._style.header}>
            <ImageBW
              source={require(asset`Full_Modal_Header.png`)}
              ibw={require(asset`Full_Modal_Header.ibw`)}
              style={this._style.headerImage}
            >
              <div style={this._style.headerContent}>
                <div
                  style={{ cursor: 'pointer', zIndex: 1 }}
                  onClick={!disableClose && !removeLeftPart ? closeModal : undefined}
                >
                  {!disableClose && !removeLeftPart && (
                    <p style={(this._style.help, { textAlign: 'left' })}>{getText(TEXT_KEY.DECLINE)}</p>
                  )}
                </div>
                <div style={this._style.titleHolder}>
                  <p
                    style={{
                      ...(titleStyle ? titleStyle : this._style.title),
                      ...(!!title && termsModalHeaderfontSizeCalc(title)),
                    }}
                    className={`termsGradientText ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}
                    data-label={(!!title && title) || ''}
                  >
                    {(!!title && title) || ''}
                  </p>
                </div>
                <div style={{ cursor: 'pointer', zIndex: 1 }} onClick={!removeRightPart && onPressHelp}>
                  {!removeRightPart && <p style={this._style.help}>{getText(TEXT_KEY.NEED_HELP)}</p>}
                </div>
              </div>
            </ImageBW>
          </div>
          <div body style={bodyStyle ? bodyStyle : this._style.body}>
            {children}
          </div>
        </div>
      </Modal>
    );
  }
}

TermsModalWrapper.defaultProps = {
  hideCloseBtn: false,
};

TermsModalWrapper.contextType = ThemeContext;

