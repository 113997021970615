import React, { Component } from 'react';
import SHistoryDetail from 'shared/components/RewardCenter/HistoryDetail';
import { getDecimalAmount } from 'shared/utils/number';
import ImageBW from '../../components/ImageBoundsWrapper';
import Button from '../../components/Button/Button';
import { Scrollbars } from 'react-custom-scrollbars';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';

export default class HistoryDetail extends Component {
  state = {
    width: 0,
  };

  onScroll = () => {
    this.setState({ width: 0.5 });
  };

  render() {
    return (
      <SHistoryDetail
        render={props => {
          const { offerDetails, onSendEmail, emailSentMessage, hasData, history } = props;
          let historyIndex;
          let voucherCode;
          if (hasData) {
            historyIndex = history.findIndex(item => {
              return item.rpid === offerDetails.rpid;
            });
            voucherCode = offerDetails.barcodeImageURL.slice(offerDetails.barcodeImageURL.search('=') + 1);
          }
          return (
            <div key={`RewardCenterHistoryDetail-${hasData}`} className="RewardCenterHistoryDetail">
              {hasData ? (
                ENVIRONMENT_VARIABLES.REWARD_CENTER_QR_LAYOUT !== 'true' ? (
                  <>
                    <div style={{ width: '100%', height: '98%' }}>
                      <Scrollbars
                        ref="scrollbars"
                        style={{ height: '460px' }}
                        onScroll={this.onScroll}
                        renderThumbVertical={props => (
                          <div {...props} className="thumb-vertical" style={{ width: this.state.width + 'rem' }} />
                        )}
                        renderTrackVertical={props => (
                          <div
                            {...props}
                            className="track-vertical"
                            style={{ opacity: this.state.width > 0 ? 1 : 0 }}
                          />
                        )}
                        renderThumbHorizontal={props => (
                          <div {...props} className="thumb-horizontal" style={{ display: 'none' }} />
                        )}
                        renderTrackHorizontal={props => (
                          <div {...props} className="track-horizontal" style={{ display: 'none' }} />
                        )}
                      >
                        <div
                          style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <div className="RewardCenterHistoryDetailTitle">{offerDetails.rewardname}</div>
                          <div className="RewardCenterHistoryDetailSubtitle">{offerDetails.subtitle}</div>
                          <ImageBW
                            source={require(asset`Shop_VIP_Divider_Glows@3x.png`)}
                            ibw={require(asset`Shop_VIP_Divider_Glows.ibw`)}
                            manualAssetScale={3}
                            style={{ height: '1.5vmin', width: '100%', marginTop: '1vmin' }}
                            innerStyle={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-evenly',
                              alignItems: 'center',
                              paddingLeft: '1vmin',
                              paddingRight: '1vmin',
                            }}
                          ></ImageBW>
                          {offerDetails.showBarcode && (
                            <>
                              <img className="RewardCenterHistoryDetailBarcode" src={offerDetails.barcodeImageURL} />
                              <div className="RewardCenterHistoryDetailDates">
                                <div className="RewardCenterHistoryDetailIssueDate">
                                  {getText(TEXT_KEY.ISSUED_ON)}
                                  {offerDetails.redeemDate}
                                </div>
                                <div className="RewardCenterHistoryDetailExpiryDate">{offerDetails.expiryDate}</div>
                              </div>
                              <Button
                                imageSource={require(asset`RoundedRect_Primary@3x.png`)}
                                imageStyle={styles.emailButtonImage}
                                containerStyle={styles.emailButtonContainer}
                                onClick={onSendEmail}
                                label={getText(TEXT_KEY.SEND_EMAIL)}
                                textStyle={styles.emailButtonText}
                                textStroke={BUTTON_STROKES.PRIMARY}
                              />
                            </>
                          )}

                          {emailSentMessage && (
                            <div className="RewardCenterHistoryDetailEmailSent">{emailSentMessage}</div>
                          )}

                          <div className="RewardCenterHistoryDetailTermsTitle">
                            {getText(TEXT_KEY.PURCHASE_AND_USAGE_LIMITATIONS)}
                          </div>
                          <div
                            className="RewardCenterHistoryDetailTerms"
                            dangerouslySetInnerHTML={{ __html: offerDetails.terms }}
                          ></div>
                        </div>
                      </Scrollbars>
                    </div>
                  </>
                ) : (
                  // STAR
                  <div style={{ width: '100%', height: '98%' }}>
                    <Scrollbars
                      ref="scrollbars"
                      onScroll={this.onScroll}
                      renderThumbVertical={props => (
                        <div {...props} className="thumb-vertical" style={{ width: this.state.width + 'rem' }} />
                      )}
                      renderTrackVertical={props => (
                        <div {...props} className="track-vertical" style={{ opacity: this.state.width > 0 ? 1 : 0 }} />
                      )}
                    >
                      <div
                        style={{
                          flex: 1,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <div className="RewardCenterHistoryDetailTitle">{offerDetails.rewardname}</div>
                        <div className="RewardCenterHistoryDetailSubtitle star">{offerDetails.subtitle}</div>
                        <ImageBW
                          source={require(asset`Shop_VIP_Divider_Glows@3x.png`)}
                          ibw={require(asset`Shop_VIP_Divider_Glows.ibw`)}
                          manualAssetScale={3}
                          style={{ height: '1.5vmin', width: '100%' }}
                          innerStyle={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-evenly',
                            alignItems: 'center',
                            paddingLeft: '1vmin',
                            paddingRight: '1vmin',
                          }}
                        />
                        {offerDetails.showBarcode && (
                          <div style={{ display: 'flex', marginTop: '2rem' }}>
                            <div>
                              <img
                                className="RewardCenterHistoryDetailBarcode star"
                                src={offerDetails.barcodeImageURL}
                              />
                              <p className="voucherText">{voucherCode}</p>
                            </div>

                            <div
                              style={{ display: 'flex', flexDirection: 'column', position: 'relative', left: '3rem' }}
                            >
                              <div className="RewardCenterHistoryDetailRemainingBalance">
                                {getText(TEXT_KEY.REMAINING_BALANCE)} {history[historyIndex].amountLeftDisplayValue}
                              </div>

                              <div className="RewardCenterHistoryDetailIssueDate">
                                {getText(TEXT_KEY.ISSUED_ON)}
                                {offerDetails.redeemDate}
                              </div>

                              <div style={{ marginTop: '2px' }} className="RewardCenterHistoryDetailExpiryDate">
                                {offerDetails.expiryDate}
                              </div>

                              <Button
                                imageSource={require(asset`RoundedRect_Primary@3x.png`)}
                                imageStyle={styles.emailButtonImage}
                                containerStyle={styles.emailButtonContainer}
                                onClick={onSendEmail}
                                label={getText(TEXT_KEY.SEND_EMAIL)}
                                textStyle={styles.emailButtonText}
                                textStroke={BUTTON_STROKES.PRIMARY}
                              />
                            </div>
                          </div>
                        )}

                        {emailSentMessage && (
                          <div className="RewardCenterHistoryDetailEmailSent">{emailSentMessage}</div>
                        )}
                        <div className="RewardCenterHistoryDetailTermsTitle star">
                          {getText(TEXT_KEY.PURCHASE_AND_USAGE_LIMITATIONS)}
                        </div>
                        <div
                          className="RewardCenterHistoryDetailTerms"
                          dangerouslySetInnerHTML={{ __html: offerDetails.terms }}
                        ></div>
                      </div>
                    </Scrollbars>
                  </div>
                )
              ) : (
                <>
                  <div className="HistoryPreloadState"></div>
                </>
              )}
            </div>
          );
        }}
      />
    );
  }
}

const styles = {
  emailButtonContainer: {
    marginTop: '1em',
    position: 'relative',
    textAlign: 'center',
    cursor: 'pointer',
    pointerEvents: 'auto',
    width: '12em',
    height: '3.5em',
    flex: 1,
  },
  emailButtonText: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: 'calc(14px + (22 - 14) * ((100vw - 800px) / (1600 - 800)))',
  },
  emailButtonImage: {
    width: '100%',
  },
};

