import styled from 'styled-components';

export const TopBarInsetWrapper = styled.div`
  position: absolute;
  top: -4px;
  left: 0;
  right: -20px;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props =>
    props.shouldRunAnimation &&
    `
    animation: glow 60ms infinite alternate;
    border-radius: 20px;
    
    @keyframes glow {
      from {
        border-radius: 25px;
        box-shadow: 0 0 10px -10px rgba(252, 230, 86, 0.2);
      }
      to {
        border-radius: 50px;
        box-shadow: 0 0 10px 6px rgba(254, 252, 175, 0.8);
      }
    }
    &:before {
      content: "";
      position: absolute;
      transform: translate3d(4px, 2px, -5px); 
      background: radial-gradient(closest-side, rgba(255,231,89,0.01)  0%, rgba(255,183,31,1) 48%, rgba(250,173,9,1) 52%, rgba(252,230,86,0.01) 100%);
      filter: blur(10px);
      animation: blur 50ms infinite alternate;
      
      @keyframes blur {
        from {
          opacity: 0.4;
          inset: -10px;
          filter: blur(5px);
        }
        to {
          opacity: 1;
          inset: -35px;
          filter: blur(10px);
        }
      }
    }
    &::after {
      content: "";
      position: absolute;
      top: -2px;
      left: 0;
      right: -1px;
      bottom: -2px;
      border: 4px solid rgb(255, 254, 238);
      border-radius: 20px;
      z-index: -1;
      animation: flicker 60ms infinite alternate;
      @keyframes flicker {
        from {
          opacity: 0;
        }
        to {
          opacity: 0.2;
        }
      }
    }
  `}
`;
