import React, { Component } from 'react';
import SVipAccessFlow from 'shared/screens/VipAccessFlow';
import './VipAccessFlow.scss';
import Utils from 'shared/utils/index';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import Button from '../../components/Button/Button';
import ThemeContext from 'shared/context/ThemeContext';

const hasVIPPass = playNow => {
  return (
    <div className="vipAccessContainer">
      <div className={'vipAccessInfo' + (Utils.ieVersion() !== 0 ? ' ieHack' : '')}>
        <div className="hasVipAccess">
          <img src={require(asset`images/Promotional_Graphics/Promotional_Graphic@3x.png`)} alt="VIP Access" />
        </div>
        <p className="vipAccessSubtitle">
          <img alt="" src={require(asset`Light_Divider@3x.png`)} />
          {getText(TEXT_KEY.ALREADY_HAVE_VIP_ACCESS)} {getText(TEXT_KEY.VIP)} {getText(TEXT_KEY.ROOM)}
        </p>
      </div>
      <Button
        imageSource={require(asset`RoundedRect_Primary@3x.png`)}
        containerStyle={{ width: '100%', padding: '2rem' }}
        onClick={playNow}
        label={getText(TEXT_KEY.PLAY_NOW)}
      />
    </div>
  );
};

const VIPAccessNeeded = (purchaseVipAccess, redeemVipAccess, enterRequirements, themeContext) => {
  const itemCount = enterRequirements?.length;
  return (
    <div className="vipAccessContainer">
      <span className="vipAccessTitle">
        {getText(TEXT_KEY.VIP)} {getText(TEXT_KEY.VIP_ACCESS_NEEDED)}
      </span>
      <div className={'vipAccessInfo' + (Utils.ieVersion() !== 0 ? ' ieHack' : '')}>
        <p className="vipAccessSubtitle">
          <img alt="" src={require(asset`Light_Divider@3x.png`)} />
          {getText(TEXT_KEY.VIP_ACCESS_WAYS)}
        </p>
        <div className="vipAccessSteps">
          {ENVIRONMENT_VARIABLES.DYNAMIC_HIGHLIMIT_POPUP &&
          ENVIRONMENT_VARIABLES.DYNAMIC_HIGHLIMIT_POPUP.toString() === 'true' ? (
            enterRequirements.map(requirement => (
              <div
                key={requirement.icon}
                style={
                  itemCount === 2
                    ? themeContext.VipAccessFlow.VipAccessTwoImagesWrapper
                    : themeContext.VipAccessFlow.VipAccessMultipleImageWrapper
                }
              >
                <img src={requirement.icon} alt="VIP Access options" />
              </div>
            ))
          ) : (
            <img
              style={themeContext.VipAccessFlow.VipAccessImage}
              src={require(asset`images/VIP_Assets/Access_Options@3x.png`)}
              alt="VIP Access options"
            />
          )}
        </div>
      </div>
      <div className={'vipAccessButtons' + (Utils.ieVersion() !== 0 ? ' ieHack' : '')}>
        <Button
          containerStyle={{ width: '28%', marginBottom: '1.5rem', marginTop: '1rem' }}
          imageSource={require(asset`RoundedRect_Primary@3x.png`)}
          label={getText(TEXT_KEY.PURCHASE_ACCESS)}
          onClick={purchaseVipAccess}
          textStyle={themeContext.VipAccessFlow.VipAccessButtonText}
          textStroke={BUTTON_STROKES.PRIMARY}
        />
        <Button
          imageSource={require(asset`RoundedRect_Quaternary@3x.png`)}
          textStroke={BUTTON_STROKES.QUATERNARY}
          containerStyle={{ width: '28%', marginBottom: '1.5rem', marginTop: '1rem' }}
          label={getText(TEXT_KEY.REDEEM_ACCESS)}
          onClick={redeemVipAccess}
          textStyle={themeContext.VipAccessFlow.VipAccessButtonText}
        />
      </div>
    </div>
  );
};

export default class VipAccessFlow extends Component {
  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-vip-access',
    };
  }

  render() {
    return (
      <SVipAccessFlow
        navigation={this.props.navigation}
        {...this.props}
        render={args => {
          const themeContext = this.context;
          const { purchaseVipAccess, redeemVipAccess, playNow, isVipRoomUnlocked, enterRequirements } = args;
          return (
            <div>
              {isVipRoomUnlocked
                ? hasVIPPass(playNow)
                : VIPAccessNeeded(purchaseVipAccess, redeemVipAccess, enterRequirements, themeContext)}
            </div>
          );
        }}
      />
    );
  }
}

VipAccessFlow.contextType = ThemeContext;

