import React, { Component } from 'react';
import SHistoryList from 'shared/components/RewardCenter/HistoryList';
import { Scrollbars } from 'react-custom-scrollbars';
import Utils from 'shared/utils/index';
import { getDecimalAmount } from 'shared/utils/number';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import ThemeContext from 'shared/context/ThemeContext';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import HistoryDetailButton from './components/HistoryDetailButton';

const emptyState = () => <div className="RewardCenterHistoryEmpty">{getText(TEXT_KEY.NO_OFFERS_REDEEMED_YET)}</div>;

const hydratedState = (props, scrollbarWidth, onScroll, themeContext) => {
  const { history, onViewDetails } = props;
  return (
    <>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column', height: '95%' }}>
        <div
          className="HistoryHeadings"
          style={{
            backgroundImage: `url(${require(asset`images/Rewards_Center_Assets/History_Banner.png`)})`,
            zIndex: -100,
            backgroundSize: '100% 100%',
          }}
        >
          {ENVIRONMENT_VARIABLES.REWARD_CENTER_QR_LAYOUT === 'true' ? (
            <>
              <div className="HistoryHeadingStar HistoryItemOverview">{getText(TEXT_KEY.OFFER)}</div>
              <div className="HistoryHeadingStar HistoryItemStatus">{getText(TEXT_KEY.STATUS)}</div>
              <div className="HistoryHeadingStar HistoryItemDate">{getText(TEXT_KEY.REDEMPTION_DATE)}</div>
              <div className="HistoryHeadingStar Remaining">{getText(TEXT_KEY.REMAINING)}</div>
              <div className="HistoryHeadingStar HistoryItemDetailsAction"></div>
            </>
          ) : (
            <>
              <div className="HistoryHeading HistoryItemOverview">{getText(TEXT_KEY.OFFER)}</div>
              <div className="HistoryHeading HistoryItemStatus">{getText(TEXT_KEY.STATUS)}</div>
              <div className="HistoryHeading HistoryItemDate">{getText(TEXT_KEY.REDEMPTION_DATE)}</div>
              <div className="HistoryHeading HistoryItemDetailsAction"></div>
            </>
          )}
        </div>

        <Scrollbars
          ref="scrollbars"
          style={{ height: '460px' }}
          onScroll={onScroll}
          renderThumbVertical={props => (
            <div {...props} style={{ ...themeContext.HistoryList.ScrollBar, width: scrollbarWidth + 'rem' }} />
          )}
          renderTrackVertical={props => (
            <div {...props} className="track-vertical" style={{ opacity: scrollbarWidth > 0 ? 1 : 0 }} />
          )}
          renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: 'none' }} />}
          renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: 'none' }} />}
        >
          {history.map((historyItem, index) => (
            <div
              key={`HistoryItem-${historyItem.rpid}`}
              className={
                ENVIRONMENT_VARIABLES.REWARD_CENTER_QR_LAYOUT === 'true'
                  ? 'HistoryItemStar ' + (index % 2 === 0 ? 'even' : 'odd')
                  : 'HistoryItem ' + (index % 2 === 0 ? 'even' : 'odd')
              }
            >
              <div className="HistoryItemOverview">
                <img
                  alt=""
                  className={'RewardCenterHistoryCategoryIcon' + (Utils.ieVersion() !== 0 ? ' ieHack' : '')}
                  src={historyItem.cat_icon}
                />
                <div className="HistoryItemName" style={{ alignSelf: 'center' }}>
                  {historyItem.desc}
                </div>
              </div>
              <div className="HistoryItemStatus">{historyItem.state}</div>
              <div className="HistoryItemDate">{historyItem.date}</div>
              {ENVIRONMENT_VARIABLES.REWARD_CENTER_QR_LAYOUT === 'true' && (
                <div className="HistoryItemRemaining">{historyItem.amountLeftDisplayValue}</div>
              )}
              <div className="HistoryItemDetailsAction">
                {historyItem.showDetailsButton && (
                  <HistoryDetailButton
                    containerStyle={{ position: 'relative' }}
                    onClick={onViewDetails(historyItem.rpid)}
                    imageStyle={{ width: '75%', height: '100%' }}
                    textStyle={{ padding: '2%' }}
                    label={getText(TEXT_KEY.DETAILS)}
                  />
                )}
              </div>
            </div>
          ))}
        </Scrollbars>
      </div>
    </>
  );
};

export default class HistoryList extends Component {
  state = {
    width: 0,
  };

  onScroll = () => {
    this.setState({ width: 0.5 });
  };

  render() {
    const themeContext = this.context;
    return (
      <SHistoryList
        render={args => {
          const { history, hasData } = args;

          let content =
            history.length > 0 ? hydratedState(args, this.state.width, this.onScroll, themeContext) : emptyState();
          return (
            <div key={`RewardCenterHistory-${hasData}`} className="RewardCenterHistory">
              {hasData ? (
                content
              ) : (
                <>
                  <div className="HistoryPreloadState"></div>
                </>
              )}
            </div>
          );
        }}
      />
    );
  }
}

HistoryList.contextType = ThemeContext;

