import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { MISSIONS_TYPES, MISSIONS_STATES } from '../constants/missionsInfo';

var generateTabs = function generateTabs(missionsAssets) {
  return [[{
    TYPE: MISSIONS_TYPES.EXPLORER,
    TAB: missionsAssets.TABS_EXPLORER_1,
    INDEX: 3
  }, {
    TYPE: MISSIONS_TYPES.EXPERT,
    TAB: missionsAssets.TABS_EXPLORER_2,
    INDEX: 2
  }, {
    TYPE: MISSIONS_TYPES.BATTLES,
    TAB: missionsAssets.TABS_EXPLORER_3,
    INDEX: 1
  }], [{
    TYPE: MISSIONS_TYPES.EXPLORER,
    TAB: missionsAssets.TABS_EXPERT_1,
    INDEX: 4
  }, {
    TYPE: MISSIONS_TYPES.EXPERT,
    TAB: missionsAssets.TABS_EXPERT_2,
    INDEX: 3
  }, {
    TYPE: MISSIONS_TYPES.BATTLES,
    TAB: missionsAssets.TABS_EXPERT_3,
    INDEX: 3
  }], [{
    TYPE: MISSIONS_TYPES.EXPLORER,
    TAB: missionsAssets.TABS_BATTLES_1,
    INDEX: 1
  }, {
    TYPE: MISSIONS_TYPES.EXPERT,
    TAB: missionsAssets.TABS_BATTLES_2,
    INDEX: 2
  }, {
    TYPE: MISSIONS_TYPES.BATTLES,
    TAB: missionsAssets.TABS_BATTLES_3,
    INDEX: 3
  }]];
};

var generateTutorialInfoScreen = function generateTutorialInfoScreen(missionsAssets) {
  return [missionsAssets.TUTORIAL_INFO_SCREEN_STEP_1, missionsAssets.TUTORIAL_INFO_SCREEN_STEP_2, missionsAssets.TUTORIAL_INFO_SCREEN_STEP_3];
};

var generateLayoutProgressbar = function generateLayoutProgressbar(missionsAssets) {
  var _ref;

  return _ref = {}, _defineProperty(_ref, MISSIONS_STATES.COMPLETED, {
    LayoutBar: missionsAssets === null || missionsAssets === void 0 ? void 0 : missionsAssets.BAR_BASE_PURPLE,
    ShadowBar: missionsAssets === null || missionsAssets === void 0 ? void 0 : missionsAssets.BAR_SHADOW
  }), _defineProperty(_ref, MISSIONS_STATES.FINISHED, {
    LayoutBar: missionsAssets === null || missionsAssets === void 0 ? void 0 : missionsAssets.BAR_BASE_PURPLE,
    ShadowBar: missionsAssets === null || missionsAssets === void 0 ? void 0 : missionsAssets.BAR_SHADOW
  }), _defineProperty(_ref, MISSIONS_STATES.REWARD, {
    LayoutBar: missionsAssets === null || missionsAssets === void 0 ? void 0 : missionsAssets.BAR_COLLECT_BUTTON,
    ShadowBar: missionsAssets === null || missionsAssets === void 0 ? void 0 : missionsAssets.BAR_SHADOW
  }), _defineProperty(_ref, MISSIONS_STATES.LOCKED, {
    LayoutBar: missionsAssets === null || missionsAssets === void 0 ? void 0 : missionsAssets.BAR_BASE_PURPLE,
    ShadowBar: missionsAssets === null || missionsAssets === void 0 ? void 0 : missionsAssets.BAR_SHADOW,
    IconLocked: missionsAssets === null || missionsAssets === void 0 ? void 0 : missionsAssets.BAR_LOCKED_ICON
  }), _defineProperty(_ref, MISSIONS_STATES.READY, {
    IconReady: missionsAssets === null || missionsAssets === void 0 ? void 0 : missionsAssets.BAR_PLAY_ICON
  }), _defineProperty(_ref, MISSIONS_STATES.RUNNING, {
    LayoutBar: missionsAssets === null || missionsAssets === void 0 ? void 0 : missionsAssets.BAR_BASE_BLUE,
    LayoutBarPurple: missionsAssets === null || missionsAssets === void 0 ? void 0 : missionsAssets.BAR_BASE_PURPLE,
    ShadowBar: missionsAssets === null || missionsAssets === void 0 ? void 0 : missionsAssets.BAR_SHADOW,
    FillBarStart: missionsAssets === null || missionsAssets === void 0 ? void 0 : missionsAssets.BAR_FILL_SIDE,
    FillBarProgess: missionsAssets === null || missionsAssets === void 0 ? void 0 : missionsAssets.BAR_FILL,
    FillBarEnd: missionsAssets === null || missionsAssets === void 0 ? void 0 : missionsAssets.BAR_FILL_SIDE
  }), _ref;
};

export { generateTabs, generateTutorialInfoScreen, generateLayoutProgressbar };