import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../api/services/rtkQuery/axiosBaseQuery';
import { getMissionsQuery, postMissionsMutation } from './queries';
export var missionsApi = createApi({
  reducerPath: 'missionsQuery',
  baseQuery: axiosBaseQuery(),
  keepUnusedDataFor: 10,
  tagTypes: ['Missions'],
  endpoints: function endpoints(builder) {
    return {
      getMissions: builder.query(getMissionsQuery),
      postMissions: builder.mutation(postMissionsMutation)
    };
  }
});
var useGetMissionsQuery = missionsApi.useGetMissionsQuery,
    useLazyGetMissionsQuery = missionsApi.useLazyGetMissionsQuery,
    usePostMissionsMutation = missionsApi.usePostMissionsMutation;
export { useGetMissionsQuery, useLazyGetMissionsQuery, usePostMissionsMutation };
var _missionsApi$endpoint = missionsApi.endpoints,
    getMissions = _missionsApi$endpoint.getMissions,
    postMissions = _missionsApi$endpoint.postMissions,
    missionsReducerPath = missionsApi.reducerPath,
    missionsQueryReducer = missionsApi.reducer,
    missionsMiddleware = missionsApi.middleware;
export { missionsReducerPath, missionsQueryReducer, missionsMiddleware, getMissions, postMissions };