import extractData from './extractData';
/**
 * The parsed event object. Fields may vary based on the event but will have at minimum these.
 * @typedef {Object} ParsedEvent
 * @property {String} name Event name.
 * @property {String} _from Parser that produced this result.
 */

/**
 * @callback Parser
 * @param {String} Data The data to parse
 * @returns {Boolean|ParsedEvent} Return either the data parsed as an object, or false.
 */

/**
 * Helper to parse events.
 * @param {Object} event The event to be parsed
 * @param {Parser[]} parsers An array of parsers to use, in order.
 * @returns {Boolean|ParsedEvent} Returns either the data parsed as on object, or false.
 */

function parseEvent(event, parsers) {
  // Early short circuit if no parsers provided.
  if (!parsers) {
    return false;
  }

  var data = extractData(event); // If extracted data is not a string, don't parse
  // It's probably a react devtools object

  if (typeof data !== 'string') {
    return false;
  }

  for (var i = 0; i < parsers.length; i++) {
    var parser = parsers[i]; // Use the parser

    var result = parser(data);

    if (result !== false) {
      // Return it
      return result;
    }
  } // If we got here, all parsers failed, return false


  return false;
}

export default parseEvent;