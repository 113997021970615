import React, { Component } from 'react';
import { Nav } from 'react-bootstrap';
import SMessages from 'shared/screens/Messages';
import theme from 'shared/assets/style/theme';
import { Scrollbars } from 'react-custom-scrollbars';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';

import MessageItem from '../../components/MessageItem';
import MessageDetails from '../../components/MessageDetails';
import ImageBW from '../../components/ImageBoundsWrapper';
import ThemeContext from 'shared/context/ThemeContext';

import Header from './components/MessagesHeader';
import { getText, TEXT_KEY } from 'shared/utils/localization';

/** @typedef {import('shared/api/Constants').InboxCategory} InboxCategory */
/** @typedef {import('shared/api/Constants').InboxMessagePreview} InboxMessagePreview */
/** @typedef {import('shared/api/Constants').InboxMessageDetails} InboxMessageDetails */

export default class Messages extends Component {
  state = {};

  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-message-inbox',
    };
  }

  /**
   * Render the categories
   * @param {InboxCategory[]} categories
   * @returns {JSX}
   */
  renderCategories = categories => {
    return categories.map(
      /** @param {InboxCategory} category */
      category => (
        <Nav.Item key={category.ic_id} className="message-categories">
          <Nav.Link eventKey={category.ic_id}>{category.name}</Nav.Link>
        </Nav.Item>
      )
    );
  };

  /**
   * Render the individual messages
   * @param {InboxMessagePreview[]} messages
   * @param {Function} messageDetailsHandle
   * @returns {JSX}
   */
  renderMessages = (messages, messageDetailsHandle, imagesCdnBaseUrl) => {
    return messages.map(
      /**
       * @param {InboxMessagePreview} message
       * @param {Number} index
       */
      (message, index) => (
        <MessageItem
          key={message.message_id}
          message={message}
          messageDetailsHandle={messageDetailsHandle}
          navigation={this.props.navigation}
          imagesCdnBaseUrl={imagesCdnBaseUrl}
        />
      )
    );
  };

  /**
   * Render Inbox
   * @param {Object} args
   */
  renderInbox = (themeContext, args) => {
    const { messages, messageDetailsHandle, imagesCdnBaseUrl } = args;
    return (
      <div>
        {messages.length > 0 && this.renderMessages(messages, messageDetailsHandle, imagesCdnBaseUrl)}
        {messages.length === 0 && (
          <div className="emptyInboxContainer" style={themeContext.Messages.EmptyInboxContainer}>
            <img className="mailIcon" src={require(asset`mail_icon_open@3x.png`)} alt="" />
            <div
              className={`mainText gradientText ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}
              data-label={`YOU'RE UP TO DATE`}
            >
              {getText(TEXT_KEY.MESSAGES_DESCRIPTION_TITLE)}
            </div>
            <div className="subText"> {getText(TEXT_KEY.MESSAGES_DESCRIPTION)}</div>
          </div>
        )}
      </div>
    );
  };

  /**
   * @param {Number} messageId
   */
  renderMessageDetails = messageId => {
    return <MessageDetails id={messageId} navigation={this.props.navigation} />;
  };

  render() {
    const themeContext = this.context;

    return (
      <SMessages
        navigation={this.props.navigation}
        render={args => {
          const { activeMessageID, backHandle, ...rest } = args;
          const detailsMode = activeMessageID !== undefined;

          return (
            <div style={{ marginBottom: '20px', paddingBottom: '-20px' }}>
              <div className="message-header" style={themeContext.Messages.HeaderContainer}>
                <Header />
                {detailsMode && (
                  <div
                    style={{
                      position: 'absolute',
                      cursor: 'pointer',
                      zIndex: 100,
                      height: '3.7rem',
                      width: '35px',
                      top: '10px',
                      left: '16px',
                    }}
                    onClick={backHandle}
                  >
                    <ImageBW
                      source={require(asset`Back_Arrow@3x.png`)}
                      ibw={require(asset`Back_Arrow.ibw`)}
                      style={{}}
                      innerStyle={{}}
                      alt="Close"
                      widthScale={0.5}
                    />
                  </div>
                )}
                <h1 style={themeContext.Messages.HeaderTitle}>{getText(TEXT_KEY.MESSAGES_TITLE)}</h1>
              </div>
              {!detailsMode && (
                <Scrollbars
                  hideTracksWhenNotNeeded
                  style={{
                    height: '500px',
                  }}
                  renderTrackVertical={({ style, ...props }) => (
                    <div
                      className="ScrollTrack"
                      style={{
                        ...style,
                        top: 8,
                        bottom: 8,
                        right: 8,
                        backgroundColor: theme.palette.common[0],
                        width: '0.5rem',
                        borderRadius: '8px',
                      }}
                      {...props}
                    />
                  )}
                  renderThumbVertical={({ style, ...props }) => (
                    <div
                      className="ScrollThumb"
                      style={{
                        ...style,
                        ...themeContext.Messages.ScrollBar,
                      }}
                      {...props}
                    >
                      <ImageBW
                        source={require(asset`Scroll_Indicator.png`)}
                        ibw={require(asset`Scroll_Indicator.ibw`)}
                        style={{
                          width: '100%',
                          height: '100%',
                        }}
                      />
                    </div>
                  )}
                  renderView={({ style, ...props }) => (
                    <div
                      style={{
                        ...style,
                        marginRight: -36,
                        marginBottom: -18,
                      }}
                      className="RewardCenterOfferScrollContainer"
                      {...props}
                    />
                  )}
                >
                  <div className="message-container">{this.renderInbox(themeContext, rest)}</div>
                </Scrollbars>
              )}
              {detailsMode && <div className="message-details">{this.renderMessageDetails(activeMessageID)}</div>}
            </div>
          );
        }}
      />
    );
  }
}

Messages.contextType = ThemeContext;
Messages.footer = () => getText(TEXT_KEY.MESSAGES_FOOTER);
Messages.isTabbed = true;

