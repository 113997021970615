import Start from './Start';
import { connect } from '../../node_modules/react-redux';
import { setAssetsUrls as _setAssetsUrls } from '../../state/actions/app';

var mapStateToProps = function mapStateToProps(state) {
  return {
    token: state.token,
    restart: state.restart,
    lastActive: state.lastActive,
    googleAuthServiceAvailable: state.availableServices.googleAuth,
    assetsUrls: state.app.assetsUrls,
    propertyId: state.playerInfo.propertyId
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    setAssetsUrls: function setAssetsUrls(assetsUrls) {
      dispatch(_setAssetsUrls(assetsUrls));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Start);