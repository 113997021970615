import React from 'react';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { Spinner } from 'react-bootstrap';
import Signup from '../Signup';
import TermsModal from '../../components/TermsModal';
import { landingPageTemplates } from './landingPageTemplates';
import {
  TrackingURLContainer,
  ContentContainer,
  ClientLogo,
  PageDescriptionContainer,
  MainImage,
  PageDescription,
  LinkIconHolder,
  InnerDiv,
  Badge,
  PageBottomText,
  MainContainer,
  FormContainer,
  FormTitle,
  SignInButtonWrapper,
  SignInButtonImage,
  PlayAsGuestButtonWrapper,
} from './styledComponents';
import audio from 'shared/utils/audio';
import generateRender from 'shared/utils/generateRender';
import Button from '../../components/Button/Button';

const TrackingURL = props => {
  const {
    landingPageState: {
      windowWidth,
      windowHeight,
      template,
      backgroundImage,
      mainImage,
      firstCheckBoxClicked,
      secondCheckBoxClicked,
      trackingPageDescription,
      trackingPageBottomTxt,
      authLoading,
      statusMessage,
      socialError,
      socialErrorType,
      error,
      isTermsModalVisible,
      terms,
      termsAccepted,
      selectedTerms,
    },
    updateLandingPageState,
    termsAndConditionsUrl,
    privacyPolicyUrl,
    navigation,
    onFacebookActivate,
    onGuestActivate,
    redirectToSplash,
    aff_id,
    closeModal,
    onSubmit,
    closeTermsModal,
    checkSelectedTerms,
  } = props;

  const activeBG = require(asset`RoundedRect_Primary@3x.png`);
  const nonActiveBG = require(asset`RoundedRect_Disabled@3x.png`);
  const activeCheckbox = require(asset`Checkbox@3x.png`);
  const nonActiveCheckbox = require(asset`Checkbox_Deselected@3x.png`);
  const isSignUpEnabled = template === landingPageTemplates.signupEnabled;

  const clientText = generateRender({
    default: '',
    hri: getText(TEXT_KEY.HRI),
    sga: getText(TEXT_KEY.SGA),
    ti: getText(TEXT_KEY.TI),
    admiral: getText(TEXT_KEY.ADMIRAL),
    admes: getText(TEXT_KEY.ADMIRAL),
  });

  const setFirstCheckBoxClicked = () => {
    audio.onClick();
    updateLandingPageState({ firstCheckBoxClicked: !firstCheckBoxClicked });
  };

  const setSecondCheckBoxClicked = () => {
    audio.onClick();
    updateLandingPageState({ secondCheckBoxClicked: !secondCheckBoxClicked });
  };

  const setTerms = () => {
    audio.onClick();
    updateLandingPageState({ termsAccepted: !termsAccepted, terms: !terms });
  };

  return (
    <>
      <TrackingURLContainer
        windowWidth={windowWidth}
        windowHeight={windowHeight}
        backgroundImage={`url(${backgroundImage})`}
      >
        {isSignUpEnabled && (
          <>
            <ClientLogo
              src={require(asset`images/TrackingUrl/starPlayLogoCopy@3x.png`)}
              alt=""
              isSignUpEnabled={isSignUpEnabled}
            />
            <PageDescriptionContainer>
              <PageDescription>{trackingPageDescription}</PageDescription>
            </PageDescriptionContainer>
          </>
        )}
        <MainContainer>
          {isSignUpEnabled && (
            <>
              <FormContainer>
                <FormTitle>{getText(TEXT_KEY.SIGNUP_TITLE)}</FormTitle>
                <Signup
                  isModal={false}
                  isLandingPage={true}
                  zIndex={101}
                  firstCheckBoxClicked={firstCheckBoxClicked}
                  secondCheckBoxClicked={secondCheckBoxClicked}
                  setFirstCheckBoxClicked={setFirstCheckBoxClicked}
                  setSecondCheckBoxClicked={setSecondCheckBoxClicked}
                  enableRegisterButton={firstCheckBoxClicked}
                  termsAndConditionsUrl={termsAndConditionsUrl}
                  privacyPolicyUrl={privacyPolicyUrl}
                  navigation={navigation}
                />
                <PlayAsGuestButtonWrapper>
                  <Button
                    imageStyle={{ width: '15em' }}
                    label={getText(TEXT_KEY.PLAY_AS_GUEST)}
                    imageSource={require(asset`./images/TrackingUrl/Grey_Button_landing@3x.png`)}
                    textStyle={{ fontSize: '21px', fontWeight: '800' }}
                    onClick={onGuestActivate}
                    textStroke={'black'}
                  />
                </PlayAsGuestButtonWrapper>
                <SignInButtonWrapper className="splash-facebook-button" onClick={onFacebookActivate}>
                  <SignInButtonImage src={require(asset`./images/Start/Buttons/Facebook_Button@3x.png`)} />
                </SignInButtonWrapper>
              </FormContainer>
            </>
          )}
          <ContentContainer isSignUpEnabled={isSignUpEnabled}>
            {!isSignUpEnabled && (
              <>
                <ClientLogo src={require(asset`images/TrackingUrl/starPlayLogoCopy@3x.png`)} alt="" />
                <PageDescriptionContainer>
                  <PageDescription>{trackingPageDescription}</PageDescription>
                </PageDescriptionContainer>
              </>
            )}
            <MainImage src={mainImage} alt="" onError="this.onerror=null" />
            <LinkIconHolder>
              <InnerDiv>
                <a href={ENVIRONMENT_VARIABLES.APPLE_STORE_URL}>
                  <Badge src={require(asset`images/TrackingUrl/badgeIOs@3x.png`)} alt="" />
                </a>
              </InnerDiv>
              <InnerDiv>
                <a href={ENVIRONMENT_VARIABLES.ANDROID_STORE_URL}>
                  <Badge src={require(asset`images/TrackingUrl/badgeAndroid@3x.png`)} alt="" />
                </a>
              </InnerDiv>
              <InnerDiv>
                <div href="#" onClick={() => redirectToSplash(aff_id.current)}>
                  <Badge src={require(asset`images/TrackingUrl/badgeWeb@3x.png`)} alt="" />
                </div>
              </InnerDiv>
            </LinkIconHolder>
            <PageBottomText>{trackingPageBottomTxt}</PageBottomText>
          </ContentContainer>
        </MainContainer>
      </TrackingURLContainer>
      <TermsModal
        isVisible={isTermsModalVisible}
        closeModal={closeModal}
        closeRetryModal={closeModal}
        terms={terms}
        activeCheckbox={activeCheckbox}
        nonActiveCheckbox={nonActiveCheckbox}
        setTerms={setTerms}
        onSubmit={onSubmit}
        activeBG={activeBG}
        nonActiveBG={nonActiveBG}
        onFacebookActivate={onFacebookActivate}
        closeTermsModal={closeTermsModal}
        selectedTerms={selectedTerms}
        checkSelectedTerms={checkSelectedTerms}
        error={error}
        socialError={socialError}
        socialErrorType={socialErrorType}
        statusMessage={statusMessage}
        clientText={clientText}
        termsAndConditionsUrl={termsAndConditionsUrl}
        privacyPolicyUrl={privacyPolicyUrl}
      />
      {authLoading && <Spinner style={{ position: 'absolute' }} animation="grow" className="custom-spinner" />}
    </>
  );
};

export default TrackingURL;

