export var AUTH_TYPES = {
  APPLE: 'apple',
  GOOGLE: 'google',
  FACEBOOK: 'facebook',
  GUEST: 'guest',
  LINK: 'link',
  STANDARD: 'standard'
};
export var LOGIN_ERROR_TYPE = {
  APPLE: 'APPLE',
  GOOGLE: 'GOOGLE',
  FACEBOOK: 'FACEBOOK',
  COUNTRY_BLOCKED: 'COUNTRY_BLOCKED'
};
export var ERROR_TYPES = {
  GEO_BLOCK: 'GEO_BLOCK'
};