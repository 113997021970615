import React from 'react';
import { Spinner } from 'react-bootstrap';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { trimInputString } from 'shared/utils/string';
import {
  CheckBoxContainer,
  FormBody,
  FormInput,
  FormInputContainer,
  Header,
  HeaderContent,
  HeaderImage,
  HelpText,
  HelpTextContainer,
  LeftInputContainer,
  MessageContainer,
  ModalFormBody,
  NewUserForm,
  Peeker,
  RegisterButtonContainer,
  RightInputContainer,
  Title,
  TitleHolder,
  SuccessAccountMessage,
  SuccessAccountWrapper,
} from '../common/styledComponents';
import CheckBoxes from './CheckBoxes';
import Button from '../../../components/Button/Button';
import theme from 'shared/assets/style/theme';

export const SignUpForm = ({
  values: { firstName, lastName, email, password, confirmPassword, acceptedTerms, emailOptIn },
  errors,
  status,
  handleSubmit,
  setFieldValue,
  handleBlur,
  handleChange,
  handleCheckBoxToggle,
  getStatusMessage,
  title,
  termsAndConditionsUrl,
  privacyPolicyUrl,
  onPressHelp,
  showPassword,
  showPasswordToggleHandle,
  isModal,
  isLandingPage,
  requireName,
  clientText,
  clientFurtherInfoText,
  isLoading,
  postSignupData,
}) => {
  const BodyComponent = isModal ? ModalFormBody : FormBody;

  const disabled = !acceptedTerms || isLoading;

  return (
    <>
      {isModal && (
        <Header>
          <HeaderImage
            source={require(asset`Full_Modal_Header.png`)}
            ibw={require(asset`Full_Modal_Header.ibw`)}
            style={{ position: 'absolute' }}
          >
            <HeaderContent>
              <TitleHolder>
                <Title data-label={(!!title && title) || ''}>{(!!title && title) || ''}</Title>
              </TitleHolder>
              <HelpTextContainer onClick={onPressHelp}>
                <HelpText>{getText(TEXT_KEY.NEED_HELP)}</HelpText>
              </HelpTextContainer>
            </HeaderContent>
          </HeaderImage>
        </Header>
      )}
      {!postSignupData?.success ? (
        <BodyComponent>
          {requireName && (
            <NewUserForm>
              <LeftInputContainer>
                <FormInputContainer>
                  <FormInput
                    error={!!errors.firstName}
                    type="text"
                    value={firstName}
                    maxLength="50"
                    onChange={handleChange('firstName')}
                    onBlur={handleBlur('firstName')}
                    placeholder={getText(TEXT_KEY.FIRST_NAME)}
                  />
                </FormInputContainer>
              </LeftInputContainer>
              <RightInputContainer>
                <FormInputContainer>
                  <FormInput
                    error={!!errors.lastName}
                    type="text"
                    value={lastName}
                    maxLength="50"
                    onChange={handleChange('lastName')}
                    onBlur={handleBlur('lastName')}
                    placeholder={getText(TEXT_KEY.LAST_NAME)}
                  />
                </FormInputContainer>
              </RightInputContainer>
            </NewUserForm>
          )}

          <FormInputContainer>
            <FormInput
              error={!!errors.email}
              type="text"
              maxLength="100"
              value={email}
              onChange={e => setFieldValue('email', trimInputString(e.target.value))}
              onBlur={handleBlur('email')}
              placeholder={getText(TEXT_KEY.EMAIL)}
            />
          </FormInputContainer>
          <FormInputContainer>
            <FormInput
              error={!!errors.password}
              type={!showPassword ? 'password' : 'text'}
              maxLength="50"
              value={password}
              onChange={handleChange('password')}
              onBlur={handleBlur('password')}
              placeholder={getText(TEXT_KEY.PASSWORD)}
            />
            <Peeker onClick={showPasswordToggleHandle}>
              {!showPassword && <img src={require(asset`Icon_Show.png`)} />}
              {showPassword && <img src={require(asset`Icon_Hide.png`)} />}
            </Peeker>
          </FormInputContainer>
          <FormInputContainer>
            <FormInput
              error={!!errors.confirmPassword}
              type={!showPassword ? 'password' : 'text'}
              maxLength="50"
              value={confirmPassword}
              onChange={handleChange('confirmPassword')}
              onBlur={handleBlur('confirmPassword')}
              placeholder={getText(TEXT_KEY.CONFIRM_PASSWORD)}
            />
          </FormInputContainer>
          <CheckBoxContainer>
            <CheckBoxes
              handleCheckBoxToggle={handleCheckBoxToggle}
              acceptedTerms={acceptedTerms}
              privacyPolicyUrl={privacyPolicyUrl}
              termsAndConditionsUrl={termsAndConditionsUrl}
              clientText={clientText}
              clientFurtherInfoText={clientFurtherInfoText}
              emailOptIn={emailOptIn}
            />
          </CheckBoxContainer>
          {getStatusMessage() && <MessageContainer success={status?.success}>{getStatusMessage()}</MessageContainer>}

          <RegisterButtonContainer>
            <Button
              label={getText(TEXT_KEY.REGISTER)}
              isDebounce={true}
              imageSource={
                !disabled
                  ? isLandingPage
                    ? require(asset`images/TrackingUrl/Green_Button_landing@3x.png`)
                    : require(asset`RoundedRect_Primary@3x.png`)
                  : isLandingPage
                  ? require(asset`images/TrackingUrl/Grey_Button_landing@3x.png`)
                  : require(asset`RoundedRect_Disabled@3x.png`)
              }
              imageStyle={isLandingPage ? { width: '15em' } : null}
              disabled={disabled}
              onClick={handleSubmit}
              textStroke={theme.palette.success[2]}
            />
          </RegisterButtonContainer>
          {isLoading && (
            <Spinner
              style={{
                position: 'absolute',
                top: '25%',
                left: '32.5%',
              }}
              animation="grow"
              className="custom-spinner"
            />
          )}
        </BodyComponent>
      ) : (
        <SuccessAccountWrapper>
          <SuccessAccountMessage>{postSignupData.msg}</SuccessAccountMessage>
        </SuccessAccountWrapper>
      )}
    </>
  );
};

