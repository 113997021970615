import HistoryDetail from './HistoryDetail';
import HistoryList from './HistoryList';
import OfferDetail from './OfferDetail';
import OfferList from './OfferList';
import RewardHome from './RewardHome';
import RewardProfile from './RewardProfile';

const RewardTabContent = {
  home: RewardHome,
  offers: OfferList,
  offer: OfferDetail,
  history: HistoryList,
  historyDetail: HistoryDetail,
  profile: RewardProfile,
};

export default RewardTabContent;

