module.exports = {
  dimensions: {
    width: 712,
    height: 243
  },
  nine: {
    top: 28,
    left: 20,
    right: 20,
    bottom: 28
  },
  optical: {
    top: 1,
    left: 1,
    right: 1,
    bottom: 1
  }
};