import React, { Component } from 'react';
import { RewardCenterTabs } from 'shared/screens/RewardCenter/Constants';
import SControlHeader from 'shared/components/RewardCenter/ControlHeader';
import ImageBW from '../../components/ImageBoundsWrapper';
import audio from 'shared/utils/audio';

export default class ControlHeader extends Component {
  state = {
    shouldShowDropdown: false,
  };

  componentDidUpdate() {
    if (!this.props.isInOffersView && this.state.shouldShowDropdown) {
      this.setState({ shouldShowDropdown: false });
    }
  }

  showDropdown = e => {
    audio.onClick();
    e.stopPropagation();
    this.setState({ shouldShowDropdown: !this.state.shouldShowDropdown });
  };

  getPrevTab = prevTab => {
    if (prevTab !== RewardCenterTabs.PROFILE && prevTab !== RewardCenterTabs.HISTORY && prevTab !== '') return prevTab;
    return RewardCenterTabs.HOME;
  };

  render() {
    const {
      activeFilter,
      activeProperty,
      onPropertyChange,
      onFilterChange,
      dismissModal,
      shouldShowBackNavigation,
      isInTutorial,
      showProfileTab,
    } = this.props;
    const hasFilters = activeFilter && activeProperty && onPropertyChange && onFilterChange;

    return (
      <SControlHeader
        render={props => {
          const { ux, api, setActiveTab, setPrevTab, onboardingStep } = props;
          const { activeTab } = ux;
          const { prevTab } = ux;
          const { filterList } = api.core;
          let activeFilterName = '';
          for (var i = 0; i < filterList.length; i++) {
            if (parseInt(filterList[i].rc_id) === activeFilter) {
              activeFilterName = filterList[i].cat_name;
            }
          }

          const dropdownHeight = this.state.shouldShowDropdown ? filterList.length * 2.9 : 2.9;
          const activeTabName = this.getPrevTab(prevTab);

          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="RewardCenterHeader">
                <div style={{ width: '10%', alignSelf: 'center' }}>
                  {shouldShowBackNavigation && (
                    <div
                      onClick={dismissModal}
                      data-tab-name={RewardCenterTabs.BACK}
                      className="RewardCenterTab BackNavigation"
                      style={{ width: '100%', height: '2.9em' }}
                    >
                      <img
                        alt=""
                        src={require(asset`images/Rewards_Center_Assets/Back_Arrow@3x.png`)}
                        style={{
                          width: '37%',
                          height: '100%',
                        }}
                      />
                    </div>
                  )}
                </div>
                <div style={{ width: showProfileTab ? '80%' : '60%' }} className="RewardCenterTabs">
                  <div
                    onClick={() => {
                      audio.onClick();
                      setPrevTab(activeTab);
                      setActiveTab(activeTabName);
                    }}
                    style={{
                      width: showProfileTab ? '33%' : '50%',
                      zIndex: 10,
                    }}
                    className={`RewardCenterTab${
                      activeTab === RewardCenterTabs.OFFERS ||
                      activeTab === RewardCenterTabs.HOME ||
                      activeTab === RewardCenterTabs.OFFER
                        ? ' Active'
                        : ''
                    }`}
                    data-tab-name={RewardCenterTabs.OFFERS}
                  >
                    <img
                      src={
                        activeTab === RewardCenterTabs.OFFERS ||
                        activeTab === RewardCenterTabs.HOME ||
                        activeTab === RewardCenterTabs.OFFER
                          ? require(asset`images/Rewards_Center_Assets/Offers_Selected@3x.png`)
                          : require(asset`images/Rewards_Center_Assets/Offers@3x.png`)
                      }
                      alt=""
                      style={{
                        width: '50%',
                        height: '100%',
                      }}
                    />
                  </div>
                  <div
                    style={{ width: showProfileTab ? '33%' : '50%', zIndex: 10 }}
                    onClick={() => {
                      audio.onClick();
                      setPrevTab(activeTab);
                      setActiveTab(RewardCenterTabs.HISTORY);
                    }}
                    className={`RewardCenterTab${
                      activeTab === RewardCenterTabs.HISTORY || activeTab === RewardCenterTabs.HISTORY_DETAIL
                        ? ' Active'
                        : ''
                    }`}
                    data-tab-name={RewardCenterTabs.HISTORY}
                  >
                    <img
                      src={
                        activeTab === RewardCenterTabs.HISTORY || activeTab === RewardCenterTabs.HISTORY_DETAIL
                          ? require(asset`images/Rewards_Center_Assets/MyHistory_Selected@3x.png`)
                          : require(asset`images/Rewards_Center_Assets/MyHistory@3x.png`)
                      }
                      alt=""
                      style={{
                        width: '70%',
                        height: '100%',
                      }}
                    />
                  </div>
                  {showProfileTab && (
                    <div
                      style={{ width: '33%', zIndex: 10 }}
                      onClick={() => {
                        console.log('setPrevTab', activeTab, 'setActiveTab', RewardCenterTabs.PROFILE);
                        audio.onClick();
                        setPrevTab(activeTab);
                        setActiveTab(RewardCenterTabs.PROFILE);
                      }}
                      className={`RewardCenterTab${activeTab === RewardCenterTabs.PROFILE ? ' Active' : ''}`}
                      data-tab-name={RewardCenterTabs.PROFILE}
                    >
                      <img
                        src={
                          activeTab === RewardCenterTabs.PROFILE
                            ? require(asset`images/Rewards_Center_Assets/MyProfile_Selected@3x.png`)
                            : require(asset`images/Rewards_Center_Assets/MyProfile@3x.png`)
                        }
                        alt=""
                        style={{
                          width: '62%',
                          height: '100%',
                        }}
                      />
                    </div>
                  )}
                </div>

                <div
                  style={{
                    width: hasFilters && activeTab === RewardCenterTabs.OFFERS ? '30%' : '0%',
                    marginTop: '0.8em',
                  }}
                >
                  {hasFilters && activeTab === RewardCenterTabs.OFFERS && (
                    <div
                      className={'RewardCenterCategoryPicker' + (this.state.shouldShowDropdown ? ' show' : ' hide')}
                      style={{ height: dropdownHeight + 'em', lineHeight: '2.5em' }}
                    >
                      <div
                        className="ControlWrapper"
                        style={{
                          backgroundImage: this.state.shouldShowDropdown
                            ? 'none'
                            : `url(${require(asset`Dropdown.png`)})`,
                          height: '2.8em',
                          pointerEvents: onboardingStep === 'select_categories' ? 'none' : 'auto',
                        }}
                        onClick={this.showDropdown}
                      >
                        <span className="activeFilterName">{activeFilterName}</span>
                      </div>

                      <div className={'filterList' + (this.state.shouldShowDropdown ? ' show' : ' hide')}>
                        {filterList.map(filter => {
                          return (
                            <div
                              data-key={`RewardCenterFilterItem-${filter.rc_id}`}
                              onClick={
                                this.props.isInTutorial
                                  ? null
                                  : e => {
                                      onFilterChange({ target: { value: filter.rc_id } });
                                      this.showDropdown(e);
                                    }
                              }
                              className="filterOption"
                            >
                              <ImageBW
                                source={require(asset`Shop_VIP_Divider_Glows@3x.png`)}
                                ibw={require(asset`Shop_VIP_Divider_Glows.ibw`)}
                                manualAssetScale={3}
                                style={{
                                  height: '0.2vmin',
                                  width: '100%',
                                  marginTop: '0.2vmin',
                                  display: parseInt(filter.rc_id) === parseInt(activeFilter) ? 'none' : 'block',
                                }}
                                innerStyle={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-evenly',
                                  alignItems: 'center',
                                }}
                              ></ImageBW>
                              <span
                                style={{
                                  paddingLeft: '15px',
                                  display: parseInt(filter.rc_id) === parseInt(activeFilter) ? 'none' : 'block',
                                }}
                              >
                                {filter.cat_name}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <img
                src={require(asset`images/Rewards_Center_Assets/Purple_Underline@3x.png`)}
                alt=""
                style={{
                  width: '100%',
                  height: 12,
                  position: 'absolute',
                  top: '6em',
                }}
              />
              <div
                style={{
                  width: '100%',
                  height: 12,
                  position: 'absolute',
                  top: '6em',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    width: '75%',
                    height: 12,
                  }}
                >
                  <img
                    className="indicator"
                    src={require(asset`images/Rewards_Center_Assets/Golden_Underline@3x.png`)}
                    alt=""
                    style={{
                      width:
                        showProfileTab && activeTab !== RewardCenterTabs.OFFERS
                          ? '25%'
                          : showProfileTab
                          ? '15%'
                          : '30%',
                      height: 12,
                      zIndex: 10,
                      alignSelf: 'center',
                      marginLeft: '1em',
                      opacity:
                        activeTab === RewardCenterTabs.OFFERS ||
                        activeTab === RewardCenterTabs.HOME ||
                        activeTab === RewardCenterTabs.OFFER
                          ? 1
                          : 0,
                    }}
                  />
                  <img
                    className="indicator"
                    src={require(asset`images/Rewards_Center_Assets/Golden_Underline@3x.png`)}
                    alt=""
                    style={{
                      width: showProfileTab ? '35%' : '40%',
                      height: 12,
                      zIndex: 10,
                      alignSelf: 'center',
                      marginLeft: '2.5em',
                      opacity:
                        activeTab === RewardCenterTabs.HISTORY || activeTab === RewardCenterTabs.HISTORY_DETAIL ? 1 : 0,
                    }}
                  />
                  {showProfileTab && (
                    <img
                      className="indicator"
                      src={require(asset`images/Rewards_Center_Assets/Golden_Underline@3x.png`)}
                      alt=""
                      style={{
                        width: showProfileTab ? '35%' : '40%',
                        height: 12,
                        zIndex: 10,
                        alignSelf: 'center',
                        marginLeft: 0,
                        opacity: activeTab === RewardCenterTabs.PROFILE ? 1 : 0,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          );
        }}
      />
    );
  }
}

