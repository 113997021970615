module.exports = {
  dimensions: {
    width: 72,
    height: 38
  },
  nine: {
    top: 2,
    left: 8,
    right: 8,
    bottom: 2
  },
  content: {
    top: 2,
    left: 8,
    right: 8,
    bottom: 2
  },
  optical: {
    top: 0,
    left: 3,
    right: 3,
    bottom: 0
  }
};