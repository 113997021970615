import React from 'react';
import { DiveSDK, EventBody } from '@divegames/dive-ts-web-sdk';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: {},
    };
  }

  componentDidMount() {
    if (ENVIRONMENT_VARIABLES.DIVE_ERROR_HANDLING === 'yes') {
      window.onunhandledrejection = function (data) {
        DiveSDK.getInstance().RecordEvent('frontendError', new EventBody().AddParam('data', JSON.stringify(data)));
      };
    }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  render() {
    const {
      hasError,
      error: { message },
    } = this.state;

    const { children } = this.props;

    if (!hasError) return children;

    const isDevelopmentMode = false;
    return isDevelopmentMode ? (
      <div>{message}</div>
    ) : (
      <div
        style={{
          background: `url(${require(asset`images/ErrorScreen/background.jpg`)})`,
          width: '100%',
          height: '100%',
          position: 'fixed',
          top: 0,
          right: 0,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          alt=""
          src={require(asset`images/ErrorScreen/message.png`)}
          style={{
            width: '80%',
            height: '80%',
            objectFit: 'contain',
          }}
        />
      </div>
    );
  }
}

export { ErrorBoundary };

