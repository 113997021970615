module.exports = {
  dimensions: {
    width: 41,
    height: 22
  },
  content: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  optical: {
    top: 1,
    left: 5,
    right: 5,
    bottom: 5
  }
};