import theme from 'shared/assets/style/theme';
export const styles = {
  header: {
    top: -20,
    width: '100%',
    height: '3.7rem',
  },
  headerImage: {
    height: '3.7rem',
    width: '100%',
    objectFit: 'contain',
    zIndex: 11,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  lightDividerBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: 30,
  },
  termsText: {
    paddingTop: 2,
    textAlign: 'center',
    color: theme.palette.primary[27],
    margin: 0,
  },
  headerTitleContainer: {
    display: 'flex',
    fontSize: '1.9rem',
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: '1px',
    padding: 0,
    margin: 0,
    justifyContent: 'center',
    lineHeight: '3.7rem',
    textShadow: '0px 1px 2px rgba(0, 0, 0, 0.5)',
  },
  inputRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  inputContainer: {
    width: '100%',
    margin: '10px 10px 0px 10px',
    '& input': {
      background: 'rgba(255, 255, 255, 0.1)',
      color: 'white',
      border: '1px solid rgba(255,255,255,0.1)',
    },
  },
  propertyIdContainer: {
    margin: '10px 10px 0px 10px',
    '& input': {
      background: 'rgba(255, 255, 255, 0.1)',
      color: 'white',
      border: '1px solid rgba(255,255,255,0.1)',
    },
  },
  rowLabel: {
    marginTop: 20,
    marginBottom: -30,
    marginLeft: 10,
  },
  backArrowContainer: {
    cursor: 'pointer',
    zIndex: 100,
    position: 'absolute',
    height: '2.3rem',
    width: '1.2rem',
    top: '1rem',
    left: '2rem',
  },
  termsModal: {
    margin: '20px',
    minHeight: '21rem',
  },
  otpConsentsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
  otpConsentsCheckbox: {
    width: 21,
    height: 20,
    marginRight: 5,
  },
  otpConsentsText: {
    paddingTop: 0,
    textAlign: 'center',
    color: theme.palette.primary[27],
    margin: 0,
    fontSize: 13,
  },
};

