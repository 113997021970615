export var ACTION_COMMANDS_EVENT_TYPE = {
  /* general events for our popup */
  DISPLAY_APP_MODAL: 'DISPLAY_APP_MODAL',
  DISMISS_APP_MODAL: 'DISMISS_APP_MODAL',
  ACCEPT_APP_MODAL: 'ACCEPT_APP_MODAL',

  /* notifications events for the native (iOS/Android) popup */
  DISPLAY_NATIVE_MODAL: 'DISPLAY_NATIVE_MODAL',
  DISMISS_NATIVE_MODAL: 'DISMISS_NATIVE_MODAL',
  ACCEPT_NATIVE_MODAL: 'ACCEPT_NATIVE_MODAL',

  /* link account events for the action commands */
  SIGN_IN_EMAIL_APP_MODAL: 'SIGN_IN_EMAIL_APP_MODAL',
  SIGN_IN_FACEBOOK_APP_MODAL: 'SIGN_IN_FACEBOOK_APP_MODAL',
  SIGN_IN_GOOGLE_APP_MODAL: 'SIGN_IN_GOOGLE_APP_MODAL',
  SIGN_IN_APPLE_APP_MODAL: 'SIGN_IN_APPLE_APP_MODAL',

  /* onboarding tutorial events for the action commands */
  SKIP_ONBOARDING_TUTORIAL: 'SKIP_ONBOARDING_TUTORIAL',
  COMPLETE_ONBOARDING_TUTORIAL: 'COMPLETE_ONBOARDING_TUTORIAL',

  /* game tutorial events for the action commands */
  SKIP_MISSIONS_TUTORIAL: 'SKIP_MISSIONS_TUTORIAL',
  COMPLETE_MISSIONS_TUTORIAL: 'COMPLETE_MISSIONS_TUTORIAL',

  /* tooltips events for the action commands */
  DISPLAY_APP_TOOLTIP: 'DISPLAY_APP_TOOLTIP',

  /** Events events */
  DISPLAY_EVENTS_TUTORIAL: 'DISPLAY_EVENTS_TUTORIAL',
  SKIP_EVENTS_TUTORIAL: 'SKIP_EVENTS_TUTORIAL'
};