import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { PRELOAD_MUSIC, CURRENTLY_PLAYING_MUSIC, CURRENTLY_PLAYING_SFX } from '../actions/types';
var initialState = {
  musicPlayers: [],
  currentlyPlayingMusic: '',
  currentlyPlayingSfx: ''
};

var audioReducer = function audioReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case PRELOAD_MUSIC:
      return _objectSpread(_objectSpread({}, state), {}, {
        musicPlayers: action.payload
      });

    case CURRENTLY_PLAYING_MUSIC:
      return _objectSpread(_objectSpread({}, state), {}, {
        currentlyPlayingMusic: action.payload
      });

    case CURRENTLY_PLAYING_SFX:
      return _objectSpread(_objectSpread({}, state), {}, {
        currentlyPlayingSfx: action.payload
      });

    default:
      return state;
  }
};

export default audioReducer;