import React from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';

const TermsAndConditions = ({ getListButton, termsAndConditions, privacyPolicy }) => {
  return (
    <>
      {getListButton(getText(TEXT_KEY.TERMS_AND_CONDITIONS), () => window.open(termsAndConditions))}
      {getListButton(getText(TEXT_KEY.PRIVACY_POLICY), () => window.open(privacyPolicy))}
    </>
  );
};

export default TermsAndConditions;

