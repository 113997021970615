import React, { useContext } from 'react';

import { Container, LogoAsset, DividerDiv, StoreLogoAsset, LinkAssetHolder } from './styledComponents';
import ThemeContext from 'shared/context/ThemeContext';
import SmartBanner from '../../../components/SmartBanner';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';

const AdmiralEsStoreDownload = () => {
  const { StoreDownload } = useContext(ThemeContext);
  return (
    <div>
      <SmartBanner style={{ position: 'unset' }} />
      <Container theme={StoreDownload.Container}>
        <LogoAsset src={require(asset`logo.png`)} alt="logo" />
        <p>¡Experimente la emoción de una nueva y emocionante experiencia de juego!</p>
        <DividerDiv />
        <a href={ENVIRONMENT_VARIABLES.APPLE_STORE_URL}>
          <StoreLogoAsset src={require(asset`images/TrackingUrl/badgeIOs@3x.png`)} alt="" />
        </a>
        <DividerDiv />
        <LinkAssetHolder href={ENVIRONMENT_VARIABLES.ANDROID_STORE_URL}>
          <StoreLogoAsset src={require(asset`images/TrackingUrl/badgeAndroid@3x.png`)} alt="" />
        </LinkAssetHolder>
      </Container>
    </div>
  );
};
export default AdmiralEsStoreDownload;

