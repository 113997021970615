export default async function preload(assetSource) {
  let cachedFile = new Image();

  return new Promise(resolve => {
    cachedFile.onload = () => {
      resolve('loaded');
    };
    cachedFile.onerror = () => {
      resolve('error');
    };
    cachedFile.src = assetSource;
  });
}
