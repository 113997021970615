import VideoAds from './VideoAds';
import { connect } from '../../node_modules/react-redux';
import { setVideosAdsInfo as _setVideosAdsInfo } from '../../state/actions/extras';

var mapStateToProps = function mapStateToProps(state) {
  return {
    // Add state from store
    coins: parseInt(state.coin.value)
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    setVideosAdsInfo: function setVideosAdsInfo(value) {
      dispatch(_setVideosAdsInfo(value));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoAds);