module.exports = {
  dimensions: {
    width: 17,
    height: 82
  },
  optical: {
    top: 5,
    left: 5,
    right: 5,
    bottom: 5
  },
  nine: {
    top: 40,
    left: 5,
    right: 6,
    bottom: 40
  }
};