import { connect } from 'react-redux';
import FormInputs from './FormInputs';

var mapStateToProps = function mapStateToProps(state) {
  return {
    copys: state.properties.data.textsFile,
    userProperty: state.properties.data.userProperty,
    propertySelector: state.properties.data.propertySelector
  };
};

export default connect(mapStateToProps)(FormInputs);