import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _featureFlagsStrategi;

import { FEATURE_FLAGS_PROVIDERS } from './Providers';
import diveFeatureFlagsProvider from './dive';
import alwaysTrueFeatureFlagsProvider from './alwaysTrue';
var featureFlagsStrategies = (_featureFlagsStrategi = {}, _defineProperty(_featureFlagsStrategi, FEATURE_FLAGS_PROVIDERS.DIVE_PROVIDER, diveFeatureFlagsProvider), _defineProperty(_featureFlagsStrategi, FEATURE_FLAGS_PROVIDERS.ALWAYS_TURE_PROVIDER, alwaysTrueFeatureFlagsProvider), _featureFlagsStrategi);

var FeatureFlagsProvider = function FeatureFlagsProvider(provider, playerId, handlerCallback) {
  return featureFlagsStrategies[provider](playerId, handlerCallback);
};

export default FeatureFlagsProvider;