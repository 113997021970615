import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { SET_IS_CONNECTED, SET_CONNECTION_TYPE, SAVE_DEVICE_INFO } from '../actions/types';
var initialState = {
  isConnected: true,
  connectionType: '',
  deviceInfo: {}
};
export var deviceReducer = function deviceReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_IS_CONNECTED:
      return _objectSpread(_objectSpread({}, state), {}, {
        isConnected: action.payload
      });

    case SET_CONNECTION_TYPE:
      return _objectSpread(_objectSpread({}, state), {}, {
        connectionType: action.payload
      });

    case SAVE_DEVICE_INFO:
      var deviceInfo = _objectSpread(_objectSpread({}, state.deviceInfo), {}, {
        deviceInfo: action.payload
      });

      return _objectSpread(_objectSpread({}, state), {}, {
        deviceInfo: deviceInfo
      });

    default:
      return state;
  }
};