import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _saveMetrics;

import { EVENT_TYPES } from '../../../../lib/analytics/constants';
import Logger from '../../../../lib/analytics/logger';
import ERROR_CODE from '../../../../utils/errorCodes';
import { HEADER, METRICS } from './constants';
import { validApiTracking, validDiveErrorHandling } from './validations';

var sendMetrics = function sendMetrics(event, body) {
  return Logger.sendEvent(event, body);
};

var requestSentMetric = function requestSentMetric(_ref) {
  var headers = _ref.headers,
      baseURL = _ref.baseURL,
      url = _ref.url;
  return validApiTracking(url) && sendMetrics(EVENT_TYPES.REQUEST_SENT, {
    requestId: headers[HEADER.REQUEST_ID],
    headers: JSON.stringify(headers),
    uri: baseURL + url
  });
};

var responseReceivedMetric = function responseReceivedMetric(_ref2) {
  var status = _ref2.status,
      data = _ref2.data,
      _ref2$config = _ref2.config,
      headers = _ref2$config.headers,
      url = _ref2$config.url;
  return validApiTracking(url) && sendMetrics(EVENT_TYPES.RESPONSE_RECEIVED, {
    requestId: headers[HEADER.REQUEST_ID],
    status: status,
    body: JSON.stringify(data)
  });
};

var restartSessionMetric = function restartSessionMetric(_ref3) {
  var headers = _ref3.config.headers,
      _ref3$request = _ref3.request,
      status = _ref3$request.status,
      response = _ref3$request.response;
  return sendMetrics(EVENT_TYPES.FRONTEND_ERROR, {
    requestId: headers[HEADER.REQUEST_ID],
    body: response,
    code: ERROR_CODE.REQUEST_HELPER_API_REQUEST_SET_RESTART_SESSION,
    status: status
  });
};

var requestCatchMetric = function requestCatchMetric(_ref4) {
  var headers = _ref4.config.headers,
      response = _ref4.request.response;
  return validDiveErrorHandling() && sendMetrics(EVENT_TYPES.FRONTEND_ERROR, {
    requestId: headers[HEADER.REQUEST_ID],
    headers: JSON.stringify(headers),
    error: response,
    code: ERROR_CODE.REQUEST_HELPER_API_REQUEST_CATCH
  });
};

export var saveMetrics = (_saveMetrics = {}, _defineProperty(_saveMetrics, METRICS.REQUEST_SENT, requestSentMetric), _defineProperty(_saveMetrics, METRICS.RESPONSE_RECEIVED, responseReceivedMetric), _defineProperty(_saveMetrics, METRICS.RESTART_SESSION, restartSessionMetric), _defineProperty(_saveMetrics, METRICS.REQUEST_CATCH, requestCatchMetric), _saveMetrics);