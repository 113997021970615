import Platform from './platform';
import Connection from './connection';
import { counterTypes } from './TimestampManager';
import { TEXT_KEY } from './localization/constants';
export var MessageTypes = {
  NONE: '',
  FIRST_NAME_ERROR: 'first_name_error',
  LAST_NAME_ERROR: 'last_name_error',
  EMAIL_ERROR: 'email_error',
  PASSWORD_ERROR: 'password_error',
  SUCCESS: 'success'
};
export default {
  isReactNative: function isReactNative() {
    return window.navigator.product === 'ReactNative';
  },
  getPlatform: function getPlatform() {
    return Platform.getPlatform();
  },
  addOnlineConnectionListener: function addOnlineConnectionListener(callback) {
    return Connection.addOnlineConnectionListener(callback);
  },
  getUA: function getUA() {
    return window.navigator.userAgent ? window.navigator.userAgent : 'ReactNative';
  },
  getInactivityTimeout: function getInactivityTimeout() {
    return 900000; // 15 minutes
  },
  quickObjectComparision: function quickObjectComparision(objectA, objectB) {
    return JSON.stringify(objectA) === JSON.stringify(objectB);
  },
  parseMarkdown: function parseMarkdown(str, reg, callback) {
    if (str.hasOwnProperty('key')) {
      return str;
    }

    return str.split(reg).map(function (s) {
      if (reg.test(s)) {
        return callback(s);
      }

      return s;
    });
  },
  isEmpty: function isEmpty(n) {
    return !(n ? typeof n === 'object' ? Array.isArray(n) ? !!n.length : !!Object.keys(n).length : true : false);
  },

  /**
   * Creates a string as hh:mm:ss for time remaining between two dates.
   * @param {Date} from Starting date.
   * @param {Date} until  End date.
   */
  buildTimeRemaining: function buildTimeRemaining(from, until, counterType, getText) {
    switch (counterType) {
      case counterTypes.MISSION_GRAND_CARD:
        return this.buildTimeRemainingBydays(from, until, getText, counterType);

      case counterTypes.EVENTS:
        return this.buildTimeRemainingBydays(from, until, getText, counterType);

      default:
        return this.buildTimeRemainingDefault(from, until, counterType, getText);
    }
  },
  buildTimeRemainingBydays: function buildTimeRemainingBydays(from, until, getText, counterType) {
    // get total seconds between the times
    var delta = Math.abs(until - from) / 1000; // calculate (and subtract) whole days

    var days = Math.floor(delta / 86400);
    delta -= days * 86400; // calculate (and subtract) whole hours

    var hrs = Math.floor(delta / 3600) % 24;
    delta -= hrs * 3600; // calculate (and subtract) whole minutes

    var mins = Math.floor(delta / 60) % 60;
    delta -= mins * 60;
    var isTimeInDanger = false;
    var timeString = '';
    var timeDangerZoneInDay = 1;

    if (days < timeDangerZoneInDay && counterType !== counterTypes.EVENTS) {
      isTimeInDanger = true;
    }

    if (days >= 0) {
      if (days < 10) {
        days = '0' + "".concat(days);
      }

      timeString += "".concat(days).concat(getText(TEXT_KEY.TIMER_LEFT_DAY), " ");
    }

    if (hrs >= 0) {
      if (hrs < 10) hrs = '0' + "".concat(hrs);
      if (hrs < 1) hrs = '01';
      timeString += "".concat(hrs).concat(getText(TEXT_KEY.TIMER_LEFT_HOUR), " ");
    }

    return {
      timeString: timeString,
      isTimeInDanger: isTimeInDanger
    };
  },
  buildTimeRemainingDefault: function buildTimeRemainingDefault(from, until, counterType, getText) {
    var msHours = 1000 * 60 * 60;
    var msMinutes = 1000 * 60;
    var msSeconds = 1000;
    var timeDangerZoneInHour = 1;
    var isTimeInDanger = false; // Difference in ms

    var ms = until - from;

    if (ms < 0) {
      return '';
    } // Extract hours


    var hrs = Math.floor(ms / msHours);
    ms -= hrs * msHours; // Extract minutes

    var mins = Math.floor(ms / msMinutes);
    ms -= mins * msMinutes; // Extract seconds

    var secs = Math.floor(ms / msSeconds);
    var timeString = '';

    if (counterType !== counterTypes.MISSION_CARD) {
      if (hrs >= 0) {
        if (hrs < 10) {
          hrs = '0' + "".concat(hrs);
        }

        timeString += "".concat(hrs, ":");
      }

      if (mins >= 0) {
        if (mins < 10) {
          mins = '0' + "".concat(mins);
        }

        timeString += "".concat(mins, ":");
      }

      if (secs >= 0) {
        if (secs < 10) {
          secs = '0' + "".concat(secs);
        }

        timeString += "".concat(secs);
      }
    } else {
      if (hrs < timeDangerZoneInHour) {
        isTimeInDanger = true;
      }

      if (hrs >= 0) {
        if (hrs < 10) {
          hrs = '0' + "".concat(hrs);
        }

        timeString += "".concat(hrs).concat(getText(TEXT_KEY.TIMER_LEFT_HOUR), " ");
      }

      if (mins >= 0) {
        if (mins < 10) {
          mins = '0' + "".concat(mins);
        }

        timeString += "".concat(mins).concat(getText(TEXT_KEY.TIMER_LEFT_MINUTE));
      }
    }

    return {
      timeString: timeString,
      isTimeInDanger: isTimeInDanger
    };
  },

  /**
   * Gets the active route / screen name from onNavigationStateChange, usually used in App.js.
   * Used for passing screen changes to analytic libraries.
   */
  getActiveRouteName: function getActiveRouteName(navigationState) {
    if (!navigationState) {
      return null;
    }

    var route = navigationState.routes[navigationState.index]; // dive into nested navigators

    if (route.routes) {
      return this.getActiveRouteName(route);
    }

    return route.routeName;
  },
  getTruncationObject: function getTruncationObject(value) {
    var truncationTypes = [{
      value: 1,
      postfix: ''
    }, {
      value: 1000,
      postfix: 'K'
    }, {
      value: 1000000,
      postfix: 'M'
    }, {
      value: 1000000000,
      postfix: 'B'
    }, {
      value: 1000000000000,
      postfix: 'T'
    }];
    var rval = 0;
    truncationTypes.forEach(function (truncationType) {
      if (value >= truncationType.value) {
        rval = truncationType;
      }
    });
    return rval;
  },
  truncateFormat: function truncateFormat(value) {
    var truncater = this.getTruncationObject(value);
    return value / truncater.value + truncater.postfix;
  },
  ieVersion: function ieVersion() {
    if (this.getPlatform() === 'web') {
      var ua = window.navigator.userAgent;
      if (ua.indexOf('Trident/7.0') > 0) return 11;
      if (ua.indexOf('Trident/6.0') > 0) return 10;
      if (ua.indexOf('Trident/5.0') > 0) return 9;
      return 0; // not IE9, 10 or 11
    }

    return 0;
  },
  shuffleArray: function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }

    return array;
  },
  getDeviceType: function getDeviceType() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent)) {
      return 'Mobile';
    }

    return 'Desktop';
  }
};