import React, { Component } from 'react';
import SFavouritesUnlocked from 'shared/screens/FavouritesUnlocked';
import ImageBW from '../../components/ImageBoundsWrapper';

export default class FavouritesUnlocked extends Component {
  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-favouritesUnlocked',
    };
  }

  render() {
    return (
      <SFavouritesUnlocked
        dismissModal={this.props.dismissModal}
        render={args => {
          const { buttonHandler } = args;

          return (
            <div
              style={{
                position: 'relative',
                width: '100%',
                height: '100%',
              }}
            >
              <div
                onClick={buttonHandler}
                style={{
                  position: 'absolute',
                  top: '-1.75rem',
                  right: '-2rem',
                  padding: 0,
                  height: '4rem',
                  width: '4rem',
                  zIndex: 9,
                  cursor: 'pointer',
                  pointerEvents: 'auto',
                }}
              >
                <ImageBW
                  source={require(asset`CloseButton@3x.png`)}
                  ibw={require(asset`CloseButton.ibw`)}
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                  resizeMode="contain"
                />
              </div>
              <img
                alt="Favourites unlocked"
                src={require(asset`images/Favorites_Info_Popup/Info_popup@3x.png`)}
                style={{ width: '100%', height: '100%' }}
              />
            </div>
          );
        }}
      />
    );
  }
}

FavouritesUnlocked.hideDefaultBackground = true;
FavouritesUnlocked.hideCloseButton = true;
