import { EVENT_TYPES } from '../constants';

export default {
  handleEvent: (eventType, payload) => {
    switch (eventType) {
      case EVENT_TYPES.VIEW:
        window.fbq('track', 'PageView');
        break;
      case EVENT_TYPES.REGISTRATION_COMPLETE:
        const { type } = payload;
        let value;
        switch (type) {
          case 'email':
            value = 1;
            break;
          case 'guest':
            value = 3;
            break;
          case 'facebook':
            value = 2;
            break;
          default:
            console.warn(`Unexpected type "${type}" passed to "${eventType}"`);
            break;
        }

        window.fbq('track', {
          status: true,
          currency: 'USD',
          value,
        });
        break;
      case EVENT_TYPES.PURCHASE_COMPLETE:
        window.fbq('track', 'Purchase', { value: payload.price, currency: 'USD' });
        break;
      default:
        break;
    }
  },
};
