import React from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import ImageBW from '../../../../components/ImageBoundsWrapper';

const HriLevelUpText = () => {
  const headerBackground = require(asset`Full_Modal_Header@3x.png`);

  return (
    <>
      <div style={{ position: 'absolute', width: '100%', top: 0, left: 0 }}>
        <div
          style={{
            position: 'absolute',
            top: '-1px',
            left: '-1px',
            height: '3.7rem',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            backgroundSize: 'calc(100% + 2px) 100%',
            backgroundImage: `url(${headerBackground})`,
          }}
        >
          <div style={{ marginBottom: -5 }} className="levelUpTitle">
            {getText(TEXT_KEY.LEVEL_UP_TEXT)}
          </div>
        </div>
      </div>
      <div style={{ height: '3.7rem' }} />
      <ImageBW
        source={require(asset`Shop_VIP_Divider_Glows.png`)}
        ibw={require(asset`Shop_VIP_Divider_Glows.ibw`)}
        style={{
          width: '100%',
          height: '2.5rem',
          objectFit: 'contain',
          position: 'absolute',
          top: '3.7rem',
        }}
      />
    </>
  );
};

export default HriLevelUpText;

