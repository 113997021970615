import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../api/services/rtkQuery/axiosBaseQuery';
import { postRestartSessionMutation } from './queries';
export var restartSessionApi = createApi({
  reducerPath: 'restartSessionQuery',
  baseQuery: axiosBaseQuery(),
  keepUnusedDataFor: 10,
  tagTypes: ['RestartSession'],
  endpoints: function endpoints(builder) {
    return {
      postRestartSession: builder.mutation(postRestartSessionMutation)
    };
  }
});
var usePostRestartSessionMutation = restartSessionApi.usePostRestartSessionMutation;
export { usePostRestartSessionMutation };
var postRestartSession = restartSessionApi.endpoints.postRestartSession,
    restartSessionReducerPath = restartSessionApi.reducerPath,
    restartSessionQueryReducer = restartSessionApi.reducer,
    restartSessionMiddleware = restartSessionApi.middleware;
export { restartSessionReducerPath, restartSessionQueryReducer, restartSessionMiddleware, postRestartSession };