import LinkAccount from './LinkAccount';
import { connect } from '../../node_modules/react-redux';

var mapStateToProps = function mapStateToProps(state) {
  return {
    memberLevel: state.playerInfo.memberLevel,
    isAccountLinked: state.playerInfo.isAccountLinked,
    assetsUrls: state.app.assetsUrls,
    cardLinkPopupImage: state.properties.data.cardLinkPopupImage,
    copys: state.properties.data.textsFile,
    linkedLoyaltyCardBonus: state.load.initAppResponse.SETTINGS.linkedLoyaltyCardBonus
  };
};

export default connect(mapStateToProps, null)(LinkAccount);