import store from '../state/store';
import { currentlyPlayingMusic, currentlyPlayingSfx } from '../state/actions/audio';
import utils from './index';
var initialState = {
  unplayedPlayer: {}
};
export default {
  setCurrentScreen: function setCurrentScreen(screenName) {
    this.currentScreen = screenName;
  },
  getCurrentScreen: function getCurrentScreen() {
    return this.currentScreen;
  },
  isInGame: function isInGame() {
    var _store$getState$gameI = store.getState().gameInfo,
        gameId = _store$getState$gameI.gameId,
        gameProvider = _store$getState$gameI.gameProvider;

    if (gameId !== '' && gameProvider !== '') {
      return true;
    }
  },
  isSoundEnabled: function isSoundEnabled() {
    var soundEffects = store.getState().playerInfo.soundEffects;

    if (soundEffects) {
      return true;
    }

    return false;
  },
  isMusicEnabled: function isMusicEnabled() {
    var music = store.getState().playerInfo.music;

    if (music) {
      return true;
    }

    return false;
  },
  getMusicByName: function getMusicByName(key) {
    var musicPlayers = store.getState().audio.musicPlayers;
    return musicPlayers[key];
  },
  getUnplayedPlayer: function getUnplayedPlayer() {
    var unplayedPlayers = store.getState().audio.unplayedPlayers;

    if (unplayedPlayers) {
      return this.getMusicByName(unplayedPlayers);
    }
  },
  IncreasePlayerVolume: function IncreasePlayerVolume(player, volume) {
    if (utils.getPlatform() === 'web') {
      player.volume += volume;
    } else {
      player.setVolume(player._volume + volume);
    }

    return player;
  },
  DecreasePlayerVolume: function DecreasePlayerVolume(player, volume) {
    if (utils.getPlatform() === 'web') {
      player.volume -= volume;
    } else {
      player.setVolume(player._volume - volume);
    }

    return player;
  },
  getPlayerVolume: function getPlayerVolume(player) {
    var volume = 0;
    if (player === undefined || player === '') return;

    if (utils.getPlatform() === 'web') {
      volume = player.volume;
    } else {
      volume = player._volume;
    }

    return volume;
  },
  fadeOut: function fadeOut(playerName) {
    var _this = this;

    var player = this.getMusicByName(playerName);
    var decreaseVolume = 0;
    var playerVolumeByDevice = this.getPlayerVolume(player);
    if (playerVolumeByDevice === undefined || playerVolumeByDevice <= 0) return;
    var fadeOutAudio = setInterval(function () {
      var volume = Number(_this.getPlayerVolume(player).toFixed(2));

      if (volume <= 1 && volume >= 0.1) {
        decreaseVolume = 0.1;

        _this.DecreasePlayerVolume(player, decreaseVolume);
      }

      if (volume <= 0.1) {
        clearInterval(fadeOutAudio);

        _this.resetPlayer(player);
      }
    }, 200);
    var timeOut = setTimeout(function () {
      clearInterval(fadeOutAudio);
      clearTimeout(timeOut);

      _this.resetPlayer(player);
    }, 2000);
  },
  fadeIn: function fadeIn(playerName) {
    var _this2 = this;

    if (playerName === 'backgroundMusic') {
      var player = this.getMusicByName(playerName);
      var increaseVolume = 0;
      var playerVolumeByDevice = this.getPlayerVolume(player);
      if (playerVolumeByDevice === undefined || playerVolumeByDevice === 1) return;
      var fadeInAudio = setInterval(function () {
        var volume = Number(_this2.getPlayerVolume(player).toFixed(2));

        if (volume < 0.9) {
          increaseVolume = 0.1;

          _this2.IncreasePlayerVolume(player, increaseVolume);
        }

        if (volume >= 0.9 && volume < 1) {
          increaseVolume = 1;

          if (utils.getPlatform() === 'web') {
            player.volume = increaseVolume;
          } else {
            player.setVolume(increaseVolume);
          }

          clearInterval(fadeInAudio);
        }
      }, 200);
      var timeOut = setTimeout(function () {
        clearInterval(fadeInAudio);
        clearTimeout(timeOut);
      }, 2000);
    }
  },
  setVolumeAndPlay: function setVolumeAndPlay(player, volume, playerName) {
    if (player === '' || player === undefined) return;

    if (utils.getPlatform() === 'web') {
      player.volume = volume;
    } else {
      player.setVolume(volume);
    }

    var isPlaying = false;

    try {
      isPlaying = player.play();
    } catch (e) {}

    this.fadeIn(playerName); // Storing unplayed audio to play it later

    if (isPlaying === undefined) return; // IE doesn't support WAV file

    if (utils.getPlatform() === 'web') {
      isPlaying.catch(function (err) {
        if (playerName === 'crowdsTalking' || playerName === 'backgroundMusic') {
          initialState.unplayedPlayer[playerName] = playerName;
        }
      });
    } else if (!isPlaying._playing) {
      if (playerName === 'crowdsTalking' || playerName === 'backgroundMusic') {
        initialState.unplayedPlayer[playerName] = playerName;
      }
    }
  },
  playMusic: function playMusic(musicName) {
    var force = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    var volume = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0.1;
    var musicPlayer = this.getMusicByName(musicName);

    if (musicPlayer) {
      store.dispatch(currentlyPlayingMusic(musicPlayer));
    }

    if (this.isMusicEnabled() && !this.isInGame()) {
      this.setVolumeAndPlay(musicPlayer, volume, musicName);
    } else {
      this.fadeOut(musicName);
    }
  },
  playSfx: function playSfx(sfxName) {
    var force = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    var volume = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
    var sfxPlayer = this.getMusicByName(sfxName);

    if (sfxPlayer && !force) {
      store.dispatch(currentlyPlayingSfx(sfxPlayer));

      if (this.isSoundEnabled() && !this.isInGame()) {
        this.setVolumeAndPlay(sfxPlayer, volume, sfxName);
      } else {
        this.resetPlayer(sfxPlayer);
      }
    }

    if (sfxPlayer && force) {
      store.dispatch(currentlyPlayingSfx(sfxPlayer));

      if (this.isSoundEnabled() || this.getCurrentScreen() === 'StartScreen') {
        this.setVolumeAndPlay(sfxPlayer, volume, sfxName);
      } else {
        this.resetPlayer(sfxPlayer);
      }
    } // Storing unplayed sfx to play it later


    if (!sfxPlayer && sfxName === 'crowdsTalking') {
      initialState.unplayedPlayer[sfxName] = sfxName;
    }
  },
  resetPlayer: function resetPlayer(player) {
    if (player === '' || player === undefined || utils.ieVersion() !== 0) return;

    if (utils.getPlatform() === 'web') {
      player.volume = 0.0;
      player.currentTime = 0;

      try {
        player.pause();
      } catch (e) {}
    } else if (player) {
      player.setVolume(0);

      try {
        player.stop();
      } catch (e) {}
    }
  },
  pauseMusic: function pauseMusic() {
    this.fadeOut('backgroundMusic');
  },
  pauseSfx: function pauseSfx() {
    var currentlyPlayingSfx = store.getState().audio.currentlyPlayingSfx;
    this.resetPlayer(currentlyPlayingSfx);
  },
  onClick: function onClick() {
    var sfxPlayer = this.getMusicByName('buttonClick');

    if (this.isSoundEnabled() || this.getCurrentScreen() === 'StartScreen') {
      try {
        sfxPlayer.play();
      } catch (e) {}
    } // Playing stored audio as soon as player interact with the application


    if (this.isSoundEnabled() && !this.isInGame() || this.getCurrentScreen() === 'StartScreen') {
      var players = Object.values(initialState.unplayedPlayer);

      if (players.length > 0) {
        for (var i = 0; i < players.length; i++) {
          if (players[i] !== '') {
            var player = this.getMusicByName(players[i]);

            if (player === 'backgroundMusic') {
              store.dispatch(currentlyPlayingMusic(player));
            } else {
              store.dispatch(currentlyPlayingSfx(player));
            }

            try {
              player.play();
            } catch (e) {}
          }
        }

        initialState.unplayedPlayer = {};
      }
    }
  },
  playCoinSfx: function playCoinSfx() {
    var timer = null;
    var coinDropLoop = this.getMusicByName('coinDropLoop');
    var coinDropTerminator = this.getMusicByName('coinDropTerminator');

    if (this.isSoundEnabled()) {
      try {
        coinDropLoop.play();
      } catch (e) {}

      timer = setTimeout(function () {
        try {
          coinDropTerminator.play();
        } catch (e) {}

        clearTimeout(timer);
      }, coinDropLoop.duration * 1000);
    }
  }
};