import TimestampManagerComponent from './TimestampManagerComponent';
import { connect } from '../../node_modules/react-redux';

var mapStateToProps = function mapStateToProps(state) {
  return {// Add state from store
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {// Add action from store
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TimestampManagerComponent);