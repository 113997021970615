import React, { useEffect } from 'react';
import { useSelector } from 'shared/node_modules/react-redux';
import SweepstakerInfo from './components/InfoScreen/EventsInfo';
import styled from 'styled-components';
import useEvents from 'shared/screens/Events/hooks/useEvents';
import EventsCard from './components/Cards';
import ScrollContainer from 'react-indiana-drag-scroll';
import QueryParamsProxy from 'shared/utils/queryParamProxy';
import Screens from 'shared/screens';

const Events = ({ navigation }) => {
  const { eventsFeatureState } = useEvents();
  const { events, assetsPackageId, texts } = eventsFeatureState;

  useEffect(() => {
    if (events.length === 0) QueryParamsProxy.navigate(navigation, Screens.Home);
  }, []);

  let eventsAssets = useSelector(state =>
    assetsPackageId ? state.assetsPackage.assets.EVENTS_FEATURE[assetsPackageId] : null
  );

  return (
    eventsAssets && (
      <Container>
        <SweepstakerInfo eventsAssets={eventsAssets} eventsTexts={texts} />
        <Background src={eventsAssets.EVENTS_BACKGROUND} />
        <EventsContainer vertical={false}>
          {events?.map(item => (
            <EventsCard object={item} eventsAssets={eventsAssets} texts={texts} />
          ))}
        </EventsContainer>
      </Container>
    )
  );
};

export default Events;

const Container = styled.div`
  height: 100%;
  align-items: center;
  width: 100vw;
`;

const Background = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
  width: 100%;
  height: 100%;
`;

const EventsContainer = styled(ScrollContainer)`
  margin-left: 5rem;
  position: relative;
  display: flex;
  flex-direction: row;
  height: 90vh;
  overflow: hidden;
  white-space: nowrap;
  padding-top: 12vh;
  padding-right: 25vh;
  gap: 7%;
  padding-left: 3rem;
  @media only screen and (min-height: 950px) and (max-height: 1100px) {
    gap: 15%;
  }
  @media only screen and (max-height: 800px) {
    padding-right: 35vh;
  }
`;

