import React, { Component } from 'react';
import SSneakPeekOnboarding from 'shared/screens/SneakPeekOnboarding';

export default class SneakPeekOnboarding extends Component {
  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-daily-spin-onboarding',
    };
  }

  render() {
    const _overlayStyle = {
      position: 'fixed',
      width: '100%',
      height: '100%',
      left: '0px',
      top: '0px',
      cursor: 'pointer',
      zIndex: 10,
    };
    return (
      <SSneakPeekOnboarding
        navigation={this.props.navigation}
        render={args => {
          const { clickHandler } = args;       
          return (
            <>
              <div onClick={clickHandler} style={_overlayStyle}></div>
              <div style={{ cursor: 'pointer'}} onClick={clickHandler}>
                <img
                  src={require(asset`images/SneakPeek/sneak_peek_onboarding@3x.png`)}
                  alt="SneakPeek Onboarding"
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              </div>
            </>
          );
        }}
      />
    );
  }
}

SneakPeekOnboarding.hideDefaultBackground = true;
SneakPeekOnboarding.hideCloseButton = true;
