import React from 'react';
import styled from 'styled-components';
import cdnPath from 'shared/utils/cdnPath';
import { EVENTS_CARD_TYPE } from 'shared/screens/Events/constants';

const GameImage = ({ item }) => {
  const gameIds = item?.activities[0]?.counter?.params?.gameIds ?? null;
  const cardType = item?.cardType ?? null;

  if (
    gameIds === null ||
    gameIds.length === 0 ||
    !(cardType === EVENTS_CARD_TYPE.SINGLE_GAME || cardType === EVENTS_CARD_TYPE.MULTIPLE_GAMES)
  )
    return <EmptyContainer />;

  return (
    <ImageContainer>
      {gameIds && gameIds.length === 1 ? (
        <Image src={`${cdnPath.SLOT_ICON_MOBILE}/${gameIds[0]}.png`} resizeMode="contain" />
      ) : gameIds.length === 2 ? (
        <>
          <ImageTop gameId={gameIds[0]} src={`${cdnPath.SLOT_ICON_MOBILE}/${gameIds[0]}.png`} resizeMode="contain" />
          <ImageBottom gameId={gameIds[1]} src={`${cdnPath.SLOT_ICON_MOBILE}/${gameIds[1]}.png`} resizeMode="contain" />
        </>
      ) : (
        <>
          <ImageLeft gameId={gameIds[0]} src={`${cdnPath.SLOT_ICON_MOBILE}/${gameIds[0]}.png`} resizeMode="contain" />
          <ImageMain gameId={gameIds[1]} src={`${cdnPath.SLOT_ICON_MOBILE}/${gameIds[1]}.png`} resizeMode="contain" />
          <ImageRight gameId={gameIds[2]} src={`${cdnPath.SLOT_ICON_MOBILE}/${gameIds[2]}.png`} resizeMode="contain" />
        </>
      )}
    </ImageContainer>
  );
};

export default GameImage;

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 95%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 2;
  display: flex;
  margin: 0 auto;
  margin-top: auto;
`;

const EmptyContainer = styled.div`
  height: 100%;
`;

const Image = styled.img`
  position: absolute;
  width: 100%;
`;

const ImageMain = styled.img`
  width: 85%;
  z-index: 3;
  display: ${({ gameId }) => (gameId ? 'block' : 'none')};
`;
const ImageLeft = styled.img`
  position: absolute;
  width: 60%;
  left: -20%;
  display: ${({ gameId }) => (gameId ? 'block' : 'none')};
`;
const ImageRight = styled.img`
  position: absolute;
  width: 60%;
  right: -20%;
  display: ${({ gameId }) => (gameId ? 'block' : 'none')};
`;
const ImageTop = styled.img`
  position: absolute;
  width: 65%;
  top: 8%;
  left: -5%;
  z-index: 3;
  display: ${({ gameId }) => (gameId ? 'block' : 'none')};
`;
const ImageBottom = styled.img`
  position: absolute;
  width: 65%;
  bottom: 14%;
  right: -5%;
  display: ${({ gameId }) => (gameId ? 'block' : 'none')};
`;

