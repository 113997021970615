import { INITIALIZE_REWARD_CENTER, SET_OFFER_HISTORY, ENTER_OFFER_VIEW, SET_REWARD_CENTER_CURR_TAB, SET_REWARD_CENTER_PREV_TAB, SET_OFFER_DETAIL, PURCHASE_OFFER, SET_ACTIVE_HISTORY_ITEM, CLEAR_REWARD_CENTER_DATA, SET_REWARD_CENTER_BASE_URL, IS_IN_OFFERS_VIEW } from './types';
export var initializeRewardCenter = function initializeRewardCenter(core) {
  return {
    type: INITIALIZE_REWARD_CENTER,
    payload: core
  };
};
export var setOfferHistory = function setOfferHistory(history) {
  return {
    type: SET_OFFER_HISTORY,
    payload: history
  };
};
export var enterOfferView = function enterOfferView(offerId) {
  return {
    type: ENTER_OFFER_VIEW,
    payload: offerId
  };
};
export var setRewardCenterCurrTab = function setRewardCenterCurrTab(currTab) {
  return {
    type: SET_REWARD_CENTER_CURR_TAB,
    payload: currTab
  };
};
export var setRewardCenterPrevTab = function setRewardCenterPrevTab(prevTab) {
  return {
    type: SET_REWARD_CENTER_PREV_TAB,
    payload: prevTab
  };
};
export var setOfferDetail = function setOfferDetail(offerDetail) {
  return {
    type: SET_OFFER_DETAIL,
    payload: offerDetail
  };
};
export var purchaseOffer = function purchaseOffer(offerId) {
  return {
    type: PURCHASE_OFFER,
    payload: offerId
  };
};
export var setActiveHistoryItem = function setActiveHistoryItem(offerPurchaseId) {
  return {
    type: SET_ACTIVE_HISTORY_ITEM,
    payload: offerPurchaseId
  };
};
export var clearRewardCenterData = function clearRewardCenterData() {
  return {
    type: CLEAR_REWARD_CENTER_DATA,
    payload: {}
  };
};
export var setRewardCenterBaseUrl = function setRewardCenterBaseUrl(value) {
  return {
    type: SET_REWARD_CENTER_BASE_URL,
    payload: value
  };
};
export var isInOffersView = function isInOffersView(value) {
  return {
    type: IS_IN_OFFERS_VIEW,
    payload: value
  };
};