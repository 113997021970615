module.exports = {
  dimensions: {
    width: 68,
    height: 42
  },
  optical: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  content: {
    top: 0,
    left: 4,
    right: 0,
    bottom: 2
  }
};