import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../api/services/rtkQuery/axiosBaseQuery';
import { postLoginMutation, postSSOLoginMutation, postGuestLoginMutation } from './queries';
export var loginApi = createApi({
  reducerPath: 'loginQuery',
  baseQuery: axiosBaseQuery(),
  keepUnusedDataFor: 10,
  tagTypes: ['Login'],
  endpoints: function endpoints(builder) {
    return {
      postLogin: builder.mutation(postLoginMutation),
      postSSOLogin: builder.mutation(postSSOLoginMutation),
      postGuestLogin: builder.mutation(postGuestLoginMutation)
    };
  }
});
var usePostLoginMutation = loginApi.usePostLoginMutation,
    usePostSSOLoginMutation = loginApi.usePostSSOLoginMutation,
    usePostGuestLoginMutation = loginApi.usePostGuestLoginMutation;
export { usePostLoginMutation, usePostSSOLoginMutation, usePostGuestLoginMutation };
var _loginApi$endpoints = loginApi.endpoints,
    postLogin = _loginApi$endpoints.postLogin,
    postGuestLogin = _loginApi$endpoints.postGuestLogin,
    postSSOLogin = _loginApi$endpoints.postSSOLogin,
    loginReducerPath = loginApi.reducerPath,
    loginQueryReducer = loginApi.reducer,
    loginMiddleware = loginApi.middleware;
export { loginReducerPath, loginQueryReducer, loginMiddleware, postLogin, postGuestLogin, postSSOLogin };