var PushNotification = {
  _notificationsEnabled: false,

  get notificationsEnabled() {
    return this._notificationsEnabled;
  },

  set notificationsEnabled(value) {
    this._notificationsEnabled = value;
  },

  /**
   * The platform should override the scheduleNotification method so that shared flows may
   * re-use functionality and send the same data.
   */
  scheduleNotification: function scheduleNotification(payload) {},
  scheduleMissYouNotification: function scheduleMissYouNotification(payload) {},
  scheduleMissionReminderNotification: function scheduleMissionReminderNotification(payload) {},
  scheduleTimedBonusNotification: function scheduleTimedBonusNotification(payload) {},
  checkPermissions: function checkPermissions() {},
  cancelInvalidNotifications: function cancelInvalidNotifications(payload) {},
  cancelMissionReminderNotification: function cancelMissionReminderNotification(payload) {},
  popInitialNotification: function popInitialNotification(payload) {}
};
export default PushNotification;