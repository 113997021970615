import React, { Component } from 'react';
import SLinkAccount from 'shared/screens/LinkAccount';
import SSOLinksInterface from 'shared/api/SSOLinksInterface';
import { SSOLinksResponseKeys } from 'shared/api/Constants';
import './LinkAccount.scss';
import ImageBW from '../../components/ImageBoundsWrapper';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import ThemeContext from 'shared/context/ThemeContext';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { AUTH_TYPES } from 'shared/utils/authFlow/constants';
import { numberWithCommas } from 'shared/utils/number';
import store from 'shared/state/store';
import Button from '../../components/Button/Button';

export default class DefaultLinkAccount extends Component {
  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-link-account',
      hideBeforeSelector: ENVIRONMENT_VARIABLES.APPLICATION_TARGET === 'hri',
    };
  }

  handleLinkAccountClick = parentComponentMethod => async () => {
    if (ENVIRONMENT_VARIABLES.SSO_ENABLED.toString() !== 'true') {
      parentComponentMethod();
      return false;
    }
    const ssoLinks = await SSOLinksInterface.getSSOLinks();

    store.dispatch(this.props.setAuthCallbackIntent(AUTH_TYPES.LINK));
    let destinationUrl = ssoLinks[SSOLinksResponseKeys.LOGIN_URL];

    if (ENVIRONMENT_VARIABLES.SSO_DEV && ENVIRONMENT_VARIABLES.SSO_DEV.toString() === 'true') {
      destinationUrl = destinationUrl.replace(
        new RegExp(`(redirect_uri=)(.*)(/${ENVIRONMENT_VARIABLES.SSO_CALLBACK_PATH})`),
        `$1${document.location.origin}$3`
      );
    }
    document.location = destinationUrl;
  };

  render() {
    const themeContext = this.context;

    return (
      <SLinkAccount
        navigation={this.props.navigation}
        {...this.props}
        render={args => {
          const { linkAccount, handleLinkAccountForm, linkedLoyaltyCardBonus } = args;

          return (
            <div style={themeContext.LinkAccount.LinkAccountContainer}>
              <span style={themeContext.LinkAccount.LinkAccountTitle}>{linkAccount.linkAccountTitle}</span>
              <img
                style={themeContext.LinkAccount.LightDividerUp}
                src={require(asset`Light_Divider@3x.png`)}
                alt="Light Divider"
              />
              <div style={themeContext.LinkAccount.AvailableCards}>
                <ImageBW
                  source={linkAccount.icon ? linkAccount.icon : require(asset`Points_Cards.png`)}
                  ibw={require(asset`CloseButton.ibw`)}
                  manualAssetScale={3}
                  style={{
                    objectFit: 'contain',
                    height: '100%',
                    width: '100%',
                  }}
                  innerStyle={{
                    cursor: 'pointer',
                  }}
                  alt="Close"
                />
              </div>
              <img
                style={themeContext.LinkAccount.LightDividerDown}
                src={require(asset`Light_Divider@3x.png`)}
                alt="Light Divider"
              />
              <p style={{ width: '100%', textAlign: 'center', marginBottom: '14px', fontWeight: 'bold' }}>
                {getText(TEXT_KEY.LINK_YOUR_ACCOUNT_TODAY_AND_EARN)} {numberWithCommas(linkedLoyaltyCardBonus.amount)}{' '}
                {linkedLoyaltyCardBonus.currency} {getText(TEXT_KEY.FREE_COINS_AND_BENEFITS)}
              </p>
              <ul
                style={{
                  ...themeContext.LinkAccount.Description,
                }}
              >
                {linkAccount.description.map(element => (
                  <li>{element}</li>
                ))}
              </ul>
              <Button
                imageSource={require(asset`ClickHere_Button@3x.png`)}
                onClick={this.handleLinkAccountClick(handleLinkAccountForm)}
                containerStyle={themeContext.LinkAccount.LinkAccountButton}
                objectFit={'contain'}
                imageStyle={{ width: '25%' }}
              />
            </div>
          );
        }}
      />
    );
  }
}

DefaultLinkAccount.hideOverlay = true;
DefaultLinkAccount.contextType = ThemeContext;

