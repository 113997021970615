import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../api/services/rtkQuery/axiosBaseQuery';
import { postRewardCenterMutation } from './queries';
export var rewardCenterApi = createApi({
  reducerPath: 'rewardCenterQuery',
  baseQuery: axiosBaseQuery(),
  keepUnusedDataFor: 60,
  tagTypes: ['RewardCenter'],
  endpoints: function endpoints(builder) {
    return {
      postRewardCenter: builder.mutation(postRewardCenterMutation)
    };
  }
});
var usePostRewardCenterMutation = rewardCenterApi.usePostRewardCenterMutation;
export { usePostRewardCenterMutation };
var postRewardCenter = rewardCenterApi.endpoints.postRewardCenter,
    rewardCenterReducerPath = rewardCenterApi.reducerPath,
    rewardCenterQueryReducer = rewardCenterApi.reducer,
    rewardCenterMiddleware = rewardCenterApi.middleware;
export { rewardCenterReducerPath, rewardCenterQueryReducer, rewardCenterMiddleware, postRewardCenter };