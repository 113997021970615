import React, { useContext } from 'react';
import {
  BottomBar,
  BottomBarFirstLine,
  BottomBarSecondContainer,
  BottomBarSecondLine,
  BottomBarThirdLine,
  FooterText,
} from '../../styledComponents';
import ThemeContext from 'shared/context/ThemeContext';
import { TEXT_KEY, getText } from 'shared/utils/localization';

const DefaultBottomBar = ({ onAccountActivate, withGuest, onGuestActivate, isGuestLoginButton }) => {
  const { Start } = useContext(ThemeContext);
  return (
    <BottomBar bgImage={require(asset`images/Daily_Spin/DailyBonus_Prizes_BottomBar.png`)}>
      <BottomBarFirstLine>{getText(TEXT_KEY.ALREADY_HAVE_ACCOUNT)}</BottomBarFirstLine>
      <BottomBarSecondContainer>
        <div onClick={onAccountActivate}>
          <BottomBarSecondLine>{getText(TEXT_KEY.LOG_IN_HERE)}</BottomBarSecondLine>
        </div>
        {withGuest && !isGuestLoginButton && (
          <BottomBarThirdLine qa-id="playAsGuestBtn" onClick={onGuestActivate}>
            {getText(TEXT_KEY.PLAY_AS_GUEST)}
          </BottomBarThirdLine>
        )}
        {getText(TEXT_KEY.FOOTER_TEXT) !== '' && (
          <FooterText theme={Start.BottomBarFooterText}>{getText(TEXT_KEY.FOOTER_TEXT)}</FooterText>
        )}
      </BottomBarSecondContainer>
    </BottomBar>
  );
};

export default DefaultBottomBar;

