import generateRender from 'shared/utils/generateRender';
import DefaultLevelUpText from './DefaultLevelUpText';
import HriLevelUpText from './HriLevelUpText';

const LevelUpText = generateRender({
  default: DefaultLevelUpText,
  hri: HriLevelUpText,
});

export default LevelUpText;
