import React, { useContext } from 'react';
import ThemeContext from 'shared/context/ThemeContext';
import store from 'shared/state/store';
import Utils from 'shared/utils/index';
import { setRewardCenterCurrTab, setRewardCenterPrevTab } from 'shared/state/actions/rewardCenter';
import { RewardCenterTabs } from 'shared/screens/RewardCenter/Constants';
import { rewardOffersCoinSizeCalc } from 'shared/utils/fontSizeCalculator';
import {
  BorderImageReward,
  CoinsIcon,
  CoinsText,
  Icon,
  LoyaltyIcon,
  LoyaltyPointsText,
  OfferCategoryName,
  OfferCost,
  OfferName,
  RewardCardContainer,
  RewardCardImage,
  RewardContentContainer,
  RewardHoverOverlay,
  RewardImage,
  RewardImagePlaceholder,
  RewardImagePlaceholderContainer,
  RewardItemContainer,
  RewardThumbnailContainer,
} from './styledComponents';

const RewardListItem = props => {
  const { item, defaultOfferThumbnail, rewardBaseUrl, onClickHandler } = props;
  const { RewardListItem } = useContext(ThemeContext);

  const rewardBorder = item.rid
    ? require(asset`images/Rewards_Center_Assets/Item_Frames/Item_Frame@3x.png`)
    : require(asset`images/Rewards_Center_Assets/Item_Frames/Rewards_Center_Frame@3x.png`);

  const placeholderBackground = rewardBaseUrl ? `url(${rewardBaseUrl + item.title_image})` : `url("${item.thumb}")`;

  const setTabs = () => {
    store.dispatch(setRewardCenterCurrTab(RewardCenterTabs.OFFERS));
    store.dispatch(setRewardCenterPrevTab(RewardCenterTabs.HOME));
  };

  const onItemClick = () => {
    onClickHandler();
    !item.rid && setTabs();
  };

  const calculatedStyles = rewardOffersCoinSizeCalc(item.rname);

  return (
    <RewardItemContainer onClick={onItemClick}>
      <RewardThumbnailContainer>
        <RewardContentContainer>
          <RewardCardContainer theme={RewardListItem.RewardCardContainer}>
            <RewardCardImage theme={RewardListItem.RewardCardImage}>
              <RewardImage>
                <RewardImagePlaceholderContainer
                  theme={RewardListItem.RewardImagePlaceholderContainer}
                  backgroundUrl={defaultOfferThumbnail}
                />
                <RewardImagePlaceholder
                  theme={RewardListItem.RewardImagePlaceholderContainer}
                  backgroundUrl={placeholderBackground}
                >
                  <RewardHoverOverlay theme={RewardListItem.RewardHoverOverlay} />
                </RewardImagePlaceholder>
              </RewardImage>
              <BorderImageReward borderImg={rewardBorder} />
            </RewardCardImage>

            {item.rid ? (
              <>
                <OfferCost theme={RewardListItem.OfferCost}>
                  <LoyaltyIcon src={Icon.loyalty} />
                  <LoyaltyPointsText theme={RewardListItem.LoyaltyPointsText}>{item.lpts}</LoyaltyPointsText>
                </OfferCost>
                <OfferName top={calculatedStyles.top} theme={RewardListItem.OfferName}>
                  <CoinsIcon size={calculatedStyles.coinSize} src={Icon.coins} />
                  <CoinsText size={calculatedStyles.fontSize} theme={RewardListItem.OfferNameText}>
                    {item.rname}
                  </CoinsText>
                </OfferName>
              </>
            ) : (
              <OfferCategoryName theme={RewardListItem.OfferCategoryName} ieVersion={Utils.ieVersion()}>
                {item.cat_name}
              </OfferCategoryName>
            )}
          </RewardCardContainer>
        </RewardContentContainer>
      </RewardThumbnailContainer>
    </RewardItemContainer>
  );
};

export default RewardListItem;

