module.exports = {
  dimensions: {
    width: 31,
    height: 47
  },
  optical: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
};