import UpdateTerms from './UpdateTerms';
import { connect } from '../../node_modules/react-redux';
import { setUpdatedTermsState as _setUpdatedTermsState } from '../../state/slices/properties/properties';

var mapStateToProps = function mapStateToProps(state) {
  return {
    UpdateTerms: state.properties.data.isTermsUpdated,
    termsAndConditionsUrl: state.app.assetsUrls ? state.app.assetsUrls.termsAndConditions : '',
    privacyPolicyUrl: state.app.assetsUrls ? state.app.assetsUrls.privacyPolicy : '',
    isAccountLinked: state.playerInfo.isAccountLinked,
    userProperty: state.properties.data.userProperty,
    propertySelector: state.properties.data.propertySelector
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    setUpdatedTermsState: function setUpdatedTermsState(state) {
      dispatch(_setUpdatedTermsState(state));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateTerms);