import React, { useContext } from 'react';
import ThemeContext from 'shared/context/ThemeContext';

const Header = () => {
  const { MessagesHeader } = useContext(ThemeContext);

  return (
    <div style={{ position: 'absolute', width: '100%' }}>
      <div style={MessagesHeader.Image} />
    </div>
  );
};

export default Header;

