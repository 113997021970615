import React, { useCallback, useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import useAnimationTimerHandler from './hooks/useAnimationTimerHandler';
import useFeatureFlags from 'shared/hooks/useFeatureFlags';
import { FE_ANIMATION } from 'shared/utils/featureFlags/constants';
import { ANIMATION } from 'shared/components/LottieAnimation/constants';

const LottieAnimation = props => {
  const {
    animationType,
    animationStyle = {},
    autoplay = false,
    speed = 1,
    startDelay = 0,
    loop = 1000,
    loopDelay = 0,
    aspectRatioSetting = null,
    needToReset,
    onAnimationRunning = () => {},
    manualIsStopped = false,
    shouldScaleY = false,
    heightToScale,
  } = props;

  const [scaleValue, setScaleValue] = useState(1);
  const { isStopped, checkToRepeatAnimation } = useAnimationTimerHandler({ startDelay, needToReset, loop, loopDelay });
  const originalAnimationWidth = ANIMATION[animationType]?.w;
  const originalAnimationHeight = ANIMATION[animationType]?.h;

  const defaultOptions = {
    loop: 0,
    autoplay,
    animationData: ANIMATION[animationType],
    rendererSettings: {
      preserveAspectRatio: aspectRatioSetting || 'xMidYMid slice',
    },
  };

  const getScaleValue = useCallback(() => {
    if (heightToScale > 0) {
      const actualAnimationHeight = (originalAnimationHeight / originalAnimationWidth) * animationStyle.width;
      const scaleValue = heightToScale / actualAnimationHeight;
      setScaleValue(scaleValue);
    }
  }, [heightToScale, shouldScaleY]);

  useEffect(() => {
    if (shouldScaleY && heightToScale > 0) {
      getScaleValue();
      window.addEventListener('resize', getScaleValue);
    }
    return () => window.removeEventListener('resize', getScaleValue);
  }, [getScaleValue]);

  const { hasFeature } = useFeatureFlags();

  return (
    <>
      {hasFeature(FE_ANIMATION) && (
        <Lottie
          options={defaultOptions}
          isStopped={manualIsStopped ? manualIsStopped : isStopped}
          speed={speed}
          eventListeners={[
            {
              eventName: 'complete',
              callback: checkToRepeatAnimation(),
            },
            {
              eventName: 'enterFrame',
              callback: animationStatus => onAnimationRunning(animationStatus),
            },
          ]}
          style={{
            pointerEvents: 'none',
            position: 'absolute',
            height: '9rem',
            width: '100%',
            transform: shouldScaleY
              ? `scaleY(${scaleValue}) translateY(${
                  ((originalAnimationHeight / originalAnimationWidth) * animationStyle.width -
                    (originalAnimationHeight / originalAnimationWidth) * animationStyle.width * scaleValue) /
                  2
                }px)`
              : ``,
            ...animationStyle,
          }}
        />
      )}
    </>
  );
};

export default LottieAnimation;

