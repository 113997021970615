export const styles = {
  modal: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  submitBtn: {
    backgroundImage: `url(${require(asset`RoundedRect_Secondary@2x.png`)}`,
    cursor: 'pointer',
    fontSize: '1.7rem',
    fontWeight: '600',
    width: 181,
    height: 59,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cancelBtn: {
    backgroundImage: `url(${require(asset`Orange_Button@2x.png`)}`,
    cursor: 'pointer',
    fontSize: '1.7rem',
    fontWeight: '600',
    width: 181,
    height: 59,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputContainer: {
    flex: 1,
    '& input': {
      background: 'rgba(255, 255, 255, 0.1)',
      color: 'white',
      border: '1px solid rgba(255,255,255,0.1)',
    },
  },
  bodyContainer: {
    maxHeight: '70vh',
    width: '90%',
    maxWidth: '800px',
  },
  modalBodyStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: '0 2vmin',
  },
};

