import { createSlice } from '@reduxjs/toolkit';
var sliceName = 'LOGIN_PROGRESS';
var initialState = {
  facebookLoginInProgress: false
};
var loginProgressInfoSlice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    setFacebookLoginProgress: function setFacebookLoginProgress(state, action) {
      state.facebookLoginInProgress = action.payload;
    }
  }
});
var actions = loginProgressInfoSlice.actions,
    reducer = loginProgressInfoSlice.reducer;
var setFacebookLoginProgress = actions.setFacebookLoginProgress;
export { setFacebookLoginProgress };
export default reducer;