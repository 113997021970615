import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

export default class CustomScrollBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
    };
  }

  onScroll = () => {
    this.setState({ width: 0.5 });
  };

  render() {
    const { customHeight = '460px', children } = this.props;
    return (
      <Scrollbars
        ref="scrollbars"
        hideTracksWhenNotNeeded
        style={{ height: `calc(100vh - ${customHeight})`, marginRight: '-2px' }}
        onScroll={this.onScroll}
        renderThumbVertical={props => (
          <div {...props} className="thumb-vertical" style={{ width: this.state.width + 'rem' }} />
        )}
        renderTrackVertical={props => (
          <div {...props} className="track-vertical" style={{ opacity: this.state.width > 0 ? 1 : 0, right: 0 }} />
        )}
        renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: 'none' }} />}
        renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: 'none' }} />}
      >
        {children}
      </Scrollbars>
    );
  }
}

