import React, { Component } from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import SFavouritesExpired from 'shared/screens/FavouritesExpired';

export default class FavouritesExpired extends Component {
  state = {};

  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-favouritesExpired',
    };
  }

  render() {
    return (
      <SFavouritesExpired
        navigation={this.props.navigation}
        dismissModal={this.props.dismissModal}
        render={args => {
          const { message, buttonHandler } = args;

          return (
            <div>
              <div className="header">
                <h1>{getText(TEXT_KEY.FAVOURITES_GAMES_EXPIRED)}</h1>
              </div>
              <div className="messageContainer">
                <p className="message">{message}</p>
              </div>
              <div className="buttonContainer">
                <div className="button" onClick={buttonHandler}>
                  <img className="buttonImage" src={require(asset`RoundedRect_Secondary.png`)} alt="Go to VIP" />
                  <span className="buttonLabel">{getText(TEXT_KEY.GO_TO_VIP)}</span>
                </div>
              </div>
            </div>
          );
        }}
      />
    );
  }
}
