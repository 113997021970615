import React, { Component } from 'react';
import SLimitedTimeOffer from 'shared/screens/LimitedTimeOffer';
import responsiveFontSize from '../../lib/responsiveFontSize';
import theme from 'shared/assets/style/theme';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import ThemeContext from 'shared/context/ThemeContext';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import Button from '../../components/Button/Button';
import { isStar } from 'shared/utils/targetApplication';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';

const gradientText = (extraBonus, _fontSize) => {
  return (
    <span
      className={`coinAmountGradientText ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}
      style={{
        textShadow: `2px 2px 4px ${theme.palette.common[1]}`,
        fontWeight: 600,
        alignSelf: 'center',
        fontSize: _fontSize,
      }}
      data-label={extraBonus}
    >
      {extraBonus}
    </span>
  );
};

export default class LimitedTimeOffer extends Component {
  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-lto',
    };
  }

  render() {
    const themeContext = this.context;

    return (
      <SLimitedTimeOffer
        navigation={this.props.navigation}
        {...this.props}
        render={args => {
          const { limitedTimeOfferData, purchaseHandler } = args;
          const price = limitedTimeOfferData
            ? `${getText(TEXT_KEY.ONLY)} ${getText(TEXT_KEY.CURRENCY_SIGN)} ${limitedTimeOfferData.PRICE}`
            : 0;
          return (
            <>
              <div className={'ModalBackground'} style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                  src={require(asset`images/LTO_Promo_Image/LTO_Promo_Image@3x.png`)}
                  alt="Limited time offer dialog"
                  style={{ height: '100%' }}
                />
              </div>
              {limitedTimeOfferData && (
                <div style={themeContext.LimitedTimeOffer.LTOWrapper}>
                  <div style={themeContext.LimitedTimeOffer.MainContainer}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '38%' }}>
                      <img
                        src={require(asset`Coin_Icon@3x.png`)}
                        className="coinIcon"
                        alt="Coin Icon"
                        style={themeContext.LimitedTimeOffer.CoinIcon}
                      />
                      <span style={{ fontSize: 'calc(14px + 20 * ((100vw - 800px) / 800))' }}>
                        {limitedTimeOfferData.AMOUNT}
                      </span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '38%' }}>
                      <span
                        style={{
                          marginRight: '1vmin',
                          fontSize: responsiveFontSize(14, 34),
                        }}
                      >
                        {getText(TEXT_KEY.PLUS_SYMBOL)}
                      </span>
                      <img
                        src={require(asset`Star_Icon@3x.png`)}
                        className="loyaltyIcon"
                        alt="Loyalty Icon"
                        style={themeContext.LimitedTimeOffer.StarIcon}
                      />
                      <span style={{ fontSize: 'calc(14px + 20 * ((100vw - 800px) / 800))' }}>
                        {limitedTimeOfferData.BONUS_LOYALTY_AMOUNT}
                      </span>
                    </div>
                  </div>
                  <div className="bannerContainer" style={themeContext.LimitedTimeOffer.BannerContainer}>
                    <span style={{ paddingLeft: '1vmin', fontSize: 'calc(14px + 15 * ((100vw - 800px) / 800))' }}>
                      {price}
                    </span>
                  </div>
                </div>
              )}
              <div
                className={'buttonContainer'}
                style={themeContext.LimitedTimeOffer.ButtonContainer}
                onClick={purchaseHandler}
              >
                <div style={themeContext.LimitedTimeOffer.BuyButtonContainer}>
                  <Button
                    textStroke={BUTTON_STROKES.GREEN}
                    label={getText(TEXT_KEY.BUY)}
                    imageSource={
                      isStar ? require(asset`RoundedRect_Secondary@3x.png`) : require(asset`Pink_Button.png`)
                    }
                    onClick={purchaseHandler}
                    containerStyle={themeContext.LimitedTimeOffer.BuyButton}
                    imageStyle={themeContext.LimitedTimeOffer.BuyButtonImageStyle}
                    textStyle={themeContext.LimitedTimeOffer.BuyButtonTextStyle}
                    isDebounce={true}
                  />
                </div>
              </div>
            </>
          );
        }}
      />
    );
  }
}

LimitedTimeOffer.contextType = ThemeContext;
LimitedTimeOffer.hideDefaultBackground = true;

