import { useEffect, useCallback, useState, useRef } from 'react';
export default function useAnimationTimerHandler({ startDelay, needToReset, loop, loopDelay }) {
  const [isStopped, setIsStopped] = useState(true);

  const setLoopTimer = useRef(null);
  const setStartAnimationTimer = useRef(null);
  const setAttemptRef = useRef(1);

  const checkToRepeatAnimation = () => () => {
    setIsStopped(true);
    if (loopDelay >= 0 && setAttemptRef.current < loop) {
      setAttemptRef.current++;
      clearTimeout(setLoopTimer.current);
      setLoopTimer.current = setTimeout(() => {
        setIsStopped(false);
      }, loopDelay);
    }
  };

  const startAnimationHandler = useCallback(() => {
    setAttemptRef.current = 1;
    if (startDelay >= 0) {
      clearTimeout(setStartAnimationTimer.current);
      setStartAnimationTimer.current = setTimeout(() => {
        setIsStopped(false);
      }, startDelay);
    }
  }, [startDelay]);

  useEffect(() => {
    startAnimationHandler();
  }, [startAnimationHandler]);

  useEffect(() => {
    if (needToReset) {
      setIsStopped(true);
      clearTimeout(setLoopTimer.current);
      startAnimationHandler();
    }
  }, [needToReset, startAnimationHandler]);

  return {
    isStopped,
    checkToRepeatAnimation,
  };
}

