import React, { useEffect, useState } from 'react';
import useMissionsTutorial from 'shared/hooks/useMissionsTutorial';
import { MissionTutorialStepsKeys, tutorialPointerEvents, TUTORIAL_NAME } from 'shared/utils/tutorial';
import styled from 'styled-components';

const skipAsset = require(asset`images/FTUE/skip_button.png`);

const MissionsTutorialLayer = props => {
  const { tutorial, missionsAssets } = props;
  const { activeTutorial, tutorialStep, tutorialName } = tutorial;
  const { skipTutorial, handleFeedForwardTutorial } = useMissionsTutorial(props);

  const {
    IMAGE,
    TOOLTIP,
    TOP = null,
    BOTTOM = null,
    RIGHT = null,
    LEFT = null,
  } = tutorialStepLayout(missionsAssets)[tutorialStep];

  const [renderImage, setImageRender] = useState(IMAGE);

  useEffect(() => {
    setImageRender(IMAGE);
  }, [IMAGE]);

  return (
    <Container activeTutorial={activeTutorial && tutorialName === TUTORIAL_NAME.MISSIONS_TUTORIAL}>
      <SkipButtonContainer onClick={skipTutorial}>
        <SkipButton src={skipAsset} />
      </SkipButtonContainer>

      <Layer
        pointerEvents={tutorialPointerEvents(tutorialStep === MissionTutorialStepsKeys.CHECK_OUT)}
        onClick={handleFeedForwardTutorial}
      >
        {IMAGE === renderImage && <Tooltip src={IMAGE} positionToooltip={TOOLTIP} />}
        {TOP && <ShadowLayer style={TOP}></ShadowLayer>}
        {BOTTOM && <ShadowLayer style={BOTTOM}></ShadowLayer>}
        {RIGHT && <ShadowLayer style={RIGHT}></ShadowLayer>}
        {LEFT && <ShadowLayer style={LEFT}></ShadowLayer>}
      </Layer>
    </Container>
  );
};

export default MissionsTutorialLayer;

const tutorialStepLayout = missionsAssets => [
  {
    IMAGE: missionsAssets?.TUTORIAL_TOOLTIP_STEP_1,
    TOOLTIP: `width: 360px; bottom: 160px; right: calc(50vw - 595px); z-index: 100;`,
  },
  {
    IMAGE: missionsAssets?.TUTORIAL_TOOLTIP_STEP_2,
    TOOLTIP: `width: 490px; top: 160px; right: 340px;`,
    TOP: {
      height: `100vh`,
      width: `100vw`,
      top: 0,
      left: 0,
    },
  },
  {
    IMAGE: missionsAssets?.TUTORIAL_TOOLTIP_STEP_3,
    TOOLTIP: `width: 50vh; top: 68vh; left: 84vh;`,
    TOP: {
      height: `83.6vh`,
      width: `39.6vh`,
      top: `0px`,
      bottom: 0,
      right: 0,
      left: `94.5vh`,
      borderBottomLeftRadius: `2.1vh`,
      borderBottomRightRadius: `2.1vh`,
    },
    BOTTOM: {
      height: `20vh`,
      width: `39.6vh`,
      top: `77px`,
      marginTop: `82vh`,
      bottom: 0,
      right: 0,
      left: `94.5vh`,
      borderBottomColor: '#fff',
      borderBottomWidth: 4,
    },
    RIGHT: { top: 0, bottom: 0, width: `100vw`, left: `134.1vh` },
    LEFT: { top: 0, bottom: 0, width: `94.5vh`, left: 0 },
  },
  {
    IMAGE: missionsAssets?.TUTORIAL_TOOLTIP_STEP_4,
    TOOLTIP: `width: 58vh; top: 68vh; left: 30vh;`,
    TOP: {
      height: `83.6vh`,
      width: `39.6vh`,
      top: `0px`,
      bottom: 0,
      right: 0,
      left: `51vh`,
      borderBottomLeftRadius: `2.1vh`,
      borderBottomRightRadius: `2.1vh`,
    },
    BOTTOM: {
      height: `20vh`,
      width: `39.6vh`,
      top: `77px`,
      marginTop: `82vh`,
      bottom: 0,
      right: 0,
      left: `51vh`,
      borderBottomColor: '#fff',
      borderBottomWidth: 4,
    },
    RIGHT: { top: 0, bottom: 0, width: `100vw`, left: `90.6vh` },
    LEFT: { top: 0, bottom: 0, width: `51vh`, left: 0 },
  },
  {
    IMAGE: missionsAssets?.TUTORIAL_TOOLTIP_STEP_5,
    TOOLTIP: `width: 50vh; top: 59vh; left: 30vh;`,
    TOP: {
      height: `73.1vh`,
      width: `39.6vh`,
      top: `0px`,
      bottom: 0,
      right: 0,
      left: `51vh`,
    },
    BOTTOM: {
      height: `20vh`,
      width: `39.6vh`,
      top: `77px`,
      marginTop: `73.4vh`,
      bottom: 0,
      right: 0,
      left: `51vh`,
      borderBottomColor: '#fff',
      borderBottomWidth: 4,
    },
    RIGHT: { top: 0, bottom: 0, width: `100vw`, left: `90.6vh` },
    LEFT: { top: 0, bottom: 0, width: `51vh`, left: 0 },
  },
  {
    IMAGE: missionsAssets?.TUTORIAL_TOOLTIP_STEP_6,
    TOOLTIP: `width: 50vh; top: 33vh; left: 36vh;`,
  },
  {
    IMAGE: missionsAssets?.TUTORIAL_TOOLTIP_STEP_7,
    TOOLTIP: `width: 50vh; top: 59vh; left: 8vh;`,
    TOP: {
      height: `73.1vh`,
      width: `39.6vh`,
      top: `0px`,
      bottom: 0,
      right: 0,
      left: `8vh`,
    },
    BOTTOM: {
      height: `20vh`,
      width: `39.6vh`,
      top: `77px`,
      marginTop: `73.4vh`,
      bottom: 0,
      right: 0,
      left: `8vh`,
      borderBottomColor: '#fff',
      borderBottomWidth: 4,
    },
    RIGHT: { top: 0, bottom: 0, width: `100vw`, left: `47.6vh` },
    LEFT: { top: 0, bottom: 0, width: `8vh`, left: 0 },
  },
  {
    IMAGE: missionsAssets?.TUTORIAL_TOOLTIP_STEP_8,
    TOOLTIP: `width: 48vh; top: 13vh; left: 52vh;`,
  },
  {
    IMAGE: missionsAssets?.TUTORIAL_TOOLTIP_STEP_9,
    TOOLTIP: `width: 410px; top: 106px; right: calc(50vw - 610px);`,
  },
  {
    IMAGE: missionsAssets?.TUTORIAL_TOOLTIP_STEP_10,
    TOOLTIP: `width: 410px; top: 106px; right: calc(50vw - 610px); z-index: 1000;`,
  },
];

const Container = styled.div`
  position: absolute;
  flex: 1;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: ${({ activeTutorial }) => (activeTutorial ? 'flex' : 'none')};
  z-index: 410;
`;

const Layer = styled.div`
  pointer-events: ${({ pointerEvents }) => pointerEvents};
  position: absolute;
  flex: 1;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

const ShadowLayer = styled.div`
  background-color: rgba(0, 0, 0, 0.45);
  position: absolute;
  z-index: 5;
`;

const SkipButtonContainer = styled.div`
  position: absolute;
  width: 20vh;
  z-index: 10;
  top: 82px;
  left: 2vh;
  cursor: pointer;
`;

const SkipButton = styled.img`
  width: 100%;
  height: 100%;
  z-index: 100;
`;

const Tooltip = styled.img`
  position: absolute;
  z-index: 6;
  ${({ positionToooltip }) => positionToooltip};
`;

