import { connect } from 'react-redux';
import RewardCenter from './RewardCenter';
import { initializeRewardCenter as _initializeRewardCenter, setRewardCenterCurrTab, clearRewardCenterData, setRewardCenterBaseUrl as _setRewardCenterBaseUrl } from '../../state/actions/rewardCenter';
import { clearOnboarding, setOnboardingStep as _setOnboardingStep } from '../../state/actions/currentOnboarding';
import { markOnboardingSeen as _markOnboardingSeen } from '../../state/actions/ftue';
import { setAuthCallbackIntent as _setAuthCallbackIntent } from '../../state/actions/callbackIntent';

var mapStateToProps = function mapStateToProps(state) {
  return {
    rewardCenter: state.rewardCenter,
    assetBaseUrl: state.app.assetBaseUrl,
    loyalty: state.loyalty,
    rewardCenterBaseUrl: state.rewardCenter.rewardCenterBaseUrl,
    propertyId: state.playerInfo.propertyId,
    isAccountLinked: state.playerInfo.isAccountLinked,
    onboardingStep: state.currentOnboarding.currentStep,
    onboardingsSeen: state.ftue.onboardingsSeen[state.playerInfo.playerId] || [],
    isVipRoomUnlocked: state.consumables.isVipRoomUnlocked,
    userProperty: state.properties.data.userProperty,
    enableOrderCard: state.properties.data.enableOrderCard,
    showLinkLoyaltyCardSetting: state.properties.data.showLinkLoyaltyCardSetting
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    setAuthCallbackIntent: function setAuthCallbackIntent(type) {
      dispatch(_setAuthCallbackIntent(type));
    },
    initializeRewardCenter: function initializeRewardCenter(core) {
      dispatch(_initializeRewardCenter(core));
    },
    setActiveTab: function setActiveTab(newTab) {
      dispatch(setRewardCenterCurrTab(newTab));
    },
    clearData: function clearData() {
      dispatch(clearRewardCenterData());
    },
    setRewardCenterBaseUrl: function setRewardCenterBaseUrl(url) {
      dispatch(_setRewardCenterBaseUrl(url));
    },
    setOnboardingStep: function setOnboardingStep(step) {
      dispatch(_setOnboardingStep(step));
    },
    markOnboardingSeen: function markOnboardingSeen(onboardingFlow) {
      dispatch(_markOnboardingSeen(onboardingFlow));
      dispatch(clearOnboarding());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RewardCenter);