import LevelBar from './LevelBar';
import { connect } from '../../node_modules/react-redux';
import { addXp as _addXp } from '../../state/slices/xp/xp';

var nextBoostMultiplier = function nextBoostMultiplier(boostPercentage) {
  var boost = (boostPercentage + 100) / 100;
  return boost % 1 !== 0 && boost < 10 ? parseFloat(boost).toFixed(1).toLocaleString() : parseInt(boost).toLocaleString();
};

var mapStateToProps = function mapStateToProps(state) {
  return {
    // Add state from store
    level: state.levelInfo.level,
    maxLevel: state.levelInfo.max,
    xp: state.xpInfo.xp,
    xpInfo: state.xpInfo,
    nextLevelUpBonusCoins: state.levelInfo.currentLevelBonusCoins,
    nextBoostMultiplier: nextBoostMultiplier(state.levelInfo.nextBoostPercent),
    nextBoostLevel: state.levelInfo.nextBoostLevel,
    nextLevelMaxBet: state.levelInfo.nextLevelMaxBet,
    gameInfo: state.gameInfo
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    // Add action from store
    addXp: function addXp(xp) {
      dispatch(_addXp(xp));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LevelBar);