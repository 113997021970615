import React from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';

const ShowMessageStep = ({ title, serverResponse, onDismiss }) => {
  return (
    <div className="form-container">
      <div className="ModalTitle">{title}</div>
      <div className="ModalBody">{serverResponse}</div>
      <div className="form-confirm-button" onClick={onDismiss}>
        <img src={require(asset`RoundedRect_Primary@2x.png`)} />
        <p className="form-confirm-button-label">{getText(TEXT_KEY.OK)}</p>
      </div>
    </div>
  );
};

export default ShowMessageStep;

