import VIPGames from './VIPGames';
import { connect } from '../../node_modules/react-redux';
import { setGameId as _setGameId } from '../../state/actions/game';
import { setAppBackground as _setAppBackground } from '../../state/actions/app';
import { setFirstTimeVisitVipRoom as _setFirstTimeVisitVipRoom } from '../../state/actions/ftue';

var mapStateToProps = function mapStateToProps(state) {
  return {
    activeConsumables: state.consumables.active,
    favouriteGames: state.favouriteGames,
    ftue: state.ftue,
    gameRooms: state.vipGames,
    lobbyPromotions: state.promotions.lobbyNews,
    showVipAccessModal: state.consumables.showVipAccessModal,
    isVipRoomUnlocked: state.consumables.isVipRoomUnlocked
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    setGameId: function setGameId(gameId) {
      dispatch(_setGameId(gameId));
    },
    setAppBackground: function setAppBackground(background) {
      dispatch(_setAppBackground(background));
    },
    setFirstTimeVisitVipRoom: function setFirstTimeVisitVipRoom(value) {
      dispatch(_setFirstTimeVisitVipRoom(value));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VIPGames);