import FavouriteOnboardingDialogue from './FavouriteOnboardingDialogue';
import { connect } from '../../node_modules/react-redux';
import { setFavouriteGames as _setFavouriteGames } from '../../state/actions/game';

var mapStateToProps = function mapStateToProps(state) {
  return {
    favouriteGames: state.favouriteGames
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    setFavouriteGames: function setFavouriteGames(gameList) {
      dispatch(_setFavouriteGames(gameList));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FavouriteOnboardingDialogue);