import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _handlerMissionProgre;

import { MISSIONS_COUNTER } from '../../screens/Missions/constants';
import SPIN_PHASE from '../../api/GameInterface/helpers/spinPhases';

var handlerSpinXTimes = function handlerSpinXTimes(_ref, counterGoal, percentage) {
  var spinPhase = _ref.spinPhase;
  return spinPhase === SPIN_PHASE.WAGE ? percentage + 100 / counterGoal : percentage;
};

var handlerSpinXTimesYGame = function handlerSpinXTimesYGame(_ref2, counterGoal, percentage, missionGameId) {
  var spinPhase = _ref2.spinPhase,
      gameId = _ref2.gameId;
  return spinPhase === SPIN_PHASE.WAGE && gameId == missionGameId ? percentage + 100 / counterGoal : percentage;
};

var handlerWinXTimes = function handlerWinXTimes(_ref3, counterGoal, percentage) {
  var spinPhase = _ref3.spinPhase;
  return spinPhase === SPIN_PHASE.WIN ? percentage + 100 / counterGoal : percentage;
};

var handlerWinXTimesYGame = function handlerWinXTimesYGame(_ref4, counterGoal, percentage, missionGameId) {
  var spinPhase = _ref4.spinPhase,
      gameId = _ref4.gameId;
  return spinPhase === SPIN_PHASE.WIN && gameId == missionGameId ? percentage + 100 / counterGoal : percentage;
};

var handlerWinXAmount = function handlerWinXAmount(_ref5, counterGoal, percentage) {
  var spinPhase = _ref5.spinPhase,
      amount = _ref5.amount;
  return spinPhase === SPIN_PHASE.WIN ? percentage + amount * 100 / counterGoal : percentage;
};

var handlerWinXAmountYGame = function handlerWinXAmountYGame(_ref6, counterGoal, percentage, missionGameId) {
  var spinPhase = _ref6.spinPhase,
      gameId = _ref6.gameId,
      amount = _ref6.amount;
  return spinPhase === SPIN_PHASE.WIN && gameId == missionGameId ? percentage + amount * 100 / counterGoal : percentage;
};

var handlerWageXAmout = function handlerWageXAmout(_ref7, counterGoal, percentage) {
  var spinPhase = _ref7.spinPhase,
      amount = _ref7.amount;
  return spinPhase === SPIN_PHASE.WAGE ? percentage + amount * 100 / counterGoal : percentage;
};

var handlerMissionProgress = (_handlerMissionProgre = {}, _defineProperty(_handlerMissionProgre, MISSIONS_COUNTER.SPIN_X_TIMES, handlerSpinXTimes), _defineProperty(_handlerMissionProgre, MISSIONS_COUNTER.SPIN_X_TIMES_Y_GAMES, handlerSpinXTimesYGame), _defineProperty(_handlerMissionProgre, MISSIONS_COUNTER.WIN_X_TIMES, handlerWinXTimes), _defineProperty(_handlerMissionProgre, MISSIONS_COUNTER.WIN_X_TIMES_Y_GAMES, handlerWinXTimesYGame), _defineProperty(_handlerMissionProgre, MISSIONS_COUNTER.WIN_X_AMOUNT, handlerWinXAmount), _defineProperty(_handlerMissionProgre, MISSIONS_COUNTER.WIN_X_AMOUNT_Y_GAMES, handlerWinXAmountYGame), _defineProperty(_handlerMissionProgre, MISSIONS_COUNTER.WAGE_X_AMOUNT, handlerWageXAmout), _handlerMissionProgre);
export default handlerMissionProgress;