import styled from 'styled-components';

const windowWidth = window.innerWidth;

const barTotalWidthPx = withCard => (withCard ? (windowWidth < 1400 ? 165 : 224) : 224);
const barTotalHeightPx = withCard => (withCard ? (windowWidth < 1400 ? 32 : 53) : 53);
const fillBarTotalHeightPx = withCard =>
  withCard
    ? windowWidth < 1400
      ? barTotalHeightPx(withCard) + 10
      : barTotalHeightPx(withCard) - 10
    : barTotalHeightPx(withCard) - 10;

const Container = styled.div`
  width: 140px;
`;

const MissionIcon = styled.img`
  ${({ theme }) => theme}
  position: absolute;
  z-index: 1;
`;

const TimeBar = styled.img`
  ${({ theme }) => theme}
  position: absolute;
`;

const BaseBar = styled.img`
  ${({ theme }) => theme}
  width: ${({ withCard }) => barTotalWidthPx(withCard)}px;
  position: absolute;
`;

const FillBar = styled.div`
  ${({ theme }) => theme}
  position: absolute;
  height: ${({ withCard }) => barTotalHeightPx(withCard)}px;
  align-items: center;
  z-index: 0;
`;

const FillProgress = styled.img`
  ${({ theme }) => theme}
  height: ${({ withCard }) => fillBarTotalHeightPx(withCard)}px;
  width: ${props =>
    ((props.percentage === 100 ? 100 : Math.min(props.percentage, 98.5)) * barTotalWidthPx(props.withCard)) / 100}px;
`;

const FillEnd = styled.img`
  ${({ theme }) => theme}
  height: ${({ withCard }) => fillBarTotalHeightPx(withCard)}px;
`;

const TextProgress = styled.p`
  ${({ theme }) => theme}
  position: absolute;
  z-index: 5;
  color: white;
  font-weight: 700;
  font-size: 30px;
  text-shadow: 0 0px 3px #000;
`;

const TimeText = {
  position: 'absolute',
  top: 53,
  color: 'white',
  fontWeight: 700,
  fontSize: 20,
  textShadow: '0 0px 2px #000',
};

const CollectBar = styled.img`
  position: absolute;
  top: 8px;
  ${({ theme }) => theme}
`;

export { Container, MissionIcon, TimeBar, BaseBar, FillBar, FillProgress, FillEnd, TextProgress, TimeText, CollectBar };

