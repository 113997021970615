import FlyingCoinsAnimation from './FlyingCoinsAnimation';
import { connect } from 'shared/node_modules/react-redux';
import { currencyDisplayComponentName } from '../../CurrencyDisplay/CurrencyDisplay';
import { chestComponentName } from '../../BottomBar/BottomBar';

const mapStateToProps = state => {
  return {
    currencyDisplayXPosition: state.layout[currencyDisplayComponentName]?.x,
    currencyDisplayYPosition: state.layout[currencyDisplayComponentName]?.y,
    currencyDisplayWidth: state.layout[currencyDisplayComponentName]?.width,
    currencyDisplayHeight: state.layout[currencyDisplayComponentName]?.height,
    chestXPosition: state.layout[chestComponentName]?.x,
    chestWidth: state.layout[chestComponentName]?.width,
    chestHeight: state.layout[chestComponentName]?.height,
  };
};

export default connect(mapStateToProps, null)(FlyingCoinsAnimation);
