import React from 'react';
import SFormSubtitle from 'shared/screens/LinkAccountForm/components/FormSubtitle';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { numberWithCommas } from 'shared/utils/number';

const TiFormSubtitle = () => {
  return (
    <SFormSubtitle
      render={args => {
        const { linkedLoyaltyCardBonus } = args;
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <p style={{ textAlign: 'center' }}>
              {getText(TEXT_KEY.LINK_CARD_SUBTITLE)} {getText(TEXT_KEY.AND)}
              <span style={{ fontWeight: 'bold' }}>
                {' '}
                {getText(TEXT_KEY.EARN)} {numberWithCommas(linkedLoyaltyCardBonus.amount)}{' '}
                {linkedLoyaltyCardBonus.currency}
              </span>
            </p>
            <img
              src={require(asset`images/Top_Bar_Assets/Coin_Icon@3x.png`)}
              style={{
                pointerEvents: 'none',
                objectFit: 'contain',
                height: '15px',
                width: '15px',
                margin: '5px 0 20px 5px',
              }}
              alt="Coin Icon"
            />
          </div>
        );
      }}
    />
  );
};

export default TiFormSubtitle;

