import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { SET_IS_DOWNLOADING, CLEAR_IS_DOWNLOADING, SET_MAX_DOWNLOADED_GAMES, SET_DOWNLOADED_GAMES, SET_DOWNLOAD_PROGRESS, CLEAR_DOWNLOAD_PROGRESS, REMOVE_DOWNLOADED_GAME, SET_DOWNLOAD_ERROR_MESSAGE, CLEAR_DOWNLOAD_ERROR_MESSAGE, SET_DOWNLOAD_FAILED_GAMES } from '../actions/types';
var initialState = {
  isDownloading: '',
  maxDownloadedGames: '',
  downloadedGames: [],
  downloadProgress: 0,
  downloadErrorMessage: '',
  downloadFailedGames: []
};

var gameDownloadReducer = function gameDownloadReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_IS_DOWNLOADING:
      return _objectSpread(_objectSpread({}, state), {}, {
        isDownloading: action.payload
      });

    case CLEAR_IS_DOWNLOADING:
      return _objectSpread(_objectSpread({}, state), {}, {
        isDownloading: ''
      });

    case SET_MAX_DOWNLOADED_GAMES:
      return _objectSpread(_objectSpread({}, state), {}, {
        maxDownloadedGames: action.payload
      });

    case SET_DOWNLOADED_GAMES:
      return _objectSpread(_objectSpread({}, state), {}, {
        downloadedGames: _objectSpread(_objectSpread({}, state.downloadedGames), {}, _defineProperty({}, action.payload.gameId, action.payload))
      });

    case REMOVE_DOWNLOADED_GAME:
      var downloadedGames = _objectSpread({}, state.downloadedGames);

      delete downloadedGames[action.payload];
      return _objectSpread(_objectSpread({}, state), {}, {
        downloadedGames: downloadedGames
      });

    case SET_DOWNLOAD_PROGRESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        downloadProgress: action.payload
      });

    case CLEAR_DOWNLOAD_PROGRESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        downloadProgress: 0
      });

    case SET_DOWNLOAD_ERROR_MESSAGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        downloadErrorMessage: action.payload
      });

    case CLEAR_DOWNLOAD_ERROR_MESSAGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        downloadErrorMessage: ''
      });

    case SET_DOWNLOAD_FAILED_GAMES:
      return _objectSpread(_objectSpread({}, state), {}, {
        downloadFailedGames: _objectSpread(_objectSpread({}, state.downloadFailedGames), {}, _defineProperty({}, action.payload, action.payload))
      });

    default:
      return state;
  }
};

export default gameDownloadReducer;