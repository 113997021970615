import styled from 'styled-components';
import theme from 'shared/assets/style/theme';

export const MainContainer = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  display: flex;
  width: 100%;
  height: 80px;
  line-height: 80px;
  font-family: Helvetica Neue, sans-serif;
  background: ${theme.palette.common[13]};
  z-index: 9998;
  overflow: hidden;
  justify-content: space-between;
  ${({ theme }) => theme}
`;

export const InnerContainer = styled.div`
  display: flex;
`;

export const CloseButtonWrapper = styled.div`
  display: block;
  width: 30px;
  margin: 20px;
`;

export const LogoWrapper = styled.div`
  display: block;
  width: 100px;
  margin: 20px;
`;

export const StoreLink = styled.a`
  color: ${theme.palette.common[0]};
`;

export const Styles = {
  modalStyle: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: window.matchMedia('(orientation: landscape)').matches ? '0 40vmin' : '0 2vmin',
  },
  modalContainerStyle: {
    height: window.matchMedia('(orientation: landscape)').matches ? '85vh' : '56vh',
    width: '100%',
    marginTop: '-1vw',
    marginBottom: '-1vw',
  },
  imageButtonStyle: {
    objectFit: 'contain',
    height: '100%',
    width: '100%',
  },
  imageButtonInnerStyle: {
    cursor: 'pointer',
  },
};

