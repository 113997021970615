import React, { Component } from 'react';
import SPurchaseFlow from 'shared/screens/PurchaseFlow';
import { Row, Col, Spinner } from 'react-bootstrap';
import { PurchaseTypes } from 'shared/utils/routeParamConstants';
import BillingProfile from './BillingProfile';
import CreditCardDetails from './CreditCardDetails';
import { EVENT_TYPES } from 'shared/lib/analytics/constants';
import Logger from 'shared/lib/analytics/logger';
import WebFinishPurchase from '../../components/WebFinishPurchase';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import { PaymentProviders } from 'shared/utils/paymentProviders';
import PurchaseFlowComponent from './components/PurchaseFlow';
import ThemeContext from 'shared/context/ThemeContext';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import Button from '../../components/Button/Button';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';

/** @typedef {import('shared/api/Constants').BoostPackageItem} Consumable */

export default class PurchaseFlow extends Component {
  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-purchase',
    };
  }

  onSubmit = (nextHandle, validatedData) => {
    nextHandle(true, validatedData);
  };

  submitAddress = (nextHandle, validatedData) => {
    nextHandle(true, validatedData, true);
  };

  getRenderable = (provider, hasOffer, props, stage, validationStage, nextHandle, errorMessage) => {
    const themeContext = this.context;

    if (stage === 1) {
      return (
        <div>
          {provider === PaymentProviders.CAMS && (
            <PurchaseFlowComponent
              onSubmit={this.submitAddress}
              errorMessage={errorMessage}
              props={props}
              hasOffer={hasOffer}
              getPurchaseStrings={this.getPurchaseStrings}
              renderTotal={this.renderTotal}
              paymentProvider={provider}
              onSubmitStar={this.onSubmit}
            />
          )}
          {provider === PaymentProviders.EWAY && (
            <CreditCardDetails
              hasOffer={hasOffer}
              getPurchaseStrings={this.getPurchaseStrings}
              renderTotal={this.renderTotal}
              onSubmit={this.onSubmit}
              paymentProvider={provider}
              {...props}
            />
          )}
        </div>
      );
    } else if (
      stage === 2 &&
      provider === PaymentProviders.CAMS &&
      ENVIRONMENT_VARIABLES.APPLICATION_TARGET !== 'star'
    ) {
      return (
        <div>
          {provider === PaymentProviders.CAMS && (
            <div style={themeContext.PurchaseFlow.CreditCardDetails}>
              <CreditCardDetails
                hasOffer={hasOffer}
                getPurchaseStrings={this.getPurchaseStrings}
                renderTotal={this.renderTotal}
                onSubmit={this.onSubmit}
                paymentProvider={provider}
                {...props}
              />
            </div>
          )}
        </div>
      );
    } else {
      this.onSubmit(nextHandle, validationStage >= stage);
    }
  };

  render() {
    return (
      <SPurchaseFlow
        navigation={this.props.navigation}
        render={props => {
          const {
            stage,
            nextHandle,
            validationStage,
            purchaseType,
            contactSupport,
            gameInfo,
            offerDetail,
            errorMessage,
            shouldShowCustomModal,
          } = props;
          let hasOffer = offerDetail ? true : false;
          let renderable = null;
          const themeContext = this.context;

          switch (stage) {
            case -1:
              renderable = <Spinner />;
              break;
            case 0:
              renderable = (
                <BillingProfile
                  hasOffer={hasOffer}
                  getPurchaseStrings={this.getPurchaseStrings}
                  renderTotal={this.renderTotal}
                  termsAndConditionsURL={this.props.termsAndConditionsURL}
                  {...props}
                />
              );
              break;
            case 1:
              renderable = this.getRenderable(
                this.props.paymentProvider,
                hasOffer,
                props,
                stage,
                validationStage,
                nextHandle,
                errorMessage
              );
              break;
            case 2:
              renderable = this.getRenderable(
                this.props.paymentProvider,
                hasOffer,
                props,
                stage,
                validationStage,
                nextHandle,
                errorMessage
              );
              break;
            case 3:
              renderable = <WebFinishPurchase navigation={this.props.navigation} />;
              break;
            case 5:
              const eventPayload = {
                game_id: gameInfo.gameId,
                game_provider: gameInfo.gameType,
                description: 'Purchase failed',
                code: 10,
              };
              Logger.sendEvent(EVENT_TYPES.ERROR_CREATED, eventPayload);
              renderable = this.renderStage3(contactSupport, themeContext);
              break;
            default:
              renderable = null;
          }

          const title = `${getText(TEXT_KEY.PURCHASE)} ${
            purchaseType === 'currency'
              ? getText(TEXT_KEY.COINS)
              : purchaseType === 'vip'
              ? `${getText(TEXT_KEY.VIP)} ${getText(TEXT_KEY.ACCESS)}`
              : getText(TEXT_KEY.BOOST)
          }`;

          return (
            <div className="form-container">
              {stage < 3 && (
                <div
                  className={`form-title ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}
                  data-label={title.toUpperCase()}
                >
                  <span>{title.toUpperCase()}</span>
                  {!shouldShowCustomModal && (
                    <img
                      src={require(asset`images/Purchase_Flow_Assets/Light_Divider@3x.png`)}
                      alt=""
                      style={{
                        position: 'absolute',
                        width: '100%',
                        top: '100%',
                        pointerEvents: 'none',
                        left: 0,
                      }}
                    />
                  )}
                </div>
              )}
              {renderable}
            </div>
          );
        }}
      />
    );
  }

  getPurchaseStrings = (purchaseType, packageData, hasOffer) => {
    const { promoLink } = this.props;
    let purchaseNameString = '';
    let purchasePriceString = getText(TEXT_KEY.CURRENCY_SIGN);

    switch (purchaseType) {
      case PurchaseTypes.CURRENCY:
        let amount;
        if (promoLink && promoLink.active && !hasOffer) {
          amount = packageData.PROMO_BOOSTED_VALUE;
        } else {
          amount = packageData.AMOUNT;
        }
        purchaseNameString += `${amount} ${getText(TEXT_KEY.COINS)}`;
        purchasePriceString += packageData.PRICE;
        break;
      case PurchaseTypes.VIP:
      case PurchaseTypes.CONSUMABLE:
        purchaseNameString += packageData.name;
        purchasePriceString += packageData.price;
        break;
      default:
    }

    return { purchaseNameString, purchasePriceString };
  };

  renderStage3(contactSupport, themeContext) {
    return (
      <>
        <div className="form-title" data-label={'OOPS'}>
          <span>{'OOPS'}</span>
          <img
            src={require(asset`images/Purchase_Flow_Assets/Light_Divider@3x.png`)}
            alt=""
            style={{
              position: 'absolute',
              width: '100%',
              top: '100%',
              pointerEvents: 'none',
              left: 0,
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img
            style={{ width: '9%', zIndex: 1, margin: '1rem 0' }}
            src={require(asset`images/Purchase_Flow_Assets/Warning_Symbol@3x.png`)}
            alt=""
          />
          <span style={{ fontWeight: 500 }}>{getText(TEXT_KEY.TRANSACTION_COULD_NOT_BE_PROCESSED)}</span>
          <span style={{ fontWeight: 500 }}>
            {getText(TEXT_KEY.NEED_HELP)} {getText(TEXT_KEY.CONTACT_CUSTOMER_SUPPORT)}
          </span>
          <Button
            imageSource={require(asset`RoundedRect_Danger@3x.png`)}
            containerStyle={{ width: '100%', padding: '2rem' }}
            onClick={contactSupport}
            label={getText(TEXT_KEY.CONTACT_BUTTON)}
            textStyle={themeContext.PurchaseFlow.ContactUsButtonTextStyle}
            textStroke={BUTTON_STROKES.DANGER}
          />
        </div>
      </>
    );
  }

  /**
   * @param  {String} item
   * @param  {String} price
   * @param  {import('../../../../shared/api/Constants').PromoCodeReward|String} promoReward
   */
  renderTotal(item, price) {
    return (
      <Row>
        <Col className={'totalContainer'}>
          <div
            className={`gradientText ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}
            data-label={item.toUpperCase()}
            style={{
              lineHeight: 1,
              fontSize: '3vmin',
              fontWeight: '600',
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '3.5vmin',
            }}
          ></div>
          <div className={'price'}>
            <span>{getText(TEXT_KEY.TOTAL)}</span>
            <span>{price}</span>
          </div>
        </Col>
      </Row>
    );
  }
}

PurchaseFlow.contextType = ThemeContext;

