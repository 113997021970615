import ENVIRONMENT_VARIABLES from './environmentVariables';

var gameVersion = function gameVersion() {
  var versionMayor = ENVIRONMENT_VARIABLES.VERSION_MAJOR;
  var versionMin = ENVIRONMENT_VARIABLES.VERSION_MINOR;
  var versionPatch = ENVIRONMENT_VARIABLES.VERSION_PATCH;
  var versionBuild = ENVIRONMENT_VARIABLES.VERSION_BUILD;
  return "".concat(versionMayor, ".").concat(versionMin, ".").concat(versionPatch, "-build.").concat(versionBuild);
};

export default gameVersion;