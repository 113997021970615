import React, { Component } from 'react';
import theme from 'shared/assets/style/theme';
import ThemeContext from 'shared/context/ThemeContext';
import SLinkAccountForm from 'shared/screens/LinkAccountForm';
import { checkIfOtpRequeridedConsents } from 'shared/screens/OneTimePassword/utils';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import ImageBW from '../../components/ImageBoundsWrapper';
import FormButton from './components/FormButton';
import FormFooter from './components/FormFooter';
import FormInputs from './components/FormInputs';
import FormSubtitle from './components/FormSubtitle';
import { styles } from './styles';
import CopySelector from 'shared/utils/copySelector';

const PROPERTY_SELECTOR_CONSTANTS = {
  NO_PROPERTY_SELECTED: '',
  GENERIC_PROPERTY: '1',
};
export default class LinkAccountForm extends Component {
  state = {
    formValues: {
      accountNumber: '',
      year: '',
      month: '',
      day: '',
      propertyId: '1',
      pin: '',
    },
    activeOTPCheckbox: false,
  };

  clearError = null;

  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-link-account',
      hideBeforeSelector: ENVIRONMENT_VARIABLES.APPLICATION_TARGET === 'hri',
    };
  }

  handleLinkBtn = handleLinkCallback => {
    handleLinkCallback(this.state.formValues);
  };

  handleInputChange = key => event => {
    this.clearError();
    this.setState(prevState => ({
      formValues: {
        ...prevState.formValues,
        [key]: event.value !== null ? event.value.toString() : '',
      },
    }));
  };

  handleOPTCheckbox = () => {
    this.setState(prevState => ({
      activeOTPCheckbox: !prevState.activeOTPCheckbox,
    }));
  };

  isPropertySelected = propertyId => {
    return propertyId !== PROPERTY_SELECTOR_CONSTANTS.NO_PROPERTY_SELECTED;
  };

  render() {
    const themeContext = this.context;
    const {
      formValues: { propertyId },
    } = this.state;

    return (
      <SLinkAccountForm
        {...this.props}
        render={args => {
          const {
            handleLinkAccount,
            handlePinAndAccount,
            handleRegisterRewardCard,
            success,
            message,
            error,
            termsAndConditionsUrl,
            propertySelector,
            userProperty,
            setPropertyId,
            clearError,
            enableOrderCard,
            findSelectedPropertyObject,
            copys,
          } = args;

          let propertySelected = setPropertyId(userProperty, propertyId);
          const selectedPropertyObj =
            this.isPropertySelected(propertySelected) && findSelectedPropertyObject(propertySelected);
          this.clearError = clearError;

          return (
            <div style={themeContext.LinkAccountForm.ScreenBackground}>
              <div style={styles.header}>
                <ImageBW
                  source={require(asset`Full_Modal_Header.png`)}
                  ibw={require(asset`Full_Modal_Header.ibw`)}
                  style={themeContext.LinkAccountForm.HeaderImage}
                >
                  <div style={themeContext.LinkAccountForm.HeaderTitle}>
                    <span style={{ textTransform: 'uppercase' }}>
                      {CopySelector.getCopy(CopySelector.COPY_TYPES.LINK_CARD_TITLE, copys)}
                    </span>
                  </div>
                </ImageBW>
              </div>
              <div className="hidenScrollBar" style={themeContext.LinkAccountForm.FormContainer}>
                <FormSubtitle copys={copys} />
                {success && (
                  <p
                    style={{
                      textAlign: 'center',
                      color: theme.palette.success[1],
                    }}
                  >
                    {message}
                  </p>
                )}
                {!success && (
                  <>
                    <FormInputs
                      handleInputChange={this.handleInputChange}
                      formValues={this.state.formValues}
                      copys={copys}
                    />
                    {!success && error && (
                      <p
                        style={{
                          textAlign: 'center',
                          color: theme.palette.error[1],
                          paddingTop: 2,
                        }}
                      >
                        {error}
                      </p>
                    )}
                    <FormFooter
                      termsAndConditionsUrl={termsAndConditionsUrl}
                      enableOrderCard={selectedPropertyObj ? selectedPropertyObj?.enableOrderCard : enableOrderCard}
                      handleRegisterRewardCard={handleRegisterRewardCard}
                      showOTPConsents={
                        selectedPropertyObj?.requiresConsents ||
                        checkIfOtpRequeridedConsents(setPropertyId(userProperty, propertyId), propertySelector)
                      }
                      activeOTPCheckbox={this.state.activeOTPCheckbox}
                      handleOPTCheckbox={this.handleOPTCheckbox}
                      selectedPropertyObj={selectedPropertyObj}
                    />
                    <FormButton
                      handleLinkBtn={this.handleLinkBtn}
                      handleLinkAccount={handleLinkAccount}
                      handlePinAndAccount={handlePinAndAccount}
                      error={error}
                      activeOTPCheckbox={this.state.activeOTPCheckbox}
                      showOTPConsents={
                        selectedPropertyObj?.requiresConsents ||
                        checkIfOtpRequeridedConsents(setPropertyId(userProperty, propertyId), propertySelector)
                      }
                    />
                  </>
                )}
              </div>
            </div>
          );
        }}
      />
    );
  }
}

LinkAccountForm.contextType = ThemeContext;

