import React, { Component } from 'react';

import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import SGenericMessage from 'shared/screens/GenericMessage';
import ImageBW from '../../components/ImageBoundsWrapper';
import { getText, TEXT_KEY } from 'shared/utils/localization';

export default class GenericMessage extends Component {
  state = {};
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <SGenericMessage
        {...this.props}
        render={
          /**
           *  @param {Object} args
           *  @param {String[]} args.apiRootList
           */
          args => {
            const { message, title, centerImage, buttonImage, dividerImage } = args;
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <span
                  style={{
                    textAlign: 'center',
                    fontSize: 'calc(18px + (30 - 18) * ((100vw - 800px) / (1600 - 800)))',
                    display: 'flex',
                    fontWeight: 600,
                  }}
                  className={`genericMessageTitleGradientText ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}
                  data-label={title}
                >
                  {title}
                </span>
                {dividerImage && (
                  <div className={'dividerImageContainer'}>
                    <img
                      src={dividerImage.src}
                      alt="Modal"
                      style={{
                        position: 'absolute',
                        width: '100%',
                        left: '0',
                        pointerEvents: 'none',
                      }}
                    />
                  </div>
                )}
                {centerImage && <img src={centerImage.src} style={{ height: '50%' }} alt="Modal" />}
                <div>{message}</div>
                {buttonImage && (
                  <img src={buttonImage.src} style={{ height: '16%' }} alt="Button" onClick={this.props.dismissModal} />
                )}
                {!buttonImage && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      flexDirection: 'column',
                      position: 'relative',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        bottom: 0,
                        left: 0,
                        right: 0,
                      }}
                      onClick={this.props.dismissModal}
                    >
                      <ImageBW
                        source={require(asset`RoundedRect_Primary@2x.png`)}
                        ibw={require(asset`Confirm_Button.ibw`)}
                        style={{ objectFit: 'contain', width: 200, height: 65 }}
                        innerStyle={{
                          cursor: 'pointer',
                        }}
                      >
                        <div
                          style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <span style={{ fontSize: '1.7rem', fontWeight: '600' }} data-label="OK">
                            {getText(TEXT_KEY.OK)}
                          </span>
                        </div>
                      </ImageBW>
                    </div>
                  </div>
                )}
              </div>
            );
          }
        }
      />
    );
  }
}
