module.exports = {
  dimensions: {
    width: 50,
    height: 59
  },
  optical: {
    top: 3,
    left: 3,
    right: 5,
    bottom: 4
  }
};