import Logger from './logger';
import { FTUE_EVENT_TYPES } from '../../constants';
import { EVENT_ACTIONS, EVENT_DOMAINS, EVENT_SCREENS, EVENT_WIDGETS } from './constants';
export var sendFtueEvent = function sendFtueEvent(eventType, payload) {
  switch (eventType) {
    case FTUE_EVENT_TYPES.START_FTUE_TUTORIAL:
      Logger.sendEvent(FTUE_EVENT_TYPES.START_FTUE_TUTORIAL, {
        action: EVENT_ACTIONS.START,
        domain: EVENT_DOMAINS.GENERAL,
        screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].TUTORIAL,
        widget: null
      });
      break;

    case FTUE_EVENT_TYPES.FTUE_TUTORIAL_NEXT_STEP:
      Logger.sendEvent(FTUE_EVENT_TYPES.FTUE_TUTORIAL_NEXT_STEP, {
        action: EVENT_ACTIONS.PRESS,
        domain: EVENT_DOMAINS.GENERAL,
        screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].TUTORIAL,
        widget: EVENT_WIDGETS[EVENT_DOMAINS.GENERAL].TUTORIAL_NEXT_STEP,
        context_tutorial_step: payload.step
      });
      break;

    case FTUE_EVENT_TYPES.DISPLAY_FTUE_TUTORIAL_STEP:
      Logger.sendEvent(FTUE_EVENT_TYPES.DISPLAY_FTUE_TUTORIAL_STEP, {
        action: EVENT_ACTIONS.DISPLAY,
        domain: EVENT_DOMAINS.GENERAL,
        screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].TUTORIAL,
        widget: null,
        context_tutorial_step: payload.step
      });
      break;

    case FTUE_EVENT_TYPES.FTUE_TUTORIAL_SKIP:
      Logger.sendEvent(FTUE_EVENT_TYPES.FTUE_TUTORIAL_SKIP, {
        action: EVENT_ACTIONS.PRESS,
        domain: EVENT_DOMAINS.GENERAL,
        screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].TUTORIAL,
        widget: EVENT_WIDGETS[EVENT_DOMAINS.GENERAL].TUTORIAL_SKIP,
        context_tutorial_step: payload.step
      });
      break;

    case FTUE_EVENT_TYPES.FTUE_TUTORIAL_COMPLETE:
      Logger.sendEvent(FTUE_EVENT_TYPES.FTUE_TUTORIAL_COMPLETE, {
        action: EVENT_ACTIONS.COMPLETE,
        domain: EVENT_DOMAINS.GENERAL,
        screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].TUTORIAL,
        widget: null,
        context_tutorial_step: payload.step
      });
      break;

    default:
      break;
  }
};