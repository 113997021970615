var formatter = require('format-number');

export var format = formatter({
  prefix: '',
  suffix: '',
  truncate: 0
});
export var messageTypes = {
  COINS: 'COINS',
  PROMO: 'PROMO',
  VIP_PASS: 'VIP_PASS',
  LOYALTY: 'LOYALTY',
  OFFER: 'OFFER',
  GAME: 'GAME',
  SNEAK_PEEK: 'SNEAK_PEEK'
};
export var rewardTypes = {
  VIP_ROOM_ACCESS: 'vip_room_access'
};