import responsiveFontSize from '../../lib/responsiveFontSize';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ImageBW from '../ImageBoundsWrapper';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import TermsModalWrapper from '../TermsModal/TermsModalComponents/TermsModalWrapper';
import ActivityIndicator from '../ActivityIndicator/ActivityIndicator';
import Button from '../Button/Button';
import { EVENT_TYPES } from 'shared/lib/analytics/constants';
import ERROR_CODE from 'shared/utils/errorCodes';
import Logger from 'shared/lib/analytics/logger';

const Star = props => {
  const {
    isVisible,
    closeModal,
    terms,
    setTerms,
    onSubmit,
    termsAndConditionsUrl,
    privacyPolicyUrl,
    isGoogleSignIn,
    googleAuth,
    disableClose,
    hideCloseBtn,
  } = props;
  const nonActiveBG = require(asset`RoundedRect_Disabled@3x.png`);
  const activeCheckbox = require(asset`Checkbox@3x.png`);
  const nonActiveCheckbox = require(asset`Checkbox_Deselected@3x.png`);
  const activeBG = require(asset`RoundedRect_Primary@3x.png`);

  const [isGoogleButtonVisible, setIsGoogleButtonVisible] = useState(false);
  const [isActivityIndicatorVisible, setIsActivityIndicatorVisible] = useState(false);
  const [googleSignInError, setGoogleSignInError] = useState(false);
  const timer = useRef(null);

  const renderGoogleButton = useCallback(() => {
    setIsGoogleButtonVisible(false);

    try {
      terms && setIsActivityIndicatorVisible(true);
      isGoogleSignIn &&
        window.isGsiLoaded &&
        googleAuth &&
        window.google.accounts.id.renderButton(document.getElementById('googleSignInBtn'), {
          theme: 'outline',
          width: 190,
          text: 'signin_with',
          hl: 'en',
          click_listener: () => closeModal(),
        });

      /**
       * delaying button's presentation in order
       * to prevent the user from seeing the
       * google's iframe weird glitches
       */
      timer.current = setTimeout(() => {
        setIsGoogleButtonVisible(true);
        setIsActivityIndicatorVisible(false);
      }, 900);
    } catch (error) {
      setIsActivityIndicatorVisible(false);
      setGoogleSignInError(true);
      Logger.sendEvent(EVENT_TYPES.FRONTEND_ERROR, {
        error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
        code: ERROR_CODE.TC_STAR_GOOGLE_RENDER_BUTTON_ERROR,
      });
    }
  }, [terms]);

  useEffect(() => {
    renderGoogleButton();
    return () => {
      setGoogleSignInError(false);
      clearTimeout(timer.current);
    };
  }, [renderGoogleButton]);

  return (
    <TermsModalWrapper
      isVisible={isVisible}
      closeModal={closeModal}
      title={getText(TEXT_KEY.TERMS_AND_CONDITIONS_TITLE)}
      zIndex={101}
      disableClose={disableClose}
      hideCloseBtn={hideCloseBtn}
    >
      <div className="terms-container">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '90%',
            margin: '1em auto',
          }}
        >
          <div className="accept-terms-container">
            <ImageBW
              source={terms ? activeCheckbox : nonActiveCheckbox}
              ibw={require(asset`Checkbox.ibw`)}
              style={{
                width: 50,
                height: 50,
              }}
              className="checkbox-image"
              onClick={setTerms}
            />
            <p style={{ fontSize: responsiveFontSize(12, 18) }} className="accept-terms-text" onClick={setTerms}>
              {getText(TEXT_KEY.STAR_AGE_AGREEMENT)}{' '}
              <a style={{ textDecoration: 'underline' }} onClick={() => window.open(termsAndConditionsUrl, '_blank')}>
                {getText(TEXT_KEY.TERMS_AND_CONDITIONS)}
              </a>
              {getText(TEXT_KEY.STAR_CONSENT_TO_MARKETING)}
            </p>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <h5>{getText(TEXT_KEY.STAR_PRIVACY_COLLECTION_TITLE)}</h5>
            <p
              style={{
                fontWeight: 300,
                fontSize: responsiveFontSize(10, 15),
              }}
            >
              {getText(TEXT_KEY.STAR_PRIVACY_COLLECTION_BODY)}{' '}
              <a
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={() => window.open(privacyPolicyUrl, '_blank')}
              >
                {getText(TEXT_KEY.PRIVACY_POLICY)}
              </a>
              {getText(TEXT_KEY.STAR_PRIVACY_POLICY_PART_ONE)}{' '}
              <a
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={() => window.open(privacyPolicyUrl, '_blank')}
              >
                {getText(TEXT_KEY.PRIVACY_POLICY)}
              </a>{' '}
              {getText(TEXT_KEY.STAR_PRIVACY_POLICY_PART_TWO)}
            </p>
          </div>
        </div>
      </div>
      <div className="terms-footer">
        {isActivityIndicatorVisible && <ActivityIndicator customStyle={{ top: 'unset', bottom: '20px' }} />}
        {isGoogleSignIn && terms && (
          <div style={{ height: '44px', width: 'auto', opacity: isGoogleButtonVisible ? 1 : 0 }}>
            <div style={{ display: 'flex', justifyContent: 'center' }} id="googleSignInBtn" />
          </div>
        )}
        {isGoogleSignIn && googleSignInError && (
          <div style={{ display: 'flex', justifyContent: 'center', height: '44px', width: 'auto' }}>
            <p style={{ color: 'red' }}>{getText(TEXT_KEY.DEFAULT_ERROR_MESSAGE)}</p>
          </div>
        )}
        {!isGoogleSignIn && (
          <div className="continue-button-container">
            <Button
              imageSource={terms ? activeBG : nonActiveBG}
              imageStyle={{ width: '195px', height: '68px' }}
              containerStyle={{ cursor: 'pointer' }}
              onClick={terms ? onSubmit : null}
              label={getText(TEXT_KEY.CONTINUE_BUTTON)}
            />
          </div>
        )}
      </div>
    </TermsModalWrapper>
  );
};

export default Star;

