import React from 'react';
import Button from '../../../../components/Button/Button';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import { getText, TEXT_KEY } from 'shared/utils/localization';

const TiFormButton = props => {
  const { handleLinkBtn, handlePinAndAccount, error } = props;

  return (
    <Button
      onClick={() => {
        if (!error) handleLinkBtn(handlePinAndAccount);
      }}
      label={getText(TEXT_KEY.LINK)}
      containerStyle={{
        cursor: 'pointer',
        fontSize: '1.7rem',
        fontWeight: '600',
        width: 181,
        height: 59,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        margin: '0 auto',
        marginTop: '20px',
        marginBottom: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      imageSource={require(asset`RoundedRect_Secondary@2x.png`)}
      textStroke={BUTTON_STROKES.SECONDARY}
    />
  );
};

export default TiFormButton;

