import PurchaseSucceeded from './PurchaseSucceeded';
import { connect } from '../../node_modules/react-redux';
import { showVipAccessModal as _showVipAccessModal } from '../../state/actions/consumables';

var mapStateToProps = function mapStateToProps(_ref) {
  var purchaseType = _ref.prePurchase.purchaseType;
  return {
    purchaseType: purchaseType
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    showVipAccessModal: function showVipAccessModal(value) {
      dispatch(_showVipAccessModal(value));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseSucceeded);