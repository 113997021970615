import React, { Component } from 'react';
import AutoForm from '../../components/Form';
import SUpdateEmail from 'shared/screens/Settings/UpdateEmail';
import ImageBW from '../../components/ImageBoundsWrapper';
import { getText, TEXT_KEY } from 'shared/utils/localization';

export default class UpdateEmail extends Component {
  render() {
    const activeBG = require(asset`RoundedRect_Primary@2x.png`);

    const success = (
      <p className="popup-message-text">
        {getText(TEXT_KEY.SUCCESS_RESPONSE)}
        <br />
        {getText(TEXT_KEY.DETAILS_UPDATED)}
      </p>
    );

    const error = (
      <p className="popup-message-text">
        {getText(TEXT_KEY.SOMETHING_WENT_WRONG)}
        <br />
        {getText(TEXT_KEY.TRY_AGAIN_OR_CONTACT_SUPPORT)}
      </p>
    );
    return (
      <SUpdateEmail
        {...this.props}
        navigation={this.props.navigation}
        render={args => {
          const { layout, messageResponse, responseSuccess, updateEmail } = args;

          const { goBackHandler, onShowPopUp, showPopUp, screensEnum } = this.props;

          const showPopUpHandler = success => {
            onShowPopUp(success ? screensEnum.updateEmailSuccess : screensEnum.updateEmailError);
          };

          return (
            <>
              {!showPopUp ? (
                <AutoForm
                  layout={layout}
                  prev={goBackHandler}
                  next={() => {
                    updateEmail(showPopUpHandler);
                  }}
                  serverResponse={messageResponse}
                  customStyle={{
                    position: 'absolute',
                    bottom: '0.8rem',
                    left: '1rem',
                    right: '1rem',
                  }}
                  formCustomContent
                  customConfirmButton
                  hideTopBar
                />
              ) : (
                <div className="popup-content-container">
                  <div />
                  {responseSuccess ? success : error}
                  <div className="done-button-container" onClick={goBackHandler}>
                    <ImageBW
                      source={activeBG}
                      ibw={require(asset`Confirm_Button.ibw`)}
                      style={{ objectFit: 'contain' }}
                      className="continue-button-background-image"
                      innerStyle={{
                        cursor: 'pointer',
                      }}
                    >
                      <div className="done-button-content">
                        <span className="done-button-text" data-label="CONTINUE">
                          {getText(TEXT_KEY.CONTINUE_BUTTON)}
                        </span>
                      </div>
                    </ImageBW>
                  </div>
                </div>
              )}
            </>
          );
        }}
      />
    );
  }
}
