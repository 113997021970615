import React, { Component } from 'react';
import styled from 'styled-components';
import SLivePanel from 'shared/components/LivePanel';
import LottieAnimation from '../LottieAnimation/LottieAnimation';
import { ANIMATION_TYPE } from 'shared/components/LottieAnimation/constants';

export default class LivePanel extends Component {
  render() {
    return (
      <SLivePanel
        totalItems={this.props.totalItems}
        itemData={this.props.itemData}
        navigation={this.props.navigation}
        render={args => {
          const { clickHandle, data, totalItems, specialOffer } = args;
          const itemIndex = this.props.index;
          let paddingTopRatio, maxHeight, verticalRadiusPercentage, alignItems;

          if (totalItems) {
            switch (totalItems) {
              case 1:
                paddingTopRatio = '157.9%';
                maxHeight = '100%';
                verticalRadiusPercentage = '5';
                alignItems = 'center';
                break;
              case 2:
                paddingTopRatio = '75.3%';
                maxHeight = '48%';
                verticalRadiusPercentage = '11';
                alignItems = itemIndex === 0 ? 'flex-end' : 'flex-start';
                break;
              case 3:
                paddingTopRatio = '49%';
                maxHeight = '30%';
                verticalRadiusPercentage = '16';
                alignItems = 'center';
                break;
              default:
                break;
            }
          }

          return (
            <LivePanelWrapper maxHeight={maxHeight} className="live-panel-item span-1-1">
              <ContentContainer alignItems={alignItems} className="contentContainer">
                <PanelHolder className="panelRatio" paddingTopRatio={paddingTopRatio}>
                  <div className="panelContainer" onClick={clickHandle}>
                    <PanelImage verticalRadiusPercentage={verticalRadiusPercentage} src={data.imageUrl} alt="" />
                    {totalItems === 1 && (
                      <LottieAnimation
                        animationType={
                          specialOffer
                            ? ANIMATION_TYPE.PROMO_BANNER_SPECIAL_ANIMATION
                            : ANIMATION_TYPE.PROMO_BANNER_REGULAR_ANIMATION
                        }
                        speed={0.8}
                        startDelay={0}
                        loopDelay={3000}
                        needToReset={false}
                        animationStyle={bannerAnimationStyle}
                      />
                    )}
                  </div>
                </PanelHolder>
              </ContentContainer>
            </LivePanelWrapper>
          );
        }}
      />
    );
  }
}

const LivePanelWrapper = styled.div`
  display: flex !important;
  flex: 0 ${({ maxHeight }) => maxHeight};
  justifycontent: center;
  max-height: ${({ maxHeight }) => maxHeight};
`;

const ContentContainer = styled.div`
  height: auto !important;
  align-items: ${({ alignItems }) => alignItems} !important;
`;

const PanelHolder = styled.div`
  width: 100%;
  padding-top: ${({ paddingTopRatio }) => paddingTopRatio};
`;

const PanelImage = styled.img`
  border-radius: 8% / ${({ verticalRadiusPercentage }) => verticalRadiusPercentage}%;
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 2;
`;

const bannerAnimationStyle = {
  width: '145%',
  height: '167%',
  bottom: '-36%',
  left: '-23%',
  zIndex: 0,
};
