import React, { useContext, useRef } from 'react';
import debounce from 'lodash/debounce';
import useMissionsProgress from 'shared/api/MissionsInterface/hooks/useMissionsProgress';
import ThemeContext from 'shared/context/ThemeContext';
import useMissionsTooltips from 'shared/hooks/useMissionsTooltips';
import { missionFinished } from 'shared/utils/missionProgress/tooltipsDetail';
import { counterTypes, normalizeTimestamp } from 'shared/utils/TimestampManager';
import LottieAnimation from '../LottieAnimation/LottieAnimation';
import { ANIMATION_TYPE } from 'shared/components/LottieAnimation/constants';
import TimeRemainingText from '../TimeRemainingText';
import {
  BaseBar,
  CollectBar,
  Container,
  FillBar,
  FillEnd,
  FillProgress,
  MissionIcon,
  TextProgress,
  TimeBar,
  TimeText,
} from './styledComponent';

const MissionsProgress = props => {
  const { MissionsProgress } = useContext(ThemeContext);
  const mouseOver = useRef(false);
  const missionFinishedTooltipDetail = missionFinished();
  const { missionsAssets, isMissionsScreen, showLinkLoyaltyCardSetting } = props;

  const { clientProgress, serverProgress } = useMissionsProgress(props);
  const { onMissionProgressPressHandler } = useMissionsTooltips({
    ...props,
    missionFinishedTooltipDetail,
  });

  const onMouseEnterHandler = () => {
    if (serverProgress < 100 && !mouseOver.current) {
      mouseOver.current = true;
      onMissionProgressPressHandler();
      setTimeout(() => (mouseOver.current = false), 4500);
    }
  };

  return (
    <Container
      onClick={
        serverProgress < 100
          ? null
          : debounce(() => {
              onMissionProgressPressHandler();
            }, 200)
      }
      onMouseEnter={onMouseEnterHandler}
    >
      <MissionIcon
        theme={showLinkLoyaltyCardSetting ? MissionsProgress.MissionIcon : MissionsProgress.MissionIconWithoutCard}
        src={missionsAssets.TOP_BAR_ICON}
      />
      <BaseBar
        withCard={showLinkLoyaltyCardSetting}
        theme={showLinkLoyaltyCardSetting ? MissionsProgress.BaseBar : MissionsProgress.BaseBarWithoutCard}
        src={missionsAssets.TOP_BAR_BASE}
      />
      <FillBar
        withCard={showLinkLoyaltyCardSetting}
        theme={showLinkLoyaltyCardSetting ? MissionsProgress.FillBar : MissionsProgress.FillBarWithoutCard}
      >
        <TextProgress
          theme={showLinkLoyaltyCardSetting ? MissionsProgress.TextProgress : MissionsProgress.TextProgressWithoutCard}
        >
          {Math.floor(clientProgress)}%
        </TextProgress>
        <FillProgress
          withCard={showLinkLoyaltyCardSetting}
          theme={showLinkLoyaltyCardSetting ? MissionsProgress.FillProgress : MissionsProgress.FillProgressWithoutCard}
          src={missionsAssets.TOP_BAR_FILL}
          percentage={clientProgress}
        />
        <FillEnd
          withCard={showLinkLoyaltyCardSetting}
          theme={showLinkLoyaltyCardSetting ? MissionsProgress.FillEnd : MissionsProgress.FillEndWithoutCard}
          src={missionsAssets.TOP_BAR_SIDE}
        />
        {(serverProgress < 100 || isMissionsScreen) && (
          <LottieAnimation
            animationType={ANIMATION_TYPE.MISSION_PROGRESS_ANIMATION}
            aspectRatioSetting="xMidYMid meet"
            animationStyle={
              showLinkLoyaltyCardSetting
                ? MissionsProgress.ProgressAnimation
                : MissionsProgress.ProgressAnimationWithoutCard
            }
            speed={2}
            startDelay={8500}
            loopDelay={4000}
          />
        )}
      </FillBar>
      <TimeBar
        theme={showLinkLoyaltyCardSetting ? MissionsProgress.TimeBar : MissionsProgress.TimeBarWithoutCard}
        src={missionsAssets.TOP_BAR_TIMER}
      />
      {serverProgress === 100 && !isMissionsScreen && (
        <CollectBar
          theme={showLinkLoyaltyCardSetting ? MissionsProgress.CollectBar : MissionsProgress.CollectBarWithoutCard}
          src={missionsAssets.TOP_BAR_COLLECT}
        />
      )}
      <TimeRemainingText
        counterType={counterTypes.MISSION_CARD}
        style={{
          ...TimeText,
          ...(showLinkLoyaltyCardSetting
            ? { ...MissionsProgress.TimeRemainingText }
            : { ...MissionsProgress.TimeRemainingTextWithoutCard }),
        }}
        until={normalizeTimestamp(props.currentMission.endTimestamp)}
        inNavBar
      />
    </Container>
  );
};

export default MissionsProgress;

