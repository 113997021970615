module.exports = {
  dimensions: {
    width: 333,
    height: 101
  },
  optical: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  nine: {
    top: 50,
    left: 50,
    right: 50,
    bottom: 50
  }
};