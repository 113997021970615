import { connect } from 'react-redux';
import { setGameId as _setGameId } from '../../state/actions/game';
import { setAppBackground as _setAppBackground } from '../../state/actions/app';
import TableGames from './TableGames';

var mapStateToProps = function mapStateToProps(state) {
  return {
    favouriteGames: state.favouriteGames,
    gameRooms: state.tableGames,
    lobbyPromotions: state.promotions.lobbyNews,
    onboardingsSeen: state.ftue.onboardingsSeen[state.playerInfo.playerId] || [],
    onboardingsUnlockLevels: state.ftue.onboardingsUnlockLevels,
    userLevel: state.levelInfo.level
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    setGameId: function setGameId(gameId) {
      dispatch(_setGameId(gameId));
    },
    setAppBackground: function setAppBackground(background) {
      dispatch(_setAppBackground(background));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableGames);