import _isEqual from 'lodash/isEqual';
import _omit from 'lodash/omit';
export var isEqual = function isEqual() {
  return _isEqual.apply(void 0, arguments);
};
export var omit = function omit() {
  return _omit.apply(void 0, arguments);
};
export var isObject = function isObject(value) {
  return typeof value === 'object' && !Array.isArray(value) && value !== null;
};
export var getObjectValues = function getObjectValues(object) {
  return Object.values(object);
};
export var getObjectKeys = function getObjectKeys(object) {
  return Object.keys(object);
};
export var hasObjectValues = function hasObjectValues(object) {
  return Object.values(object).length > 0;
};