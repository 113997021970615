import UpgradeAccountFlow from './UpgradeAccountFlow';
import { connect } from '../../node_modules/react-redux';
import { addNotif as _addNotif } from '../../state/actions/notification';
import { setAuthorized as _setAuthorized, setToken as _setToken, setRestart as _setRestart } from '../../state/actions/auth';
import { setPlayerId as _setPlayerId } from '../../state/actions/playerInfo';
import { setCasinoId as _setCasinoId, setCasinoIdStatus as _setCasinoIdStatus, isAccountLinked as _isAccountLinked, setKYCStatus as _setKYCStatus, setCurrentMemberLevel as _setCurrentMemberLevel } from '../../state/actions/playerInfo';
import { initiatePurchase as _initiatePurchase } from '../../state/actions/purchase';
import { setFormInfo as _setFormInfo } from '../../state/actions/formInfo';
import { setRewardCenterCurrTab } from '../../state/actions/rewardCenter';

var mapStateToProps = function mapStateToProps(state) {
  return {
    // Add state from store
    formInfo: state.formInfo,
    casinoIdStatus: state.playerInfo.casinoIdStatus,
    isKYCDone: state.playerInfo.isKYCDone,
    KYCResult: state.playerInfo.KYCResult,
    KYCInfo: state.playerInfo.KYCInfo
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    // Add action from store
    addNotif: function addNotif(notif) {
      dispatch(_addNotif(notif));
    },
    setPlayerId: function setPlayerId(playerId) {
      dispatch(_setPlayerId(playerId));
    },
    setAuthorized: function setAuthorized(value) {
      dispatch(_setAuthorized(value));
    },
    setToken: function setToken(token) {
      dispatch(_setToken(token));
    },
    setRestart: function setRestart(restart) {
      dispatch(_setRestart(restart));
    },
    setCasinoId: function setCasinoId(id) {
      dispatch(_setCasinoId(id));
    },
    setCasinoIdStatus: function setCasinoIdStatus(status) {
      dispatch(_setCasinoIdStatus(status));
    },
    isAccountLinked: function isAccountLinked(value) {
      dispatch(_isAccountLinked(value));
    },
    setCurrentMemberLevel: function setCurrentMemberLevel(level) {
      dispatch(_setCurrentMemberLevel(level));
    },
    setKYCStatus: function setKYCStatus(status) {
      dispatch(_setKYCStatus(status));
    },
    initiatePurchase: function initiatePurchase() {
      dispatch(_initiatePurchase());
    },
    setActiveTab: function setActiveTab(newTab) {
      dispatch(setRewardCenterCurrTab(newTab));
    },
    setFormInfo: function setFormInfo(formInfo) {
      dispatch(_setFormInfo(formInfo));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeAccountFlow);