import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _tooltipHandlers;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { EVENT_EMITTER_TYPE } from '../../../../constants';
import Emitter from '../../../../services/EmitterService';
import utils from '../../../../utils';
import { ACTION_COMMANDS_EVENT_TYPE, ACTION_COMMANDS_TOOLTIP_TYPE, ACTION_COMMANDS_TYPE } from '../../constants';
import { EVENTS_ACTIONS } from '../../../../screens/Events/constants/dictionary';
import { EVENTS_EVENT_TYPES } from '../../../../screens/Events/constants';
import { sendEventsEvent } from '../../../../screens/Events/analytics/eventsLogger';

var setTooltipCommands = function setTooltipCommands(actionCommand) {
  var _actionCommand$toolti, _actionCommand$toolti2, _actionCommand$toolti3, _actionCommand$toolti4;

  Emitter.emit(EVENT_EMITTER_TYPE.ACTION_COMMANDS, {
    actionType: ACTION_COMMANDS_TYPE.NOTIFY_TRIGGER_PROCESSED_REQUEST,
    data: {
      event: ACTION_COMMANDS_EVENT_TYPE.DISPLAY_APP_TOOLTIP,
      triggerExecution: actionCommand === null || actionCommand === void 0 ? void 0 : (_actionCommand$toolti = actionCommand.tooltip) === null || _actionCommand$toolti === void 0 ? void 0 : (_actionCommand$toolti2 = _actionCommand$toolti.onDisplayAction) === null || _actionCommand$toolti2 === void 0 ? void 0 : _actionCommand$toolti2.triggerExecution,
      payload: actionCommand === null || actionCommand === void 0 ? void 0 : (_actionCommand$toolti3 = actionCommand.tooltip) === null || _actionCommand$toolti3 === void 0 ? void 0 : (_actionCommand$toolti4 = _actionCommand$toolti3.onDisplayAction) === null || _actionCommand$toolti4 === void 0 ? void 0 : _actionCommand$toolti4.payload
    }
  });
  Emitter.emit(EVENT_EMITTER_TYPE.ACTION_COMMANDS, {
    actionType: ACTION_COMMANDS_TYPE.CLEAR_ACTION_COMMAND
  });
};

var notifyEventsTooltip = function notifyEventsTooltip(actionCommand, props) {
  sendEventsEvent(EVENTS_EVENT_TYPES.DISPLAY_EVENTS_TOOLTIP);
  props.postEvents({
    action: EVENTS_ACTIONS.NOTIFY_EVENT_TOOLTIP_DISPLAYED,
    eventId: actionCommand.tooltip.onDisplayAction.eventId,
    tooltipType: actionCommand.tooltip.tooltipType,
    playerId: props.playerId
  });
  Emitter.emit(EVENT_EMITTER_TYPE.ACTION_COMMANDS, {
    actionType: ACTION_COMMANDS_TYPE.CLEAR_ACTION_COMMAND
  });
};

var tooltipHandlers = (_tooltipHandlers = {}, _defineProperty(_tooltipHandlers, ACTION_COMMANDS_TOOLTIP_TYPE.CARD_LINK, setTooltipCommands), _defineProperty(_tooltipHandlers, ACTION_COMMANDS_TOOLTIP_TYPE.REWARD_CENTER, setTooltipCommands), _defineProperty(_tooltipHandlers, ACTION_COMMANDS_TOOLTIP_TYPE.EVENT_ABOUT_TO_END, notifyEventsTooltip), _defineProperty(_tooltipHandlers, ACTION_COMMANDS_TOOLTIP_TYPE.EVENT_COMPLETED, notifyEventsTooltip), _defineProperty(_tooltipHandlers, ACTION_COMMANDS_TOOLTIP_TYPE.EVENT_EXPIRED, notifyEventsTooltip), _tooltipHandlers);
export var handleTooltip = function handleTooltip(details, props, actionCommand) {
  props.setTooltipDetails(details);
  props.shouldShowTooltip(true);
  setTimeout(function () {
    var out = _objectSpread(_objectSpread({}, details), {}, {
      animationState: utils.getPlatform() === 'web' ? 'AnimationFadeOut' : 'out'
    });

    props.setTooltipDetails(out);
  }, 3500);
  setTimeout(function () {
    props.shouldShowTooltip(false);
    tooltipHandlers[actionCommand.tooltip.tooltipType](actionCommand, props);
  }, 4000);
};