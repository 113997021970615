import React, { Component } from 'react';
import SKYCLockout from 'shared/screens/PurchaseFlow/KYCLockout';
import ImageBW from '../../components/ImageBoundsWrapper';
import { onPressHelp } from 'shared/utils/redirectionHelp';

export default class KYCLockout extends Component {
  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-kyc-lockout',
    };
  }

  render() {
    return (
      <SKYCLockout
        navigation={this.props.navigation}
        render={({ title, message, buttonLabel }) => {
          return (
            <>
              <div className="header">
                <ImageBW
                  source={require(asset`Full_Modal_Header@3x.png`)}
                  ibw={require(asset`Full_Modal_Header.ibw`)}
                  style={{ position: 'absolute', width: '100%', height: '100%' }}
                  innerStyle={{}}
                  widthScale={0.5}
                />
                <div className="header-content">
                  <h1 className="title">{title}</h1>
                </div>
              </div>

              <div className="content">
                <img alt="" src={require(asset`images/Purchase_Flow_Assets/Message_Chat_Box@3x.png`)} />

                <p>{message}</p>

                <button className="button-container" onClick={onPressHelp}>
                  <ImageBW
                    source={require(asset`RoundedRect_Primary@2x.png`)}
                    ibw={require(asset`Confirm_Button.ibw`)}
                    style={{}}
                    className="button"
                  >
                    <div className="button-content">
                      <span className="button-text" data-label={buttonLabel}>
                        {buttonLabel}
                      </span>
                    </div>
                  </ImageBW>
                </button>
              </div>
            </>
          );
        }}
      />
    );
  }
}
