export var SET_ASSET_BASE_URL = 'SET_ASSET_BASE_URL';
export var SET_TARGET = 'SET_TARGET';
export var SET_USE_PROD = 'SET_USE_PROD';
export var SET_AD_BLOCKER = 'SET_AD_BLOCKER';
export var SHOW_CUSTOM_MODAL = 'SHOW_CUSTOM_MODAL';
export var SET_ASSET_REFERENCE_COUNT = 'SET_ASSET_REFERENCE_COUNT';
export var MARK_ASSET_LOADED = 'MARK_ASSET_LOADED';
export var SET_INIT_ASSETS_LOADED = 'SET_INIT_ASSETS_LOADED';
export var SET_INIT_TEXTS_LOADED = 'SET_INIT_TEXTS_LOADED';
export var SET_LAST_INIT_APP = 'SET_LAST_INIT_APP';
export var SET_INIT_APP_RESPONSE = 'SET_INIT_APP_RESPONSE';
export var SET_RESTART_SESSION = 'SET_RESTART_SESSION';
export var SET_IS_INIT_APP_EXECUTING = 'SET_IS_INIT_APP_EXECUTING';
export var SET_IS_DIVE_INITIALIZED = 'SET_IS_DIVE_INITIALIZED';
export var SET_NAV_STATE = 'SET_NAV_STATE';
export var HIDE_TIMER_ON_FINISH = 'HIDE_TIMER_ON_FINISH';
export var HIDE_ACTIVE_TRACKER_ON_FINISH = 'HIDE_ACTIVE_TRACKER_ON_FINISH';
export var SET_SIDEBAR_STATE = 'SET_SIDEBAR_STATE';
export var SET_GAME_ID = 'SET_GAME_ID';
export var SET_GAME_TYPE = 'SET_GAME_TYPE';
export var SET_GAME_PROVIDER = 'SET_GAME_PROVIDER';
export var SET_GAME_STATE = 'SET_GAME_STATE';
export var SET_LAST_GAME_VISITED = 'SET_LAST_GAME_VISITED';
export var CLEAR_GAME_INFO = 'CLEAR_GAME_INFO';
export var SET_GAME_INFO = 'SET_GAME_INFO';
/* Modal */

export var SET_MODAL = 'SET_MODAL';
export var SET_MODALS_LOADED = 'SET_MODALS_LOADED';
/* Player Info and Settings */

export var SET_CASINO_ID_STATUS = 'SET_CASINO_ID_STATUS';
export var SET_CASINO_ID = 'SET_CASINO_ID';
export var SET_KYC_STATUS = 'SET_KYC_STATUS';
export var SET_PROPERTY_NAME = 'SET_PROPERTY_NAME';
export var SET_PROPERTY_ID = 'SET_PROPERTY_ID';
export var SET_SOUND_SETTINGS = 'SET_SOUND_SETTINGS';
export var IS_ACCOUNT_LINKED = 'IS_ACCOUNT_LINKED';
export var SET_CURRENT_MEMBER_LEVEL = 'SET_CURRENT_MEMBER_LEVEL';
export var SET_IS_DEV_USER = 'SET_IS_DEV_USER';
export var SET_AFF_ID = 'SET_AFF_ID';
export var SET_RATING_POPUP_TIME = 'SET_RATING_POPUP_TIME';
export var SET_AD_BLOCKER_POPUP_TIME = 'SET_AD_BLOCKER_POPUP_TIME';
export var SET_GAMES_OPENED_AMOUNT = 'SET_GAMES_OPENED_AMOUNT';
export var SET_APPSFLYER_GAMES_OPENED_AMOUNT_CONFIG = 'SET_APPSFLYER_GAMES_OPENED_AMOUNT_CONFIG';
/* Legal Acknowledgement */

export var SET_TERMS_VIEWED = 'SET_TERMS_VIEWED';
/* Player Extras */

export var SET_COIN = 'SET_COIN';
export var ADD_COINS = 'ADD_COINS';
export var SUBTRACT_COINS = 'SUBTRACT_COINS';
export var SET_PREMIUM = 'SET_PREMIUM';
export var SET_LOYALTY = 'SET_LOYALTY';
export var SET_LTO_STATE = 'SET_LTO_STATE';
export var SET_LAST_WIN = 'SET_LAST_WIN';
export var SET_TIME_REMAINING = 'SET_TIME_REMAINING';
export var ADD_LOYALTY = 'ADD_LOYALTY';
/* Auth */

export var SET_TOKEN = 'SET_TOKEN';
export var SET_FB_TOKEN = 'SET_FB_TOKEN';
export var SET_GOOGLE_TOKEN = 'SET_GOOGLE_TOKEN';
export var SET_RESTART = 'SET_RESTART';
export var SET_AUTHORIZED = 'SET_AUTHORIZED';
export var SET_LAST_ACTIVE = 'SET_LAST_ACTIVE';
export var CLEAR_TOKEN = 'CLEAR_TOKEN';
export var CLEAR_GOOGLE_TOKEN = 'CLEAR_GOOGLE_TOKEN';
export var SET_PLAYER_ID = 'SET_PLAYER_ID';
export var UPDATE_LAST_LOGIN_INFO = 'UPDATE_LAST_LOGIN_INFO';
export var CLEAR_LOGIN_INFO = 'CLEAR_LOGIN_INFO';
/* Live Panels */

export var SET_LIVE_PANELS = 'SET_LIVE_PANELS';
/* CDN Base Urls */

export var SET_CDN_BASE_URL = 'SET_CDN_BASE_URL';
/* Game List */

export var SET_NEW_GAMES = 'SET_NEW_GAMES';
export var SET_FEATURED_GAMES = 'SET_FEATURED_GAMES';
export var SET_SLOT_GAMES = 'SET_SLOT_GAMES';
export var SET_TABLE_GAMES = 'SET_TABLE_GAMES';
export var SET_VIP_GAMES = 'SET_VIP_GAMES';
export var SET_FAVOURITE_GAMES = 'SET_FAVOURITE_GAMES';
export var SET_CACHE_ID = 'SET_CACHE_ID';
/* Form */

export var SET_FORM_INFO = 'SET_FORM_INFO';
export var CLEAR_FORM_INFO = 'CLEAR_FORM_INFO';
/* Toast */

export var ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export var REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export var SEND_PUSH_NOTIFICATION = 'SEND_PUSH_NOTIFICATION';
export var REMOVE_PUSH_NOTIFICATION = 'REMOVE_PUSH_NOTIFICATION';
export var UPDATE_MAILBOX_UNREAED_MESSAGE = 'UPDATE_MAILBOX_UNREAED_MESSAGE';
/* Pre Purchase Actions */

export var SET_CARD_NICKNAME = 'SET_CARD_NICKNAME';
export var SET_PRE_PURCHASE_ROUTE = 'SET_PRE_PURCHASE_ROUTE';
export var SET_PRE_PURCHASE_ROUTE_PARAMS = 'SET_PRE_PURCHASE_ROUTE_PARAMS';
export var SET_PROMO_CODE = 'SET_PROMO_CODE';
export var CLEAR_PRE_PURCHASE_STORE = 'CLEAR_PRE_PURCHASE_STORE';
export var SET_PURCHASE_TYPE = 'SET_PURCHASE_TYPE';
export var IS_OUT_OF_COINS_PURCHASE = 'IS_OUT_OF_COINS_PURCHASE';
export var IS_LTO_PURCHASE = 'IS_LTO_PURCHASE';
export var SET_FORM_URL = 'SET_FORM_URL';
export var SET_ACCESS_CODE = 'SET_ACCESS_CODE';
export var SET_TRANSACTION_ID = 'SET_TRANSACTION_ID';
export var SET_PURCHASE_PROFILE = 'SET_PURCHASE_PROFILE';
/* Purchcase Actions */

export var INITIATE_PURCHASE = 'INITIATE_PURCHASE';
export var ASSIGN_TRANSACTION_UUID = 'ASSIGN_TRANSACTION_UUID';
export var ASSIGN_USER_UUID = 'ASSIGN_USER_UUID';
export var INITIALIZE_SESSION_UUID = 'INITIALIZE_SESSION_UUID';
export var SET_ACTION_TRIGGER = 'SET_ACTION_TRIGGER';
export var SET_PURCHASE_OVERLAY = 'SET_PURCHASE_OVERLAY';
/* Shop List Actions */

export var SET_SHOP_LIST_COINS = 'SET_SHOP_LIST_COINS';
export var SET_SHOP_LIST_VIP = 'SET_SHOP_LIST_VIP';
export var SET_USED_PROMO_CODE = 'SET_USED_PROMO_CODE';
export var SET_PAYMENT_PROVIDER = 'SET_PAYMENT_PROVIDER';
export var SET_SALE_AVAILABLE = 'SET_SALE_AVAILABLE';
/* Reward Center Actions */

export var INITIALIZE_REWARD_CENTER = 'INITIALIZE_REWARD_CENTER';
export var SET_OFFER_HISTORY = 'SET_OFFER_HISTORY';
export var ENTER_OFFER_VIEW = 'ENTER_OFFER_VIEW';
export var SET_REWARD_CENTER_CURR_TAB = 'SET_REWARD_CENTER_CURR_TAB';
export var SET_REWARD_CENTER_PREV_TAB = 'SET_REWARD_CENTER_PREV_TAB';
export var SET_OFFER_DETAIL = 'SET_OFFER_DETAIL';
export var PURCHASE_OFFER = 'PURCHASE_OFFER';
export var SET_ACTIVE_HISTORY_ITEM = 'SET_ACTIVE_HISTORY_ITEM';
export var CLEAR_REWARD_CENTER_DATA = 'CLEAR_REWARD_CENTER_DATA';
export var SET_REWARD_CENTER_BASE_URL = 'SET_REWARD_CENTER_BASE_URL';
export var IS_IN_OFFERS_VIEW = 'IS_IN_OFFERS_VIEW';
/* SSO Auth Physically Verification / Link Card */

export var SET_AUTH_CALLBACK_INTENT = 'SET_AUTH_CALLBACK_INTENT';
export var SET_AUTH_CALLBACK_PURCHASE_DATA = 'SET_AUTH_CALLBACK_PURCHASE_DATA';
/* API Root Selection Actions */

export var SET_API_ROOT_INDEX = 'SET_API_ROOT_INDEX';
export var SET_APP_BACKGROUND = 'SET_APP_BACKGROUND';
export var SET_ASSETS_URLS = 'SET_ASSETS_URLS';
export var SET_IS_CONNECTED = 'SET_IS_CONNECTED';
export var SET_CONNECTION_TYPE = 'SET_CONNECTION_TYPE';
export var SAVE_DEVICE_INFO = 'SAVE_DEVICE_INFO';
/* Consumables */

export var SET_ACTIVE_CONSUMABLES = 'SET_ACTIVE_CONSUMABLES';
export var SET_FREE_CONSUMABLES = 'SET_FREE_CONSUMABLES';
export var SHOW_VIP_ACCESS_MODAL = 'SHOW_VIP_ACCESS_MODAL';
export var SET_VIP_ROOM_UNLOCKED = 'SET_VIP_ROOM_UNLOCKED';
export var SET_OUT_OF_COINS_OFFER = 'SET_OUT_OF_COINS_OFFER';
export var SET_LTO_OFFER = 'SET_LTO_OFFER';
export var CLEAR_LTO_OFFER = 'CLEAR_LTO_OFFER';
export var SET_WELCOME_BONUS = 'SET_WELCOME_BONUS';
export var SET_TIMED_BONUS = 'SET_TIMED_BONUS';
export var SET_IS_WELCOME_BONUS_CONSUMED = 'SET_IS_WELCOME_BONUS_CONSUMED';
/* FTUE */

export var SET_FIRST_TIME_VISIT_VIP_ROOM = 'SET_FIRST_TIME_VISIT_VIP_ROOM';
export var SET_USER_RESPONSE = 'SET_USER_RESPONSE';
export var SET_FIRST_TIME_FAVOURITE_HELPER = 'SET_FIRST_TIME_FAVOURITE_HELPER';
export var MARK_ONBOARDING_SEEN = 'MARK_ONBOARDING_SEEN';
export var SET_ONBOARDING_UNLOCK_LEVEL = 'SET_ONBOARDING_UNLOCK_LEVEL';
/* Bet info */

export var SET_BET_INFO = 'SET_BET_INFO';
/* Pending rewards */

export var ADD_PENDING_MESSAGE = 'ADD_PENDING_MESSAGE';
export var SHIFT_PENDING_MESSAGE = 'SHIFT_PENDING_MESSAGE';
export var CLAIM_PENDING_MESSAGE = 'CLAIM_PENDING_MESSAGE';
export var SET_DEFAULT_VALUES_FOR_APPSFLYER_LINKS = 'SET_DEFAULT_VALUES_FOR_APPSFLYER_LINKS';
export var ACTIVATE_PROMOCODE = 'ACTIVATE_PROMOCODE';
export var DISMISS_INVALID_PROMOCODE = 'DISMISS_INVALID_PROMOCODE';
/* Video Ads */

export var SET_VIDEO_ADS_INFO = 'SET_VIDEO_ADS_INFO';
/* Should/Must update */

export var SET_UPDATE_DECLINED = 'SET_UPDATE_DECLINED';
export var SET_FORCE_UPDATE = 'SET_FORCE_UPDATE';
/* Onboarding Layer */

export var SET_ONBOARDING_HIGHLIGHTED_POSITION = 'SET_ONBOARDING_HIGHLIGHTED_POSITION';
export var SET_ONBOARDING_STEP = 'SET_ONBOARDING_STEP';
export var CLEAR_ONBOARDING = 'CLEAR_ONBOARDING';
export var SET_FTUE_STEP = 'SET_FTUE_STEP';
export var SET_FTUE_STATE = 'SET_FTUE_STATE';
export var SET_FTUE_GAME = 'SET_FTUE_GAME';
/* Panels */

export var SET_DEFAULT_PANELS = 'SET_DEFAULT_PANELS';
export var SET_REGULAR_PANELS = 'SET_REGULAR_PANELS';
export var SET_STICKY_PANELS = 'SET_STICKY_PANELS';
/* Promotions (Welcome and Lobby News) */

export var SET_WELCOME_NEWS = 'SET_WELCOME_NEWS';
export var SET_LOBBY_NEWS = 'SET_LOBBY_NEWS';
export var SET_LOBBY_BACKGROUND_PROMO = 'SET_LOBBY_BACKGROUND_PROMO';
/* Tooltip */

export var SHOULD_SHOW_TOOLTIP = 'SHOULD_SHOW_TOOLTIP';
export var TOOLTIP_DETAILS = 'TOOLTIP_DETAILS';
export var SHOW_FEATURE_COMPLETE_TOOLTIP = 'SHOW_FEATURE_COMPLETE_TOOLTIP';
export var SET_CURRENT_SCREEN = 'SET_CURRENT_SCREEN';
/* Available Services */

export var SET_AVAILABLE_SERVICE_STATUS = 'SET_AVAILABLE_SERVICE_STATUS';
/* Audio */

export var PRELOAD_MUSIC = 'PRELOAD_MUSIC';
export var CURRENTLY_PLAYING_MUSIC = 'CURRENTLY_PLAYING_MUSIC';
export var CURRENTLY_PLAYING_SFX = 'CURRENTLY_PLAYING_SFX';
/* App Tracking Transparency Status */

export var SET_ATT_PERMISSION_STATUS = 'SET_ATT_PERMISSION_STATUS';
/* Download */

export var SET_IS_DOWNLOADING = 'SET_IS_DOWNLOADING';
export var CLEAR_IS_DOWNLOADING = 'CLEAR_IS_DOWNLOADING';
export var SET_MAX_DOWNLOADED_GAMES = 'SET_MAX_DOWNLOADED_GAMES';
export var SET_DOWNLOAD_PROGRESS = 'SET_DOWNLOAD_PROGRESS';
export var CLEAR_DOWNLOAD_PROGRESS = 'CLEAR_DOWNLOAD_PROGRESS';
export var SET_DOWNLOADED_GAMES = 'SET_DOWNLOADED_GAMES';
export var REMOVE_DOWNLOADED_GAME = 'REMOVE_DOWNLOADED_GAME';
export var SET_DOWNLOAD_ERROR_MESSAGE = 'SET_DOWNLOAD_ERROR_MESSAGE';
export var CLEAR_DOWNLOAD_ERROR_MESSAGE = 'CLEAR_DOWNLOAD_ERROR_MESSAGE';
export var SET_DOWNLOAD_FAILED_GAMES = 'SET_DOWNLOAD_FAILED_GAMES';
/* Feature Flags */

export var SET_FEATURE_FLAGS = 'SET_FEATURE_FLAGS';
/* Agsi does not send us the bet amount in the spin event
they send a separeted event (stakeUpdate) every time the 
player changes the new bet. Use this to track that new bet */

export var SET_NEW_AGSI_BET = 'SET_NEW_AGSI_BET';
export var SET_FEATURE_MODE_ENABLED = 'SET_FEATURE_MODE_ENABLED';