import React, { Component } from 'react';
import SGameUnavailable from 'shared/screens/Game/GameUnavailable';
import theme from 'shared/assets/style/theme';
import ImageBW from '../../components/ImageBoundsWrapper';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import Button from '../../components/Button/Button';

export default class GameUnavailable extends Component {
  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-gameUnavailable',
    };
  }

  render() {
    return (
      <SGameUnavailable
        navigation={this.props.navigation}
        dismissModal={this.props.dismissModal}
        render={args => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
                height: '100%',
              }}
            >
              <div
                style={{
                  height: '30%',
                  width: '100%',
                  textAlign: 'center',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <span
                  style={{
                    textAlign: 'center',
                    fontSize: '2em',
                    display: 'block',
                    marginTop: '1.2vmin',
                    fontWeight: 600,
                    textShadow: `2px 2px 4px ${theme.palette.common[0]}`,
                  }}
                  className={`modalTitleGradientText ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}
                  data-label={'SORRY!'}
                >
                  {getText(TEXT_KEY.SORRY)}!
                </span>
                <span
                  style={{
                    fontSize: '1.1em',
                    marginTop: '-2.5em',
                    textAlign: 'center',
                  }}
                >
                  {getText(TEXT_KEY.SOMETHING_WENT_WRONG)}
                </span>
              </div>
              <ImageBW
                source={require(asset`Shop_VIP_Divider_Glows@3x.png`)}
                ibw={require(asset`Shop_VIP_Divider_Glows.ibw`)}
                manualAssetScale={3}
                style={{ height: '7vmin', width: '100%', marginTop: '1vmin' }}
                innerStyle={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                  paddingLeft: '1vmin',
                  paddingRight: '1vmin',
                }}
              ></ImageBW>
              <span
                style={{
                  fontSize: '1.2em',
                  marginTop: -30,
                  display: 'block',
                  width: '90%',
                  textAlign: 'center',
                  textShadow: `${theme.palette.primary[16]} 1px 1px 4px`,
                }}
              >
                {getText(TEXT_KEY.GAME_UNAVAILABLE)},
              </span>
              <span
                style={{
                  fontSize: '1.2em',
                  width: '90%',
                  textAlign: 'center',
                  textShadow: `${theme.palette.primary[16]} 1px 1px 4px`,
                }}
              >
                {getText(TEXT_KEY.TRY_AGAIN_LATER)}
              </span>
              <Button
                imageSource={require(asset`RoundedRect_Primary@3x.png`)}
                containerStyle={{
                  height: '25%',
                  marginTop: '1.1em',
                  width: '100%',
                  textAlign: 'center',
                  marginBottom: '1em',
                }}
                textStyle={{
                  paddingTop: '5px',
                }}
                imageStyle={{ width: '23%', height: '100%' }}
                onClick={this.props.dismissModal}
                label={getText(TEXT_KEY.OK)}
              />
            </div>
          );
        }}
      />
    );
  }
}

GameUnavailable.hideCloseButton = true;

