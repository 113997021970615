import { SHOULD_SHOW_TOOLTIP, TOOLTIP_DETAILS, SHOW_FEATURE_COMPLETE_TOOLTIP, SET_CURRENT_SCREEN } from './types';
export var shouldShowTooltip = function shouldShowTooltip(value) {
  return {
    type: SHOULD_SHOW_TOOLTIP,
    payload: value
  };
};
export var tooltipDetails = function tooltipDetails(value) {
  return {
    type: TOOLTIP_DETAILS,
    payload: value
  };
};
export var showFeatureCompleteTooltip = function showFeatureCompleteTooltip(value) {
  return {
    type: SHOW_FEATURE_COMPLETE_TOOLTIP,
    payload: value
  };
};
export var setCurrentScreen = function setCurrentScreen(value) {
  return {
    type: SET_CURRENT_SCREEN,
    payload: value
  };
};