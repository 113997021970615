import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import useLogin from 'shared/screens/Login/useLogin';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import { trimInputString } from 'shared/utils/string';
import ThemeContext from 'shared/context/ThemeContext';
import ActivityIndicator from '../../components/ActivityIndicator/ActivityIndicator';
import CustomScrollBar from '../../components/CustomScrollBar';
import PasswordInput from '../../components/PasswordInput';
import TermsModalWrapper from '../../components/TermsModal/TermsModalComponents/TermsModalWrapper';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import {
  Container,
  ForgotPassword,
  FormBody,
  FormInput,
  LoginButton,
  Password,
  Peeker,
  Status,
  ModalContainer,
} from './styles';

const Login = ({ navigation }) => {
  const { formikValidation, closeModal, openForgotPassword } = useLogin(navigation);

  const { values, errors, status, handleSubmit, setFieldValue, handleBlur, handleChange, isValid } = formikValidation;

  const themeContext = useContext(ThemeContext);

  return ENVIRONMENT_VARIABLES.SSO_ENABLED !== 'true' ? (
    <TermsModalWrapper
      isVisible={true}
      closeModal={closeModal}
      title={getText(TEXT_KEY.LOGIN)}
      zIndex={101}
      modalContainerStyle={ModalContainer}
    >
      <CustomScrollBar customHeight="0px">
        <Container>
          <FormBody>
            <FormInput className="form-input-group">
              <Form.Control
                className="transparentInput"
                placeholder={getText(TEXT_KEY.EMAIL)}
                autoCapitalize="none"
                value={values.email}
                onChange={e => setFieldValue('email', trimInputString(e.target.value))}
                onBlur={handleBlur('email')}
              />
              {errors.email && <Form.Text className="form-input-status">{errors.email}</Form.Text>}
            </FormInput>
            <Password>
              <FormInput className="form-input-group">
                <PasswordInput
                  placeholder={getText(TEXT_KEY.PASSWORD)}
                  value={values.password}
                  onChange={handleChange('password')}
                  onBlur={handleBlur('password')}
                  secureTextEntry={true}
                  customClassName="transparentInput"
                  customPeekerStyle={Peeker}
                />
                {errors.password && <Form.Text className="form-input-status">{errors.password}</Form.Text>}
              </FormInput>
              <ForgotPassword onClick={openForgotPassword}>{getText(TEXT_KEY.FORGOT_PASSWORD)}</ForgotPassword>
            </Password>
          </FormBody>

          {status && <Status>{status}</Status>}

          <LoginButton
            isValid={isValid}
            disabled={!isValid}
            textStroke={BUTTON_STROKES.PRIMARY}
            onClick={handleSubmit}
            label={getText(TEXT_KEY.LOGIN)}
            containerStyle={{ height: '100px' }}
            textStyle={themeContext.Login.LoginButtonText}
          />
        </Container>
      </CustomScrollBar>
    </TermsModalWrapper>
  ) : (
    <ActivityIndicator />
  );
};

export default Login;

