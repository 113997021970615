export default {
  API_ROOT: "api.star.gtpdev.com",
  SSO_ENABLED: "true",
  TITLE: "StarPlay - Play Slots | Free Coins Daily",
  FACEBOOK_PIXEL_BASE: "905511169587580",
  XTREMEPUSH_ID: "MnwnZ6F6GRh5p_8Okxi_iKTuQScAO4mF",
  APPLICATION_TARGET: "star",
  GOOGLE_CLIENT_ID: "802943799479-v6m96h0u5o9e04d37vj7qbrbcoo98p1m.apps.googleusercontent.com",
  GOOGLE_AD_URL: "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js",
  ENVIRONMENT: "dev",
  PRELOAD_ASSETS: "true",
  MOBILE_WEB_ONE_LINK: "https://starplay.onelink.me/Mefp/ed252480",
  HELP_LINK: "starplayhelp.zendesk.com",
  SITE_KEY: "false",
  ACCOUNT_EMAIL: "true",
  ACCOUNT_CHANGE_PASSWORD: "true",
  PLAY_AS_GUEST: "true",
  APPLE_STORE_URL: "https://apps.apple.com/ca/app/starplay/id1383367157",
  ANDROID_STORE_URL: "https://play.google.com/store/apps/details?id=au.com.star.gtp",
  SSO_CALLBACK_PATH: "star_auth_callback",
  DSDK_APP_TOKEN: "343e07c4-612f-41dd-9ec2-f7979227e3f0",
  DSDK_HASH_KEY: "1AC1232AB29A543AFCD4C",
  DSDK_SV_URL: "https://analytics.bluebat.dive.games/star",
  DSDK_API_URL: "https://api-sandbox.bluebat.dive.games",
  DSDK_DOMAIN: "star.gtpdev.com",
  EXPERIAN_ENVIRONMENT_KEY: "3ccd45e9-c8b0-4908-88d9-bf2ce6361ad1",
  SSO_DEV: "false",
  NODE_ENV: "production",
  CDN_ROOT: "star.gtpdev.com",
  APP_ID: undefined,
  VERSION_MAJOR: "2",
  VERSION_MINOR: "5",
  VERSION_PATCH: "3",
  VERSION_BUILD: "1",
  PUBLIC_URL: undefined,
  ZENDESK_URL: "https://starplayhelp.zendesk.com/hc/en-us",
  LINKING_CARD_POPUP_ON_HIT_REWARD_CENTER: undefined,
  SHOW_TIER_TOOLTIP_IF_PHYSICALLY_VERIFIED: undefined,
  SHOW_PROPERTY_SELECTOR: undefined,
  DYNAMIC_HIGHLIMIT_POPUP: "false",
  DYNAMIC_PROPERTY: "false",
  KEYCHAIN_SERVICE_KEY: undefined,
  SHARED_PREFERENCES_KEY: undefined,
  REWARD_CENTER_QR_LAYOUT: "true",
  DIVE_ERROR_HANDLING: "yes" ? "yes" : 'no',
  ENABLE_API_TRACKING: "true",
  GOOGLE_TAG_MANAGER_CONTAINER_ID: "GTM-NSPX2GP",
  APPSFLYER_PATH_PREFIX: undefined,
  LANGUAGE: "en",
  PLAY_AS_GUEST_TYPE: "TEXT",
  ONE_TRUST_DATA_DOMAIN_SCRIPT: undefined
};