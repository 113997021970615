import theme from 'shared/assets/style/theme';
export const styles = {
  inputContainer: {
    flex: 1,
    marginTop: '-0.5em',
    '& input': {
      background: 'rgba(255, 255, 255, 0.1)',
      color: 'white',
      border: '1px solid rgba(255,255,255,0.1)',
    },
  },
};
