import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import AssetPreloader from './AssetPreloader';
import { connect } from '../../node_modules/react-redux';
import { setAssetReferenceCount as _setAssetReferenceCount, markAssetLoaded as _markAssetLoaded, setInitAppResponse as _setInitAppResponse, setInitAssetsLoaded as _setInitAssetsLoaded } from '../../state/actions/load';
import appInitDispatch from '../../lib/load/redux/appInitDispatch';
import { clearToken as _clearToken } from '../../state/actions/auth';

var mapStateToProps = function mapStateToProps(state) {
  return {
    load: state.load,
    token: state.token,
    assetsUrl: state.app.assetsUrls
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return _objectSpread(_objectSpread({}, appInitDispatch(dispatch)), {}, {
    setAssetReferenceCount: function setAssetReferenceCount(count) {
      dispatch(_setAssetReferenceCount(count));
    },
    markAssetLoaded: function markAssetLoaded(asset) {
      dispatch(_markAssetLoaded(asset));
    },
    setInitAssetsLoaded: function setInitAssetsLoaded(response) {
      dispatch(_setInitAssetsLoaded(response));
    },
    setInitAppResponse: function setInitAppResponse(response) {
      dispatch(_setInitAppResponse(response));
    },
    clearToken: function clearToken() {
      dispatch(_clearToken());
    }
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetPreloader);