import React from 'react';
import { isMobile } from 'react-device-detect';
import ImageBW from '../ImageBoundsWrapper';
import LinkToStore from './components/LinkToStore';
import ResetPassword from '../../screens/ResetPassword';
import PlayerInterface from 'shared/api/PlayerInterface';
import QueryParamsProxy from 'shared/utils/queryParamProxy';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { MainContainer, InnerContainer, CloseButtonWrapper, LogoWrapper, Styles } from './styledComponents';

const LINK = ENVIRONMENT_VARIABLES.MOBILE_WEB_ONE_LINK;

export default class SmartBanner extends React.Component {
  state = {
    isShown: true,
    showRecoverPass: {},
  };

  hideBanner = () => {
    this.setState({
      isShown: false,
    });
  };

  componentDidMount() {
    this.checkRecoverPassRequest();
  }

  checkRecoverPassRequest = async () => {
    if (!isMobile) return;
    let urlParams = QueryParamsProxy.getAllParams();
    if (!urlParams) return;
    let id = urlParams.i;
    let token = urlParams.t;
    let platform = urlParams.platform;
    if (id && token && platform) {
      let recoverRequest = await PlayerInterface.recoverPasswordTokenCheck({ id, token });
      if (!recoverRequest.success) {
        this.setState({
          showRecoverPass: {
            visible: true,
            invalid: true,
            message: recoverRequest.error,
          },
        });
      } else {
        this.setState({
          showRecoverPass: {
            visible: true,
            invalid: false,
            id,
            token,
          },
        });
      }
    }
  };

  render() {
    return isMobile && this.state.isShown ? (
      <MainContainer theme={this.props.style}>
        <ResetPassword
          isVisible={this.state.showRecoverPass.visible}
          title={getText(TEXT_KEY.RESET_PASSWORD_TITLE)}
          closeModal={() => this.setState({ showRecoverPass: { ...this.state.showRecoverPass, visible: false } })}
          zIndex={101}
          navigation={this.props.navigation}
          modalStyle={Styles.modalStyle}
          modalContainerStyle={Styles.modalContainerStyle}
          invalid={this.state.showRecoverPass.invalid}
          errorMessage={this.state.showRecoverPass.message}
          recoverId={this.state.showRecoverPass.id}
          recoverToken={this.state.showRecoverPass.token}
        />
        <InnerContainer>
          <CloseButtonWrapper onClick={this.hideBanner}>
            <ImageBW
              source={require(asset`CloseButton@3x.png`)}
              ibw={require(asset`CloseButton.ibw`)}
              manualAssetScale={3}
              style={Styles.imageButtonStyle}
              innerStyle={Styles.imageButtonInnerStyle}
              alt="Close"
            />
          </CloseButtonWrapper>
          <LogoWrapper>
            <ImageBW
              source={require(asset`StarPlayLogo-1.png`)}
              ibw={require(asset`CloseButton.ibw`)}
              manualAssetScale={3}
              style={Styles.imageButtonStyle}
              innerStyle={Styles.imageButtonInnerStyle}
              alt="Close"
            />
          </LogoWrapper>
          <LinkToStore storeLink={LINK} />
        </InnerContainer>
      </MainContainer>
    ) : (
      <div />
    );
  }
}

