import { NavigationActions } from '@react-navigation/core';

var _navigator;

function setTopLevelNavigator(navigatorRef) {
  _navigator = navigatorRef;
}

function navigate(routeName, params) {
  _navigator.dispatch(NavigationActions.navigate({
    routeName: routeName,
    params: params
  }));
}

function getCurrentRoute() {
  return _navigator.state.nav;
}

export default {
  navigate: navigate,
  setTopLevelNavigator: setTopLevelNavigator,
  getCurrentRoute: getCurrentRoute
};