import React, { Component } from 'react';
import SForgotPassword from 'shared/screens/ForgotPassword';
import AutoFormInput from '../../components/AutoFormInput';
import { styles } from './styles';
import theme from 'shared/assets/style/theme';
import TermsModalWrapper from '../../components/TermsModal/TermsModalComponents/TermsModalWrapper';
import CustomScrollBar from '../../components/CustomScrollBar';
import Button from '../../components/Button/Button';
import { getText, TEXT_KEY } from 'shared/utils/localization';

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
    };
  }

  handleInputChange = (event, field) => {
    this.setState({ [field]: event.value });
  };

  render() {
    const { email } = this.state;

    return (
      <SForgotPassword
        {...this.props}
        render={args => {
          const { handleSubmit, success, error, handleCancel } = args;
          return (
            <TermsModalWrapper
              isVisible={true}
              closeModal={handleCancel}
              title={getText(TEXT_KEY.FORGOT_PASSWORD_TITLE)}
              zIndex={101}
              bodyStyle={styles.modalBodyStyle}
            >
              <CustomScrollBar>
                <div style={styles.modal}>
                  <p style={{ textAlign: 'center', marginTop: 20 }}>{getText(TEXT_KEY.ENTER_YOUR_REGISTERED_EMAIL)} </p>

                  {!success && error && (
                    <p
                      style={{
                        textAlign: 'center',
                        color: theme.palette.error[1],
                      }}
                    >
                      {error}
                    </p>
                  )}

                  {success && (
                    <p
                      style={{
                        textAlign: 'center',
                        color: theme.palette.success[1],
                      }}
                    >
                      {getText(TEXT_KEY.ACCOUNT_HAS_BEEN_LINKED)}
                    </p>
                  )}

                  {!success && (
                    <div style={styles.bodyContainer}>
                      <AutoFormInput
                        customClassName="transparentInput"
                        emitInput={e => this.handleInputChange(e, 'email')}
                        value={email}
                        label="Email"
                        type="text"
                        containerStyle={styles.inputContainer}
                        trimInput={true}
                      />
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Button
                          imageSource={require(asset`RoundedRect_Primary@2x.png`)}
                          containerStyle={{ marginTop: 20 }}
                          label={getText(TEXT_KEY.SUBMIT)}
                          onClick={() => handleSubmit({ email })}
                        />
                        <Button
                          imageSource={require(asset`RoundedRect_Danger@3x.png`)}
                          containerStyle={{ marginTop: 20 }}
                          label={getText(TEXT_KEY.CANCEL)}
                          onClick={handleCancel}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </CustomScrollBar>
            </TermsModalWrapper>
          );
        }}
      />
    );
  }
}

