module.exports = {
  dimensions: {
    width: 182,
    height: 182
  },
  optical: {
    top: 0,
    left: 24,
    right: 24,
    bottom: 43
  },
  nine: {
    top: 25,
    left: 51,
    right: 51,
    bottom: 76
  },
  content: {
    top: 8,
    left: 32,
    right: 32,
    bottom: 56
  }
};