import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Animated from 'animated/lib/targets/react-dom';
import SLinkLoyaltyCard from 'shared/screens/LinkLoyaltyCard';
import ImageBW from '../../components/ImageBoundsWrapper';
import FlowStep from './components';

export default class LinkLoyaltyCard extends Component {
  render() {
    return (
      <SLinkLoyaltyCard
        navigation={this.props.navigation}
        render={props => {
          if (props.flowStep === SLinkLoyaltyCard.FLOW_STEPS.LOADING) {
            return (
              <div className="ActivityIndicator">
                <div className="ActivityItem" />
                <div className="ActivityItem" />
                <div className="ActivityItem" />
              </div>
            );
          }

          return (
            <Modal show>
              <div className={'ModalBackground'}>
                <ImageBW
                  className="ModalBackgroundAsset"
                  as={Animated.div}
                  source={require(asset`Dialog.png`)}
                  ibw={require(asset`Dialog.ibw`)}
                />
              </div>

              <div className="ModalCloseButton" onClick={props.onDismiss}>
                <ImageBW
                  source={require(asset`CloseButton@3x.png`)}
                  ibw={require(asset`CloseButton.ibw`)}
                  manualAssetScale={3}
                  style={{
                    objectFit: 'contain',
                    height: '100%',
                    width: '100%',
                  }}
                  innerStyle={{
                    cursor: 'pointer',
                  }}
                  alt="Close"
                />
              </div>
              <Modal.Body>
                <FlowStep {...props} />
              </Modal.Body>
            </Modal>
          );
        }}
      />
    );
  }
}
