import React from 'react';
import Animated from 'animated/lib/targets/react-dom';
import ImageBW from '../ImageBoundsWrapper';
import Utils from 'shared/utils/index';

export default function DefaultTooltip({ props }) {
  const {
    displayText,
    iconType,
    children,
    arrowStyle,
    containerStyle,
    source,
    actionHandler,
    ibw,
    animationClassName,
    right,
    style,
    bottom,
    displayTextStyle,
    innerStyle,
  } = props;

  return (
    <Animated.div
      className={`Tooltip-Container ${animationClassName ? animationClassName : 'AnimationFadeIn'}`}
      style={{
        ...style,
      }}
    >
      {!bottom && (
        <ImageBW
          source={require(asset`FlyOut_Triangle@3x.png`)}
          ibw={require(asset`FlyOut_Triangle.ibw`)}
          manualAssetScale={3}
          className="Arrow"
          style={(arrowStyle && arrowStyle) || {}}
        />
      )}

      <ImageBW
        source={require(asset`FlyOut_Backing_9Slice@3x.png`)}
        ibw={require(asset`FlyOut_Backing_9Slice.ibw`)}
        manualAssetScale={3}
        className="Container"
        style={(containerStyle && containerStyle) || {}}
        onClick={actionHandler}
        innerStyle={innerStyle}
      >
        {children ? (
          children
        ) : (
          <div className="Tooltip-Label">
            {ibw && <ImageBW source={source} ibw={ibw} style={{}} className={`Icon ${iconType}`} />}
            <div className="Label" style={displayTextStyle}>
              {displayText}
            </div>
          </div>
        )}
        {bottom && (
          <ImageBW
            source={require(asset`FlyOut_Triangle@3x.png`)}
            ibw={require(asset`FlyOut_Triangle.ibw`)}
            manualAssetScale={3}
            className="Arrow"
            style={{
              transform: 'rotate(180deg)',
              top: Utils.ieVersion() !== 0 ? '6.5rem' : '5.7rem',
              right: right ? '2rem' : '10rem',
            }}
          />
        )}
      </ImageBW>
    </Animated.div>
  );
}

